import React from 'react';

import { InfoCircleOutlined } from '@ant-design/icons';
import clsx from 'clsx';

export interface MonetizationTabsProps {
  title: string;
  subTitle: string;
  isActive: boolean;
  id: string;
  handleSetActive: (id: string) => void;
}

const MonetizationTabs = ({ title, subTitle, isActive, handleSetActive, id }: MonetizationTabsProps) => {
  return (
    <div
      className={clsx('h-full w-fit cursor-pointer px-2')}
      onClick={() => {
        handleSetActive(id);
      }}
    >
      <h4 className={clsx('mb-4 text-20 font-semibold text-[#141414] lg:text-[24px]', isActive && 'text-secondary')}>
        {title}
      </h4>
      <div
        className={clsx(
          'inline-block whitespace-nowrap text-14 font-semibold text-grey lg:text-16',
          isActive && 'text-secondary',
        )}
      >
        {subTitle} <InfoCircleOutlined className="ml-1" />
      </div>
      {isActive && <div className="mt-3 h-[3px] w-full rounded-[27px] bg-secondary"></div>}
    </div>
  );
};

export default MonetizationTabs;
