import { useQuery } from '@tanstack/react-query';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import useSessionStorage from 'hooks/useSessionStorage';

import withPermission from 'hocs/withPermission';

import userApi, { IUser } from 'config/api/user/userApi';
import { SECONDARY_COLOR } from 'config/constants/theme';
import dateHelper from 'config/helpers/dateHelper';

import NotFound from 'pages/exception/NotFound';

import UserSettingPermissionForm from 'components/feature/user-setting/UserSettingPermissionForm';
import UserSettingTabPane from 'components/feature/user-setting/UserSettingTabPane';
import BackBtn from 'components/shared/BackBtn';
import Loading from 'components/shared/Loading';

import { ConfigProvider, Tabs } from 'antd';
import type { TabsProps } from 'antd';

const { getUserDetailByEmail } = userApi;
const { formatText } = dateHelper;

const tabItems: TabsProps['items'] = [
  {
    key: 'app',
    label: 'App permissions',
  },
  {
    key: 'account',
    label: 'Account permissions',
  },
];

const UserSettingEditPage = () => {
  const { email } = useParams<{ email: string }>();
  const [tabActive, setTabActive] = useSessionStorage<'app' | 'account'>('setting/tab-active', 'app');

  const { data, isLoading, isError, refetch } = useQuery(
    [email],
    async () => {
      if (!email) return Promise.reject('Email is required');
      const addUser = sessionStorage.getItem('addUserEmail');
      if (email === addUser) {
        return { email: addUser } as unknown as IUser;
      }

      const user = await getUserDetailByEmail(email);
      if (!user) return Promise.reject('User not found');

      return user as unknown as IUser;
    },
    {
      refetchOnWindowFocus: true,
    },
  );

  const tabPane = useMemo(() => {
    switch (tabActive) {
      case 'app':
        return data && <UserSettingTabPane triggerRefresh={refetch} userData={data} />;
      case 'account':
        return data && <UserSettingPermissionForm triggerRefresh={refetch} isGame={false} data={data} />;
      default:
        return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, tabActive]);

  if (isError) return <NotFound />;

  if (isLoading)
    return (
      <div className="flex min-h-[64vh] items-center justify-center">
        <Loading />
      </div>
    );

  return (
    <div className="page-container">
      <BackBtn text="Users and permissions" />
      <h3 className="page-section-title">{data?.email}</h3>
      <p className="page-section-subtitle">
        Signed In : {data?.lastLogin ? formatText(new Date(data?.lastLogin)) : ''}
      </p>

      <ConfigProvider
        theme={{
          token: {
            colorPrimary: SECONDARY_COLOR,
          },
        }}
      >
        <Tabs
          items={tabItems}
          activeKey={tabActive}
          onChange={(activeKey) => setTabActive(activeKey as 'app' | 'account')}
        />
        {tabPane}
      </ConfigProvider>
    </div>
  );
};

export default withPermission(UserSettingEditPage);
