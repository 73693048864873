import { DatePicker } from 'antd';
import { Dayjs } from 'dayjs';


interface ISingleDateProps {
  value: Dayjs | null;
  onValueChanged: (newValue: Dayjs | null) => void;
  title?: string;
}

const SingleDate = ({ value, onValueChanged, title = 'Select Date' }: ISingleDateProps) => {
  return (
    <div>
      <h6 className="mb-2 text-16 font-medium">{title}</h6>
      <DatePicker
        value={value}
        onChange={onValueChanged}
      />
    </div>
  );
};

export default SingleDate;