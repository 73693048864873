import React from 'react';

import ForbiddenImg from 'content/assets/images/forbidden.png';

import useDocumentTitle from 'hooks/useDocumentTitle';

const Forbidden = () => {
  useDocumentTitle('Forbidden');
  return (
    <div className="flex h-full w-full items-center justify-center">
      <img src={ForbiddenImg} alt="Forbidden" className="w-80 max-w-[75%]" />
    </div>
  );
};

export default Forbidden;
