import { useQuery } from '@tanstack/react-query';
import React, { useMemo } from 'react';
import LayoutContextConsumer from 'contexts/LayoutContext';
import RawDataTable from 'components/feature/charts/RawDataTable';
import reportApi from 'config/api/bigquery/reportApi';
import Loading from 'components/shared/Loading';
import ComingSoonPage from 'pages/main/ComingSoonPage';
import { IReportFilter } from '../../../config/types/reports';

interface Props {
  filter: IReportFilter;
}

type TReport = {
  date_str: string;
  a1: number;
  n1: number;
  rr1: number;
  revenue_ads1: number;
  revenue_iap1: number;
  revenue_total1: number;
  pu_ads1: number;
  pu_iap1: number;
  pu_total1: number;
  new_pu_iap1: number;
  arppu_ads1: number;
  arppu_iap1: number;
  cr_ads1: number;
  cr_iap1: number;
  acu: number;
  pcu: number;
  a7: number;
  n7: number;
  revenue_ads7: number;
  revenue_iap7: number;
  revenue_total7: number;
  pu_ads7: number;
  pu_iap7: number;
  pu_total7: number;
  new_pu_iap7: number;
  a30: number;
  n30: number;
  revenue_ads30: number;
  revenue_iap30: number;
  revenue_total30: number;
  pu_ads30: number;
  pu_iap30: number;
  pu_total30: number;
  new_pu_iap30: number;
};

const DailyReport = ({ filter }: Props) => {
  const { currentApp } = LayoutContextConsumer();
  const isValidGame = ['SkyDancer2', 'DaymareZero'].includes(currentApp?.id ?? '');

  const { data: todayData, isLoading: todayLoading } = useQuery({
    queryKey: ['daily-report', filter.singleDate?.format('YYYY-MM-DD')],
    queryFn: async () => {
      if (!filter || !filter.singleDate || filter.isFirstLoad === true || isValidGame === false) {
        return [];
      }
      const res = await reportApi.getReportTemplate({
        params: {
          date: filter.singleDate?.format('YYYY-MM-DD'),
        },
      });
      return res as unknown as TReport[];
    },
  });

  const { data: yesterdayData, isLoading: yesterdayLoading } = useQuery({
    queryKey: ['daily-report', filter.singleDate?.subtract(1, 'day').format('YYYY-MM-DD')],
    queryFn: async () => {
      if (!filter || !filter.singleDate || filter.isFirstLoad === true || isValidGame === false) {
        return [];
      }
      const res = await reportApi.getReportTemplate({
        params: {
          date: filter.singleDate?.subtract(1, 'day').format('YYYY-MM-DD'),
        },
      });
      return res as unknown as TReport[];
    },
  });

  const { data: sevenDaysAgoData, isLoading: sevenDaysAgoLoading } = useQuery({
    queryKey: ['daily-report', filter.singleDate?.subtract(7, 'day').format('YYYY-MM-DD')],
    queryFn: async () => {
      if (!filter || !filter.singleDate || filter.isFirstLoad === true || isValidGame === false) {
        return [];
      }
      const res = await reportApi.getReportTemplate({
        params: {
          date: filter.singleDate?.subtract(7, 'day').format('YYYY-MM-DD'),
        },
      });
      return res as unknown as TReport[];
    },
  });

  const { data: thirtyDaysAgoData, isLoading: thirtyDaysAgoLoading } = useQuery({
    queryKey: ['daily-report', filter.singleDate?.subtract(30, 'day').format('YYYY-MM-DD')],
    queryFn: async () => {
      if (!filter || !filter.singleDate || filter.isFirstLoad === true || isValidGame === false) {
        return [];
      }
      const res = await reportApi.getReportTemplate({
        params: {
          date: filter.singleDate?.subtract(30, 'day').format('YYYY-MM-DD'),
        },
      });
      return res as unknown as TReport[];
    },
  });

  const tableData = useMemo(() => {
    const compare2Days = (day1?: TReport, day2?: TReport) => {
      if (!day1 || !day2) {
        return undefined;
      }
      const keys = Object.keys(day1) as (keyof TReport)[];
      return keys.reduce((acc, key) => {
        const value1 = day1[key];
        const value2 = day2[key];
        if (typeof value1 === 'number' && typeof value2 === 'number') {
          const value = (value1 - value2) / value2 * 100;
          acc[key] = <span className={value > 0 ? "text-blue" : "text-red-500"}>{value.toFixed(2)}%</span>;
        } else {
          acc[key] = '-';
        }
        return acc;
      }, {} as any) as TReport;
    }

    const dates = [
      todayData?.[0],
      yesterdayData?.[0],
      sevenDaysAgoData?.[0],
      thirtyDaysAgoData?.[0],
      compare2Days(todayData?.[0], yesterdayData?.[0]),
      compare2Days(todayData?.[0], sevenDaysAgoData?.[0]),
      compare2Days(todayData?.[0], thirtyDaysAgoData?.[0])
    ];

    const reportTemplate = [
      [
        '',
        (<span className='font-semibold'>{filter.singleDate?.format('YYYY-MM-DD') ?? '(Date)'}</span>),
        (<span className='font-semibold'>1 day ago</span>),
        (<span className='font-semibold'>7 days ago</span>),
        (<span className='font-semibold'>30 days ago</span>),
        (<span className='font-semibold'>%<br />1 day ago</span>),
        (<span className='font-semibold'>%<br />7 days ago</span>),
        (<span className='font-semibold'>%<br />30 days ago</span>),
        (<span className='font-semibold'>Description</span>)
      ],
      ['A1', ...dates.map((date) => date?.a1 ?? "-"), 'Active User'],
      ['N1', ...dates.map((date) => date?.n1 ?? "-"), 'New Login (Register) User'],
      [
        'RR1',
        ...dates.map((date) => (typeof date?.rr1 === "number") ? <span>{date?.rr1 + "%"}</span> : date?.rr1 ?? "-"),
        'New User Retention Rate'
      ],
      ['Ads Revenue in 1 day', ...dates.map((date) => date?.revenue_ads1 ?? "-"), 'Ads Revenue'],
      ['IAP Revenue in 1 day', ...dates.map((date) => date?.revenue_iap1 ?? "-"), 'IAP Revenue'],
      ['Total Revenue in 1 day', ...dates.map((date) => date?.revenue_total1 ?? "-"), 'Total Revenue'],
      ['Ads PU1', ...dates.map((date) => date?.pu_ads1 ?? "-"), 'Ads Paying User'],
      ['IAP PU1', ...dates.map((date) => date?.pu_iap1 ?? "-"), 'IAP Paying User'],
      ['Both PU1', ...dates.map((date) => date?.pu_total1 ?? "-"), 'Total Paying User'],
      ['New IAP PU1', ...dates.map((date) => date?.new_pu_iap1 ?? "-"), 'First Charge User'],
      ['ARPPU1 Ads', ...dates.map((date) => date?.arppu_ads1 ?? "-"), 'Average Revenue Per Ads Paying User'],
      ['ARPPU1 IAP', ...dates.map((date) => date?.arppu_iap1 ?? "-"), 'Average Revenue Per IAP Paying User'],
      ['Conversion Rate Ads 1 day(%)', ...dates.map((date) => (typeof date?.cr_ads1 === "number") ? <span>{date?.cr_ads1 + "%"}</span> : date?.cr_ads1 ?? "-"), 'Conversion Ads Rate'],
      ['Conversion Rate IAP 1 day(%)', ...dates.map((date) => (typeof date?.cr_iap1 === "number") ? <span>{date?.cr_iap1 + "%"}</span> : date?.cr_iap1 ?? "-"), 'Conversion IAP Rate'],

      ['ACU', ...dates.map((date) => date?.acu ?? "-"), 'Average Of Concurrent Users'],
      ['PCU', ...dates.map((date) => date?.pcu ?? "-"), 'Peak Concurrent Users'],

      ['A7', ...dates.map((date) => date?.a7 ?? "-"), 'Active User'],
      ['N7', ...dates.map((date) => date?.n7 ?? "-"), 'New Login (Register) User'],
      ['Ads Revenue in 7 days', ...dates.map((date) => date?.revenue_ads7 ?? "-"), 'Revenue'],
      ['IAP Revenue in 7 days', ...dates.map((date) => date?.revenue_iap7 ?? "-"), 'Revenue'],
      ['Total Revenue in 7 days', ...dates.map((date) => date?.revenue_total7 ?? "-"), 'Revenue'],
      ['Ads PU7', ...dates.map((date) => date?.pu_ads7 ?? "-"), 'Paying User'],
      ['IAP PU7', ...dates.map((date) => date?.pu_iap7 ?? "-"), 'Paying User'],
      ['Both PU7', ...dates.map((date) => date?.pu_total7 ?? "-"), 'Paying User'],
      ['New IAP PU7', ...dates.map((date) => date?.new_pu_iap7 ?? "-"), 'First Charge User'],

      ['A30', ...dates.map((date) => date?.a30 ?? "-"), 'Active User'],
      ['N30', ...dates.map((date) => date?.n30 ?? "-"), 'New Login (Register) User'],
      ['Ads PU30', ...dates.map((date) => date?.pu_ads30 ?? "-"), 'Paying User'],
      ['IAP PU30', ...dates.map((date) => date?.pu_iap30 ?? "-"), 'Paying User'],
      ['Both PU30', ...dates.map((date) => date?.pu_total30 ?? "-"), 'Paying User'],
      ['New IAP PU30', ...dates.map((date) => date?.new_pu_iap30 ?? "-"), 'First Charge User'],
      ['Ads Revenue in 30 days', ...dates.map((date) => date?.revenue_ads30 ?? "-"), 'Revenue'],
      ['IAP Revenue in 30 days', ...dates.map((date) => date?.revenue_iap30 ?? "-"), 'Revenue'],
      ['Total Revenue in 30 days', ...dates.map((date) => date?.revenue_total30 ?? "-"), 'Revenue'],
    ];

    return reportTemplate;
  }, [todayData, yesterdayData, sevenDaysAgoData, thirtyDaysAgoData]);

  const isLoading = (todayLoading || yesterdayLoading || sevenDaysAgoLoading || thirtyDaysAgoLoading);

  if (isValidGame === false) {
    return <ComingSoonPage />;
  }

  return (
    <div className="pb-4">
      {isLoading && <Loading />}
      {tableData && (
        <RawDataTable
          rawData={tableData.map((row) => row.map((cell) => typeof cell === 'object' ? (cell as JSX.Element).props.children : cell))}
          title="Report"
          headers={[]}
          data={tableData}
        />
      )}
    </div>
  );
};

export default DailyReport;
