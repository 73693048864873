import React from 'react';

import useDocumentTitle from 'hooks/useDocumentTitle';

import withPermission from 'hocs/withPermission';

import ComingSoonPage from 'pages/main/ComingSoonPage';

const SettingPage = () => {
  useDocumentTitle('Project Setting');

  return <ComingSoonPage />;
};

export default withPermission(SettingPage);
