import React from 'react';
import { Navigate } from 'react-router-dom';

const HomePage = () => {
  const id = sessionStorage.getItem('currentApp');

  return <Navigate to={id ? '/overview' : '/apps'} replace />;
};

export default HomePage;
