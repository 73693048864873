import React from 'react';

interface ISkeleton {
  height: number;
}

const Skeleton = ({ height }: ISkeleton) => {
  return <div className={`w-full animate-pulse bg-gray-100 dark:bg-gray-300`} style={{ height }}></div>;
};

export default Skeleton;
