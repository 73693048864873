import resourceEnum from 'config/constants/resource';

import api from '..';

export interface IApp {
  id: string;
  name: string;
  icon?: string;
  status: boolean;
  appstore?: string;
  ggplay?: string;
  domain?: string;
}

export interface IAppResponse {
  games: IApp[];
  resources: {
    [key: string]: resourceEnum[];
  };
}

const getApps = () => api.get('/apps');

export { getApps };
