export const filterCategory = [
  {
    value: 'new-users',
    label: 'New Users',
  },
  {
    value: 'new-users-country',
    label: 'New Users grouped by Country',
    disabled: true,
  },
  {
    value: 'retention',
    label: 'Retention',
  },
  {
    value: 'returning-users',
    label: 'Returning Users',
  },
  {
    value: 'return-users-country',
    label: 'Returning Users grouped by Country',
    disabled: true,
  },
  {
    value: 'users-new-returning',
    label: 'Users grouped by New and Returning',
  },
];
