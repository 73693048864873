import * as XLSX from 'xlsx';

export const allCountries = {
  label: 'All',
  value: 'All',
};

export const payingTypes = [
  {
    label: 'All',
    value: 'All',
  },
  {
    label: 'True',
    value: 'True',
  },
  {
    label: 'False',
    value: 'False',
  },
];

export const gameEvents: {
  [key: string]: {
    label: string;
    value: string;
  }[];
} = {
  DaymareZero: [
    {
      label: '7-Day Beginner',
      value: 'beginner',
    },
    {
      label: 'Star Light',
      value: 'star_light',
    },
    {
      label: 'Mid Autumn',
      value: 'second_event',
    },
    {
      label: 'Battle Pass',
      value: 'battle_pass',
    },
  ]
};

export const supportedTimezones = [
  {
    label: '+7',
    value: 7,
  },
  {
    label: '0',
    value: 0,
  },
  {
    label: '-7',
    value: -7,
  },
];

export const platforms = [
  {
    label: 'iOS',
    value: 'IOS',
  },
  {
    label: 'Android',
    value: 'ANDROID',
  },
];

export const channels = [
  {
    label: 'Facebook',
    value: 'Facebook',
  },
];

export const dayLtvPeriodOptions: { label: string, value: number }[] = [
  { label: '1', value: 1 },
  { label: '3', value: 3 },
  { label: '7', value: 7 },
  { label: '14', value: 14 },
  { label: '30', value: 30 },
  { label: '60', value: 60 },
  { label: '90', value: 90 },
  { label: '120', value: 120 },
];

export const monthLtvPeriodOptions: { label: string, value: number }[] = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '6', value: 6 },
  { label: '9', value: 9 },
  { label: '12', value: 12 },
];

export const CALENDAR_TEXT_ICON = '📅';

export const dateStrToUnix = (str: string | null | undefined, timezone: number = 0, isEndDate: boolean = false): number | undefined => {
  if (str !== null && str !== undefined) {
    const unix = new Date(str + 'T00:00:00Z').getTime() * 1000 + timezone * 3600000000;
    return isEndDate === true ? unix + 86400000000 : unix;
  }
  return undefined;
};
export const currHourToUnix = (timezone: number = 0): number | undefined => {
  return Math.floor(Date.now() / 1000) + timezone * 3600;
};

export const appTimezoneConst: Record<string, number> = {
  DaymareZero: 7,
};

const convertFileName = (title: string) => {
  return title.replace(/ /g, '_').toLowerCase();
};

export const toExcelFileName = (title: string | undefined) => {
  return 'seen_' + (title ? convertFileName(title) : new Date().getTime());
}

export const writeExcelFile = (data: any, fileName: string, type: 'json' | 'string') => {
  const ws = type === 'json' ? XLSX.utils.json_to_sheet(data) : XLSX.utils.aoa_to_sheet(data);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  XLSX.writeFile(wb, `${fileName}.xlsx`);
};

export const formatTimezoneOffset = (offset: number): string => {
  const intOffset = Math.floor(Math.abs(offset));
  const hours = intOffset.toString().padStart(2, '0');
  const minutes = '00';
  const sign = offset >= 0 ? '+' : '-';
  return `${sign}${hours}:${minutes}`;
}