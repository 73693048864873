import LayoutContextConsumer from 'contexts/LayoutContext';

import { IDashboardSessionData, IFirstLaunch, IMimilandLove, IMimilandTutorial } from 'pages/dashboard/Dashboard';

import ChartContainer from 'components/shared/ChartContainer';

import { IVersionHistory } from 'config/api/version-history/versionHistoryApi';
import { CALENDAR_TEXT_ICON } from 'config/constants/general';
import { GREY_COLOR, PRIMARY_COLOR } from 'config/constants/theme';
import numberHelper from 'config/helpers/numberHelper';

import LineChartCompare from '../charts/LineChartCompare';

import { Col, Row } from 'antd';

interface IDashboardContentProps {
  isCompare: boolean;
  dashboardData: IDashboardSessionData[];
  versionData?: IVersionHistory[];
  firstLaunchData?: IFirstLaunch[];
  mimilandLove?: IMimilandLove[];
  mimilandTutorial?: IMimilandTutorial[];
  compareData?: {
    dashboardData?: IDashboardSessionData[];
    firstLaunchData?: IFirstLaunch[];
    mimilandLove?: IMimilandLove[];
    mimilandTutorial?: IMimilandTutorial[];
  };
}

const { toMinute, calAvg } = numberHelper;

const DashboardContent = ({
  isCompare,
  dashboardData,
  versionData,
  firstLaunchData,
  mimilandLove,
  mimilandTutorial,
  compareData,
}: IDashboardContentProps) => {
  const { currentApp } = LayoutContextConsumer();

  return (
    <Row gutter={[28, 40]}>
      {mimilandLove && (
        <Col span={24} lg={12}>
          <div className="mb-4 flex flex-wrap justify-between lg:flex-nowrap">
            <h6 className="text-14 font-semibold lg:text-16">Total Loves</h6>
          </div>
          <ChartContainer>
            <LineChartCompare
              data={mimilandLove}
              compareData={
                compareData?.mimilandLove?.map((item) => ({
                  ...item,
                  step: 'C-' + item.step,
                })) || []
              }
              xField="time"
              yField={['data', 'data']}
              seriesField={['step', 'step']}
              height={340}
              xAxis={{
                label: {
                  style(_, index, items) {
                    const date = items[index].id;
                    const verData = versionData?.find((version) => version.date === date);

                    return {
                      fill: verData ? PRIMARY_COLOR : GREY_COLOR,
                      fontWeight: verData ? 600 : 400,
                    };
                  },
                  formatter(text) {
                    const verData = versionData?.find((version) => version.date === text);
                    return `${verData ? CALENDAR_TEXT_ICON : ''}${text}`;
                  },
                  autoRotate: true,
                },
                range: [0, 1],
              }}
              tooltip={{
                title(title, datum) {
                  const verData = versionData?.find((item) => item.date === datum.time);

                  return verData ? `${verData.version} - ${verData.title} - ${verData.platform.toUpperCase()}` : title;
                },
              }}
            />
          </ChartContainer>
        </Col>
      )}
      {mimilandTutorial && (
        <>
          <Col span={24} lg={12}>
            <div className="mb-4 flex flex-wrap justify-between lg:flex-nowrap">
              <h6 className="text-14 font-semibold lg:text-16">Average Message</h6>
            </div>
            <ChartContainer>
              <LineChartCompare
                data={mimilandTutorial.map((item) => ({ ...item, category: 'Average Message' })) || []}
                compareData={compareData?.mimilandTutorial?.map((item) => ({ ...item, category: 'Compare' })) || []}
                xField="time"
                yField={['avgMess', 'avgMess']}
                seriesField={['category', 'category']}
                height={340}
                xAxis={{
                  label: {
                    style(_, index, items) {
                      const date = items[index].id;
                      const verData = versionData?.find((version) => version.date === date);

                      return {
                        fill: verData ? PRIMARY_COLOR : GREY_COLOR,
                        fontWeight: verData ? 600 : 400,
                      };
                    },
                    formatter(text) {
                      const verData = versionData?.find((version) => version.date === text);
                      return `${verData ? CALENDAR_TEXT_ICON : ''}${text}`;
                    },
                    autoRotate: true,
                  },
                  range: [0, 1],
                }}
                tooltip={{
                  title(title, datum) {
                    const verData = versionData?.find((item) => item.date === datum.time);

                    return verData
                      ? `${verData.version} - ${verData.title} - ${verData.platform.toUpperCase()}`
                      : title;
                  },
                }}
              />
            </ChartContainer>
          </Col>
          <Col span={24} lg={12}>
            <div className="mb-4 flex flex-wrap justify-between lg:flex-nowrap">
              <h6 className="text-14 font-semibold lg:text-16">Average Distance</h6>
            </div>
            <ChartContainer>
              <LineChartCompare
                data={mimilandTutorial.map((item) => ({ ...item, category: 'Average Distance' })) || []}
                compareData={compareData?.mimilandTutorial?.map((item) => ({ ...item, category: 'Compare' })) || []}
                xField="time"
                yField={['avgDistance', 'avgDistance']}
                seriesField={['category', 'category']}
                height={340}
                xAxis={{
                  label: {
                    style(_, index, items) {
                      const date = items[index].id;
                      const verData = versionData?.find((version) => version.date === date);

                      return {
                        fill: verData ? PRIMARY_COLOR : GREY_COLOR,
                        fontWeight: verData ? 600 : 400,
                      };
                    },
                    formatter(text) {
                      const verData = versionData?.find((version) => version.date === text);
                      return `${verData ? CALENDAR_TEXT_ICON : ''}${text}`;
                    },
                    autoRotate: true,
                  },
                  range: [0, 1],
                }}
                yAxis={[
                  {
                    label: {
                      formatter: (val) => `${val}m`,
                    },
                    min: 0,
                  },
                ]}
                tooltip={{
                  formatter: (datum) => {
                    return {
                      name: datum.category,
                      value: `${datum.avgDistance}m`,
                    };
                  },
                  title(title, datum) {
                    const verData = versionData?.find((item) => item.date === datum.time);

                    return verData
                      ? `${verData.version} - ${verData.title} - ${verData.platform.toUpperCase()}`
                      : title;
                  },
                }}
              />
            </ChartContainer>
          </Col>
        </>
      )}
      {/* {firstLaunchData && (
        <Col span={24} lg={12}>
          <div className="mb-4 flex flex-wrap justify-between lg:flex-nowrap">
            <h6 className="text-14 font-semibold lg:text-16">Average Duration Of First Launch</h6>
          </div>
          <ChartContainer>
            <LineChartCompare
              data={firstLaunchData}
              compareData={compareData?.firstLaunchData || []}
              xField="time"
              yField={['data', 'data']}
              seriesField={['version', 'version']}
              height={340}
              xAxis={{
                label: {
                  style(_, index, items) {
                    const date = items[index].id;
                    const verData = versionData?.find((version) => version.date === date);

                    return {
                      fill: verData ? PRIMARY_COLOR : GREY_COLOR,
                      fontWeight: verData ? 600 : 400,
                    };
                  },
                  formatter(text) {
                    const verData = versionData?.find((version) => version.date === text);
                    return `${verData ? CALENDAR_TEXT_ICON : ''}${text}`;
                  },
                  autoRotate: true,
                },
                range: [0, 1],
              }}
              yAxis={[
                {
                  label: {
                    formatter: (val) => `${val}s`,
                  },
                },
              ]}
              tooltip={{
                formatter: (datum) => {
                  return {
                    name: datum.version,
                    value: `${numberHelper.toMinute(datum.data * 1000)}`,
                  };
                },
                title(title, datum) {
                  const verData = versionData?.find((item) => item.date === datum.time);

                  return verData ? `${verData.version} - ${verData.title} - ${verData.platform.toUpperCase()}` : title;
                },
              }}
            />
          </ChartContainer>
        </Col>
      )} */}

      <Col span={24} lg={12}>
        <div className="mb-4 flex flex-wrap justify-between lg:flex-nowrap">
          <h6 className="text-14 font-semibold lg:text-16">Playtime (mean per user)</h6>
          <h6 className="text-14 font-normal lg:text-14">
            Avg. {toMinute(calAvg(dashboardData.map((item) => item.playtimePerUser)))}
          </h6>
        </div>
        <ChartContainer>
          <LineChartCompare
            data={
              dashboardData
                ? dashboardData.map((data) => ({
                    period: data.period,
                    playtime: data.playtimePerUser / 60000,
                    category: 'Playtime',
                  }))
                : []
            }
            compareData={
              compareData?.dashboardData?.map((data) => ({
                period: data.period,
                playtime: data.playtimePerUser / 60000,
                category: 'Compare',
              })) || []
            }
            xField="period"
            yField={['playtime', 'playtime']}
            seriesField={['category', 'category']}
            height={340}
            yAxis={[
              {
                label: {
                  formatter: (val) => `${parseFloat(val).toFixed(2)}m`,
                },
                min: 0,
              },
              {
                label: {
                  formatter: (val) => `${parseFloat(val).toFixed(2)}m`,
                },
              },
            ]}
            xAxis={{
              label: {
                style(_, index, items) {
                  const date = items[index].id;
                  const verData = versionData?.find((version) => version.date === date);

                  return {
                    fill: verData ? PRIMARY_COLOR : GREY_COLOR,
                    fontWeight: verData ? 600 : 400,
                  };
                },
                formatter(text) {
                  const verData = versionData?.find((version) => version.date === text);
                  return `${verData ? CALENDAR_TEXT_ICON : ''}${text}`;
                },
                autoRotate: true,
              },
              range: [0, 1],
            }}
            tooltip={{
              formatter: (datum) => {
                return {
                  name: datum.category,
                  value: toMinute(datum.playtime * 60000),
                };
              },
              title(title, datum) {
                const verData = versionData?.find((item) => item.date === datum.period);

                return verData ? `${verData.version} - ${verData.title} - ${verData.platform.toUpperCase()}` : title;
              },
            }}
          />
        </ChartContainer>
      </Col>

      <Col span={24} lg={12}>
        <div className="mb-4 flex flex-wrap justify-between lg:flex-nowrap">
          <h6 className="text-14 font-semibold lg:text-16">Playtime (mean per session)</h6>
          <h6 className="text-14 font-normal lg:text-14">
            Avg. {toMinute(calAvg(dashboardData.map((item) => item.playtimePerSession)))}
          </h6>
        </div>
        <ChartContainer>
          <LineChartCompare
            data={
              dashboardData
                ? dashboardData.map((data) => ({
                    period: data.period,
                    playtime: data.playtimePerSession / 60000,
                    category: 'Playtime',
                  }))
                : []
            }
            compareData={
              compareData?.dashboardData?.map((data) => ({
                period: data.period,
                playtime: data.playtimePerSession / 60000,
                category: 'Compare',
              })) || []
            }
            xField="period"
            yField={['playtime', 'playtime']}
            seriesField={['category', 'category']}
            height={340}
            yAxis={[
              {
                label: {
                  formatter: (val) => `${parseFloat(val).toFixed(2)}m`,
                  autoRotate: true,
                },
                min: 0,
              },
              {
                label: {
                  formatter: (val) => ``,
                  autoRotate: true,
                },
              },
            ]}
            tooltip={{
              formatter: (datum) => {
                return {
                  name: datum.category,
                  value: numberHelper.toMinute(datum.playtime * 60000),
                };
              },
              title(title, datum) {
                const verData = versionData?.find((item) => item.date === datum.period);

                return verData ? `${verData.version} - ${verData.title} - ${verData.platform.toUpperCase()}` : title;
              },
            }}
            xAxis={{
              label: {
                style(_, index, items) {
                  const date = items[index].id;
                  const verData = versionData?.find((version) => version.date === date);

                  return {
                    fill: verData ? PRIMARY_COLOR : GREY_COLOR,
                    fontWeight: verData ? 600 : 400,
                  };
                },
                formatter(text) {
                  const verData = versionData?.find((version) => version.date === text);
                  return `${verData ? CALENDAR_TEXT_ICON : ''}${text}`;
                },
                autoRotate: true,
              },
              range: [0, 1],
            }}
          />
        </ChartContainer>
      </Col>
      <Col span={24} lg={12}>
        <div className="mb-4 flex flex-wrap justify-between lg:flex-nowrap">
          <h6 className="text-14 font-semibold lg:text-16">
            Users (split by <span className="text-secondary">new</span>/<span className="text-yellow">returning</span>)
          </h6>
          <h6 className="text-14 font-normal lg:text-14">
            Avg. (
            <span className="text-secondary">{calAvg(dashboardData.map((item) => item.newUsers)).toFixed(0)}</span>/
            <span className="text-yellow">
              {calAvg(dashboardData.map((item) => item.DAU - item.newUsers)).toFixed(0)}
            </span>
            )
          </h6>
        </div>
        <ChartContainer>
          <LineChartCompare
            data={
              dashboardData
                ? [
                    ...dashboardData.map((item) => {
                      return {
                        period: item.period,
                        users: item.newUsers,
                        category: 'New Users',
                      };
                    }),
                    ...dashboardData.map((item) => {
                      return {
                        period: item.period,
                        users: item.DAU - item.newUsers,
                        category: 'Returning Users',
                      };
                    }),
                  ]
                : []
            }
            compareData={
              compareData?.dashboardData
                ? [
                    ...compareData.dashboardData.map((item) => {
                      return {
                        period: item.period,
                        users: item.newUsers,
                        category: 'C-New Users',
                      };
                    }),
                    ...compareData.dashboardData.map((item) => {
                      return {
                        period: item.period,
                        users: item.DAU - item.newUsers,
                        category: 'C-Returning Users',
                      };
                    }),
                  ]
                : []
            }
            xField="period"
            yField={['users', 'users']}
            seriesField={['category', 'category']}
            height={340}
            xAxis={{
              label: {
                style(_, index, items) {
                  const date = items[index].id;
                  const verData = versionData?.find((version) => version.date === date);

                  return {
                    fill: verData ? PRIMARY_COLOR : GREY_COLOR,
                    fontWeight: verData ? 600 : 400,
                  };
                },
                formatter(text) {
                  const verData = versionData?.find((version) => version.date === text);
                  return `${verData ? CALENDAR_TEXT_ICON : ''}${text}`;
                },
                autoRotate: true,
              },
              range: [0, 1],
            }}
            tooltip={{
              formatter: (datum) => {
                return {
                  name: datum.category,
                  value: datum.users,
                };
              },
              title(title, datum) {
                const verData = versionData?.find((item) => item.date === datum.period);

                return verData ? `${verData.version} - ${verData.title} - ${verData.platform.toUpperCase()}` : title;
              },
            }}
          />
        </ChartContainer>
      </Col>
      {currentApp?.id === 'SkyDancer2' && (
        <Col span={24} lg={12}>
          <div className="mb-4 flex flex-wrap justify-between lg:flex-nowrap">
            <h6 className="text-14 font-semibold lg:text-16">Binding Account Users</h6>
            <h6 className="text-14 font-normal lg:text-14">
              Avg. {calAvg(dashboardData.map((item) => item.bindingUsers as number))}
            </h6>
          </div>
          <ChartContainer>
            <LineChartCompare
              data={
                dashboardData
                  ? dashboardData.map((data) => ({
                      period: data.period,
                      users: data.bindingUsers as number,
                      category: 'Binding Account Users',
                    }))
                  : []
              }
              compareData={
                compareData?.dashboardData?.map((data) => ({
                  period: data.period,
                  users: data.bindingUsers as number,
                  category: 'Compare',
                })) || []
              }
              xField="period"
              yField={['users', 'users']}
              seriesField={['category', 'category']}
              height={340}
              xAxis={{
                label: {
                  style(_, index, items) {
                    const date = items[index].id;
                    const verData = versionData?.find((version) => version.date === date);

                    return {
                      fill: verData ? PRIMARY_COLOR : GREY_COLOR,
                      fontWeight: verData ? 600 : 400,
                    };
                  },
                  formatter(text) {
                    const verData = versionData?.find((version) => version.date === text);
                    return `${verData ? CALENDAR_TEXT_ICON : ''}${text}`;
                  },
                  autoRotate: true,
                },
                range: [0, 1],
              }}
              tooltip={{
                title(title, datum) {
                  const verData = versionData?.find((item) => item.date === datum.period);

                  return verData ? `${verData.version} - ${verData.title} - ${verData.platform.toUpperCase()}` : title;
                },
              }}
            />
          </ChartContainer>
        </Col>
      )}
      <Col span={24} lg={12}>
        <div className="mb-4 flex flex-wrap justify-between lg:flex-nowrap">
          <h6 className="text-14 font-semibold lg:text-16">Session Count</h6>
          <h6 className="text-14 font-normal lg:text-14">
            Avg. {calAvg(dashboardData.map((item) => item.sessions)).toFixed(0)}
          </h6>
        </div>
        <ChartContainer>
          <LineChartCompare
            data={
              dashboardData
                ? dashboardData.map((data) => ({
                    period: data.period,
                    sessions: data.sessions,
                    category: 'Sessions',
                  }))
                : []
            }
            compareData={
              compareData?.dashboardData?.map((data) => ({
                period: data.period,
                sessions: data.sessions,
                category: 'Compare',
              })) || []
            }
            xField="period"
            yField={['sessions', 'sessions']}
            seriesField={['category', 'category']}
            height={340}
            xAxis={{
              label: {
                style(_, index, items) {
                  const date = items[index].id;
                  const verData = versionData?.find((version) => version.date === date);

                  return {
                    fill: verData ? PRIMARY_COLOR : GREY_COLOR,
                    fontWeight: verData ? 600 : 400,
                  };
                },
                formatter(text) {
                  const verData = versionData?.find((version) => version.date === text);
                  return `${verData ? CALENDAR_TEXT_ICON : ''}${text}`;
                },
                autoRotate: true,
              },
              range: [0, 1],
            }}
            tooltip={{
              title(title, datum) {
                const verData = versionData?.find((item) => item.date === datum.period);

                return verData ? `${verData.version} - ${verData.title} - ${verData.platform.toUpperCase()}` : title;
              },
            }}
          />
        </ChartContainer>
      </Col>
    </Row>
  );
};

export default DashboardContent;
