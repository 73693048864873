import resourceEnum from 'config/constants/resource';
import { USER_ROLE } from 'config/constants/userRole';

import api from '..';

import timezone from 'moment-timezone';

export interface IUser {
  email: string;
  role: USER_ROLE;
  resources?: {
    [key: string]: resourceEnum[];
  };
  games?: string[] | '*';
  profile?: IUserProfile;
  id?: string;
  lastLogin?: string | Date;
  permission: {
    [key: string]: IUserPermission;
  };
  emailReport?: boolean;
}

export interface IUserProfile {
  displayName?: string | null;
  photoURL?: string | null;
  id?: string;
}

export interface IUserPermission {
  role: USER_ROLE;
  resources: resourceEnum[];
}

export interface IUserPermissionBody {
  permission: {
    [key: string]: IUserPermission;
  };
}

export interface IUserRoleBody {
  role?: USER_ROLE;
  resources?: {
    [key: string]: resourceEnum[];
  };
  games?: string[];
}

const getUserDetail = () => {
  return api.get<IUser>('/user');
};

const getUserDetailByEmail = (email: string) => {
  return api.get<IUser>(`/user/detail/${email}`);
};

const getAllUser = () => {
  return api.get<IUser[]>('/user/all');
};

const logUser = () => {
  return api.post(
    '/user/log',
    {},
    {
      params: {
        time: timezone().utcOffset('-07:00'),
      },
    },
  );
};

const addUserRole = (email: string, body: IUserRoleBody) => {
  return api.post(`/user/${email}`, body);
};

const checkUserExist = (email: string) => {
  return api.get(`/user/exist/${email}`);
};

const updateUserPermission = (email: string, body: IUserPermissionBody) => {
  return api.post(`/user/permission/${email}`, body);
};

const updateUserProfile = (body: IUserProfile) => {
  return api.put('/user/profile', body);
};

const updateUserEmailReport = (email: string, emailReport: boolean) => {
  return api.post(`/user/email-report/${email}`, undefined, {
    params: {
      emailReport,
    },
  });
};

const deleteUser = (email: string) => {
  return api.delete(`/user/${email}`);
};

const userApi = {
  getUserDetail,
  getUserDetailByEmail,
  checkUserExist,
  addUserRole,
  updateUserProfile,
  updateUserPermission,
  updateUserEmailReport,
  getAllUser,
  logUser,
  deleteUser,
};

export default userApi;
