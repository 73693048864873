import api from '..';

import { AxiosRequestConfig } from 'axios';

const getCountries = (config?: AxiosRequestConfig) => {
  const game = sessionStorage.getItem('currentApp');
  if (game) return api.get(`countries/${game}`, config);
  return api.get('/countries');
};

const getVersions = (config?: AxiosRequestConfig) => {
  const game = sessionStorage.getItem('currentApp');
  if (game) return api.get(`apps/versions/${game}`, config);
  return api.get('/versions');
};

const generalApi = {
  getCountries,
  getVersions,
};

export default generalApi;
