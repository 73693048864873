import useDocumentTitle from 'hooks/useDocumentTitle';

import withPermission from 'hocs/withPermission';

import ExploreContent from 'components/feature/explore/ExploreContent';
import ExploreFilter from 'components/feature/explore/ExploreFilter';

import { BG_GREY_COLOR, GREY_COLOR_2, SECONDARY_COLOR } from 'config/constants/theme';

import { ConfigProvider } from 'antd';

const ExplorePage = () => {
  useDocumentTitle('Explore');

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: SECONDARY_COLOR,
          colorBorder: GREY_COLOR_2,
          colorBgTextHover: BG_GREY_COLOR,
        },
      }}
    >
      <div className="pb-4">
        <section className="mb-4">
          <h3 className="page-section-title">Explore</h3>
        </section>

        <section className="mb-8">
          <ExploreFilter />
        </section>

        <section className="mb-8">
          <ExploreContent />
        </section>
      </div>
    </ConfigProvider>
  );
};

export default withPermission(ExplorePage);
