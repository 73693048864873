import React from 'react';

import { IStep } from 'pages/funnels/FunnelsCreatePage';

import { CopyOutlined, DeleteOutlined, EditOutlined, FilterOutlined, PlusOutlined } from '@ant-design/icons';

interface IFunnelStepHeaderProps extends IStep {
  tools: {
    addStep: () => void;
    delStep: (id: string) => void;
    copyStep: (id: string) => void;
  };
}

const FunnelStepHeader = ({ id, name, tools, order }: IFunnelStepHeaderProps) => {
  return (
    <div className="flex flex-wrap justify-between gap-4">
      <span className="text-20 font-semibold">
        {order}. {name}
      </span>

      <div className="flex gap-3">
        <div
          className="h-fit w-fit rounded px-1 text-16 hover:bg-bg-red-light hover:text-primary"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <EditOutlined />
        </div>
        <div
          className="h-fit w-fit rounded px-1 text-16 hover:bg-bg-red-light hover:text-primary"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <FilterOutlined />
        </div>
        <div
          className="h-fit w-fit rounded px-1 text-16 hover:bg-bg-red-light hover:text-primary"
          onClick={(e) => {
            tools.addStep();
            e.stopPropagation();
          }}
        >
          <PlusOutlined />
        </div>
        <div
          className="h-fit w-fit rounded px-1 text-16 hover:bg-bg-red-light hover:text-primary"
          onClick={(e) => {
            tools.copyStep(id);
            e.stopPropagation();
          }}
        >
          <CopyOutlined />
        </div>
        <div
          className="h-fit w-fit rounded px-1 text-16 hover:bg-bg-red-light hover:text-primary"
          onClick={(e) => {
            tools.delStep(id);
            e.stopPropagation();
          }}
        >
          <DeleteOutlined />
        </div>
      </div>
    </div>
  );
};

export default FunnelStepHeader;
