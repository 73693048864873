import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import LayoutContextConsumer from 'contexts/LayoutContext';
import RawDataTable from 'components/feature/charts/RawDataTable';
import RawDataTable2 from '../../charts/RawDataTable2';
import Loading from 'components/shared/Loading';
import daymareZeroApi from 'config/api/bigquery/daymareZeroApi';
import { itemToCell_icon } from '../../../layout/protected/Custom';

interface Props {
  rangeDate: {
    start?: string;
    end?: string;
  };
  rangeDate2: {
    start?: string,
    end?: string,
  }
  country: string;
  reloadTrigger: boolean;
  isFirstLoad: boolean;
  feature: string;
}

interface I7DayQuest {
  quest_group: string,
  quest_id: string,
  complete_times: number,
  claim_times: number,
  avg_level: number,
  avg_day_played: number,
}

interface I7DayShop {
  day: string,
  slot: string,
  buy_times: number,
}

interface IBattlePass {
  period: string,
  free_times: number,
  free_level: number,
  free_users: number,
  premium_times: number,
  premium_level: number,
  premium_users: number,
  buy_times: number,
  buy_users: number,
  total_level_bought: number,
  total_gem_spent: number,
  iap_premium: number,
  iap_ultimate: number,
  iap_upgrade: string,
}

const { get7DayQuest, get7DayShop, battlePass } = daymareZeroApi;

const Daymare7DayQuest = ({ rangeDate, rangeDate2, country, reloadTrigger, isFirstLoad, feature }: Props) => {
  const { platform } = LayoutContextConsumer();

  const { data: rawData, isLoading: dataLoading } = useQuery(
    ['b7day-quest', reloadTrigger],
    async () => {
      if (isFirstLoad === true || !rangeDate.start || !rangeDate.end) {
        return [];
      }

      const res = await get7DayQuest({
        params: {
          platform: platform === 'unified' ? undefined : platform,
          start: rangeDate.start,
          end: rangeDate.end,
          createdStart: rangeDate2?.start,
          createdEnd: rangeDate2?.end, 
          country: country !== 'All' ? country : undefined,
          feature: feature,
        },
      });

      return res as unknown as I7DayQuest[];
    },
  );

  const { data: shopData, isLoading: shopLoading } = useQuery(
    ['b7day-shop', reloadTrigger],
    async () => {
      if (isFirstLoad === true || !rangeDate.start || !rangeDate.end) {
        return [];
      }

      const res = await get7DayShop({
        params: {
          platform: platform === 'unified' ? undefined : platform,
          start: rangeDate.start,
          end: rangeDate.end,
          createdStart: rangeDate2?.start,
          createdEnd: rangeDate2?.end,
          country: country !== 'All' ? country : undefined,
          feature: feature,
        },
      });

      return res as unknown as I7DayShop[];
    },
  );

  const isBattlePass = (): boolean => {
    return ['battle_pass'].includes(feature);
  }

  const { data: battlePassData, isLoading: battlePassLoading } = useQuery(
    ['battle-pass', reloadTrigger],
    async () => {
      if (isFirstLoad === true || !rangeDate.start || !rangeDate.end || isBattlePass() === false) {
        return [];
      }
      const res = await battlePass({
        params: {
          platform: platform === 'unified' ? undefined : platform,
          start: rangeDate.start,
          end: rangeDate.end,
          createdStart: rangeDate2?.start,
          createdEnd: rangeDate2?.end,
          country: country !== 'All' ? country : undefined,
          feature: feature,
        },
      });
      return res as unknown as IBattlePass[];
    },
  );

  const tableData = useMemo(() => {
    return rawData?.map((item) => {
      return [
        { icon: '', value: item.quest_group },
        { icon: '', value: item.quest_id },
        { icon: 'times', value: `${item.complete_times}` },
        { icon: 'times', value: `${item.claim_times}` },
        { icon: '', value: `${item.avg_level}` },
        { icon: '', value: `${item.avg_day_played}` },
      ];
    });
  }, [rawData]);

  const tableOfShopData = useMemo(() => {
    return shopData?.map((item) => {
      return [
        { icon: '', value: item.day },
        { icon: '', value: item.slot },
        { icon: 'times', value: `${item.buy_times}` },
      ];
    });
  }, [shopData]);

  const tableOfBattlePassData = useMemo(() => {
    return battlePassData?.map((item) => {
      return [
        { icon: '', value: item.period },
        { icon: 'times', value: `${item.free_times}` },
        { icon: 'users', value: `${item.free_users}` },
        { icon: '', value: `${item.free_level}` },
        { icon: 'times', value: `${item.premium_times}` },
        { icon: 'users', value: `${item.premium_users}` },
        { icon: '', value: `${item.premium_level}` },
        { icon: 'times', value: `${item.buy_times}` },
        { icon: 'users', value: `${item.buy_users}` },
        { icon: '', value: `${item.total_level_bought}` },
        { icon: '', value: `${item.total_gem_spent}` },
        { icon: 'times', value: `${item.iap_premium}` },
        { icon: 'times', value: `${item.iap_ultimate}` },
        { icon: 'times', value: `${item.iap_upgrade}` },
      ];
    });
  }, [battlePassData]);

  const isLoading = dataLoading || shopLoading || battlePassLoading;

  return (
    <div className="w-full">
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex gap-8 flex-wrap">
          {tableData && tableData.length > 0 && (
            <RawDataTable
              isCenterFull
              title="Quest"
              headers={[
                'Quest\nGroup',
                'Quest ID',
                'Complete',
                'Claim',
                'Avg Level',
                'Avg Day\nPlayed',
                ]}
              data={tableData.map((item) => {
                return item.map((subItem) => {
                  return itemToCell_icon({ value: subItem.value, icon: subItem.icon });
                });
              })}
              rawData={rawData}
            />
          )}
          {tableOfShopData && tableOfShopData.length > 0 && (
            <RawDataTable
              isCenterFull
              title="Shop"
              headers={[
                'Day',
                'Slot ID',
                'Buy',
              ]}
              data={tableOfShopData.map((item) => {
                return item.map((subItem) => {
                  return itemToCell_icon({ value: subItem.value, icon: subItem.icon });
                });
              })}
              rawData={shopData}
            />
          )}
          {tableOfBattlePassData && tableOfBattlePassData.length > 0 && (
            <RawDataTable2
              isCenterFull
              title="Rewards"
              headers={[
                { header: 'Day', subHeaders: null },
                { header: 'Free', subHeaders: ['Times', 'Users', 'T. Level'] },
                { header: 'Premium', subHeaders: ['Times', 'Users', 'T. Level'] },
                { header: 'Buy Level', subHeaders: ['Times', 'Users', 'T. Level', 'Gem'] },
                { header: 'Buy Battle Pass', subHeaders: ['Premium', 'Ultimate', 'Upgrade'] },
              ]}
              data={tableOfBattlePassData.map((item) => {
                return item.map((subItem) => {
                  return itemToCell_icon({ value: subItem.value, icon: subItem.icon });
                });
              })}
              rawData={shopData}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Daymare7DayQuest;
