import React, { useCallback, useMemo } from 'react';

import { filterCategory } from 'config/constants/explore';
import menuHelper, { MenuItem } from 'config/helpers/menuHelper';

import ExploreModalCustomContent from './ExploreModalCustomContent';

import { Button, Divider, Menu, Modal } from 'antd';
import clsx from 'clsx';
import capitalize from 'lodash/capitalize';
import { IFilter, useExploreStore } from 'stores/exploreStore';

interface IExploreModalProps {
  isModalVisible: boolean;
  setIsModalVisible: (value: boolean) => void;
  filter: IFilter;
  setFilter: (value: IFilter) => void;
}

const { getItem } = menuHelper;

const listMetric = [
  getItem('All', 'all'),
  getItem('Engagement', 'engagement'),
  getItem('Popular', 'popular'),
  getItem('Business', 'business'),
  getItem('Error', 'error'),
  getItem('Ads', 'ads'),
  getItem('Design', 'design'),
  getItem('Impression', 'impression'),
  getItem('Progression', 'progression'),
  getItem('Resource', 'resource'),
  getItem('Custom Query', 'custom'),
];

const ExploreModal = ({ isModalVisible, setIsModalVisible }: IExploreModalProps) => {
  const { filter, setFilter } = useExploreStore((state) => state);

  const handleCancel = useCallback(() => {
    setIsModalVisible(false);
  }, [setIsModalVisible]);

  const content = useMemo(() => {
    switch (filter.metric) {
      case 'engagement':
        return (
          <div className="flex flex-col">
            <div className="flex items-center rounded-t bg-bg-grey px-2 py-3">
              <h6 className="text-16 font-bold ">Metric</h6>
            </div>
            <Divider
              style={{
                margin: '0px',
              }}
            />
            {filterCategory.map((item) => (
              <React.Fragment key={item.value}>
                <div
                  className={clsx(
                    'flex items-center px-2 py-3 hover:text-secondary',
                    item.disabled ? 'cursor-not-allowed opacity-50 hover:text-black' : 'cursor-pointer',
                  )}
                  onClick={() => {
                    if (!item.disabled) {
                      setFilter({
                        ...filter,
                        category: item.value,
                      });
                      handleCancel();
                    }
                  }}
                >
                  <span className="text-14 font-medium">{item.label}</span>
                </div>
                <Divider
                  style={{
                    margin: '0px',
                  }}
                />
              </React.Fragment>
            ))}
          </div>
        );
      case 'custom':
        return <ExploreModalCustomContent />;
      default:
        return null;
    }
  }, [filter, handleCancel, setFilter]);

  return (
    <Modal
      title="Metric Picker"
      open={isModalVisible}
      onCancel={handleCancel}
      footer={
        <div className="flex justify-end">
          <Button size="small" onClick={handleCancel}>
            Close
          </Button>
        </div>
      }
      width={976}
    >
      <div className="my-6 flex">
        <Menu
          theme="light"
          items={listMetric}
          style={{
            paddingRight: '16px',
          }}
          onSelect={(item: MenuItem) => {
            setFilter({
              ...filter,
              metric: item?.key as string,
            });
          }}
          defaultSelectedKeys={[filter.metric]}
        />

        <div className="flex-1 px-4">
          <h4 className="text-16 font-medium">{capitalize(filter.metric)}</h4>
          <div className="mt-2">{content}</div>
        </div>
      </div>
    </Modal>
  );
};

export default ExploreModal;
