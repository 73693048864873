import toastHelper from 'config/helpers/toastHelper';

import api from '..';

import { AxiosRequestConfig } from 'axios';

export interface FunnelDataRequest {
  option: string;
  country?: string;
  version?: string;
  platform?: string;
}

export interface FunnelDataV2Request {
  option: string;
  country?: string;
  version?: string;
  platform?: string;
  start?: string;
  end?: string;
}

export const funnelApi = {
  getData: (body: FunnelDataRequest) => {
    const game = sessionStorage.getItem('currentApp');
    if (game) {
      return api.post(`funnel/${game}`, body);
    } else {
      toastHelper.error('Please select a game first');
    }
  },
  getDataV2: (body: FunnelDataV2Request) => {
    const game = sessionStorage.getItem('currentApp');
    if (game) {
      return api.post(`funnel/v2/${game}`, body);
    } else {
      toastHelper.error('Please select a game first');
    }
  },
  getVersions: (config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game) {
      return api.get(`funnel/versions/${game}`, config);
    } else {
      toastHelper.error('Please select a game first');
    }
  },
};
