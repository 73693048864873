import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';

import LayoutContextConsumer from 'contexts/LayoutContext';

import useDocumentTitle from 'hooks/useDocumentTitle';

import withAdminPermission from 'hocs/withAdminPermission';

import versionHistoryApi, { IVersionHistory } from 'config/api/version-history/versionHistoryApi';
import appHelper from 'config/helpers/appHelper';
import dateHelper from 'config/helpers/dateHelper';

import NotFound from 'pages/exception/NotFound';

import VersionHistoryFilter from 'components/feature/version-history/VersionHistoryFilter';
import VersionHistoryModal from 'components/feature/version-history/VersionHistoryModal';
import VersionHistoryTimeline from 'components/feature/version-history/VersionHistoryTimeline';
import Loading from 'components/shared/Loading';

import { Col, Empty, Row } from 'antd';
import groupBy from 'lodash/groupBy';

const { getAll } = versionHistoryApi;
const { format } = dateHelper;
const { checkFalse } = appHelper;

const VersionHistoryPage = () => {
  useDocumentTitle('Version History');
  const { currentApp, platform } = LayoutContextConsumer();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [editData, setEditData] = useState<IVersionHistory | null>();

  const { data, isLoading, isError, refetch } = useQuery(['version-history', currentApp, platform], async () => {
    const res = await getAll({
      params: {
        platform: platform === 'unified' ? undefined : platform,
      },
    });
    const data = res as unknown as IVersionHistory[];
    const grouped = groupBy(data, (item) => format(item.date));

    return grouped;
  });

  const handleClose = () => {
    setModalOpen(false);
    setEditModalOpen(false);
    setEditData(null);
  };

  const handleEditModal = (values: IVersionHistory) => {
    setEditModalOpen(true);
    setEditData(values);
    setModalOpen(true);
  };

  if (isError) return <NotFound />;

  if (isLoading)
    return (
      <div className="w-full h-full flex justify-center items-center">
        <Loading />
      </div>
    );

  return (
    <div>
      <section>
        <h3 className="page-section-title">Version History</h3>
      </section>
      <Row className="mt-10 max-lg:flex-col-reverse" gutter={[12, 12]}>
        <Col span={24} lg={19}>
          {checkFalse(data) ? <VersionHistoryTimeline data={data} handleEdit={handleEditModal} /> : <Empty />}
        </Col>

        <Col span={24} lg={5}>
          <VersionHistoryFilter setModalOpen={setModalOpen} />
        </Col>
      </Row>

      <VersionHistoryModal
        visible={modalOpen}
        onClose={handleClose}
        refetch={() => {
          refetch();
        }}
        isEdit={editModalOpen}
        data={editData ? editData : undefined}
      />
    </div>
  );
};

export default withAdminPermission(VersionHistoryPage);
