import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import AuthConsumer from 'hooks/useAuth';

const AuthLayout = () => {
  const { isAuth } = AuthConsumer();
  return isAuth ? (
    <Navigate to="/" replace />
  ) : (
    <div className="relative flex h-[100vh] w-full items-center justify-center bg-primary object-cover object-center px-1">
      <Outlet />
    </div>
  );
};

export default AuthLayout;
