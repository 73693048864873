import { useQuery } from '@tanstack/react-query';
import { useMemo, useState } from 'react';

import RawDataTable from 'components/feature/charts/RawDataTable';
import Pager from '../../../shared/Pager';
import Loading from 'components/shared/Loading';
import LayoutContextConsumer from '../../../../contexts/LayoutContext';
import daymareZeroApi from 'config/api/bigquery/daymareZeroApi';
import { IVersionHistory } from 'config/api/version-history/versionHistoryApi';
import { dateStrToUnix, appTimezoneConst, writeExcelFile, toExcelFileName } from '../../../../config/constants/general';

interface Props {
  rangeDate: {
    start?: string;
    end?: string;
  };
  versionHistoryData?: IVersionHistory[];
  version: string | null;
  country: string;
  userID: string | null;
  userName: string | null;
  paying: string | null;
  reloadTrigger: boolean;
  isFirstLoad: boolean;
  page: number;
}

interface IUserEquipment {
  user_id: string,
  s1_id: string, s1_rarity: string, s1_level: string,
  s1_weapon_id: string, s1_weapon_rarity: string, s1_weapon_level: string,
  s1_head_id: string, s1_head_rarity: string, s1_head_level: string,
  s1_top_id: string, s1_top_rarity: string, s1_top_level: string,
  s1_bottom_id: string, s1_bottom_rarity: string, s1_bottom_level: string,
  s1_shoes_id: string, s1_shoes_rarity: string, s1_shoes_level: string,
  s2_id: string, s2_rarity: string, s2_level: string,
  s2_weapon_id: string, s2_weapon_rarity: string, s2_weapon_level: string,
  s2_head_id: string, s2_head_rarity: string, s2_head_level: string,
  s2_top_id: string, s2_top_rarity: string, s2_top_level: string,
  s2_bottom_id: string, s2_bottom_rarity: string, s2_bottom_level: string,
  s2_shoes_id: string, s2_shoes_rarity: string, s2_shoes_level: string,
  s3_id: string, s3_rarity: string, s3_level: string,
  s3_weapon_id: string, s3_weapon_rarity: string, s3_weapon_level: string,
  s3_head_id: string, s3_head_rarity: string, s3_head_level: string,
  s3_top_id: string, s3_top_rarity: string, s3_top_level: string,
  s3_bottom_id: string, s3_bottom_rarity: string, s3_bottom_level: string,
  s3_shoes_id: string, s3_shoes_rarity: string, s3_shoes_level: string,
  s4_id: string, s4_rarity: string, s4_level: string,
  s4_weapon_id: string, s4_weapon_rarity: string, s4_weapon_level: string,
  s4_head_id: string, s4_head_rarity: string, s4_head_level: string,
  s4_top_id: string, s4_top_rarity: string, s4_top_level: string,
  s4_bottom_id: string, s4_bottom_rarity: string, s4_bottom_level: string,
  s4_shoes_id: string, s4_shoes_rarity: string, s4_shoes_level: string,
  s5_id: string, s5_rarity: string, s5_level: string,
  s5_weapon_id: string, s5_weapon_rarity: string, s5_weapon_level: string,
  s5_head_id: string, s5_head_rarity: string, s5_head_level: string,
  s5_top_id: string, s5_top_rarity: string, s5_top_level: string,
  s5_bottom_id: string, s5_bottom_rarity: string, s5_bottom_level: string,
  s5_shoes_id: string, s5_shoes_rarity: string, s5_shoes_level: string,
}

interface IUserEquipment_Counter {
  total_items: number,
}

const { getUserEquipment, countUserEquipment } = daymareZeroApi;

const DaymareUserEquipment = ({ rangeDate, versionHistoryData, country, userID, userName, paying, reloadTrigger, isFirstLoad }: Props) => {
  const { currentApp } = LayoutContextConsumer();
  const [userEquipmentPage, setUserEquipmentPage] = useState<number>(1);

  const { data: rawData, isLoading: rawDataLoading } = useQuery(
    ['user-equipment', reloadTrigger, userEquipmentPage],
    async () => {
      if (isFirstLoad === true) {
        return [];
      }
      //let endUnix = dateStrToUnix(rangeDate.end, timezone);
      //if (endUnix) {
      //  endUnix += 86400000000;
      //}
      const res = await getUserEquipment({
        params: {
          page: userEquipmentPage,
          pageSize: 10,
        },
        data: {
          userID: userID,
          //startUnix: dateStrToUnix(rangeDate.start, timezone),
          //endUnix: endUnix,
        }
      });
      return res as unknown as IUserEquipment[];
    }
  );

  const { data: rawDataCounter, isLoading: rawDataCounterLoading } = useQuery(
    ['user-equipment-counter', reloadTrigger],
    async () => {
      if (isFirstLoad === true) {
        return [];
      }
      //let endUnix = dateStrToUnix(rangeDate.end, timezone);
      //if (endUnix) {
      //  endUnix += 86400000000;
      //}
      const res = await countUserEquipment({
        params: {
        },
        data: {
          userID: userID,
          //startUnix: dateStrToUnix(rangeDate.start, timezone),
          //endUnix: endUnix,
        }
      });
      return res as unknown as IUserEquipment_Counter[];
    }
  );

  const guiFormOfRawData = useMemo(() => {
    return rawData?.map((item) => {
      return [
        { value: item.user_id },
        { value: (item.s1_id !== null) ? `${item.s1_id} | ${item.s1_rarity} | ${item.s1_level}` : undefined },
        { value: (item.s1_weapon_id !== null) ? `${item.s1_weapon_id} | ${item.s1_weapon_rarity} | ${item.s1_weapon_level}` : undefined },
        { value: (item.s1_head_id !== null) ? `${item.s1_head_id} | ${item.s1_head_rarity} | ${item.s1_head_level}` : undefined },
        { value: (item.s1_top_id !== null) ? `${item.s1_top_id} | ${item.s1_top_rarity} | ${item.s1_top_level}` : undefined },
        { value: (item.s1_bottom_id !== null) ? `${item.s1_bottom_id} | ${item.s1_bottom_rarity} | ${item.s1_bottom_level}` : undefined },
        { value: (item.s1_shoes_id !== null) ? `${item.s1_shoes_id} | ${item.s1_shoes_rarity} | ${item.s1_shoes_level}` : undefined },
        { value: (item.s2_id !== null) ? `${item.s2_id} | ${item.s2_rarity} | ${item.s2_level}` : undefined },
        { value: (item.s2_weapon_id !== null) ? `${item.s2_weapon_id} | ${item.s2_weapon_rarity} | ${item.s2_weapon_level}` : undefined },
        { value: (item.s2_head_id !== null) ? `${item.s2_head_id} | ${item.s2_head_rarity} | ${item.s2_head_level}` : undefined },
        { value: (item.s2_top_id !== null) ? `${item.s2_top_id} | ${item.s2_top_rarity} | ${item.s2_top_level}` : undefined },
        { value: (item.s2_bottom_id !== null) ? `${item.s2_bottom_id} | ${item.s2_bottom_rarity} | ${item.s2_bottom_level}` : undefined },
        { value: (item.s2_shoes_id !== null) ? `${item.s2_shoes_id} | ${item.s2_shoes_rarity} | ${item.s2_shoes_level}` : undefined },
        { value: (item.s3_id !== null) ? `${item.s3_id} | ${item.s3_rarity} | ${item.s3_level}` : undefined },
        { value: (item.s3_weapon_id !== null) ? `${item.s3_weapon_id} | ${item.s3_weapon_rarity} | ${item.s3_weapon_level}` : undefined },
        { value: (item.s3_head_id !== null) ? `${item.s3_head_id} | ${item.s3_head_rarity} | ${item.s3_head_level}` : undefined },
        { value: (item.s3_top_id !== null) ? `${item.s3_top_id} | ${item.s3_top_rarity} | ${item.s3_top_level}` : undefined },
        { value: (item.s3_bottom_id !== null) ? `${item.s3_bottom_id} | ${item.s3_bottom_rarity} | ${item.s3_bottom_level}` : undefined },
        { value: (item.s3_shoes_id !== null) ? `${item.s3_shoes_id} | ${item.s3_shoes_rarity} | ${item.s3_shoes_level}` : undefined },
        { value: (item.s4_id !== null) ? `${item.s4_id} | ${item.s4_rarity} | ${item.s4_level}` : undefined },
        { value: (item.s4_weapon_id !== null) ? `${item.s4_weapon_id} | ${item.s4_weapon_rarity} | ${item.s4_weapon_level}` : undefined },
        { value: (item.s4_head_id !== null) ? `${item.s4_head_id} | ${item.s4_head_rarity} | ${item.s4_head_level}` : undefined },
        { value: (item.s4_top_id !== null) ? `${item.s4_top_id} | ${item.s4_top_rarity} | ${item.s4_top_level}` : undefined },
        { value: (item.s4_bottom_id !== null) ? `${item.s4_bottom_id} | ${item.s4_bottom_rarity} | ${item.s4_bottom_level}` : undefined },
        { value: (item.s4_shoes_id !== null) ? `${item.s4_shoes_id} | ${item.s4_shoes_rarity} | ${item.s4_shoes_level}` : undefined },
        { value: (item.s5_id !== null) ? `${item.s5_id} | ${item.s5_rarity} | ${item.s5_level}` : undefined },
        { value: (item.s5_weapon_id !== null) ? `${item.s5_weapon_id} | ${item.s5_weapon_rarity} | ${item.s5_weapon_level}` : undefined },
        { value: (item.s5_head_id !== null) ? `${item.s5_head_id} | ${item.s5_head_rarity} | ${item.s5_head_level}` : undefined },
        { value: (item.s5_top_id !== null) ? `${item.s5_top_id} | ${item.s5_top_rarity} | ${item.s5_top_level}` : undefined },
        { value: (item.s5_bottom_id !== null) ? `${item.s5_bottom_id} | ${item.s5_bottom_rarity} | ${item.s5_bottom_level}` : undefined },
        { value: (item.s5_shoes_id !== null) ? `${item.s5_shoes_id} | ${item.s5_shoes_rarity} | ${item.s5_shoes_level}` : undefined },
      ];
    });
  }, [rawData]);

  const guiFormOfCounterData = useMemo(() => {
    if (rawDataCounter !== undefined && rawDataCounter.length > 0) {
      return rawDataCounter[0].total_items;
    }
    return 1;
  }, [rawDataCounter]);

  const onDownload = async () => {
    //let endUnix = dateStrToUnix(rangeDate.end, timezone);
    //if (endUnix) {
    //  endUnix += 86400000000;
    //}
    const res = await getUserEquipment({
      params: {
      },
      data: {
        userID: userID,
        //startUnix: dateStrToUnix(rangeDate.start, timezone),
        //endUnix: endUnix,
      }
    });
    writeExcelFile(res, toExcelFileName('User Equipment'), 'json');
  };

  const isLoading = rawDataLoading || rawDataCounterLoading;
  const timezone = (currentApp && appTimezoneConst[currentApp.id]) ? appTimezoneConst[currentApp.id] : 0;
  
  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex gap-0 flex-wrap">
          {
            guiFormOfRawData && guiFormOfRawData.length > 0 && (
              <RawDataTable
                isCenterFull
                title="User Equipment"
                headers={[
                  'User ID',
                  'Hero 1',
                  'Hero 1 - Weapon',
                  'Hero 1 - Head',
                  'Hero 1 - Top',
                  'Hero 1 - Bottom',
                  'Hero 1 - Shoes',
                  'Hero 2',
                  'Hero 2 - Weapon',
                  'Hero 2 - Head',
                  'Hero 2 - Top',
                  'Hero 2 - Bottom',
                  'Hero 2 - Shoes',
                  'Hero 3',
                  'Hero 3 - Weapon',
                  'Hero 3 - Head',
                  'Hero 3 - Top',
                  'Hero 3 - Bottom',
                  'Hero 3 - Shoes',
                  'Hero 4',
                  'Hero 4 - Weapon',
                  'Hero 4 - Head',
                  'Hero 4 - Top',
                  'Hero 4 - Bottom',
                  'Hero 4 - Shoes',
                  'Hero 5',
                  'Hero 5 - Weapon',
                  'Hero 5 - Head',
                  'Hero 5 - Top',
                  'Hero 5 - Bottom',
                  'Hero 5 - Shoes',
                ]}
                data={guiFormOfRawData.map((item) => {
                  return item.map((subItem) => {
                    return subItem.value;
                  });
                })}
                onDownload={onDownload}
              />
            )
          }
          <Pager
            page={userEquipmentPage}
            totalPage={Math.floor((guiFormOfCounterData + 9) / 10)}
            setPage={setUserEquipmentPage}
          />
        </div>
      )}
    </div>
  );
};

export default DaymareUserEquipment;
