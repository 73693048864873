const ThemeButton = {
  paddingContentHorizontal: 16,
  paddingContentVertical: 14,
  fontSize: 16,
  controlHeight: 42,
  borderRadius: 6,
  controlHeightSM: 36,
  borderRadiusSM: 8,
};

export default ThemeButton;
