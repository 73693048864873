import React from 'react';

import googleIcon from 'content/assets/images/google-icon.svg';

import AuthConsumer from 'hooks/useAuth';
import useDocumentTitle from 'hooks/useDocumentTitle';

import Logo from 'components/shared/Logo';

const SignInPage: React.FC = () => {
  const { signIn } = AuthConsumer();

  useDocumentTitle('Sign In');

  return (
    <div className="flex flex-col items-center">
      <Logo height={76} type="white" />
      <h1 className="mt-7 mb-9 text-[42px] font-[700] text-white">WELCOME</h1>

      <button
        onClick={signIn}
        className="flex w-[100%] cursor-pointer items-center justify-center gap-[10px] rounded-[15px] border-none bg-white px-4 py-4 shadow-primary-shadow outline-none hover:shadow-primary-shadow-hover min-[400px]:w-[360px]"
      >
        <img src={googleIcon} alt="google" className="h-5" />
        <span className="text-[18px] font-[500] leading-5">Sign In With Google</span>
      </button>
    </div>
  );
};

export default SignInPage;
