import { useQuery } from '@tanstack/react-query';
import React from 'react';

import LayoutContextConsumer from 'contexts/LayoutContext';

import RawDataTable from 'components/feature/charts/RawDataTable';
import Loading from 'components/shared/Loading';

import mimilandApi from 'config/api/bigquery/mimilandApi';
import { IVersionHistory } from 'config/api/version-history/versionHistoryApi';

import { Empty } from 'antd';

interface Props {
  rangeDate: {
    start?: string;
    end?: string;
  };
  versionHistoryData?: IVersionHistory[];
  version: string | null;
}

interface IAvgEmote {
  time: string;
  times: number;
  users: number;
  DAU: number;
}

const { getMimilandAvgEmote } = mimilandApi;

const AvgEmote = ({ rangeDate, version }: Props) => {
  const { platform } = LayoutContextConsumer();

  const { data, isLoading } = useQuery(['avg-emote', rangeDate, version, platform], () => {
    if (!rangeDate.start || !rangeDate.end) return [];

    const res = getMimilandAvgEmote({
      platform: platform === 'unified' ? undefined : platform,
      version,
      start: rangeDate.start,
      end: rangeDate.end,
    });

    return res as unknown as IAvgEmote[];
  });

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : data && data.length > 0 ? (
        <RawDataTable
          isCenterFull
          title="Avg Emote"
          headers={['Date', 'Times', 'Users', 'DAU', 'Average']}
          data={
            data.map((item): [string, string, string, string, string] => [
              item.time,
              item.times.toFixed(0),
              item.users.toFixed(0),
              item.DAU.toFixed(0),
              (item.times / item.users).toFixed(2),
            ]) ?? []
          }
        />
      ) : (
        <Empty />
      )}
    </div>
  );
};

export default AvgEmote;
