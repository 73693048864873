import { AiFillFunnelPlot, AiOutlineLineChart } from 'react-icons/ai';
import { MdOutlineAttachMoney } from 'react-icons/md';
import { RiFileChartFill, RiLayoutMasonryFill, RiSettings3Fill } from 'react-icons/ri';
import { TbReportAnalytics, TbVersionsFilled } from 'react-icons/tb';
import { TiHome } from 'react-icons/ti';
import Timezone from 'components/shared/Timezone';
import userRole from 'config/constants/userRole';
import SidebarItem, { ISidebarItem } from './SidebarItem';
import { FaHandsHelping } from 'react-icons/fa';

const listItems: ISidebarItem[] = [
  {
    id: 'overview',
    link: 'overview',
    icon: <TiHome size={18} />,
    label: 'Overview',
    viewableRoles: Object.values(userRole),
  },
  {
    id: 'dashboard',
    link: 'dashboard',
    icon: <RiLayoutMasonryFill size={18} />,
    label: 'Dashboard',
  },
  {
    id: 'cohorts',
    link: 'cohorts',
    icon: <RiFileChartFill size={18} />,
    label: 'Cohorts',
  },
  {
    id: 'funnels',
    link: 'funnels',
    icon: <AiFillFunnelPlot size={18} />,
    label: 'Funnels',
  },
  {
    id: 'reports',
    link: 'reports',
    icon: <TbReportAnalytics size={18} />,
    label: 'Reports',
    viewableRoles: [userRole.SUPERADMIN, userRole.ADMIN],
  },
  {
    id: 'custom-tracking',
    link: 'custom-tracking',
    icon: <AiOutlineLineChart size={18} />,
    label: 'Custom Tracking',
  },
  {
    id: 'monetization',
    link: 'monetization',
    icon: <MdOutlineAttachMoney size={18} />,
    label: 'Monetization',
    viewableRoles: Object.values(userRole),
  },
  {
    id: 'branding',
    link: 'branding',
    icon: <FaHandsHelping size={18} />,
    label: 'Branding',
    viewableRoles: [userRole.SUPERADMIN, userRole.ADMIN],
  },
  {
    id: 'version-history',
    link: 'version-history',
    icon: <TbVersionsFilled size={18} />,
    label: 'Version History',
  },
  {
    id: 'setting',
    link: 'setting',
    icon: <RiSettings3Fill size={18} />,
    label: 'Setting',
  },
];

const Sidebar = () => {
  return (
    <div className="flex h-full flex-col gap-8 p-2 min-[450px]:p-4 md:p-6 lg:p-8">
      {listItems.map((item) => {
        return <SidebarItem key={item.id} {...item} />;
      })}

      <div className="mx-auto mt-auto">
        <Timezone />
      </div>
    </div>
  );
};

export default Sidebar;
