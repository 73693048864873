import toastHelper from 'config/helpers/toastHelper';
import { DataType, OperatorType, OrderType } from 'config/types/general';

import api from '..';

import { AxiosRequestConfig } from 'axios';

export interface CustomQuery {
  id: string;
  name: string;
  fields: string[];
  limit: number;
  order: {
    field: string;
    type: OrderType;
  };
  condition: {
    field: string;
    operator: OperatorType;
    value: any;
    type: DataType;
  }[];
  game: string;
  user?: string;
}

export interface CustomQueryResponse {
  query: string;
  data: {
    [key: string]: any;
  }[];
  queryReq: CustomQuery;
}

export type CustomQueryGameSchema = {
  field: string;
  type: DataType;
};

const customQueryApi = {
  runCustomQuery: (data: Omit<CustomQuery, 'id'>, config?: AxiosRequestConfig) => {
    return api.post('/custom-query', data, config);
  },
  getCustomQueries: (config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game) {
      return api.get('/custom-query', config);
    } else {
      toastHelper.error('Please select a game first');
      return;
    }
  },
  getCustomQuery: (id: string, config?: AxiosRequestConfig) => {
    return api.get(`/custom-query/${id}`, config);
  },
  saveQuery: (data: Omit<CustomQuery, 'id'>, config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game) {
      return api.put(`/custom-query/${game}`, data, config);
    } else {
      toastHelper.error('Please select a game first');
      return;
    }
  },
  updateQuery: (id: string, data: CustomQuery, config?: AxiosRequestConfig) => {
    return api.post(`/custom-query/${id}`, data, config);
  },
  deleteCustomQuery: (id: string, config?: AxiosRequestConfig) => {
    return api.delete(`/custom-query/${id}`, config);
  },
  getGameSchema: (config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game) {
      return api.get(`/custom-query/schema/${game}`, config);
    } else {
      toastHelper.error('Please select a game first');
      return;
    }
  },
  updateGameSchema: (data: CustomQueryGameSchema[], config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game) {
      return api.post(
        `/custom-query/schema/${game}`,
        {
          schema: data,
        },
        config,
      );
    } else {
      toastHelper.error('Please select a game first');
    }
  },
};

export default customQueryApi;
