import React, { useMemo } from 'react';

import {
  BLUE_COLOR,
  GREEN_COLOR,
  PINK_COLOR,
  PURPLE_COLOR,
  SECONDARY_COLOR,
  YELLOW_COLOR,
  generateColor,
} from 'config/constants/theme';

import { DualAxes, DualAxesConfig } from '@ant-design/plots';

export const lineChartColors = [
  SECONDARY_COLOR,
  YELLOW_COLOR,
  PURPLE_COLOR,
  PINK_COLOR,
  GREEN_COLOR,
  '#060047',
  BLUE_COLOR,
  ...generateColor(),
];

interface Props extends DualAxesConfig {
  data: any[];
  compareData?: any[];
  seriesField?: string[];
}

const geometryBase = {
  geometry: 'line',
  lineStyle: {
    lineWidth: 3,
  },
  smooth: true,
  color: lineChartColors,
  point: {
    size: 5,
  },
};

const geometryCompare = {
  ...geometryBase,
  lineStyle: {
    lineWidth: 1,
    lineDash: [5, 5],
    opacity: 0.8,
  },
  point: {
    size: 0,
  },
};

const LineChartCompare = ({ data, compareData, seriesField, xField, yField, height, ...props }: Props) => {
  const compare = useMemo(() => {
    const xFields = Array.from(new Set(data.map((item) => item[xField]))).sort((a, b) => a.localeCompare(b));
    const cpXFields = Array.from(new Set(compareData?.map((item) => item[xField]))).sort((a, b) => a.localeCompare(b));

    const newCpData = [];

    if (!compareData || !cpXFields) {
      return [data];
    }

    for (let i = 0; i < xFields.length && i < cpXFields.length; i++) {
      const cp = compareData?.filter((item) => item[xField] === cpXFields[i]);

      if (cp?.length > 0) {
        newCpData.push(...cp.map((item) => ({ ...item, [xField]: xFields[i] })));
      }
    }

    return [data, newCpData];
  }, [data, compareData, xField]);

  const config: DualAxesConfig = {
    data: compare,
    xField,
    yField,
    height,
    legend: {
      position: 'bottom',
      marker: (_, index) => {
        return {
          symbol: 'circle',
          style: {
            fill: lineChartColors[index],
          },
        };
      },
    },
    xAxis: {
      range: [0, 1],
      label: {
        autoRotate: true,
      },
    },
    yAxis: [
      {
        min: 0,
      },
    ],

    geometryOptions: seriesField
      ? [
          {
            seriesField: seriesField[0],
            ...geometryBase,
          },
          {
            seriesField: seriesField[1],
            ...geometryCompare,
            legend: false,
          },
        ]
      : [geometryBase, geometryCompare],
    ...props,
  };

  return <DualAxes {...config} />;
};

export default React.memo(LineChartCompare);
