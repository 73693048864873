enum resourceEnum {
  DASHBOARD = 'dashboard',
  COHORT = 'cohort',
  FUNNEL = 'funnel',
  EXPLORE = 'explore',
  REMOTE_CONFIG = 'remote-config',
  MONETIZATION = 'monetization',
  VERSION_HISTORY = 'version-history',
  SETTING = 'setting',
  ALL = '*',
}

export const resourcePermission = {
  'admin': [resourceEnum.EXPLORE, resourceEnum.REMOTE_CONFIG, resourceEnum.MONETIZATION],
  'viewer': [],
  'editor': [resourceEnum.EXPLORE, resourceEnum.REMOTE_CONFIG, resourceEnum.MONETIZATION],
  'financial': [resourceEnum.MONETIZATION],
  'financial-edit': [resourceEnum.MONETIZATION],
  'version-history': [resourceEnum.VERSION_HISTORY],
};

export default resourceEnum;
