import React, { ChangeEvent } from 'react';
import { Input } from 'antd';

interface IUserIDInput {
  value: string;
  onValueChanged: (newValue: string) => void;
}

const UserIDInput: React.FC<IUserIDInput> = ({ value, onValueChanged }: IUserIDInput) => {

  const inputFile = React.useRef<HTMLInputElement | null>(null);

  const onButtonClick = () => {
    inputFile.current?.click();
  };

  const onFileChanged = (e: ChangeEvent<HTMLInputElement>): void => {
    e.preventDefault();
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const blob = new Blob([file], { type: 'text/plain' });
      const reader = new FileReader();
      reader.onload = async (e1) => {
        value = (e1.target?.result as unknown as string);
        onValueChanged(value);
      };
      reader.readAsText(blob);
    }
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    onValueChanged(e.target.value);
  };

  return (
    <div className="">
      <h6 className="mb-2 text-16 font-[500]">User ID</h6>
      <Input id="txt-user-id" type="text" value={value} style={{ width: '200px' }} onChange={onInputChange} />
      <input type='file' id='file' ref={inputFile} style={{ display: 'none' }} onChange={onFileChanged} />
      <button className="import-button" onClick={onButtonClick} style={{ margin:'0px 0px 0px 2px' }}>Import</button>
    </div>
  );
};

export default UserIDInput;