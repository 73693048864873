import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import AuthConsumer from 'hooks/useAuth';

const RequireAuth = () => {
  const { isAuth } = AuthConsumer();
  return isAuth ? <Outlet /> : <Navigate to="/auth/sign-in" replace />;
};

export default RequireAuth;
