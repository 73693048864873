import { useQuery } from '@tanstack/react-query';

import RawDataTable from 'components/feature/charts/RawDataTable';
import Loading from 'components/shared/Loading';

import skyDancer2Api from 'config/api/bigquery/skyDancer2Api';
import { IVersionHistory } from 'config/api/version-history/versionHistoryApi';

import { Empty } from 'antd';

interface Props {
  rangeDate: {
    start?: string;
    end?: string;
  };
  versionHistoryData?: IVersionHistory[];
  version: string | null;
}

interface IStageProgression {
  stage: string;
  install_source: string;
  completed_users: number;
}

const { getStageProgression } = skyDancer2Api;

const SkyStageProgression = ({ rangeDate, version }: Props) => {
  const { data, isLoading } = useQuery(['stage-progression', version], async () => {
    if (!rangeDate.start || !rangeDate.end) return [];

    const res = await getStageProgression({
      params: {
        version,
      },
    });

    return res as unknown as IStageProgression[];
  });

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : data && data.length > 0 ? (
        <div className="overflow-x-auto">
          <RawDataTable
            isCenterFull
            title="Stage Progression"
            headers={['Stage', 'Install Source', 'Completed Users']}
            data={data
              .sort((a, b) => {
                const [aMajor, aMinor] = a.stage.split('-').map(Number);
                const [bMajor, bMinor] = b.stage.split('-').map(Number);

                if (aMajor !== bMajor) {
                  return aMajor - bMajor;
                } else {
                  return aMinor - bMinor;
                }
              })
              .map((item): [string, string, string] => [
                item.stage,
                item.install_source,
                item.completed_users.toFixed(0),
              ])}
          />
        </div>
      ) : (
        <Empty />
      )}
    </div>
  );
};

export default SkyStageProgression;
