import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { BiCoinStack, BiUser } from 'react-icons/bi';

import LayoutContextConsumer from 'contexts/LayoutContext';

import RawDataTable from 'components/feature/charts/RawDataTable';
import Loading from 'components/shared/Loading';

import skyDancer2Api from 'config/api/bigquery/skyDancer2Api';
import { IVersionHistory } from 'config/api/version-history/versionHistoryApi';
import numberHelper from 'config/helpers/numberHelper';

interface Props {
  rangeDate: {
    start?: string;
    end?: string;
  };
  versionHistoryData?: IVersionHistory[];
  version: string | null;
  country: string;
}

interface IResourceCoinIncome {
  time: string;
  platform: string;
  DAU: number;
  end_game: number;
  end_game_users: number;
  offline_earning: number;
  offline_earning_users: number;
  quick_earning: number;
  quick_earning_users: number;
  growth_fund: number;
  growth_fund_users: number;
  daily_quest: number;
  daily_quest_users: number;
  daily_deal: number;
  daily_deal_users: number;
  campaign: number;
  campaign_users: number;
  weekly_quest: number;
  weekly_quest_users: number;
  reset_item: number;
  reset_item_users: number;
  coin_pack_in_shop: number;
  coin_pack_in_shop_users: number;
  achievement: number;
  achievement_users: number;
  old_user: number;
  old_user_users: number;
  daily_reward: number;
  daily_reward_users: number;
  iap: number;
  iap_users: number;
  newbie_gift: number;
  newbie_gift_users: number;
  event_beginer: number;
  event_beginer_users: number;
}

interface IResourceCoinExpend {
  time: string;
  platform: string;
  DAU: number;
  unlock_talent: number;
  unlock_talent_users: number;
  daily_deal: number;
  daily_deal_users: number;
  upgrade_item: number;
  upgrade_item_users: number;
}

interface IResourceGemExpend {
  time: string;
  platform: string;
  DAU: number;
  replenish_energy: number;
  replenish_energy_users: number;
  buy_equipment_deal: number;
  buy_equipment_deal_users: number;
  buy_scroll_deal: number;
  buy_scroll_deal_users: number;
  buy_chest: number;
  buy_chest_SPECIAL_CHEST_X10: number;
  buy_chest_SPECIAL_CHEST_X1: number;
  buy_chest_DIAMOND_CHEST: number;
  buy_chest_BRONZE_CHEST: number;
  buy_chest_users: number;
  buy_chest_SPECIAL_CHEST_X10_users: number;
  buy_chest_SPECIAL_CHEST_X1_users: number;
  buy_chest_DIAMOND_CHEST_users: number;
  buy_chest_BRONZE_CHEST_users: number;
  buy_coins_packs: number;
  buy_coins_packs_users: number;
  reset_daily_deals: number;
  reset_daily_deals_users: number;
}

interface IResourceRubyExpend {
  time: string
  platform: string
  DAU: number
  adventure_swipe_amount: number
  boss_raid_x1_amount: number
  boss_raid_x5_amount: number
  boss_raid_x10_amount: number
  skip_ads_amount: number
  adventure_swipe_users: number
  boss_raid_x1_users: number
  boss_raid_x5_users: number
  boss_raid_x10_users: number
  skip_ads_users: number
}

interface IResourceGemIncome {
  time: string;
  platform: string;
  DAU: number;
  level_up: number;
  level_up_users: number;
  event_beginer: number;
  event_beginer_users: number;
  newbie_gift: number;
  newbie_gift_users: number;
  growth_fund: number;
  growth_fund_users: number;
  daily_deal: number;
  daily_deal_users: number;
  iap: number;
  iap_users: number;
  campaign: number;
  campaign_users: number;
  daily_reward: number;
  daily_reward_users: number;
  daily_quest: number;
  daily_quest_users: number;
  weekly_quest: number;
  weekly_quest_users: number;
  achievement: number;
  achievement_users: number;
}

const { getResource, getResourceV2 } = skyDancer2Api;

const { convertText } = numberHelper;

const ResourceItem = ({ users, value }: { users: string; value: string }) => {
  return (
    <div className="">
      <div className="flex items-center gap-1">
        <BiCoinStack className="text-12" />
        <p className="text-14">{value}</p>
      </div>
      <div className="flex items-center gap-1 whitespace-nowrap">
        <BiUser className="text-12" />
        <p className="text-14">{users}</p>
      </div>
    </div>
  );
};

const SkyResource = ({ rangeDate, versionHistoryData, version, country }: Props) => {
  const { platform } = LayoutContextConsumer();

  const { data: coinIncomeData, isLoading: coinIncomeLoading } = useQuery(
    ['resource-coin-income', version, rangeDate, country, platform],
    async () => {
      if (!rangeDate.start || !rangeDate.end) return [];

      const res = await getResource({
        params: {
          version,
          platform: platform === 'unified' ? undefined : platform,
          start: rangeDate.start,
          end: rangeDate.end,
          type: 'coin_income',
        },
      });

      return res as unknown as IResourceCoinIncome[];
    },
  );

  const { data: rubyExpendData, isLoading: rubyExpendLoading } = useQuery(
    ['resource-ruby-expend', version, rangeDate, country, platform],
    async () => {
      if (!rangeDate.start || !rangeDate.end) return [];

      const res = await getResourceV2({
        params: {
          version,
          platform: platform === 'unified' ? undefined : platform,
          start: rangeDate.start,
          end: rangeDate.end,
          type: 'ruby_expend',
        },
      });
      console.debug('res', res);

      return res as unknown as IResourceRubyExpend[];
    },
  );



  const { data: coinExpendData, isLoading: coinExpendLoading } = useQuery(
    ['resource-expend-data', version, rangeDate, country, platform],
    async () => {
      if (!rangeDate.start || !rangeDate.end) return [];

      const res = await getResource({
        params: {
          version,
          platform: platform === 'unified' ? undefined : platform,
          start: rangeDate.start,
          end: rangeDate.end,
          type: 'coin_expend',
        },
      });

      return res as unknown as IResourceCoinExpend[];
    },
  );

  const { data: gemIncomeData, isLoading: gemIncomeLoading } = useQuery(
    ['resource-gem-income', version, rangeDate, country, platform],
    async () => {
      if (!rangeDate.start || !rangeDate.end) return [];

      const res = await getResource({
        params: {
          version,
          platform: platform === 'unified' ? undefined : platform,
          start: rangeDate.start,
          end: rangeDate.end,
          type: 'gem_income',
        },
      });

      return res as unknown as IResourceGemIncome[];
    },
  );

  const { data: gemExpendData, isLoading: gemExpendLoading } = useQuery(
    ['resource-gem-expend', version, rangeDate, country, platform],
    async () => {
      if (!rangeDate.start || !rangeDate.end) return [];

      const res = await getResource({
        params: {
          version,
          platform: platform === 'unified' ? undefined : platform,
          start: rangeDate.start,
          end: rangeDate.end,
          type: 'gem_expend',
        },
      });

      return res as unknown as IResourceGemExpend[];
    },
  );

  const rubyExpendTableData = useMemo(() => {
    return rubyExpendData?.map((item) => {
      return [
        {
          value: item.time,
          users: null,
        },
        {
          value: item.platform,
          users: null,
        },
        {
          value: item.adventure_swipe_amount,
          users: `${convertText(item.adventure_swipe_users)} (${((item.adventure_swipe_users / item.DAU) * 100).toFixed(1)}%)`,
          userRaw: item.adventure_swipe_users,
        },
        {
          value: item.boss_raid_x1_amount,
          users: `${convertText(item.boss_raid_x1_users)} (${((item.boss_raid_x1_users / item.DAU) * 100).toFixed(1)}%)`,
          userRaw: item.boss_raid_x1_users,
        },
        {
          value: item.boss_raid_x5_amount,
          users: `${convertText(item.boss_raid_x5_users)} (${((item.boss_raid_x5_users / item.DAU) * 100).toFixed(1)}%)`,
          userRaw: item.boss_raid_x5_users,
        },
        {
          value: item.boss_raid_x10_amount,
          users: `${convertText(item.boss_raid_x10_users)} (${((item.boss_raid_x10_users / item.DAU) * 100).toFixed(1)}%)`,
          userRaw: item.boss_raid_x10_users,
        },
        {
          value: item.skip_ads_amount,
          users: `${convertText(item.skip_ads_users)} (${((item.skip_ads_users / item.DAU) * 100).toFixed(1)}%)`,
          userRaw: item.skip_ads_users,
        },
      ];
    });
  }, [rubyExpendData]);

  const coinIncomeTableData = useMemo(() => {
    return coinIncomeData?.map((item) => {
      return [
        {
          value: item.time,
          users: null,
          usersRaw: null,
        },
        {
          value: item.platform,
          users: null,
          usersRaw: null,
        },
        {
          value: item.end_game,
          users: `${convertText(item.end_game_users)} (${((item.end_game_users / item.DAU) * 100).toFixed(1)}%)`,
          usersRaw: item.end_game_users,
        },
        {
          value: item.offline_earning,
          users: `${convertText(item.offline_earning_users)} (${((item.offline_earning_users / item.DAU) * 100).toFixed(
            1,
          )}%)`,
          usersRaw: item.offline_earning_users,
        },
        {
          value: item.quick_earning,
          users: `${convertText(item.quick_earning_users)} (${((item.quick_earning_users / item.DAU) * 100).toFixed(
            1,
          )}%)`,
          usersRaw: item.quick_earning_users,
        },
        {
          value: item.growth_fund,
          users: `${convertText(item.growth_fund_users)} (${((item.growth_fund_users / item.DAU) * 100).toFixed(1)}%)`,
          usersRaw: item.growth_fund_users,
        },
        {
          value: item.daily_quest,
          users: `${convertText(item.daily_quest_users)} (${((item.daily_quest_users / item.DAU) * 100).toFixed(1)}%)`,
          usersRaw: item.daily_quest_users,
        },
        {
          value: item.daily_deal,
          users: `${convertText(item.daily_deal_users)} (${((item.daily_deal_users / item.DAU) * 100).toFixed(1)}%)`,
          usersRaw: item.daily_deal_users,
        },
        {
          value: item.campaign,
          users: `${convertText(item.campaign_users)} (${((item.campaign_users / item.DAU) * 100).toFixed(1)}%)`,
          usersRaw: item.campaign_users,
        },
        {
          value: item.weekly_quest,
          users: `${convertText(item.weekly_quest_users)} (${((item.weekly_quest_users / item.DAU) * 100).toFixed(
            1,
          )}%)`,
          usersRaw: item.weekly_quest_users,
        },
        {
          value: item.reset_item,
          users: `${convertText(item.reset_item_users)} (${((item.reset_item_users / item.DAU) * 100).toFixed(1)}%)`,
          usersRaw: item.reset_item_users,
        },
        {
          value: item.coin_pack_in_shop,
          users: `${convertText(item.coin_pack_in_shop_users)} (${(
            (item.coin_pack_in_shop_users / item.DAU) *
            100
          ).toFixed(1)}%)`,
          usersRaw: item.coin_pack_in_shop_users,
        },
        {
          value: item.achievement,
          users: `${convertText(item.achievement_users)} (${((item.achievement_users / item.DAU) * 100).toFixed(1)}%)`,
          usersRaw: item.achievement_users,
        },
        {
          value: item.old_user,
          users: `${convertText(item.old_user_users)} (${((item.old_user_users / item.DAU) * 100).toFixed(1)}%)`,
          usersRaw: item.old_user_users,
        },
        {
          value: item.daily_reward,
          users: `${convertText(item.daily_reward_users)} (${((item.daily_reward_users / item.DAU) * 100).toFixed(
            1,
          )}%)`,
          usersRaw: item.daily_reward_users,
        },
        {
          value: item.iap,
          users: `${convertText(item.iap_users)} (${((item.iap_users / item.DAU) * 100).toFixed(1)}%)`,
          usersRaw: item.iap_users,
        },
        {
          value: item.newbie_gift,
          users: `${convertText(item.newbie_gift_users)} (${((item.newbie_gift_users / item.DAU) * 100).toFixed(1)}%)`,
          usersRaw: item.newbie_gift_users,
        },
        {
          value: item.event_beginer,
          users: `${convertText(item.event_beginer_users)} (${((item.event_beginer_users / item.DAU) * 100).toFixed(
            1,
          )}%)`,
          usersRaw: item.event_beginer_users,
        },
      ];
    });
  }, [coinIncomeData]);

  const coinExpendTableData = useMemo(() => {
    return coinExpendData?.map((item) => {
      return [
        {
          value: item.time,
          users: null,
        },
        {
          value: item.platform,
          users: null,
        },
        {
          value: item.unlock_talent,
          users: `${convertText(item.unlock_talent_users)} (${((item.unlock_talent_users / item.DAU) * 100).toFixed(
            1,
          )}%)`,
        },
        {
          value: item.daily_deal,
          users: `${convertText(item.daily_deal_users)} (${((item.daily_deal_users / item.DAU) * 100).toFixed(1)}%)`,
        },
        {
          value: item.upgrade_item,
          users: `${convertText(item.upgrade_item_users)} (${((item.upgrade_item_users / item.DAU) * 100).toFixed(
            1,
          )}%)`,
        },
      ];
    });
  }, [coinExpendData]);

  const gemIncomeTableData = useMemo(() => {
    return gemIncomeData?.map((item) => {
      return [
        {
          value: item.time,
          users: null,
        },
        {
          value: item.platform,
          users: null,
        },
        {
          value: item.level_up,
          users: `${convertText(item.level_up_users)} (${((item.level_up_users / item.DAU) * 100).toFixed(1)}%)`,
        },
        {
          value: item.event_beginer,
          users: `${convertText(item.event_beginer_users)} (${((item.event_beginer_users / item.DAU) * 100).toFixed(
            1,
          )}%)`,
        },
        {
          value: item.newbie_gift,
          users: `${convertText(item.newbie_gift_users)} (${((item.newbie_gift_users / item.DAU) * 100).toFixed(1)}%)`,
        },
        {
          value: item.growth_fund,
          users: `${convertText(item.growth_fund_users)} (${((item.growth_fund_users / item.DAU) * 100).toFixed(1)}%)`,
        },
        {
          value: item.daily_deal,
          users: `${convertText(item.daily_deal_users)} (${((item.daily_deal_users / item.DAU) * 100).toFixed(1)}%)`,
        },
        {
          value: item.iap,
          users: `${convertText(item.iap_users)} (${((item.iap_users / item.DAU) * 100).toFixed(1)}%)`,
        },
        {
          value: item.campaign,
          users: `${convertText(item.campaign_users)} (${((item.campaign_users / item.DAU) * 100).toFixed(1)}%)`,
        },
        {
          value: item.daily_reward,
          users: `${convertText(item.daily_reward_users)} (${((item.daily_reward_users / item.DAU) * 100).toFixed(
            1,
          )}%)`,
        },
        {
          value: item.daily_quest,
          users: `${convertText(item.daily_quest_users)} (${((item.daily_quest_users / item.DAU) * 100).toFixed(1)}%)`,
        },
        {
          value: item.weekly_quest,
          users: `${convertText(item.weekly_quest_users)} (${((item.weekly_quest_users / item.DAU) * 100).toFixed(
            1,
          )}%)`,
        },
        {
          value: item.achievement,
          users: `${convertText(item.achievement_users)} (${((item.achievement_users / item.DAU) * 100).toFixed(1)}%)`,
        },
      ];
    });
  }, [gemIncomeData]);

  const gemExpendTableData = useMemo(() => {
    return gemExpendData?.map((item) => {
      return [
        {
          value: item.time,
          users: null,
        },
        {
          value: item.platform,
          users: null,
        },
        {
          value: item.replenish_energy,
          users: `${convertText(item.replenish_energy_users)} (${(
            (item.replenish_energy_users / item.DAU) *
            100
          ).toFixed(1)}%)`,
        },
        {
          value: item.buy_equipment_deal,
          users: `${convertText(item.buy_equipment_deal_users)} (${(
            (item.buy_equipment_deal_users / item.DAU) *
            100
          ).toFixed(1)}%)`,
        },
        {
          value: item.buy_scroll_deal,
          users: `${convertText(item.buy_scroll_deal_users)} (${((item.buy_scroll_deal_users / item.DAU) * 100).toFixed(
            1,
          )}%)`,
        },
        {
          value: item.buy_chest,
          users: `${convertText(item.buy_chest_users)} (${((item.buy_chest_users / item.DAU) * 100).toFixed(1)}%)`,
        },
        {
          value: item.buy_chest_SPECIAL_CHEST_X10,
          users: `${convertText(item.buy_chest_SPECIAL_CHEST_X10_users)} (${(
            (item.buy_chest_SPECIAL_CHEST_X10_users / item.DAU) *
            100
          ).toFixed(1)}%)`,
        },
        {
          value: item.buy_chest_SPECIAL_CHEST_X1,
          users: `${convertText(item.buy_chest_SPECIAL_CHEST_X1_users)} (${(
            (item.buy_chest_SPECIAL_CHEST_X1_users / item.DAU) *
            100
          ).toFixed(1)}%)`,
        },
        {
          value: item.buy_chest_DIAMOND_CHEST,
          users: `${convertText(item.buy_chest_DIAMOND_CHEST_users)} (${(
            (item.buy_chest_DIAMOND_CHEST_users / item.DAU) *
            100
          ).toFixed(1)}%)`,
        },
        {
          value: item.buy_chest_BRONZE_CHEST,
          users: `${convertText(item.buy_chest_BRONZE_CHEST_users)} (${(
            (item.buy_chest_BRONZE_CHEST_users / item.DAU) *
            100
          ).toFixed(1)}%)`,
        },
        {
          value: item.buy_coins_packs,
          users: `${convertText(item.buy_coins_packs_users)} (${((item.buy_coins_packs_users / item.DAU) * 100).toFixed(
            1,
          )}%)`,
        },
        {
          value: item.reset_daily_deals,
          users: `${convertText(item.reset_daily_deals_users)} (${(
            (item.reset_daily_deals_users / item.DAU) *
            100
          ).toFixed(1)}%)`,
        },
      ];
    });
  }, [gemExpendData]);

  const isLoading = coinIncomeLoading || coinExpendLoading || gemIncomeLoading || gemExpendLoading || rubyExpendLoading;

  return (
    <div className="w-full">
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex gap-8 flex-wrap">
          {rubyExpendTableData && rubyExpendTableData.length > 0 && (
            <RawDataTable
              title="Ruby Expend"
              isCenterFull
              headers={[
                'Time',
                'Platform',
                'Adventure Swipe',
                'Boss Raid X1',
                'Boss Raid X5',
                'Boss Raid X10',
                'Skip Ads',
              ]}
              data={rubyExpendTableData.map((item) => {
                return item.map((subItem) => {
                  if (subItem.users) {
                    return <ResourceItem users={subItem.users} value={subItem.value as unknown as string} />;
                  } else {
                    return subItem.value;
                  }
                });
              })}
              rawData={rubyExpendData}
            />
          )}
          {coinIncomeTableData && coinIncomeTableData.length > 0 && (
            <RawDataTable
              isCenterFull
              title="Coin Income"
              headers={[
                'Time',
                'Platform',
                'End Game',
                'Offline Earning',
                'Quick Earning',
                'Growth Fund',
                'Daily Quest',
                'Daily Deal',
                'Campaign',
                'Weekly Quest',
                'Reset Item',
                'Coin Pack In Shop',
                'Achievement',
                'Old User',
                'Daily Reward',
                'IAP',
                'Newbie Gift',
                'Event Beginer',
              ]}
              data={coinIncomeTableData.map((item) => {
                return item.map((subItem) => {
                  if (subItem.users) {
                    return <ResourceItem users={subItem.users} value={subItem.value as unknown as string} />;
                  } else {
                    return subItem.value;
                  }
                });
              })}
              rawData={coinIncomeData}
            />
          )}

          {coinExpendTableData && coinExpendTableData.length > 0 && (
            <RawDataTable
              title="Coin Expend"
              isCenterFull
              headers={['Time', 'Platform', 'Unlock Talent', 'Daily Deal', 'Upgrade Item']}
              data={coinExpendTableData.map((item) => {
                return item.map((subItem) => {
                  if (subItem.users) {
                    return <ResourceItem users={subItem.users} value={subItem.value as unknown as string} />;
                  } else {
                    return subItem.value;
                  }
                });
              })}
              rawData={coinExpendData}
            />
          )}

          {gemIncomeTableData && gemIncomeTableData.length > 0 && (
            <RawDataTable
              isCenterFull
              title="Gem Income"
              headers={[
                'Time',
                'Platform',
                'Level Up',
                'Event Beginer',
                'Newbie Gift',
                'Growth Fund',
                'Daily Deal',
                'IAP',
                'Campaign',
                'Daily Reward',
                'Daily Quest',
                'Weekly Quest',
                'Achievement',
              ]}
              data={gemIncomeTableData.map((item) => {
                return item.map((subItem) => {
                  if (subItem.users) {
                    return <ResourceItem users={subItem.users} value={subItem.value as unknown as string} />;
                  } else {
                    return subItem.value;
                  }
                });
              })}
              rawData={gemIncomeData}
            />
          )}

          {gemExpendTableData && gemExpendTableData.length > 0 && (
            <RawDataTable
              title="Gem Expend"
              isCenterFull
              headers={[
                'Time',
                'Platform',
                'Replenish Energy',
                'Buy Equipment Deal',
                'Buy Scroll Deal',
                'Buy Chest',
                'Buy Chest (Special X10)',
                'Buy Chest (Special X1)',
                'Buy Chest (Diamond)',
                'Buy Chest (Bronze)',
                'Buy Coins Packs',
                'Reset Daily Deals',
              ]}
              data={gemExpendTableData.map((item) => {
                return item.map((subItem) => {
                  if (subItem.users) {
                    return <ResourceItem users={subItem.users} value={subItem.value as unknown as string} />;
                  } else {
                    return subItem.value;
                  }
                });
              })}
              rawData={gemExpendData}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default SkyResource;
