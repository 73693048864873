import { useMutation, useQuery } from '@tanstack/react-query';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import LayoutContextConsumer from 'contexts/LayoutContext';

import AuthConsumer from 'hooks/useAuth';

import Loading from 'components/shared/Loading';

import customQueryApi, { CustomQuery, CustomQueryGameSchema } from 'config/api/bigquery/custom-query';
import userRole from 'config/constants/userRole';
import toastHelper from 'config/helpers/toastHelper';
import { dataTypeList } from 'config/types/general';

import { EditOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Form, Input, Modal, Select, Tag } from 'antd';
import { useExploreStore } from 'stores/exploreStore';

const { getGameSchema, updateGameSchema, getCustomQueries, deleteCustomQuery } = customQueryApi;

const ExploreModalCustomContent = () => {
  const [editModal, setEditModal] = useState(false);
  const { currentApp } = LayoutContextConsumer();
  const { setCategories, filter, setFilter, setModalOpen } = useExploreStore((state) => state);
  const { role } = AuthConsumer();

  const navigate = useNavigate();
  const [form] = Form.useForm();

  const handleCancel = () => {
    setEditModal(false);
  };

  const handleSubmit = async (values: { schema: CustomQueryGameSchema[] }) => {
    updateSchema(values.schema);
  };

  const handleDeleteQuery = async (id: string) => {
    await deleteCustomQuery(id);
    refetchQueries();
  };

  const {
    data: schema,
    refetch,
    isLoading: isSchemaLoading,
  } = useQuery(
    ['custom-query', 'query-schema', currentApp],
    async () => {
      const res = await getGameSchema();

      return res as unknown as CustomQueryGameSchema[];
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const {
    data: customQueries,
    isLoading: isQueriesLoading,
    refetch: refetchQueries,
  } = useQuery(
    ['custom-query', 'list-query', currentApp],
    async () => {
      const res = await getCustomQueries({
        params: {
          game: currentApp?.id,
        },
      });

      return res as unknown as CustomQuery[];
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const { mutate: updateSchema } = useMutation({
    mutationKey: ['custom-query', 'update-schema', currentApp],
    mutationFn: async (data: CustomQueryGameSchema[]) => {
      await updateGameSchema(data);
      toastHelper.success('Schema updated');
      refetch();
      setEditModal(false);
    },
  });

  useEffect(() => {
    form.setFieldValue('schema', schema);
  }, [schema, form]);

  useEffect(() => {
    if (customQueries) {
      setCategories(
        customQueries.map((query) => {
          return {
            label: query.name,
            value: query.id,
          };
        }),
      );
      setFilter({
        ...filter,
        category: customQueries[0]?.id,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customQueries, setCategories]);

  const isLoading = useMemo(() => {
    return isSchemaLoading || isQueriesLoading;
  }, [isSchemaLoading, isQueriesLoading]);

  return (
    <div>
      <div className="flex gap-2 mb-4 justify-end">
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            navigate('/explore/query-builder');
          }}
          size="small"
        >
          New Query
        </Button>

        {(role === userRole.SUPERADMIN || role === userRole.ADMIN) && (
          <Button
            type="primary"
            icon={<EditOutlined />}
            onClick={() => {
              setEditModal(true);
            }}
            size="small"
          >
            Edit schema
          </Button>
        )}
      </div>

      <div className="flex flex-col">
        {customQueries &&
          customQueries.map((query, index) => {
            return (
              <Fragment key={query.id}>
                <div
                  key={query.id}
                  onClick={(e) => {
                    e.stopPropagation();
                    setFilter({
                      ...filter,
                      category: query.id,
                    });
                    setModalOpen(false);
                  }}
                >
                  {index !== 0 && <Divider className="my-3" />}
                  <div className="flex gap-4 items-center justify-between px-3 hover:text-secondary cursor-pointer">
                    <div className="flex items-center gap-1">
                      <span className="font-medium text-14 mr-2">{query.name}</span>
                      {query.fields.map((field) => (
                        <Tag key={field} color="blue">
                          {field}
                        </Tag>
                      ))}
                    </div>

                    <div className="flex gap-2">
                      <Button
                        icon={<EditOutlined />}
                        onClick={() => {
                          navigate(`/explore/query-builder?edit=${query.id}`);
                        }}
                        size="small"
                        type="ghost"
                      />
                      <Button
                        icon={<MinusCircleOutlined className="text-primary" />}
                        size="small"
                        type="ghost"
                        onClick={async () => {
                          await handleDeleteQuery(query.id);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Fragment>
            );
          })}
      </div>

      <Modal
        forceRender
        open={editModal}
        onCancel={handleCancel}
        footer={[
          <Button
            type="primary"
            size="small"
            name="save-schema"
            key="save-schema"
            onClick={() => {
              form.submit();
            }}
            disabled={isLoading}
          >
            Save
          </Button>,
          <Button size="small" name="cancel-schema" key={'cancel-schema'} onClick={handleCancel}>
            Cancel
          </Button>,
        ]}
        title="Edit schema"
      >
        <div className="py-4">
          {isLoading ? (
            <Loading />
          ) : (
            <Form
              form={form}
              initialValues={{
                schema: schema
                  ? schema?.map((field) => ({
                      field: field.field,
                      type: field.type,
                    }))
                  : [],
              }}
              onFinish={(values) => {
                handleSubmit(values);
              }}
            >
              <Form.List name="schema">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <div key={key} className="flex gap-2 items-baseline">
                        <Form.Item
                          {...restField}
                          name={[name, 'field']}
                          rules={[{ required: true, message: 'Missing field name' }]}
                        >
                          <Input placeholder="Field" />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'type']}
                          rules={[{ required: true, message: 'Missing data type' }]}
                        >
                          <Select
                            options={dataTypeList.map((type) => ({
                              label: type,
                              value: type,
                            }))}
                            style={{
                              width: 120,
                            }}
                          />
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </div>
                    ))}
                    <Form.Item>
                      <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />} size="small">
                        Add field
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Form>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default ExploreModalCustomContent;
