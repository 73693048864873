import React from 'react';
import { Link } from 'react-router-dom';

import useDocumentTitle from 'hooks/useDocumentTitle';

import FunnelChart from 'components/feature/funnels/FunnelChart';
import ChartContainer from 'components/shared/ChartContainer';

import { ArrowLeftOutlined } from '@ant-design/icons';

const data = [
  {
    action: 'Step 1',
    users: 331,
    percent: 100,
  },
  {
    action: 'Step 2',
    users: 322,
    percent: 97.28,
  },
  {
    action: 'Step 3',
    users: 294,
    percent: 88.82,
  },
  {
    action: 'Step 4',
    users: 267,
    percent: 82.7,
  },
  {
    action: 'Step 5',
    users: 250,
    percent: 80.66,
  },
  {
    action: 'Step 6',
    users: 242,
    percent: 78,
  },
  {
    action: 'Step 7',
    users: 230,
    percent: 70,
  },
];

const FunnelsListPage = () => {
  useDocumentTitle('Funnels');

  return (
    <div className="w-full">
      <Link
        to={'/funnels/create'}
        className="mb-5 block w-fit cursor-pointer rounded-lg bg-[#C1C1C1] py-1 px-2 font-sans text-14 text-white hover:text-white"
      >
        <ArrowLeftOutlined className="mr-2" />
        Step
      </Link>
      <div className="mb-12 w-full overflow-auto">
        <h6 className="text-26 font-semibold">Tutorial - Frist PVP</h6>
        <p className="mb-5 text-14 font-normal text-grey">All steps 2023/22/03 - 2022/22/03</p>
        <div className="min-w-[600px]">
          <ChartContainer>
            <FunnelChart data={data} xField={'action'} yField={'percent'} />
          </ChartContainer>
        </div>
      </div>

      <div className="mb-12 w-full overflow-auto">
        <h6 className="text-26 font-semibold">Tutorial - (Over All)</h6>
        <p className="mb-5 text-14 font-normal text-grey">All steps 2023/22/03 - Today</p>
        <div className="min-w-[600px]">
          <ChartContainer>
            <FunnelChart data={data} xField={'action'} yField={'percent'} />
          </ChartContainer>
        </div>
      </div>
    </div>
  );
};

export default FunnelsListPage;
