import { useQuery } from '@tanstack/react-query';

import LayoutContextConsumer from 'contexts/LayoutContext';

import RawDataTable from 'components/feature/charts/RawDataTable';
import Loading from 'components/shared/Loading';

import { IVersionHistory } from 'config/api/version-history/versionHistoryApi';

import { Empty } from 'antd';
import gunAndDungeonApi from 'config/api/bigquery/gunAndDungeonApi';
import { BiCoinStack, BiUser } from 'react-icons/bi';

interface Props {
  rangeDate: {
    start?: string;
    end?: string;
  };
  versionHistoryData?: IVersionHistory[];
  version: string | null;
  country?: string;
}

interface ILevelData {
  platform: string
  level: number
  start_times: number
  start_users: number
  complete_times: number
  complete_users: number
  total_time_spent: number
  fail_times: number
  fail_users: number
  fail_rate: number
}



const { getGunAndDungeonLevelData } = gunAndDungeonApi;

const LevelItem = ({ users, value }: { users: string; value: string }) => {
  return (
    <div className="">
      <div className="flex items-center gap-1">
        <BiCoinStack className="text-12" />
        <p className="text-14">{value}</p>
      </div>
      <div className="flex items-center gap-1 whitespace-nowrap">
        <BiUser className="text-12" />
        <p className="text-14">{users}</p>
      </div>
    </div>
  );
};

const GunLevel = ({ rangeDate, version, country }: Props) => {
  const { platform, currentApp } = LayoutContextConsumer();

  const { data: datas, isLoading } = useQuery(['level', currentApp?.id, rangeDate, version, platform, country], async () => {
    if (!rangeDate.start || !rangeDate.end) return { formatedData: [], rawData: [] };

    const res = (await getGunAndDungeonLevelData({
      platform: platform === 'unified' ? undefined : platform,
      version,
      start: rangeDate.start,
      end: rangeDate.end,
      country: country === 'All' ? undefined : country,
    })) as unknown as ILevelData[] ?? [];

    const formatedData = res.map((item) => [
      item.platform,
      item.level,
      <LevelItem users={item.start_users.toFixed(0)} value={item.start_times.toFixed(0)} />,
      <LevelItem users={item.complete_users.toFixed(0)} value={item.complete_times.toFixed(0)} />,
      <LevelItem users={item.fail_users.toFixed(0)} value={item.fail_times.toFixed(0)} />,
      `${item.fail_rate.toFixed(1)}%`
      // item.total_time_spent.toFixed(0),
    ]) ?? []

    return { formatedData, rawData: res };
  });

  const data = datas?.formatedData;

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : data && data.length > 0 ? (
        <RawDataTable
          isCenterFull
          title="Level"
          headers={[
            'Platform',
            'Level',
            'Start',
            'Complete',
            'Fail',
            'Fail Rate'
            // 'Total Time Spent',
          ]}
          data={
            data
          }
          rawData={datas?.rawData ?? []}
        />
      ) : (
        <Empty />
      )}
    </div>
  );
};

export default GunLevel;
