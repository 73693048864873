import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import LayoutContextConsumer from 'contexts/LayoutContext';
import useDocumentTitle from 'hooks/useDocumentTitle';
import useIsomorphicLayoutEffect from 'hooks/useIsomorphicLayoutEffect';
import ComingSoonPage from 'pages/main/ComingSoonPage';
import CountrySelect from 'components/shared/CountrySelect';
import FullDatePicker from 'components/shared/FullDatePicker';
import UserIDInput from 'components/shared/UserIDInput';
import TextInput from '../../components/shared/TextInput';
import DropdownSelect from '../../components/shared/DropdownSelect';
import DateRangeV2 from '../../components/shared/DateRangeV2';
import CusCheckbox from '../../components/shared/CusCheckbox';
import versionHistoryApi, { IVersionHistory } from 'config/api/version-history/versionHistoryApi';
import { customTrackingConst } from 'config/constants/customTracking';
import { FilterType, customTrackingExtraFilterConst } from 'config/constants/customTrackingExtraFilter';
import dateHelper from 'config/helpers/dateHelper';
import { Radio } from 'antd';
import { gameEvents, payingTypes } from '../../config/constants/general';

const { format, getDayBefore } = dateHelper;

const defaultRange = {
  start: format(getDayBefore(new Date(), 10)),
  end: format(getDayBefore(new Date(), 2)),
}
const defaultRangeToday = {
  start: format(getDayBefore(new Date(), 7)),
  end: format(getDayBefore(new Date(), 0)),
}

const CustomTrackingPage = () => {
  const { currentApp, platform } = LayoutContextConsumer();
  useDocumentTitle('Custom Tracking');

  const listTabs = currentApp && customTrackingConst[currentApp.id] ? customTrackingConst[currentApp.id] : [];
  const listEvents = currentApp && gameEvents[currentApp.id] ? gameEvents[currentApp.id] : [];

  const [tab, setTab] = useState<string>(listTabs[0] ? listTabs[0].id : '');
  const [reloadTrigger, setReloadTrigger] = useState<boolean>(false);
  const [isFirstLoad, setFirstLoad] = useState<boolean>(true);
  const [rangeDate, setRangeDate] = useState<{ start?: string; end?: string; }>();
  const [rangeDate2, setRangeDate2] = useState<{ start?: string; end?: string; }>();
  const [selectedCountry, setSelectedCountry] = useState<string>('All');
  const [userID, setUserID] = useState<string>('');
  const [userName, setUserName] = useState<string>('');
  const [paying, setPaying] = useState<string>('All');
  const [event, setEvent] = useState<string>(listEvents[0].value);
  const [isTutSessionOnly, setTutSessionOnly] = useState<boolean>(false);
  const [chestID, setChestID] = useState<string>('');

  const appExtraFilters = (currentApp && customTrackingExtraFilterConst[currentApp.id]) ? customTrackingExtraFilterConst[currentApp.id] : {};
  const tabExtraFilters = (appExtraFilters && appExtraFilters[tab]) ? appExtraFilters[tab] : [];

  const { data: versionHistoryData } = useQuery(
    ['version-history', currentApp, platform, rangeDate],
    async () => {
      const response = await versionHistoryApi.getAll({
        params: {
          platform: platform && platform !== 'unified' ? platform : undefined,
          startDate: rangeDate?.start,
          endDate: rangeDate?.end,
        },
      });
      return response as unknown as IVersionHistory[];
    },
    {
      refetchOnWindowFocus: false,
    },
  );
  useIsomorphicLayoutEffect(() => {
    setTab(listTabs && listTabs[0] ? listTabs[0].id : '');
  }, [listTabs]);

  // Update date range when change tab
  useEffect(() => {
    if (tab) {
      const tabInfo = listTabs.find((item) => item.id === tab);
      if (tabInfo) {
        if (tabInfo.defaultRange) {
          setRangeDate(tabInfo.defaultRange);
        } else {
          setRangeDate(currentApp?.id === 'DaymareZero' ? defaultRangeToday : defaultRange);
        }
      }
    }
  }, [tab])


  const renderTab = useMemo(() => {
    if (!tab) return null;

    const tabInfo = listTabs.find((item) => item.id === tab);

    if (!tabInfo) return null;

    const TabComponent = tabInfo.component;

    return (
      <TabComponent
        rangeDate={rangeDate}
        setRangeDate={setRangeDate}
        rangeDate2={rangeDate2}
        country={selectedCountry}
        versionHistoryData={versionHistoryData}
        userID={userID}
        userName={userName}
        paying={paying}
        reloadTrigger={reloadTrigger}
        isFirstLoad={isFirstLoad}
        isTutSessionOnly={isTutSessionOnly}
        feature={event}
        chestID={chestID}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rangeDate, tab, selectedCountry, reloadTrigger]);

  if (!currentApp || listTabs.length === 0) {
    return <ComingSoonPage />;
  }

  return (
    <div>
      <h3 className="page-section-title">Custom Tracking</h3>
      <Radio.Group
        value={tab}
        buttonStyle="solid"
        className="mt-4"
        onChange={(e: any) => {
          setFirstLoad(true);
          setTab(e.target.value);
        }}
      >
        {listTabs
          .filter((item) => item.type !== 'event')
          .map((item) => (
            <Radio.Button key={item.id} value={item.id}>
              {item.name}
            </Radio.Button>
          ))}
      </Radio.Group>

      <div className="my-4 flex flex-wrap gap-6">
      {
        (tabExtraFilters && tabExtraFilters.length > 0)
        ? tabExtraFilters.map((item) => {
          if (item.type === FilterType.Apply) {
            return (<button className="primary-button" onClick={() => {
              if (isFirstLoad) {
                setFirstLoad(false);
              }
              setReloadTrigger(!reloadTrigger);
            }}>Apply</button>);
          }
          if (item.type === FilterType.DefaultDate) {
            return (<FullDatePicker rangeDate={rangeDate} setRangeDate={setRangeDate} />);
          }
          if (item.type === FilterType.Country) {
            return (<CountrySelect selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry} />);
          }
          if (item.type === FilterType.UserID) {
            return (<UserIDInput value={userID as unknown as string} onValueChanged={setUserID} />);
          }
          if (item.type === FilterType.UserName) {
            return (<TextInput value={userName as unknown as string} onValueChanged={setUserName} />);
          }
          if (item.type === FilterType.Paying) {
            return (<DropdownSelect value={paying as unknown as string} onValueChanged={setPaying} options={payingTypes} title='Paying Types' />);
          }
          if (item.type === FilterType.DateRange1) {
            const title = item.title ?? 'Select Date';
            return (<DateRangeV2 rangeDate={rangeDate} setRangeDate={setRangeDate} title={title} />);
          }
          if (item.type === FilterType.DateRange2) {
            const title = item.title ?? 'Account Created';
            return (<DateRangeV2 rangeDate={rangeDate2} setRangeDate={setRangeDate2} title={title} />);
          }
          if (item.type === FilterType.TutorialSessionOnly) {
            return (<CusCheckbox value={isTutSessionOnly} onValueChanged={setTutSessionOnly} title={item.title} />);
          }
          if (item.type === FilterType.Event) {
            return (<DropdownSelect value={event as unknown as string} onValueChanged={setEvent} options={listEvents} title='Event' />);
          }
          if (item.type === FilterType.ChestID) {
            return (<TextInput value={chestID as unknown as string} onValueChanged={setChestID} title='Chest ID' />);
          }
          return (<></>);
        })
        : (<>
          <FullDatePicker rangeDate={rangeDate} setRangeDate={setRangeDate} />
          <CountrySelect selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry} />
        </>)
      }
      </div>
      {renderTab}
    </div>
    
  );
};

export default CustomTrackingPage;
