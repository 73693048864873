import { useQuery } from '@tanstack/react-query';
import { useMemo, useState } from 'react';

import RawDataTable from 'components/feature/charts/RawDataTable';
import Pager from '../../../shared/Pager';
import Loading from 'components/shared/Loading';

import daymareZeroApi from 'config/api/bigquery/daymareZeroApi';
import { IVersionHistory } from 'config/api/version-history/versionHistoryApi';
import { toExcelFileName, writeExcelFile } from '../../../../config/constants/general';
import LayoutContextConsumer from '../../../../contexts/LayoutContext';

interface Props {
  rangeDate: {
    start?: string;
    end?: string;
  };
  rangeDate2: {
    start?: string;
    end?: string;
  };
  versionHistoryData?: IVersionHistory[];
  version: string | null;
  country: string;
  userID: string | null;
  userName: string | null;
  paying: string | null;
  reloadTrigger: boolean;
  isFirstLoad: boolean;
  page: number;
}

interface IUserProfile {
  user_pseudo_id: string,
  user_id: string,
  user_name: string,
  email: string,
  login_method: string,
  created_time: number,
  created_date: string,
  last_action_time: number,
  last_action_date: string,
  country: string,
  platform: string,
  device_model: string,
  user_level: number,
  day_played: number,
  launch_count: number,
  topup_sum: number,
  topup_times: number,
  highest_wave: number,
  combat_point: number,
  highest_process: number,
  curr_claim_process: number,
  is_banned: boolean,
}

interface IUserProfile_Counter {
  total_items: number,
}

const { getUserProfile, countUserProfile } = daymareZeroApi;

const DaymareUserProfile = ({ rangeDate, rangeDate2, versionHistoryData, country, userID, userName, paying, reloadTrigger, isFirstLoad }: Props) => {
  const { platform } = LayoutContextConsumer();
  const [userProfilePage, setUserProfilePage] = useState<number>(1);

  const { data: rawData, isLoading: rawDataLoading } = useQuery(
    ['user-profile', reloadTrigger, userProfilePage],
    async () => {
      if (isFirstLoad === true) {
        return [];
      }
      const res = await getUserProfile({
        params: {
          start: rangeDate?.start,
          end: rangeDate?.end,
          platform: platform === 'unified' ? undefined : platform,
          country: country !== 'All' ? country : undefined,
          page: userProfilePage,
          pageSize: 10,
        },
        data: {
          userID: userID,
          userName: userName,
          paying: paying !== 'All' ? paying : undefined,
          lastTimeFrom: rangeDate2?.start,
          lastTimeTo: rangeDate2?.end,
        }
      });
      return res as unknown as IUserProfile[];
    }
  );

  const { data: rawDataCounter, isLoading: rawDataCounterLoading } = useQuery(
    ['user-profile-counter', reloadTrigger],
    async () => {
      if (isFirstLoad === true) {
        return [];
      }
      const res = await countUserProfile({
        params: {
          start: rangeDate?.start,
          end: rangeDate?.end,
          platform: platform === 'unified' ? undefined : platform,
          country: country !== 'All' ? country : undefined,
        },
        data: {
          userID: userID,
          userName: userName,
          paying: paying !== 'All' ? paying : undefined,
          lastTimeFrom: rangeDate2?.start,
          lastTimeTo: rangeDate2?.end,
        }
      });
      return res as unknown as IUserProfile_Counter[];
    }
  );

  const guiFormOfRawData = useMemo(() => {
    return rawData?.map((item) => {
      return [
        { value: item.user_id },
        { value: item.user_name },
        { value: item.email },
        { value: item.login_method },
        { value: item.created_date },
        { value: item.last_action_date },
        { value: item.country },
        { value: item.platform },
        { value: item.device_model },
        { value: item.user_level },
        { value: item.day_played },
        { value: item.launch_count },
        { value: item.topup_sum },
        { value: item.topup_times },
        { value: item.highest_wave },
        { value: item.combat_point },
        { value: item.highest_process },
        { value: item.curr_claim_process },
      ];
    });
  }, [rawData]);

  const guiFormOfCounterData = useMemo(() => {
    if (rawDataCounter !== undefined && rawDataCounter.length > 0) {
      return rawDataCounter[0].total_items;
    }
    return 1;
  }, [rawDataCounter]);

  const onDownload = async () => {
    const res = await getUserProfile({
      params: {
        start: rangeDate.start,
        end: rangeDate.end,
        platform: platform === 'unified' ? undefined : platform,
        country: country !== 'All' ? country : undefined,
      },
      data: {
        userID: userID,
        userName: userName,
        paying: paying !== 'All' ? paying : undefined,
        lastTimeFrom: rangeDate2?.start,
        lastTimeTo: rangeDate2?.end,
      },
    });
    writeExcelFile(res, toExcelFileName('User Profile'), 'json');
  };

  const isLoading = rawDataLoading || rawDataCounterLoading;
  
  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex gap-0 flex-wrap">
          {
            guiFormOfRawData && guiFormOfRawData.length > 0 && (
              <RawDataTable
                isCenterFull
                title="User Profile"
                headers={[
                  'User ID',
                  'User Name',
                  'Email',
                  'Login Method',
                  'Created At',
                  'Last Action At',
                  'Country',
                  'Platform',
                  'Device Model',
                  'User Level',
                  'Day Played',
                  'Launch Count',
                  'Topup Sum',
                  'Topup Times',
                  'Highest Wave',
                  'Combat Point',
                  'Highest Process',
                  'Claimed Process',
                ]}
                data={guiFormOfRawData.map((item) => {
                  return item.map((subItem) => {
                    return subItem.value;
                  });
                })}
                onDownload={onDownload}
              />
            )
          }
          <Pager
            page={userProfilePage}
            totalPage={Math.floor((guiFormOfCounterData + 9) / 10)}
            setPage={setUserProfilePage}
          />
        </div>
      )}
    </div>
  );
};

export default DaymareUserProfile;
