import { useQuery } from '@tanstack/react-query';

import LayoutContextConsumer from 'contexts/LayoutContext';

import RawDataTable from 'components/feature/charts/RawDataTable';
import Loading from 'components/shared/Loading';

import skyDancer2Api from 'config/api/bigquery/skyDancer2Api';
import { IVersionHistory } from 'config/api/version-history/versionHistoryApi';
import { BROWN_COLOR, GREEN_COLOR, SECONDARY_COLOR } from 'config/constants/theme';
import numberHelper from 'config/helpers/numberHelper';

const { convertText } = numberHelper;

interface Props {
  rangeDate: {
    start?: string;
    end?: string;
  };
  versionHistoryData?: IVersionHistory[];
  version: string | null;
}

interface IIAPPack {
  time: string
  platform: string
  country: string
  pack_id: string
  buy_times: number
  buy_unique_users: number
  success_times: number
  success_unique_users: number
  complete_times: number
  complete_unique_users: number
  cancel_times: number
  cancel_unique_users: number
  failed_times: number
  failed_unique_users: number
}

const { getIAPPack } = skyDancer2Api;

const SkyIAPTracking = ({ rangeDate, versionHistoryData, version }: Props) => {
  const { platform } = LayoutContextConsumer();

  const { data: packData, isLoading: packLoading } = useQuery(['iap-pack', version, rangeDate, platform], async () => {
    if (!rangeDate.start || !rangeDate.end) return [];

    const res = await getIAPPack({
      params: {
        version,
        platform: platform === 'unified' ? undefined : platform,
        start: rangeDate.start,
        end: rangeDate.end,
      },
    });

    return res as unknown as IIAPPack[];
  });
  const isLoading = packLoading;

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex gap-8 flex-wrap">
          {packData && packData.length > 0 && (
            <RawDataTable
              isCenterFull
              title="Pack Interaction"
              colors={[
                BROWN_COLOR,
                BROWN_COLOR,
                BROWN_COLOR,
                BROWN_COLOR,
                SECONDARY_COLOR,
                SECONDARY_COLOR,
                SECONDARY_COLOR,
                SECONDARY_COLOR,
                SECONDARY_COLOR,
                GREEN_COLOR,
                GREEN_COLOR,
                GREEN_COLOR,
                GREEN_COLOR,
                GREEN_COLOR,
              ]}
              headers={[
                'Date',
                'Platform',
                'Country',
                'Pack ID',
                'Buy Times',
                'Local Success Times',
                'Server Success Times',
                'Failed Times',
                'Cancel Times',
                'Buy Unique Users',
                'Local Success Unique Users',
                'Server Success Unique Users',
                'Failed Unique Users',
                'Cancel Unique Users',
              ]}
              data={packData.map((item) => {
                return [
                  item.time,
                  item.platform,
                  item.country,
                  item.pack_id,
                  convertText(item.buy_times),
                  convertText(item.success_times),
                  convertText(item.complete_times),
                  convertText(item.failed_times),
                  convertText(item.cancel_times),
                  convertText(item.buy_unique_users),
                  convertText(item.success_unique_users),
                  convertText(item.complete_unique_users),
                  convertText(item.failed_unique_users),
                  convertText(item.cancel_unique_users),
                ];
              })}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default SkyIAPTracking;
