import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import LayoutContextConsumer from 'contexts/LayoutContext';

import Loading from 'components/shared/Loading';

import customQueryApi, { CustomQuery, CustomQueryResponse } from 'config/api/bigquery/custom-query';

import CustomQueryChart from '../charts/CustomQueryChart';

import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useExploreStore } from 'stores/exploreStore';

const { runCustomQuery, getCustomQuery, getCustomQueries } = customQueryApi;

const ExploreCustomQuery = () => {
  const navigate = useNavigate();

  const { currentApp } = LayoutContextConsumer();

  const { filter, setCategories, setFilter } = useExploreStore((state) => state);

  const { data, isLoading } = useQuery(['custom-query', 'query', filter], async () => {
    const res = await getCustomQuery(filter.category);
    const data = res as unknown as CustomQuery;
    if (filter.country !== 'All') {
      data.condition.push({
        field: 'country',
        operator: '=',
        value: filter.country,
        type: 'string',
      });
    }

    if (res) {
      const query = await runCustomQuery(data);

      return query as unknown as CustomQueryResponse;
    } else {
      return null;
    }
  });

  const { data: customQueries } = useQuery(['custom-query', 'list-query', currentApp], async () => {
    const res = await getCustomQueries({
      params: {
        game: currentApp?.id,
      },
    });

    return res as unknown as CustomQuery[];
  });
  useEffect(() => {
    if (customQueries) {
      setCategories(
        customQueries.map((query) => {
          return {
            label: query.name,
            value: query.id,
          };
        }),
      );
      setFilter({
        ...filter,
        category: customQueries[0]?.id,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customQueries, setCategories]);

  return (
    <div>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => {
          navigate('/explore/query-builder');
        }}
        size="small"
      >
        New Query
      </Button>

      <div className="mt-4">{isLoading ? <Loading /> : <CustomQueryChart data={data?.data ? data.data : []} />}</div>
    </div>
  );
};

export default ExploreCustomQuery;
