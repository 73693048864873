import { CgSoftwareDownload } from 'react-icons/cg';

import useExportExcel from 'hooks/useExportExcel';

import uniqueId from 'lodash/uniqueId';

interface Props {
  headers: string[];
  data: string[][];
}

const FunnelTable = ({ headers, data }: Props) => {
  const exportExcel = useExportExcel({
    data: [headers, ...data],
    fileName: 'seen_funnel',
    type: 'string',
  });

  return (
    <>
      <div className="h-[64vh] w-fit overflow-auto">
        <div className="flex justify-between p-2">
          <div></div>
          <CgSoftwareDownload className="text-26 cursor-pointer hover:text-secondary" onClick={exportExcel} />
        </div>
        <table className="w-fit max-w-full">
          <thead className="sticky top-0">
            <tr key={uniqueId()}>
              {headers.map((label) => (
                <th key={uniqueId()} className={'table-head font-medium'}>
                  {label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row) => {
              const len = row.length;

              const opacity = parseFloat(row[len - 1]);

              return (
                <tr
                  key={uniqueId()}
                  style={{
                    backgroundColor: opacity > 0 ? `rgba(250,146,102, ${0.5 + opacity})` : 'inherit',
                    // backgroundColor: 'inherit',
                  }}
                >
                  {row.slice(0, len - 1).map((value, i) => (
                    <td key={uniqueId()} className={'table-border'}>
                      <div className="table-cohort-cell px-4 py-2">
                        <p className={`${i > 0 ? '' : 'text-left'}`}>{value}</p>
                      </div>
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default FunnelTable;
