import React, { useEffect, useState } from 'react';

import dateHelper from 'config/helpers/dateHelper';

import { ClockCircleOutlined } from '@ant-design/icons';

const { getTimeZone } = dateHelper;

const Timezone = () => {
  const [time, setTime] = useState(getTimeZone());

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(getTimeZone());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="flex w-fit gap-2 whitespace-nowrap rounded-lg bg-[#F2F2F2] px-[8px] py-[10px] text-[11px] text-[#6A6A6A]">
      <ClockCircleOutlined />

      <span title="(UTC-7)">{time}</span>
    </div>
  );
};

export default Timezone;
