import React from 'react';

import { IApp } from 'config/api/apps/appApi';
import toastHelper from 'config/helpers/toastHelper';

import clsx from 'clsx';

interface IAppGridItem extends IApp {
  handleSelectApp: (id: string) => void;
}

const AppGridItem = ({ name, icon, id, status, handleSelectApp, ggplay, appstore, domain }: IAppGridItem) => {
  const handleSelect = (id: string) => {
    if (status) {
      handleSelectApp(id);
    } else {
      toastHelper.info('Coming soon');
    }
  };

  return (
    <div
      className="flex w-full cursor-pointer items-center gap-4 rounded-[10px] bg-white py-[10px] px-3 hover:text-primary"
      onClick={() => {
        handleSelect(id);
      }}
    >
      {icon ? (
        <img src={icon} alt={name} width={100} height={100} className="rounded-lg" />
      ) : (
        <div className="h-[100px] min-h-[100px] w-[100px] min-w-[100px] rounded-lg bg-grey2"></div>
      )}

      <div className="flex-1">
        <h4 className="text-left text-lg font-[600] leading-tight">{name}</h4>
        <div className="mt-3 flex items-center gap-4">
          <span className="flex items-center gap-2 text-xs text-text-body">
            <div className={clsx('h-3 w-3 rounded-full', appstore ? 'bg-green' : 'bg-yellow')}></div>
            IOS
          </span>
          <span className="flex items-center gap-2 text-xs text-text-body">
            <div className={clsx('h-3 w-3 rounded-full', ggplay ? 'bg-green' : 'bg-yellow')}></div>
            Android
          </span>
        </div>
      </div>
    </div>
  );
};

export default AppGridItem;
