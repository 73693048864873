export const BRANDS = [
  {
    value: 'PARTNER_MINIGAME_MN',
    label: 'MOLLYNISTA',
  },
  {
    value: 'PARTNER_MINIGAME_PP',
    label: 'Phương Phát',
  },
];
