import CusSelect from './CusSelect';
import TagSelect from './TagSelect';


interface ILtvPeriodPickerProps {
  title?: string;
  options: { label: string, value: number }[];
  values: number[];
  onValuesChanged: (newValues: number[]) => void;
}

const LtvPeriodPicker = ({ values, onValuesChanged, options, title = 'Period' }: ILtvPeriodPickerProps) => {
  return (
    <div className="max-w-lg">
      <h6 className="mb-2 text-16 font-[500]">{title}</h6>
      <CusSelect
        value={values}
        options={options}
        style={{
          width: '360px',
        }}
        onChange={(selected) => {
          onValuesChanged(selected.sort((a: number, b: number) => a - b));
        }}
        mode="multiple"
        tagRender={TagSelect}
        maxTagCount={'responsive'}
      />
    </div>
  );
};

export default LtvPeriodPicker;