import React, { useEffect, useState } from 'react';
import { DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';


interface IMonthRangeProps {
  setRangeDate: (rangeDate: { start?: string; end?: string }) => void;
  rangeDate?: { start?: string; end?: string } | null;
  isDisabled?: boolean;
  title?: string;
}

const { MonthPicker } = DatePicker;

const MonthRange = ({
  setRangeDate,
  rangeDate,
  isDisabled = false,
  title = 'Select Month',
}: IMonthRangeProps) => {
  const [startDate, setStartDate] = useState<Dayjs | null | undefined>(
    rangeDate?.start ? dayjs(rangeDate.start + '-01') : null
  );
  const [endDate, setEndDate] = useState<Dayjs | null | undefined>(
    rangeDate?.end ? dayjs(rangeDate.end + '-01') : null
  );

  useEffect(() => {
    if (rangeDate) {
      setStartDate(rangeDate.start ? dayjs(rangeDate.start + '-01') : null);
      setEndDate(rangeDate.end ? dayjs(rangeDate.end + '-01') : null);
    }
  }, [rangeDate]);

  const onStartDateChanged = (date: Dayjs | null) => {
    setStartDate(date);
    setRangeDate({
      start: date ? date.format('YYYY-MM') : undefined,
      end: endDate ? endDate.format('YYYY-MM') : undefined,
    });
  };

  const onEndDateChanged = (date: Dayjs | null) => {
    setEndDate(date);
    setRangeDate({
      start: startDate ? startDate.format('YYYY-MM') : undefined,
      end: date ? date.format('YYYY-MM') : undefined,
    });
  };

  return (
    <div>
      <h6 className="mb-2 text-16 font-medium">{title}</h6>
      <div style={{ display: 'flex', gap: '4px' }}>
        <MonthPicker
          value={startDate}
          onChange={onStartDateChanged}
          disabled={isDisabled}
        />
        <MonthPicker
          value={endDate}
          onChange={onEndDateChanged}
          disabled={isDisabled}
        />
      </div>
    </div>
  );
};

export default MonthRange;