import React from 'react';

import logoWhite from 'content/assets/images/logo-white.png';
import logo from 'content/assets/images/logo.png';

interface ILogoProps {
  type?: 'white' | 'primary';
  height?: number;
}

const Logo = ({ type = 'primary', height = 76 }: ILogoProps) => {
  return <img src={type === 'white' ? logoWhite : logo} alt="logo" style={{ height }} />;
};

export default Logo;
