import { useState } from 'react';

import { IVersionHistory } from 'config/api/version-history/versionHistoryApi';
import appHelper from 'config/helpers/appHelper';
import dateHelper from 'config/helpers/dateHelper';

import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Timeline } from 'antd';
import clsx from 'clsx';
import sortBy from 'lodash/sortBy';
import uniqueId from 'lodash/uniqueId';

interface IVersionHistoryTimelineProps {
  data: {
    [key: string]: IVersionHistory[];
  };
  handleEdit: (values: IVersionHistory) => void;
}

interface ITimelineItem {
  handleEditModal: (values: IVersionHistory) => void;
  data: IVersionHistory;
}

const { compareDate, formatText } = dateHelper;
const { convertParagraph } = appHelper;

const TimelineItem = ({ data, handleEditModal }: ITimelineItem) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  return (
    <div
      className="hover:opacity-80 cursor-pointer"
      onClick={() => {
        handleEditModal(data);
      }}
    >
      <h6 className="text-14 font-medium">
        {data.version} - {data.title}
        {isVisible ? (
          <div
            className="inline-block hover:text-primary"
            onClick={(e) => {
              e.stopPropagation();
              setIsVisible(false);
            }}
          >
            <EyeInvisibleOutlined size={24} className="ml-2 mr-1" />
            <span>Hide</span>
          </div>
        ) : (
          <div
            className="inline-block hover:text-primary"
            onClick={(e) => {
              e.stopPropagation();
              setIsVisible(true);
            }}
          >
            <EyeOutlined size={24} className="ml-2 mr-1" />
            <span>View more</span>
          </div>
        )}
      </h6>

      <p
        className={clsx('text-14', isVisible ? 'line-clamp-none' : 'line-clamp-3')}
        dangerouslySetInnerHTML={{
          __html: convertParagraph(data.description || ''),
        }}
      ></p>
      <br />
    </div>
  );
};

const VersionHistoryTimeline = ({ data, handleEdit }: IVersionHistoryTimelineProps) => {
  return (
    <div className="px-2">
      {Object.keys(data)
        .sort((a, b) => compareDate(b, a))
        .map((key) => {
          const item = data[key];

          return (
            <div key={uniqueId()}>
              <h6 className="text-16 font-medium mb-4">{formatText(new Date(key))}</h6>

              <div className="px-3">
                <Timeline
                  items={sortBy(item, (tmp) => tmp.version).map((item) => ({
                    children: item ? <TimelineItem handleEditModal={handleEdit} data={item} /> : <></>,
                  }))}
                />
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default VersionHistoryTimeline;
