import { ReactNode } from 'react';
import { CgSoftwareDownload } from 'react-icons/cg';

import useExportExcel from 'hooks/useExportExcel';

import { IVersionHistory } from 'config/api/version-history/versionHistoryApi';
import { CALENDAR_TEXT_ICON, toExcelFileName } from 'config/constants/general';
import { SECONDARY_COLOR } from 'config/constants/theme';
import numberHelper from 'config/helpers/numberHelper';

import { Tooltip } from 'antd';
import clsx from 'clsx';
import uniqueId from 'lodash/uniqueId';

interface Props {
  headers: string[];
  data: string[][] | ReactNode[][] | (string | ReactNode | number)[][];
  isCenterFull?: boolean;
  colors?: string[];
  versionHistoryData?: IVersionHistory[];
  rawData?: any;
  title?: string;
  onDownload?: () => void;
}

export const DateVersion = ({ verData, date }: { verData?: IVersionHistory; date: string }) => {
  return (
    <Tooltip
      title={verData ? `${verData.version} - ${verData.title} - ${verData.platform.toUpperCase()}` : ''}
      arrow={false}
    >
      <th scope="row" className={clsx('font-medium text-center', verData && 'text-primary')}>
        {verData ? CALENDAR_TEXT_ICON : ''} {date}
      </th>
    </Tooltip>
  );
};

const RawDataTable = ({ headers, data, isCenterFull, colors, title, rawData, onDownload }: Props) => {
  const defaultExportExcel = useExportExcel({
    data: rawData ? rawData : [headers, ...data],
    fileName: toExcelFileName(title),
    type: rawData ? 'json' : 'string',
  });

  const exportExcel = () => {
    if (onDownload) {
      onDownload();
    } else {
      defaultExportExcel();
    }
  };

  return (
    <div className="w-fit max-w-full overflow-x-scroll">
      <div className="flex justify-between p-2">
        <h6 className="text-16 font-[500]">{title}</h6>
        <CgSoftwareDownload className="text-26 cursor-pointer hover:text-secondary" onClick={exportExcel} />
      </div>

      <div className={clsx('overflow-auto overflow-x-auto max-h-[64vh]')}>
        <table className="w-fit max-w-full">
          <thead className="sticky top-0 z-10">
            <tr key={uniqueId()}>
              {headers.map((label, i) => (
                <th
                  key={uniqueId()}
                  className={'table-head font-medium text-white'}
                  style={{
                    backgroundColor: colors ? colors[i] : SECONDARY_COLOR,
                  }}
                >
                  {label.split('\n').map((text, i) => (
                    <p key={uniqueId()} className={`${i === 0 ? 'mb-0' : 'mt-0'}`}>
                      {text}
                    </p>
                  ))}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row) => (
              <tr key={uniqueId()}>
                {row.map((value) => (
                  <td key={uniqueId()} className={'table-border'}>
                    <div className="table-cohort-cell px-4 py-2">
                      {typeof value === 'string' ? (
                        <p className={`${numberHelper.isNumber(value) || isCenterFull ? '' : 'text-left'}`}>{value}</p>
                      ) : (
                        value
                      )}
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RawDataTable;
