import React from 'react';
import { Route, RouteProps, Routes } from 'react-router-dom';

import 'content/styles/index.css';

import { LayoutContextProvider } from 'contexts/LayoutContext';

import CustomRouter from 'config/routes/CustomRouter';
import RequireAuth from 'config/routes/RequireAuth';
import commonRoutes from 'config/routes/commonRoutes';
import history from 'config/routes/history';
import protectedRoutes from 'config/routes/protectedRoutes';
import publicRoutes from 'config/routes/publicRoutes';

import AuthLayout from 'components/layout/nonProtected/AuthLayout';
import ProtectedLayout from 'components/layout/protected';

function App() {
  const renderRouter = (routes: RouteProps[]) => {
    return routes.map((route, index) => <Route path={route.path} key={index} element={route.element} />);
  };

  return (
    <CustomRouter history={history}>
      <Routes>
        <Route path="/auth" element={<AuthLayout />}>
          {renderRouter(publicRoutes)}
        </Route>
        <Route element={<RequireAuth />}>
          <Route
            path="/"
            element={
              <LayoutContextProvider>
                <ProtectedLayout />
              </LayoutContextProvider>
            }
          >
            {renderRouter(protectedRoutes)}
          </Route>
        </Route>

        {renderRouter(commonRoutes)}
      </Routes>
    </CustomRouter>
  );
}

export default App;
