import React from 'react';

import useDocumentTitle from 'hooks/useDocumentTitle';

import CohortContent from 'components/feature/cohorts/CohortContent';

const CohortPage = () => {
  useDocumentTitle('Cohorts');

  return <CohortContent />;
};

export default CohortPage;
