import { useQuery } from '@tanstack/react-query';

import LayoutContextConsumer from 'contexts/LayoutContext';

import RawDataTable from 'components/feature/charts/RawDataTable';
import Loading from 'components/shared/Loading';

import mimilandApi from 'config/api/bigquery/mimilandApi';
import { IVersionHistory } from 'config/api/version-history/versionHistoryApi';

import { Empty } from 'antd';

interface Props {
  rangeDate: {
    start?: string;
    end?: string;
  };
  versionHistoryData?: IVersionHistory[];
  version: string | null;
}

interface IStepSummary {
  group: string;
  platform: string;
  total_chat_times: number;
  total_heart_times: number;
  total_chat_box_times: number;
  total_chat_log_times: number;
  total_move_distance: number;
  total_users: number;
  avrg_message: number;
  avrg_heart: number;
  avrg_chat_box: number;
  avrg_chat_log: number;
  avrg_distance: number;
  DAU: number;
}

const { getMimilandStepSummaryFull } = mimilandApi;

const StepSummary = ({ rangeDate, version }: Props) => {
  const { platform } = LayoutContextConsumer();

  const { data, isLoading } = useQuery(['step-summary-full', rangeDate, version, platform], () => {
    if (!rangeDate.start || !rangeDate.end) return [];

    const res = getMimilandStepSummaryFull({
      platform: platform === 'unified' ? undefined : platform,
      version,
      start: rangeDate.start,
      end: rangeDate.end,
    });

    return res as unknown as IStepSummary[];
  });

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : data && data.length > 0 ? (
        <RawDataTable
          isCenterFull
          title="Step Summary"
          headers={[
            'Date',
            'Total Chat Times',
            'Total Heart Times',
            'Total Chat Box Times',
            'Total Chat Log Times',
            'Total Move Distance',
            'Total Users',
            'Average Message',
            'Average Heart',
            'Average Chat Box',
            'Average Chat Log',
            'Average Distance',
          ]}
          data={data?.map((item) => [
            item.group,
            item.total_chat_times?.toFixed(0),
            item.total_heart_times?.toFixed(0),
            item.total_chat_box_times?.toFixed(0),
            item.total_chat_log_times?.toFixed(0),
            item.total_move_distance?.toFixed(2),
            item.DAU?.toFixed(0),
            ((item.total_chat_times / item.DAU) * 100)?.toFixed(2),
            ((item.total_heart_times / item.DAU) * 100)?.toFixed(2),
            ((item.total_chat_box_times / item.DAU) * 100)?.toFixed(2),
            ((item.total_chat_log_times / item.DAU) * 100)?.toFixed(2),
            ((item.total_move_distance / item.DAU) * 100)?.toFixed(2),
          ])}
        />
      ) : (
        <Empty />
      )}
    </div>
  );
};

export default StepSummary;
