import React, { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import LayoutContextConsumer from 'contexts/LayoutContext';

import useWindowDimensions from 'hooks/useWindowDimensions';

import AppSelect from 'components/shared/AppSelect';

import LayoutHeader from './Header';
import HeaderSelectApp from './HeaderSelectApp';
import LayoutSidebar from './Sidebar';

import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { Drawer, Input, Layout } from 'antd';

const { Content, Sider } = Layout;

const MainLayout: React.FC = () => {
  const [searchDrawer, setSearchDrawer] = useState(false);
  const { pathname } = useLocation();

  const dimension = useWindowDimensions();

  const { handleSearch, closeSidebar, sidebarOpen, setSidebarOpen } = LayoutContextConsumer();

  const onSearchClose = () => {
    setSearchDrawer(false);
  };

  return (
    <Layout
      style={{
        position: 'relative',
      }}
    >
      <div className="sticky top-0 left-0 right-0 z-50">
        <LayoutHeader setSearchDrawer={setSearchDrawer} setSidebarDrawer={setSidebarOpen} />
      </div>
      <Layout
        style={{
          height: 'calc(100vh - 76px)',
          position: 'relative',
          // marginTop: 76,
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        {!pathname.includes('apps') && !pathname.includes('user-setting') && (
          <>
            {dimension && dimension.width > 768 && (
              <Sider
                theme="light"
                width={(dimension ? (dimension?.width > 1440 ? 300 : null) : null) || 250}
                style={{
                  position: 'sticky',
                  top: 0,
                  height: 'calc(100vh - 76px)',
                  overflow: 'auto',
                  left: 0,
                  backgroundColor: '#fff',
                }}
              >
                <LayoutSidebar />
              </Sider>
            )}
            <div className="absolute right-3 top-3 z-10 mb-2 max-[450px]:relative max-[450px]:text-right md:right-5 md:top-6 lg:right-8 lg:top-8">
              <AppSelect />
            </div>
          </>
        )}
        <Content className="xxl:px-11 xxl:py-6 px-3 py-3 md:px-5 md:py-6 lg:py-8 lg:px-9">
          <Outlet />
          <div className="pb-1 md:py-4"></div>
        </Content>
      </Layout>
      <Drawer
        placement={'right'}
        onClose={closeSidebar}
        closable={false}
        open={sidebarOpen}
        width={dimension && (dimension.width > 450 ? '378px' : '90%')}
        title={
          <div className="flex justify-end">
            <button onClick={closeSidebar} className="border-none bg-transparent p-1 text-18 text-primary outline-none">
              <CloseOutlined />
            </button>
          </div>
        }
      >
        <div className="max-w-[300px]">
          <div className="px-2 min-[450px]:px-8">
            <HeaderSelectApp isDrawer={true} />
          </div>
          <LayoutSidebar />
        </div>
      </Drawer>
      <Drawer
        height={'auto'}
        placement={'top'}
        onClose={onSearchClose}
        open={searchDrawer}
        headerStyle={{ display: 'none' }}
      >
        <Input
          prefix={<SearchOutlined />}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value;
            handleSearch(value);
          }}
        />
      </Drawer>
    </Layout>
  );
};

export default MainLayout;
