import { RouteProps } from 'react-router-dom';

import SignIn from 'pages/auth/SignInPage';

const publicRoutes: RouteProps[] = [
  {
    path: 'sign-in',
    element: <SignIn />,
  },
];

export default publicRoutes;
