import { useQuery } from '@tanstack/react-query';

import LayoutContextConsumer from 'contexts/LayoutContext';

import RawDataTable from 'components/feature/charts/RawDataTable';
import Loading from 'components/shared/Loading';

import daymareZeroApi from 'config/api/bigquery/daymareZeroApi';
import { IVersionHistory } from 'config/api/version-history/versionHistoryApi';
import { BROWN_COLOR, GREEN_COLOR, SECONDARY_COLOR } from 'config/constants/theme';
import { toExcelFileName, writeExcelFile } from '../../../../config/constants/general';
import numberHelper from 'config/helpers/numberHelper';

const { convertText } = numberHelper;

interface Props {
  rangeDate: {
    start?: string;
    end?: string;
  };
  versionHistoryData?: IVersionHistory[];
  version: string | null;
  country: string | null;
}

interface IIAPPack {
  time: string
  platform: string
  pack_id: string
  buy_times: number
  buy_unique_users: number
  local_process_failed_times: number
  local_process_failed_unique_users: number
  server_verify_success_times: number
  server_verify_success_unique_users: number
  server_verify_failed_times: number
  server_verify_failed_unique_users: number
  cancel_times: number
  cancel_unique_users: number
}

const { getIAPPackBuying, downloadIAPPackBuying } = daymareZeroApi;

const DaymareIAPPackBuyingDetails = ({ rangeDate, country, versionHistoryData, version }: Props) => {
  const { platform } = LayoutContextConsumer();

  const { data: packData, isLoading: packLoading } = useQuery(['iap-pack', country, version, rangeDate, platform], async () => {
    if (!rangeDate.start || !rangeDate.end) return [];

    const res = await getIAPPackBuying({
      params: {
        version,
        platform: platform === 'unified' ? undefined : platform,
        start: rangeDate.start,
        end: rangeDate.end,
        country: country !== 'All' ? country : undefined,
      },
    });

    return res as unknown as IIAPPack[];
  });

  const onDownload = async () => {
    const res = await downloadIAPPackBuying({
      params: {
        version,
        platform: platform === 'unified' ? undefined : platform,
        start: rangeDate.start,
        end: rangeDate.end,
        country: country !== 'All' ? country : undefined,
      },
    });
    writeExcelFile(res, toExcelFileName('Pack Interaction'), 'json');
  };

  const isLoading = packLoading;

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex gap-8 flex-wrap">
          {packData && packData.length > 0 && (
            <RawDataTable
              isCenterFull
              title="Pack Interaction"
              colors={[
                BROWN_COLOR,
                BROWN_COLOR,
                BROWN_COLOR,
                SECONDARY_COLOR,
                SECONDARY_COLOR,
                SECONDARY_COLOR,
                SECONDARY_COLOR,
                SECONDARY_COLOR,
                GREEN_COLOR,
                GREEN_COLOR,
                GREEN_COLOR,
                GREEN_COLOR,
                GREEN_COLOR,
              ]}
              headers={[
                'Date',
                'Platform',
                'Pack ID',
                'Buy Times',
                'Local Process Failed Times',
                'Server Verify Success Times',
                'Server Verify Failed Times',
                'Cancel Times',
                'Buy Users',
                'Local Process Failed Users',
                'Server Verify Success Users',
                'Server Verify Failed Users',
                'Cancel Users',
              ]}
              data={packData.map((item) => {
                return [
                  item.time,
                  item.platform,
                  item.pack_id,
                  convertText(item.buy_times),
                  convertText(item.local_process_failed_times),
                  convertText(item.server_verify_success_times),
                  convertText(item.server_verify_failed_times),
                  convertText(item.cancel_times),
                  convertText(item.buy_unique_users),
                  convertText(item.local_process_failed_unique_users),
                  convertText(item.server_verify_success_unique_users),
                  convertText(item.server_verify_failed_unique_users),
                  convertText(item.cancel_unique_users),
                ];
              })}
              onDownload={onDownload}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default DaymareIAPPackBuyingDetails;
