import React from 'react';
import { DualAxes, DualAxesConfig } from '@ant-design/plots';

const ColumnLineChart = ({
  dataColumn,
  dataLine,
  xField,
  yFieldColumn,
  yFieldLine,
  colorFieldColumn,
  colorFieldLine,
}: {
    dataColumn: any,
    dataLine: any,
    xField: string,
    yFieldColumn: string,
    yFieldLine: string,
    colorFieldColumn: string,
    colorFieldLine: string,
  }) => {
  const config: DualAxesConfig = {
    data: [dataColumn, dataLine],
    xField,
    yField: [yFieldColumn, yFieldLine],
    geometryOptions: [
      {
        geometry: 'column',
        isGroup: true,
        seriesField: colorFieldColumn,
        columnWidthRatio: 0.4,
        label: {
          content: ''
        },
        color: ['#B5CBFC', '#F2E1CA', '#C7BCE5'],
      },
      {
        geometry: 'line',
        seriesField: colorFieldLine,
        lineStyle: ({ tag }: { tag: string }) => {
          if (!['Today', 'A. Today'].includes(tag)) {
            return {
              opacity: 0.8,
              lineDash: [10, 10],
            };
          }
          return {
            opacity: 0.8,
            lineWidth: 3,
          };
        },
        smooth: true,
        color: ['#5D91FF', '#FF9F11', '#7F59C2'],
      },
    ],
    legend: {
      position: 'bottom-left',
      offsetX: 16,
      offsetY: 20,
    },
  };
  return <DualAxes {...config} />;
};

export default React.memo(ColumnLineChart);
