import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';

import LayoutContextConsumer from 'contexts/LayoutContext';

import useIsomorphicLayoutEffect from 'hooks/useIsomorphicLayoutEffect';

import CountrySelect from 'components/shared/CountrySelect';
import Loading from 'components/shared/Loading';

import { funnelApi } from 'config/api/bigquery/funnelApi';
import generalApi from 'config/api/bigquery/generalApi';
import { funnelOpt } from 'config/constants/funnel';
import { GREY_COLOR_2, SECONDARY_COLOR } from 'config/constants/theme';
import appHelper from 'config/helpers/appHelper';
import numberHelper from 'config/helpers/numberHelper';

import FunnelChart from './FunnelChart';
import FunnelTable from './FunnelTable';

import { ConfigProvider, Select } from 'antd';
import clsx from 'clsx';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import FullDatePicker from 'components/shared/FullDatePicker';
import dayjs from 'dayjs';

interface IFilter {
  country: string;
  version: string;
  funnel: string;
  playingDays: string;
}

interface IDataResponse {
  level: number | string;
  users: number;
  version: string;
}

const { getVersions } = generalApi;
const { getData, getDataV2 } = funnelApi;
const { convertText } = appHelper;
const { toPercent } = numberHelper;

const calOpacity = (value: number, total: number) => {
  let result = value / total;

  if (1 - result > 0.03) {
    return 1 - result;
  } else {
    return 0;
  }
};

const FunnelContent = () => {
  const { currentApp, platform } = LayoutContextConsumer();
  const shouldUseV2 = currentApp?.id === 'GunAndDungeon';

  const listOpts = useMemo(() => {
    if (!currentApp?.id) return [];

    return get(funnelOpt, currentApp?.id, []) as { value: string; level: string }[];
  }, [currentApp?.id]);

  const [filter, setFilter] = useState<IFilter>({
    country: 'All',
    version: '',
    funnel: listOpts[0].value,
    playingDays: '',
  });

  const [displayChart, setDisplayChart] = useState(false);
  const [rangeDate, setRangeDate] = useState<{ start?: string; end?: string }>({
    start: dayjs().subtract(7, 'day').format('YYYY-MM-DD'),
    end: dayjs().format('YYYY-MM-DD'),
  });


  useIsomorphicLayoutEffect(() => {
    setFilter({ ...filter, funnel: listOpts[0].value, country: 'All', version: '' });
  }, [currentApp]);


  const { data, isLoading, isError } = useQuery(
    ['funnel', filter, platform, rangeDate],
    async () => {
      if (!filter.version) {
        return [];
      }
      const res = await (shouldUseV2 ? getDataV2 : getData)({
        option: filter.funnel,
        country: filter.country !== 'All' ? filter.country : undefined,
        version: filter.version !== 'All' ? filter.version : undefined,
        platform: platform !== 'unified' ? platform : undefined,
        ...rangeDate
      });
      return res as unknown as IDataResponse[];
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const { data: versions } = useQuery(['versions', currentApp?.id], async () => {
    const res = await getVersions();

    return res as unknown as { version: string; platform: string }[];
  });

  const versionList = useMemo(() => {
    if (!versions) return [];
    const groupVersions = groupBy(versions, 'platform');

    let verList = [];
    if (platform !== 'unified') {
      verList = groupVersions[platform.toUpperCase()].map((item) => item.version);
    } else {
      verList = versions.map((item) => item.version);
      // remove duplicate
      verList = Array.from(new Set(verList));
    }
    const result = numberHelper.sortVersions(verList).map((item) => ({
      label: item,
      value: item,
    }));

    return result;
  }, [platform, versions]);

  const tableData = useMemo(() => {
    if (!data || data.length === 0 || isError) return null;

    const headers = [
      listOpts.find((item) => item.value === filter.funnel)?.level || '',
      'Users',
      '% Drop by total',
      '% Drop by previous',
    ];

    if (filter.version === 'All') {
      return {
        headers: headers.slice(0, 4),
        data: data?.map((item, i) => {
          return [
            convertText(item.level.toString()),
            item.users.toString(),
            toPercent(item.users, data[0].users), // Cal % step
            toPercent(item.users, i > 0 ? data[i - 1].users : data[0].users), // Cal % step
            calOpacity(item.users, i > 0 ? data[i - 1].users : data[0].users).toFixed(2),
          ];
        }),
      };
    } else {
      return {
        headers,
        data: data?.map((item, i) => [
          convertText(item.level.toString()),
          item.users.toString(),
          toPercent(item.users, data[0].users),
          toPercent(item.users, i > 0 ? data[i - 1].users : data[0].users), // Cal % step
          calOpacity(item.users, i > 0 ? data[i - 1].users : data[0].users).toFixed(2),
        ]),
      };
    }
  }, [data, filter.funnel, filter.version, listOpts, isError]);

  const funnelData = useMemo(() => {
    if (!data || data.length === 0 || isError) return [];

    return data.map((item, i) => {
      return {
        level: convertText(item.level.toString()),
        users: item.users,
        percent: toPercent(item.users, data[i > 0 ? i - 1 : 0].users),
      };
    });
  }, [data, isError]);

  useEffect(() => {
    if (versions && versionList.length > 0) {
      setFilter({ ...filter, version: versionList[0].value });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [versionList]);

  if (isError) {
    return <div>Error</div>;
  }

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: SECONDARY_COLOR,
            colorBorder: GREY_COLOR_2,
          },
        }}
      >
        <div className="flex flex-wrap items-end gap-6">
          <div className="">
            <h6 className="mb-2 text-16 font-[500]">Funnel picker</h6>
            <Select
              options={listOpts.map((item) => ({ label: item.level, value: item.value }))}
              value={filter.funnel}
              onChange={(value) => {
                setFilter({ ...filter, funnel: value });
              }}
              style={{
                minWidth: '200px',
              }}
            />
          </div>
          {shouldUseV2 && <FullDatePicker rangeDate={rangeDate} setRangeDate={setRangeDate} />}

          {versions && versionList.length > 0 && (
            <div className="">
              <h6 className="mb-2 text-16 font-[500]">Versions</h6>
              <Select
                options={[{ label: 'All', value: 'All' }, ...versionList]}
                value={filter.version}
                onChange={(value) => {
                  setFilter({ ...filter, version: value });
                }}
                style={{
                  minWidth: '200px',
                }}
              />
            </div>
          )}

          <CountrySelect
            selectedCountry={filter.country}
            setSelectedCountry={(value) => {
              setFilter({ ...filter, country: value });
            }}
          />
          <div className="">
            <h6 className="mb-2 text-16 font-[500]">Data display</h6>
            <Select
              options={[
                { label: 'Table', value: false },
                { label: 'Chart', value: true },
              ]}
              value={displayChart}
              onChange={(value) => {
                setDisplayChart(value);
              }}
              style={{
                minWidth: '200px',
              }}
            />
          </div>
        </div>
      </ConfigProvider>

      <div className="mt-6">
        {isLoading && <Loading />}
        {data && data.length > 0 && (
          <>
            {displayChart ? (
              <div className="w-full overflow-auto">
                <div className={clsx(data.length > 15 ? 'w-[150vw]' : 'w-full')}>
                  <FunnelChart data={funnelData} xField="level" yField="users" className="w-max" />
                </div>
              </div>
            ) : (
              tableData && <FunnelTable headers={tableData.headers} data={tableData.data ? tableData?.data : []} />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default FunnelContent;
