import { useQuery } from '@tanstack/react-query';
import React from 'react';

import LayoutContextConsumer from 'contexts/LayoutContext';

import RawDataTable from 'components/feature/charts/RawDataTable';
import Loading from 'components/shared/Loading';

import mimilandApi from 'config/api/bigquery/mimilandApi';
import { IVersionHistory } from 'config/api/version-history/versionHistoryApi';

import { Empty } from 'antd';

interface Props {
  rangeDate: {
    start?: string;
    end?: string;
  };
  versionHistoryData?: IVersionHistory[];
  version: string | null;
}

interface IStatusStat {
  time: string;
  step: string;
  times: number;
  total_donut_1: number;
  total_donut_3: number;
  total_donut_4: number;
  total_throw: number;
  total_throw_inside: number;
}

interface IFailedStat {
  time: string;
  status: string;
  times: number;
  total_donut_1: number;
  total_donut_3: number;
  total_donut_4: number;
  total_throw: number;
  total_throw_inside: number;
}

const { getMimilandCatchCoCoFailedStats, getMimilandCatchCoCoStatusStats } = mimilandApi;

const CatchCoCo = ({ rangeDate, version }: Props) => {
  const { platform } = LayoutContextConsumer();

  const { data: statusStats, isLoading: statusLoading } = useQuery(
    ['catch-coco/status', rangeDate, version, platform],
    () => {
      if (!rangeDate.start || !rangeDate.end) return [];

      const res = getMimilandCatchCoCoStatusStats({
        platform: platform === 'unified' ? undefined : platform,
        version,
        start: rangeDate.start,
        end: rangeDate.end,
      });

      return res as unknown as IStatusStat[];
    },
  );

  const { data: failedStats, isLoading: failedLoading } = useQuery(
    ['catch-coco/failed', rangeDate, version, platform],
    () => {
      if (!rangeDate.start || !rangeDate.end) return [];

      const res = getMimilandCatchCoCoFailedStats({
        platform: platform === 'unified' ? undefined : platform,
        version,
        start: rangeDate.start,
        end: rangeDate.end,
      });

      return res as unknown as IFailedStat[];
    },
  );

  const isLoading = statusLoading || failedLoading;

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex gap-8 flex-wrap">
          {statusStats && statusStats.length > 0 ? (
            <RawDataTable
              isCenterFull
              title="Start/Failed/Success"
              headers={[
                'Date',
                'Step',
                'Times',
                'Total Donut 1',
                'Total Donut 3',
                'Total Donut 4',
                'Total Throw',
                'Total Throw Inside',
              ]}
              data={
                statusStats?.map((item) => [
                  item.time,
                  item.step,
                  item.times.toFixed(0),
                  item.total_donut_1.toFixed(0),
                  item.total_donut_3.toFixed(0),
                  item.total_donut_4.toFixed(0),
                  item.total_throw.toFixed(0),
                  item.total_throw_inside.toFixed(0),
                ]) || []
              }
            />
          ) : (
            <Empty />
          )}

          {failedStats && failedStats.length > 0 ? (
            <RawDataTable
              title="Failed Reasons"
              isCenterFull
              headers={[
                'Date',
                'Step',
                'Times',
                'Total Donut 1',
                'Total Donut 3',
                'Total Donut 4',
                'Total Throw',
                'Total Throw Inside',
              ]}
              data={
                failedStats?.map((item) => [
                  item.time,
                  item.status,
                  item.times.toFixed(0),
                  item.total_donut_1.toFixed(0),
                  item.total_donut_3.toFixed(0),
                  item.total_donut_4.toFixed(0),
                  item.total_throw.toFixed(0),
                  item.total_throw_inside.toFixed(0),
                ]) || []
              }
            />
          ) : (
            <Empty />
          )}
        </div>
      )}
    </div>
  );
};

export default CatchCoCo;
