import {
  BLUE_COLOR,
  GREEN_COLOR,
  PINK_COLOR,
  PURPLE_COLOR,
  SECONDARY_COLOR,
  YELLOW_COLOR,
  generateColor,
} from 'config/constants/theme';

import { Pie, PieConfig } from '@ant-design/plots';

export const pieChartColors = [
  SECONDARY_COLOR,
  YELLOW_COLOR,
  PURPLE_COLOR,
  PINK_COLOR,
  GREEN_COLOR,
  '#060047',
  BLUE_COLOR,
  ...generateColor(),
];

const PieChart = ({ data, angleField, colorField, height, ...props }: PieConfig) => {
  const config: PieConfig = {
    data,
    angleField,
    colorField,
    label: {
      type: 'inner',
      offset: '-30%',
      content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
      style: {
        fontSize: 14,
        textAlign: 'center',
      },
    },
    interactions: [
      {
        type: 'element-active',
      },
    ],
    color: pieChartColors,
    ...props,
  };
  return <Pie {...config} />;
};

export default PieChart;
