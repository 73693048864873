import React from 'react';

import TagSelect from 'components/shared/TagSelect';
import CusSelect from './CusSelect';

interface IDropdownSelect {
  value: string;
  onValueChanged: (newValue: string) => void;
  options: { label: string, value: string }[];
  title: string;
}

const DropdownSelect = ({ value, onValueChanged, options, title }: IDropdownSelect) => {
  return (
    <div className="">
      <h6 className="mb-2 text-16 font-[500]">{title}</h6>
      <CusSelect
        value={value}
        options={options}
        style={{
          minWidth: '200px',
        }}
        onChange={(selected) => {
          onValueChanged(selected);
        }}
        tagRender={TagSelect}
        showSearch
      />
    </div>
  );
};

export default DropdownSelect;
