import React from 'react';
import { DayPicker, DayPickerProps } from 'react-day-picker';

import 'content/styles/cus-daypicker.css';

const CusDayPicker = (props: DayPickerProps) => {
  return (
    <DayPicker
      {...props}
      modifiersClassNames={{
        selected: 'cus-day-picker-selected',
        today: 'cus-day-picker-today',
        range_start: 'cus-day-picker-range-start',
        range_end: 'cus-day-picker-range-end',
        range_middle: 'cus-day-picker-range-middle',
      }}
    />
  );
};

export default CusDayPicker;
