import React from 'react';

import { SECONDARY_COLOR } from 'config/constants/theme';

import { Tag } from 'antd';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';

const TagSelect = (props: CustomTagProps) => {
  const { label, closable, onClose } = props;
  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      color={SECONDARY_COLOR}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{ marginRight: 6, backgroundColor: SECONDARY_COLOR }}
    >
      {label}
    </Tag>
  );
};

export default TagSelect;
