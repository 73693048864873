import React from 'react';
import { useLocation } from 'react-router-dom';

import LayoutContextConsumer from 'contexts/LayoutContext';

import AuthConsumer from 'hooks/useAuth';

import Forbidden from 'pages/exception/Forbidden';

import resourceEnum from 'config/constants/resource';
import userRole from 'config/constants/userRole';

/**
 * This is a higher-order component that checks user permissions and resources before rendering a
 * wrapped component.
 * @param WrappedComponent - A React component that will be wrapped with the withPermission
 * higher-order component.
 * @returns The `withPermission` function returns a higher-order component (HOC) that takes a component
 * as an argument and returns a new component that checks the user's role and resources to determine if
 * they have permission to access the page. If the user does not have permission, the `Forbidden`
 * component is rendered. If the user has permission, the original component is rendered with its
 * props.
 */
const withPermission = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
  const Wrapper: React.FC<P> = (props) => {
    const { role } = AuthConsumer();
    const { resources } = LayoutContextConsumer();

    const { pathname } = useLocation();

    const checkPermission = (): boolean => {
      if (role === userRole.SUPERADMIN) return false;

      return (
        (role !== userRole.ADMIN && role !== userRole.SUPERADMIN) ||
        !resources.includes(pathname.split('/')[1] as resourceEnum)
      );
    };

    return checkPermission() ? <Forbidden /> : <WrappedComponent {...props} />;
  };

  Wrapper.displayName = `withPermission(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;

  return Wrapper;
};

export default withPermission;
