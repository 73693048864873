import { useQuery } from '@tanstack/react-query';
import { useMemo, useState } from 'react';

import LayoutContextConsumer from 'contexts/LayoutContext';

import LineChart from 'components/feature/charts/LineChart';
import ChartContainer from 'components/shared/ChartContainer';
import CusSelect from 'components/shared/CusSelect';
import Loading from 'components/shared/Loading';
import TagSelect from 'components/shared/TagSelect';

import mimilandApi from 'config/api/bigquery/mimilandApi';
import { IVersionHistory } from 'config/api/version-history/versionHistoryApi';
import { CALENDAR_TEXT_ICON } from 'config/constants/general';
import { GREY_COLOR, PRIMARY_COLOR } from 'config/constants/theme';

import { Col, Row } from 'antd';
import { groupBy } from 'lodash';

const { getMimilandTabItems, getMimilandItemClick } = mimilandApi;

interface ITabItem {
  emote_name: string;
  tab_id: string;
}

interface IItemClickData {
  date: string;
  emote_name: string;
  platform: string;
  data: number;
}

interface Props {
  rangeDate: {
    start?: string;
    end?: string;
  };
  versionHistoryData?: IVersionHistory[];
  version: string | null;
}

const EmoteItemClick = ({ rangeDate, versionHistoryData, version }: Props) => {
  const { platform } = LayoutContextConsumer();
  const [poseItems, setPoseItems] = useState<string[]>([]);
  const [actionItems, setActionItems] = useState<string[]>([]);

  const { data: tabItems } = useQuery(['tab-items'], async () => {
    const res = await getMimilandTabItems();

    return res as unknown as ITabItem[];
  });

  const { data: poseData, isLoading: poseLoading } = useQuery(['pose-data', version, poseItems, platform], async () => {
    if (!poseItems.length) return [];

    const res = await getMimilandItemClick({
      ...rangeDate,
      version,
      items: poseItems,
      tab: 'pose',
      platform: platform === 'unified' ? undefined : platform,
    });

    return res as unknown as IItemClickData[];
  });

  const { data: actionData, isLoading: actionLoading } = useQuery(
    ['action-data', version, actionItems, platform],
    async () => {
      if (!actionItems.length) return [];

      const res = await getMimilandItemClick({
        ...rangeDate,
        version,
        items: actionItems,
        tab: 'action',
        platform: platform === 'unified' ? undefined : platform,
      });

      return res as unknown as IItemClickData[];
    },
  );

  const groupTabItems = useMemo(() => {
    if (tabItems) {
      const group = groupBy(tabItems, 'tab_id');
      const newGroup: {
        [key: string]: string[];
      } = {};

      Object.keys(group).forEach((key) => {
        newGroup[key] = group[key].map((item) => item.emote_name);
      });

      setPoseItems(newGroup['pose'].slice(0, 5));
      setActionItems(newGroup['action'].slice(0, 5));

      return [newGroup['pose'], newGroup['action']];
    } else {
      return [[], []];
    }
  }, [tabItems]);

  return (
    <Row gutter={[28, 40]}>
      <Col span={24} lg={12}>
        <div className="mb-4 flex flex-wrap justify-between lg:flex-nowrap">
          <h6 className="text-14 font-semibold lg:text-16">Pose</h6>
        </div>

        {groupTabItems[0] && (
          <CusSelect
            mode="multiple"
            value={poseItems}
            options={groupTabItems[0].map((item) => ({ value: item, label: item }))}
            onChange={(e) => setPoseItems(e)}
            className="w-full mb-4"
            tagRender={TagSelect}
            maxTagCount={'responsive'}
          />
        )}
        {poseLoading && <Loading />}

        {poseData && poseData.length > 0 && (
          <ChartContainer>
            <LineChart
              data={poseData}
              xField="time"
              yField="data"
              seriesField="emote_name"
              height={330}
              xAxis={{
                label: {
                  style(_, index, items) {
                    const date = items[index].id;
                    const verData = versionHistoryData?.find((version) => version.date === date);

                    return {
                      fill: verData ? PRIMARY_COLOR : GREY_COLOR,
                      fontWeight: verData ? 600 : 400,
                    };
                  },
                  formatter(text) {
                    const verData = versionHistoryData?.find((version) => version.date === text);
                    return `${verData ? CALENDAR_TEXT_ICON : ''}${text}`;
                  },
                  autoRotate: true,
                },
                range: [0, 1],
              }}
            />
          </ChartContainer>
        )}
      </Col>

      <Col span={24} lg={12}>
        <div className="mb-4 flex flex-wrap justify-between lg:flex-nowrap">
          <h6 className="text-14 font-semibold lg:text-16">Action</h6>
        </div>
        {groupTabItems[1] && (
          <CusSelect
            mode="multiple"
            value={actionItems}
            options={groupTabItems[1].map((item) => ({ value: item, label: item }))}
            onChange={(e) => setActionItems(e)}
            className="w-full mb-4"
            tagRender={TagSelect}
            maxTagCount={'responsive'}
          />
        )}

        {actionLoading && <Loading />}
        {actionData && actionData.length > 0 && (
          <ChartContainer>
            <LineChart
              data={actionData}
              xField="time"
              yField="data"
              seriesField="emote_name"
              height={330}
              xAxis={{
                label: {
                  style(_, index, items) {
                    const date = items[index].id;
                    const verData = versionHistoryData?.find((version) => version.date === date);

                    return {
                      fill: verData ? PRIMARY_COLOR : GREY_COLOR,
                      fontWeight: verData ? 600 : 400,
                    };
                  },
                  formatter(text) {
                    const verData = versionHistoryData?.find((version) => version.date === text);
                    return `${verData ? CALENDAR_TEXT_ICON : ''}${text}`;
                  },
                  autoRotate: true,
                },
                range: [0, 1],
              }}
            />
          </ChartContainer>
        )}
      </Col>
    </Row>
  );
};

export default EmoteItemClick;
