import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useMemo, useState } from 'react';

import LayoutContextConsumer from 'contexts/LayoutContext';

import AuthConsumer from 'hooks/useAuth';
import useDocumentTitle from 'hooks/useDocumentTitle';
import useLocalStorage from 'hooks/useLocalStorage';

import NotFound from 'pages/exception/NotFound';
import ComingSoonPage from 'pages/main/ComingSoonPage';

import MonetizationFilter from 'components/feature/monetization/MonetizationFilter';
import MonetizationTable from 'components/feature/monetization/MonetizationTable';
import MonetizationTabs from 'components/feature/monetization/MonetizationTabs';
import Loading from 'components/shared/Loading';

import cohortApi from 'config/api/bigquery/cohortApi';
import exploreApi, { IDAUResponse } from 'config/api/bigquery/exploreApi';
import revenueApi from 'config/api/bigquery/revenueApi';
import versionHistoryApi, { IVersionHistory } from 'config/api/version-history/versionHistoryApi';
import { filterData } from 'config/constants/monetization';
import userRole from 'config/constants/userRole';
import dateHelper from 'config/helpers/dateHelper';
import numberHelper from 'config/helpers/numberHelper';

import isArray from 'lodash/isArray';
import sum from 'lodash/sum';
import uniq from 'lodash/uniq';
import { appTimezoneConst, formatTimezoneOffset } from '../../config/constants/general';

interface IReport {
  period: string;
  DAU: number;
  IAP_Revenue: number;
  IAP_Paying_Users: number;
  Ads_revenue: number;
  Ads_Paying_Users: number;
  IAP_Ads_Paying_Users: number;
  first_paying_users: number;
}

interface IRetention {
  [key: string]: number[];
}

export interface IFilter {
  dataType: string[];
  rangeDate: {
    start?: string;
    end?: string;
  };
  country: string;
}

const VALID_APPS = ['SkyDancer2', 'GunAndDungeon', "DaymareZero"];

const { getReports } = revenueApi;
const { getRetention } = cohortApi;
const { getUsers } = exploreApi;
const { getAll: getVersions } = versionHistoryApi;
const { compareDate, format, getDayBefore } = dateHelper;
const { toPercent, convertMoney } = numberHelper;

const MonetizationPage = () => {
  useDocumentTitle('Monetization');
  const { role } = AuthConsumer();

  const [active, setActive] = useState('total');
  const [saveDataType, setSaveDataType] = useLocalStorage<string[]>(
    `monetization-data-type`,
    role === userRole.PARTNER ? ['DAU', 'new-user'] : ['DAU', 'RR1', 'RR3', 'RR7'],
  );
  const [filter, setFilter] = useState<IFilter>({
    dataType: saveDataType,
    rangeDate: {
      start: format(getDayBefore(new Date(), 32)),
      end: format(getDayBefore(new Date(), 2)),
    },
    country: 'All',
  });

  useEffect(() => {
    setSaveDataType(filter.dataType);
  }, [filter.dataType, setSaveDataType]);

  const { currentApp, platform } = LayoutContextConsumer();
  const timezone = (currentApp && appTimezoneConst[currentApp.id]) ? appTimezoneConst[currentApp.id] : 0;

  const handleSetActive = (id: string) => {
    setActive(id);
  };

  // Get Data

  const {
    data: reportData,
    isLoading: isReportLoading,
    isError: isReportError,
  } = useQuery(['report', currentApp, filter.rangeDate, filter.country, platform], async () => {
    const response = await getReports(
      {
        ...filter.rangeDate,
        country: filter.country !== 'All' ? filter.country : undefined,
      },
      {
        params: {
          platform: platform && platform !== 'unified' ? platform : undefined,
        },
      },
    );
    return response as unknown as IReport[];
  });

  const {
    data: retentionData,
    isLoading: isRetentionLoading,
    isError: isRetentionError,
  } = useQuery(['retention', currentApp, filter.rangeDate, filter.country, platform], async () => {
    const response = await getRetention(
      {
        ...filter.rangeDate,
        country: filter.country !== 'All' ? filter.country : null,
        period: [0, 1, 3, 7, 15, 30],
        platform: platform && platform !== 'unified' ? platform : undefined,
        timezone: formatTimezoneOffset(timezone),
      },
      {
        params: {
          platform: platform && platform !== 'unified' ? platform : undefined,
        },
      },
    );
    return response as unknown as IRetention;
  });

  const {
    data: dauData,
    isLoading: isDAULoading,
    isError: isDAUError,
  } = useQuery(['dau', currentApp, filter.rangeDate, filter.country, platform], async () => {
    const response = await getUsers(
      {
        start: filter.rangeDate.start,
        end: filter.rangeDate.end,
        country: filter.country !== 'All' ? filter.country : undefined,
      },
      {
        params: {
          platform: platform && platform !== 'unified' ? platform : undefined,
        },
      },
    );
    return response as unknown as IDAUResponse;
  });

  const { data: versionHistoryData } = useQuery(
    ['version-history', currentApp, filter.rangeDate, platform],
    async () => {
      const response = await getVersions({
        params: {
          platform: platform && platform !== 'unified' ? platform : undefined,
          startDate: filter.rangeDate?.start,
          endDate: filter.rangeDate?.end,
        },
      });
      return response as unknown as IVersionHistory[];
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  // Check loading & error
  const isLoading = useMemo(
    () => isRetentionLoading || isDAULoading || isReportLoading,
    [isRetentionLoading, isDAULoading, isReportLoading],
  );
  const isError = useMemo(
    () => isRetentionError || isDAUError || isReportError,
    [isRetentionError, isDAUError, isReportError],
  );

  // Modify data for table
  const tabItems = useMemo(() => {
    if (isLoading || !reportData) return null;
    else {
      const ads = sum(isArray(reportData) ? reportData.map((item) => item.Ads_revenue) : []);
      const iap = sum(isArray(reportData) ? reportData.map((item) => item.IAP_Revenue) : []);
      const total = (ads ? ads : 0) + (iap ? iap : 0);
      return [
        {
          id: 'total',
          title: total ? `$${total.toFixed(2)}` : '$0',
          subTitle: 'Total revenue',
        },
        {
          id: 'iap',
          title: iap ? `$${iap.toFixed(2)}` : '$0',
          subTitle: 'Total IAP revenue',
        },
        {
          id: 'ads',
          title: ads ? `$${ads.toFixed(2)}` : '$0',
          subTitle: 'Total ads revenue',
        },
      ];
    }
  }, [reportData, isLoading]);

  const tableData = useMemo(() => {
    if (!reportData || !filter.dataType || !isArray(reportData)) return null;

    let labelsFilter = filter.dataType.map((item) => {
      const label = filterData.find((data) => data.key === item);
      return label?.label || '';
    });
    // Combine list of period from all data

    const period = uniq([
      ...(isArray(reportData) ? reportData?.map((item) => item.period) : []),
      ...(retentionData ? Object.keys(retentionData) : []),
    ]).sort((a, b) => compareDate(b, a));

    const filterDataRetention: { [key: string]: string[] } = {};
    period.forEach((item) => {
      const retention = retentionData?.[item];
      const dau = dauData?.find((dau) => dau.period === item);
      const data: string[] = [];
      filter.dataType.forEach((type) => {
        if (type === 'DAU') {
          data.push(dau?.DAU ? dau.DAU.toFixed(0) : '--');
        } else if (type === 'new-user') {
          data.push(dau?.newUsers ? dau.newUsers.toFixed(0) : '--');
        } else if (type === 'RR1') {
          data.push(retention ? toPercent(retention[1], retention[0]) : '--');
        } else if (type === 'RR3') {
          data.push(retention?.[2] ? toPercent(retention[2], retention[0]) : '--');
        } else if (type === 'RR7') {
          data.push(retention?.[3] ? toPercent(retention[3], retention[0]) : '--');
        } else if (type === 'RR15') {
          data.push(retention?.[4] ? toPercent(retention[4], retention[0]) : '--');
        } else if (type === 'RR30') {
          data.push(retention?.[5] ? toPercent(retention[5], retention[0]) : '--');
        }
      });
      filterDataRetention[item] = data;
    });

    const data = period.map((item, index) => {
      // Get the IAP and Ads data of the current period
      const report = reportData.filter((iapItem) => iapItem.period === item);

      const iapRevenue = report ? report.reduce((acc, cur) => acc + cur.IAP_Revenue, 0) : 0;
      const adsRevenue = report ? report.reduce((acc, cur) => acc + cur.Ads_revenue, 0) : 0;
      const totalUserIAP = report ? report.reduce((acc, cur) => acc + cur.IAP_Paying_Users, 0) : 0;
      const totalFirstPayingUsers = report ? report.reduce((acc, cur) => acc + cur.first_paying_users, 0) : 0;
      const totalUserAds = report ? report.reduce((acc, cur) => acc + cur.Ads_Paying_Users, 0) : 0;
      const totalUserBoth = report ? report.reduce((acc, cur) => acc + cur.IAP_Ads_Paying_Users, 0) : 0;
      const totalUserOne = totalUserIAP + totalUserAds - totalUserBoth;

      const totalRevenue = (iapRevenue ? iapRevenue : 0) + (adsRevenue ? adsRevenue : 0);
      const dau = dauData?.find((dau) => dau.period === item);
      let arpdau = '--';
      let arrppu = '--';
      let paidRate = '--';

      if (dau && dau.DAU) {
        if (active === 'iap') {
          arpdau = convertMoney(iapRevenue / dau.DAU);
          arrppu = convertMoney(totalUserIAP ? iapRevenue / totalUserIAP : 0);
          paidRate = toPercent(totalUserIAP, dau.DAU);
        } else if (active === 'ads') {
          arpdau = convertMoney(adsRevenue / dau.DAU);
          arrppu = convertMoney(totalUserAds ? adsRevenue / totalUserAds : 0);
          paidRate = toPercent(totalUserAds, dau.DAU);
        } else if (active === 'total') {
          arpdau = convertMoney(totalRevenue / dau.DAU);
          arrppu = convertMoney(totalUserOne ? totalRevenue / totalUserOne : 0);
          paidRate = toPercent(totalUserOne, dau.DAU);
        }
      }

      // Sum all data
      return [
        item,
        ...filterDataRetention[item],
        active === 'iap' || active === 'total' ? convertMoney(iapRevenue) : '',
        active === 'iap' || active === 'total' ? totalUserIAP.toFixed(0) : '',
        active === 'iap' || active === 'total' ? totalFirstPayingUsers.toFixed(0) : '',
        active === 'ads' || active === 'total' ? convertMoney(adsRevenue) : '',
        active === 'ads' || active === 'total' ? totalUserAds.toFixed(0) : '',
        active === 'total' ? convertMoney(totalRevenue) : '',
        totalUserBoth.toFixed(0),
        arpdau,
        arrppu,
        paidRate,
      ].filter((item) => item !== '');
    });

    return {
      labels: [
        'Date',
        ...labelsFilter,
        active === 'iap' || active === 'total' ? 'IAP' : '',
        active === 'iap' || active === 'total' ? 'Paying Users IAP' : '',
        active === 'iap' || active === 'total' ? 'First Paying Users' : '',
        active === 'ads' || active === 'total' ? 'Ads' : '',
        active === 'ads' || active === 'total' ? 'Paying Users Ads' : '',
        active === 'total' ? 'Total' : '',
        'Paying Users Both',
        'ARPU',
        'ARPPU',
        'Paid Rate',
      ].filter((item) => item !== ''),
      data,
    };
  }, [reportData, retentionData, dauData, filter.dataType, active]);

  // Render components
  if (isError) return <NotFound />;
  if (VALID_APPS.includes(currentApp?.id ?? "") === false) return <ComingSoonPage />;

  return (
    <div className="pb-3">
      <h3 className="page-section-title">Monetization</h3>
      {isLoading ? (
        <div className="flex min-h-[50vh] w-full items-center justify-center text-center">
          <Loading />
        </div>
      ) : (
        <>
          <section className="monetization-tabs mt-10 flex gap-12 overflow-auto">
            {tabItems &&
              tabItems.map((item) => (
                <MonetizationTabs
                  key={item.id}
                  {...item}
                  isActive={item.id === active}
                  handleSetActive={handleSetActive}
                />
              ))}
          </section>
          <section className="my-10">
            <MonetizationFilter
              options={filterData.map((item) => ({
                label: item.label,
                value: item.key,
                disabled: item.disabled,
              }))}
              filter={filter}
              setFilter={setFilter}
            />
          </section>
          {tableData && (
            <MonetizationTable
              versionData={versionHistoryData ? versionHistoryData : []}
              columnsData={tableData.data}
              labels={tableData.labels}
            />
          )}
        </>
      )}
    </div>
  );
};

export default MonetizationPage;
