import toastHelper from 'config/helpers/toastHelper';

import api from '..';

import { AxiosRequestConfig } from 'axios';

interface IBodyDAU {
  start?: string;
  end?: string;
  country?: string;
}

export interface IDAUResponse
  extends Array<{
    period: string;
    DAU: number;
    newUsers: number;
    returningUsers: number;
  }> {}

const exploreApi = {
  getUsers: (body?: IBodyDAU, config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game) {
      if (body) return api.post(`new-returning/${game}`, body, config);
      return api.post(`new-returning/${game}`, null, config);
    } else toastHelper.error('Please select a game first');
  },
};

export default exploreApi;
