import React from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

interface ILoading {
  fontSize: number;
  marginLeft: number;
}

const LoadingInline = ({ fontSize = 48, marginLeft = 0 }: ILoading) => {
  const antIcon = <LoadingOutlined style={{ fontSize, marginLeft }} spin />;
  return (<Spin indicator={antIcon} />);
}

export default LoadingInline;
