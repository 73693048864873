import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { BiTime, BiUser } from 'react-icons/bi';

import LayoutContextConsumer from 'contexts/LayoutContext';

import RawDataTable, { DateVersion } from 'components/feature/charts/RawDataTable';
import Loading from 'components/shared/Loading';

import mimilandApi from 'config/api/bigquery/mimilandApi';
import { IVersionHistory } from 'config/api/version-history/versionHistoryApi';
import numberHelper from 'config/helpers/numberHelper';

import { Empty, Tooltip } from 'antd';
import get from 'lodash/get';
import moment from 'moment';

interface Props {
  rangeDate: {
    start?: string;
    end?: string;
  };
  versionHistoryData?: IVersionHistory[];
  version: string | null;
}

const { getMimilandDailyRewardImpr, getMimilandDailyRewardClaim } = mimilandApi;
const { toPercent } = numberHelper;

interface IDailyRewardImpr {
  time: string;
  platform: string;
  DAU: number;
  impr_auto_times: number;
  impr_auto_users: number;
  impr_menu_times: number;
  impr_menu_users: number;
}

interface IDailyRewardClaim {
  week: string;
  date_str: string;
  day_of_week: string;
  milestone: number;
  times: number;
  users: number;
}

interface IDailyRewardClaimTableData {
  [key: string]: {
    users: number;
    times: number;
  };
}

const DataItem = ({ users, value, title, dau }: { users: number; value: string; title?: string; dau?: number }) => {
  const rate = dau ? toPercent(users, dau) : 0;
  return (
    <Tooltip
      title={
        <span>
          <p>
            - {title}: {value} times
          </p>
          {dau && (
            <p>
              - Users / DAU: {users}/{dau} ({rate})
            </p>
          )}
        </span>
      }
    >
      <div className="flex items-center gap-1">
        <BiTime className="text-12" />
        <p className="text-14">{value}</p>
      </div>
      <div className="flex items-center gap-1 whitespace-nowrap">
        <BiUser className="text-12" />
        <p className="text-14">
          {users} {rate ? `(${rate})` : ''}
        </p>
      </div>
    </Tooltip>
  );
};

const DailyReward = ({ rangeDate, versionHistoryData }: Props) => {
  const { platform } = LayoutContextConsumer();

  const { data: drImprData, isLoading: drImprLoading } = useQuery(
    ['mml-daily-reward-impr', rangeDate, platform],
    async () => {
      if (!rangeDate.start || !rangeDate.end) return [];

      const res = await getMimilandDailyRewardImpr({
        params: {
          platform: platform === 'unified' ? undefined : platform,
          start: rangeDate.start,
          end: rangeDate.end,
        },
      });

      return res as unknown as IDailyRewardImpr[];
    },
  );

  const { data: drClaimData, isLoading: drClaimLoading } = useQuery(
    ['mml-daily-reward-claim', rangeDate, platform],
    async () => {
      if (!rangeDate.start || !rangeDate.end) return [];

      const res = await getMimilandDailyRewardClaim({
        params: {
          platform: platform === 'unified' ? undefined : platform,
          start: rangeDate.start,
          end: rangeDate.end,
        },
      });

      return res as unknown as IDailyRewardClaim[];
    },
  );

  const drClaimTableData = useMemo(() => {
    if (!drClaimData) return {};

    const tableData: {
      [key: string]: IDailyRewardClaimTableData;
    } = {};

    drClaimData.forEach((item) => {
      const week = item.week;
      const day =
        item.day_of_week === 'SUN' || item.day_of_week === 'SAT'
          ? `${item.day_of_week}_${Number(item.milestone) + 1}`
          : item.day_of_week;
      if (!tableData[week]) {
        tableData[week] = {
          [day]: {
            users: item.users,
            times: item.times,
          },
        };
      }

      tableData[week][day] = {
        users: item.users,
        times: item.times,
      };
    });

    return tableData;
  }, [drClaimData]);

  const isLoading = drImprLoading || drClaimLoading;

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="flex flex-col gap-10">
            {drClaimTableData ? (
              <RawDataTable
                isCenterFull
                title="Daily Reward Claim Data"
                headers={[
                  'Week',
                  'MON',
                  'TUE',
                  'WED',
                  'THU',
                  'FRI',
                  'SAT 1',
                  'SAT 2',
                  'SAT 3',
                  'SUN 1',
                  'SUN 2',
                  'SUN 3',
                ]}
                data={
                  Object.keys(drClaimTableData).map((item) => {
                    const data = drClaimTableData[item];
                    const weekMoment = moment(item + '-1', 'YYYY-WW-E');
                    const startOfWeek = weekMoment.startOf('isoWeek').format('MM/DD');
                    const endOfWeek = weekMoment.endOf('isoWeek').format('MM/DD');

                    return [
                      <div>
                        <p>{item}</p>
                        <p className="text-xs">
                          ({startOfWeek} - {endOfWeek})
                        </p>
                      </div>,
                      <DataItem
                        users={get(data, 'MON.users', 0) as number}
                        value={get(data, 'MON.times', 0) as unknown as string}
                        title="Monday Claim"
                      />,
                      <DataItem
                        users={get(data, 'TUE.users', 0) as number}
                        value={get(data, 'TUE.times', 0) as unknown as string}
                        title="Tuesday Claim"
                      />,
                      <DataItem
                        users={get(data, 'WED.users', 0) as number}
                        value={get(data, 'WED.times', 0) as unknown as string}
                        title="Wednesday Claim"
                      />,
                      <DataItem
                        users={get(data, 'THU.users', 0) as number}
                        value={get(data, 'THU.times', 0) as unknown as string}
                        title="Thursday Claim"
                      />,
                      <DataItem
                        users={get(data, 'FRI.users', 0) as number}
                        value={get(data, 'FRI.times', 0) as unknown as string}
                        title="Friday Claim"
                      />,
                      <DataItem
                        users={get(data, 'SAT_1.users', 0) as number}
                        value={get(data, 'SAT_1.times', 0) as unknown as string}
                        title="Saturday 1 Claim"
                      />,
                      <DataItem
                        users={get(data, 'SAT_2.users', 0) as number}
                        value={get(data, 'SAT_2.times', 0) as unknown as string}
                        title="Saturday 2 Claim"
                      />,
                      <DataItem
                        users={get(data, 'SAT_3.users', 0) as number}
                        value={get(data, 'SAT_3.times', 0) as unknown as string}
                        title="Saturday 3 Claim"
                      />,
                      <DataItem
                        users={get(data, 'SUN_1.users', 0) as number}
                        value={get(data, 'SUN_1.times', 0) as unknown as string}
                        title="Sunday 1 Claim"
                      />,
                      <DataItem
                        users={get(data, 'SUN_2.users', 0) as number}
                        value={get(data, 'SUN_2.times', 0) as unknown as string}
                        title="Sunday 2 Claim"
                      />,
                      <DataItem
                        users={get(data, 'SUN_3.users', 0) as number}
                        value={get(data, 'SUN_3.times', 0) as unknown as string}
                        title="Sunday 3 Claim"
                      />,
                    ];
                  }) ?? []
                }
                rawData={drClaimData}
              />
            ) : (
              <Empty />
            )}
          </div>
          <div className="flex flex-col gap-10">
            {drImprData && drImprData.length > 0 ? (
              <RawDataTable
                isCenterFull
                title="Daily Reward Impression Data"
                headers={['Date', 'Platform', 'DAU', 'Impression \n (Auto)', 'Impression \n (Menu)']}
                data={
                  drImprData.map((item) => {
                    const verData = versionHistoryData?.find((ver) => ver.date === item.time);
                    return [
                      <DateVersion verData={verData} date={item.time} />,
                      item.platform,
                      item.DAU.toFixed(0),
                      <DataItem
                        users={item.impr_auto_users}
                        value={item.impr_auto_times.toFixed(0)}
                        title="Impression Auto"
                        dau={item.DAU}
                      />,
                      <DataItem
                        users={item.impr_menu_users}
                        value={item.impr_menu_times.toFixed(0)}
                        title="Impression Menu"
                        dau={item.DAU}
                      />,
                    ];
                  }) ?? []
                }
                rawData={drImprData}
              />
            ) : (
              <Empty />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default DailyReward;
