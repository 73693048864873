import React from 'react';

import { supportedTimezones } from 'config/constants/general';

import TagSelect from 'components/shared/TagSelect';
import CusSelect from './CusSelect';

interface ITimezoneSelect {
  value: number;
  onValueChanged: (newValue: number) => void;
}

const TimezoneSelect = ({ value, onValueChanged }: ITimezoneSelect) => {
  return (
    <div className="">
      <h6 className="mb-2 text-16 font-[500]">Timezone</h6>
      <CusSelect
        value={value}
        options={supportedTimezones}
        style={{
          minWidth: '200px',
        }}
        onChange={(selected) => {
          onValueChanged(selected);
        }}
        tagRender={TagSelect}
        showSearch
      />
    </div>
  );
};

export default TimezoneSelect;
