import toastHelper from 'config/helpers/toastHelper';

import api from '..';

import { AxiosRequestConfig } from 'axios';

const getGunAndDungeonLevelData = (query: Record<string, any>, config: AxiosRequestConfig = {}) => {
  const game = sessionStorage.getItem('currentApp');
  if (game) {
    return api.get(`gun-and-dungeon/level`, {
      ...config,
      params: query,
    });
  } else {
    toastHelper.error('Please select a game first');
  }
};

const gunAndDungeonApi = {
  getGunAndDungeonLevelData,
};

export default gunAndDungeonApi;
