import { Platform } from 'config/constants/platform';
import toastHelper from 'config/helpers/toastHelper';

import api from '..';

import { AxiosRequestConfig } from 'axios';

export interface IVersionHistory {
  id: string;
  version: string;
  game?: string;
  title: string;
  description?: string;
  date: string | Date;
  platform: Platform;
}

const versionHistoryApi = {
  getAll: (config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game) return api.get(`version-history/${game}`, config);
    else toastHelper.error('Please select a game first');
  },
  add: (data: IVersionHistory) => {
    const game = sessionStorage.getItem('currentApp');
    if (game) return api.put(`version-history/${game}`, data);
    else toastHelper.error('Please select a game first');
  },
  update: (data: IVersionHistory, id: string) => {
    const game = sessionStorage.getItem('currentApp');
    if (game) return api.post(`version-history/${game}/${id}`, data);
    else toastHelper.error('Please select a game first');
  },
  deleteVersion: (id: string) => {
    const game = sessionStorage.getItem('currentApp');
    if (game) return api.delete(`version-history/${game}/${id}`);
    else toastHelper.error('Please select a game first');
  },
};

export default versionHistoryApi;
