import { useQuery } from '@tanstack/react-query';

import RawDataTable from 'components/feature/charts/RawDataTable';
import Loading from 'components/shared/Loading';

import skyDancer2Api from 'config/api/bigquery/skyDancer2Api';
import { IVersionHistory } from 'config/api/version-history/versionHistoryApi';

import { Empty } from 'antd';

interface Props {
  rangeDate: {
    start?: string;
    end?: string;
  };
  versionHistoryData?: IVersionHistory[];
  version: string | null;
  country: string;
}

interface ILastAction {
  dtt_battle_count: number;
  world: string;
  road_index: string;
  users: number;
}

const { getLastActionUserLeft } = skyDancer2Api;

const SkyLastAction = ({ rangeDate, version, country }: Props) => {
  const { data, isLoading } = useQuery(['last-action', version, rangeDate, country], async () => {
    if (!rangeDate.start || !rangeDate.end) return [];

    const res = await getLastActionUserLeft({
      params: {
        version,
        start: rangeDate.start,
        end: rangeDate.end,
        country: country === 'All' ? undefined : country,
      },
    });

    return res as unknown as ILastAction[];
  });

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : data && data.length > 0 ? (
        <div className="overflow-x-auto">
          <RawDataTable
            isCenterFull
            title="Last Action"
            headers={['Battle Count', 'World', 'Road Index', 'Users']}
            data={data.map((item): [string, string, string, string] => [
              item.dtt_battle_count.toFixed(0),
              item.world,
              item.road_index,
              item.users.toFixed(0),
            ])}
          />
        </div>
      ) : (
        <Empty />
      )}
    </div>
  );
};

export default SkyLastAction;
