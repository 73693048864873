import React from 'react';

import {
  BLUE_COLOR,
  GREEN_COLOR,
  PINK_COLOR,
  PURPLE_COLOR,
  SECONDARY_COLOR,
  YELLOW_COLOR,
  generateColor,
} from 'config/constants/theme';

import { Column, ColumnConfig } from '@ant-design/plots';

export const columnChartColors = [
  SECONDARY_COLOR,
  YELLOW_COLOR,
  PURPLE_COLOR,
  PINK_COLOR,
  GREEN_COLOR,
  '#060047',
  BLUE_COLOR,
  ...generateColor(),
];

const ColumnChart = ({ data, xField, yField, seriesField, height, ...props }: ColumnConfig) => {
  const config: ColumnConfig = {
    data,
    xField,
    yField,
    seriesField,
    maxColumnWidth: 60,
    legend: {
      position: 'bottom',
      marker: (_, index) => {
        return {
          symbol: 'circle',
          style: {
            fill: columnChartColors[index],
          },
        };
      },
    },
    color: columnChartColors,
    ...props,
  };

  return <Column {...config} />;
};

export default React.memo(ColumnChart);
