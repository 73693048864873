import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import daymareZeroApi from 'config/api/bigquery/daymareZeroApi';
import LoadingInline from '../../shared/LoadingInline';

interface Props {
  platformF: string;
  country: string;
  channel: string;
}

const { addCampaignReport } = daymareZeroApi;

const CampaignReport = ({ platformF, country, channel }: Props) => {
  const [status, setStatus] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(false);

  const readFile = () => {
    if (country && country !== 'All') {
      const input = document.getElementById('fileInput') as HTMLInputElement;
      if (input.files && input.files[0]) {
        setStatus('');
        setLoading(true);

        const file = input.files[0];
        const reader = new FileReader();

        reader.onload = (e) => {
          try {
            const data = new Uint8Array(e.target?.result as ArrayBuffer);
            const workbook = XLSX.read(data, { type: 'array' });

            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(sheet, {
              raw: false,
            });

            writeData(jsonData);
          } catch (error) {
            setStatus(`FAILED: ${error}`);
            setLoading(false);
          }
        };

        reader.readAsArrayBuffer(file);
      } else {
        setStatus('Please select a file !!');
      }
    } else {
      setStatus('Please select a country !!');
    }
  };

  const writeData = async (json: any) => {
    const res = await addCampaignReport({
      params: {
        platform: platformF,
        country: country !== 'All' ? country : undefined,
        channel: channel,
      },
      data: json,
    });
    setStatus(res as unknown as string);
    setLoading(false);
  };

  return (
    <div className="">
      <input type="file" id="fileInput" accept=".xlsx, .xls" />
      <button className="primary-button" type="button" id="readButton" onClick={readFile}>
        Write DB
      </button>
      {isLoading
        ? (<LoadingInline fontSize={24} marginLeft={20} />)
        : (<label style={{ marginLeft: "15px" }}>{status}</label>)
      }
    </div>
  );
};

export default CampaignReport;
