import { DATE_FORMAT, DATE_FORMAT_TEXT, HOUR_FORMAT, MONTH_FORMAT, TIMEZONE_FORMAT } from 'config/constants/format';

import moment from 'moment';
import timezone from 'moment-timezone';

const dateHelper = {
  format: (date: Date | string) => {
    return moment(date).format(DATE_FORMAT);
  },

  formatMonth: (date: Date) => {
    return moment(date).format(MONTH_FORMAT);
  },

  formatText: (date: Date) => {
    return moment(date).format(DATE_FORMAT_TEXT);
  },

  formatHours: (date: Date) => {
    return moment(date).format(HOUR_FORMAT);
  },

  compareDateByMinutes: (date1: Date, date2: Date) => {
    return moment(date2).diff(date1, 'minutes');
  },

  compareDate: (date1: string, date2: string) => {
    const date1Date = new Date(date1);
    const date2Date = new Date(date2);
    if (date1Date > date2Date) {
      return 1;
    } else if (date1Date < date2Date) {
      return -1;
    } else {
      return 0;
    }
  },

  equalDate: (date1: Date, date2: Date) => {
    return moment(date1).format(DATE_FORMAT) === moment(date2).format(DATE_FORMAT);
  },

  getAllDaysInWeek: (date: Date) => {
    const days = [];
    const day = moment(date).startOf('week');
    for (let i = 0; i < 7; i++) {
      days.push(day.clone().add(i, 'days').toDate());
    }
    return days;
  },

  getAllDaysInMonth: (date: Date) => {
    const days = [];
    const day = moment(date).startOf('month');
    const endOfMonth = moment(date).endOf('month');
    while (day <= endOfMonth) {
      days.push(day.toDate());
      day.add(1, 'days');
    }
    return days;
  },

  getDayDifferent: (date1: Date, date2: Date) => {
    return moment(date1).diff(date2, 'days');
  },

  getDaysBetween: (date1: Date, date2: Date) => {
    const days = [];
    const day = moment(date1);
    while (day <= moment(date2)) {
      days.push(day.format(DATE_FORMAT));
      day.add(1, 'days');
    }
    return days.sort((a, b) => moment(b).diff(moment(a), 'days'));
  },

  getTimeZone: () => {
    return timezone().utcOffset('-07:00').format(TIMEZONE_FORMAT);
  },

  getDayBefore: (date: Date, days: number) => {
    return moment(date).subtract(days, 'days').toDate();
  },
};

export default dateHelper;
