export type DataType = 'integer' | 'float' | 'string' | 'date' | 'boolean';
export type OperatorType = '>=' | '<=' | '>' | '<' | '!=' | '=' | 'IN' | 'NOT_IN' | 'LIKE' | 'NOT_LIKE' | 'BETWEEN';

export type OrderType = 'ASC' | 'DESC';

type DataTypeToOperators = Record<DataType, OperatorType[]>;

export const dataTypeToOperators: DataTypeToOperators = {
  integer: ['>=', '<=', '>', '<', '!=', '='],
  float: ['>=', '<=', '>', '<', '!=', '='],
  string: ['=', '!=', 'IN', 'NOT_IN', 'LIKE', 'NOT_LIKE'],
  date: ['>=', '<=', '>', '<', '!=', '=', 'BETWEEN'],
  boolean: ['=', '!='],
};

export const dataTypeList: DataType[] = ['integer', 'float', 'string', 'date', 'boolean'];
