import capitalize from 'lodash/capitalize';

const compareSearchKeyword = (searchKeyword: string, searchKeywordToCompare: string) => {
  return searchKeyword.toLowerCase().includes(searchKeywordToCompare.toLowerCase());
};

const checkFalse = (data: any) => {
  if (Array.isArray(data)) {
    return data.length !== 0;
  } else if (typeof data === 'object') {
    return Object.keys(data).length !== 0;
  }
  return !!data;
};

const convertText = (text: string) => {
  return capitalize(text.replace(/_/g, ' '));
};

const convertParagraph = (paragraph: string) => {
  return paragraph.replace(/\n/g, `<br />`);
};

const appHelper = {
  compareSearchKeyword,
  checkFalse,
  convertText,
  convertParagraph,
};

export default appHelper;
