import React from 'react';

import {
  GREEN_COLOR,
  GREY_COLOR_2,
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  TEXT_COLOR,
  TEXT_COLOR_2,
  YELLOW_COLOR,
} from 'config/constants/theme';

import ThemeButton from './ThemeButton';

import { ConfigProvider } from 'antd';

interface ProviderProps {
  children: React.ReactNode;
}

const ThemeProvider = ({ children }: ProviderProps) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: PRIMARY_COLOR,
          colorFillSecondary: SECONDARY_COLOR,
          colorError: PRIMARY_COLOR,
          colorSuccess: GREEN_COLOR,
          colorWarning: YELLOW_COLOR,
          colorTextHeading: TEXT_COLOR,
          colorTextDescription: TEXT_COLOR_2,
          fontFamily: ['Poppins', 'sans-serif'].join(','),
        },
        components: {
          Button: ThemeButton,
          Menu: {
            colorPrimary: SECONDARY_COLOR,
          },
          Pagination: {
            colorPrimary: PRIMARY_COLOR,
            colorBgBase: PRIMARY_COLOR,
            colorBgTextHover: GREY_COLOR_2,
          },
          Tabs: {
            colorBorderBg: 'transparent',
            colorBorder: 'transparent',
          },
          Skeleton: {
            colorFill: 'rgba(0, 0, 0, 0.15)',
            colorFillContent: 'rgba(0, 0, 0, 0.06)',
            colorPrimary: 'rgba(0, 0, 0, 0.06)',
          },
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
};

export default ThemeProvider;
