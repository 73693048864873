import { MessageArgsProps, message } from 'antd';

export const TOAST_CONFIG: MessageArgsProps = {
  content: undefined,
  duration: 3,
};

const dismiss = () => {
  message.destroy();
};

const success = (msg: string, config = TOAST_CONFIG) => {
  dismiss();
  message.success({
    ...config,
    content: msg,
  });
};
const error = (msg: string, config = TOAST_CONFIG) => {
  dismiss();
  message.error({
    ...config,
    content: msg,
  });
};
const warning = (msg: string, config = TOAST_CONFIG) => {
  dismiss();
  message.warning({
    ...config,
    content: msg,
  });
};
const loading = (msg: string, config = TOAST_CONFIG) => {
  dismiss();
  message.loading({
    ...config,
    content: msg,
  });
};
const info = (msg: string, config = TOAST_CONFIG) => {
  dismiss();
  message.info({
    ...config,
    content: msg,
  });
};

const toastHelper = {
  success,
  error,
  warning,
  loading,
  info,
  dismiss,
};

export default toastHelper;
