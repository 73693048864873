import React, { useState } from 'react';

import { IQuery, IStep } from 'pages/funnels/FunnelsCreatePage';

import { MinusCircleOutlined } from '@ant-design/icons';
import { Button, Input, Select } from 'antd';
import { uniqueId } from 'lodash';

interface IFunnelStepProps extends IStep {
  tools: {
    addStep: () => void;
    delStep: (id: string) => void;
    copyStep: (id: string) => void;
  };
}

const queryInit = (): IQuery => {
  return {
    id: uniqueId(),
    method: 'include',
    params: '',
    op: 'equal',
    value: '',
  };
};

const queryMethod = [
  {
    label: 'Include',
    value: 'include',
  },
  {
    label: 'Exclude',
    value: 'exclude',
  },
];

const queryOps = [
  {
    label: 'Equal to (=)',
    value: 'equal',
  },
  {
    label: 'Contains',
    value: 'contains',
  },
  {
    label: 'Starts with',
    value: 'starts_with',
  },
  {
    label: 'RegExp Match',
    value: 'regexp_match',
  },
  {
    label: 'RegExp Contains',
    value: 'regexp_contains',
  },
  {
    label: 'In',
    value: 'in',
  },
  {
    label: 'Is Null',
    value: 'null',
  },
];

const FunnelStep = ({ name, id, query, order, tools }: IFunnelStepProps) => {
  const [listQuery, setListQuery] = useState<IQuery[]>([queryInit()]);

  const addQuery = (logic: 'AND' | 'OR') => {
    setListQuery([
      ...listQuery,
      {
        ...queryInit(),
        logic,
      },
    ]);
  };

  const deleteQuery = (id: string) => {
    const newListQuery = listQuery.filter((query) => query.id !== id);

    setListQuery(newListQuery);
  };

  return (
    <div className="m-2">
      <div className="mb-2 flex flex-col gap-2">
        {listQuery.map((query, index) => (
          <div className="flex flex-wrap items-center gap-3 md:flex-nowrap" key={query.id}>
            {query.logic && <span>{query.logic}</span>}
            <Select options={queryMethod} className="w-[150px]" />
            <Input type="text" placeholder="Params..." className="w-[200px]" />

            <Select options={queryOps} className="w-[150px]" />

            <Input type="text" placeholder="Value..." className="w-[200px]" />
            {index > 0 && (
              <div
                className="cursor-pointer hover:text-primary"
                onClick={() => {
                  deleteQuery(query.id);
                }}
              >
                <MinusCircleOutlined size={20} />
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="flex gap-3">
        <Button
          size="small"
          style={{ fontSize: 14 }}
          onClick={() => {
            addQuery('AND');
          }}
        >
          AND
        </Button>

        <Button
          size="small"
          style={{ fontSize: 14 }}
          onClick={() => {
            addQuery('OR');
          }}
        >
          OR
        </Button>
      </div>
    </div>
  );
};

export default FunnelStep;
