import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import RawDataTable from 'components/feature/charts/RawDataTable';
import Loading from 'components/shared/Loading';

import daymareZeroApi from 'config/api/bigquery/daymareZeroApi';
import { IVersionHistory } from 'config/api/version-history/versionHistoryApi';
import LayoutContextConsumer from '../../../../contexts/LayoutContext';
import numberHelper from '../../../../config/helpers/numberHelper';
import { itemToCell_rawStr } from '../../../layout/protected/Custom';

interface Props {
  rangeDate: {
    start?: string;
    end?: string;
  };
  versionHistoryData?: IVersionHistory[];
  version: string | null;
  country: string;
  reloadTrigger: boolean;
  isFirstLoad: boolean;
}

interface IGuiClick {
  period: string;
  id: string;
  times: number;
  users: number;
  daily_users: number;
}

const { getGuiClick } = daymareZeroApi;
const { convertText } = numberHelper;

const DaymareGuiClick = ({ rangeDate, versionHistoryData, country, reloadTrigger, isFirstLoad }: Props) => {
  const { platform } = LayoutContextConsumer();

  const { data: rawData, isLoading: rawDataLoading } = useQuery(
    ['gui-click', reloadTrigger],
    async () => {
      if (isFirstLoad === true) {
        return [];
      }
      const res = await getGuiClick({
        params: {
          start: rangeDate?.start,
          end: rangeDate?.end,
          platform: platform === 'unified' ? undefined : platform,
          country: country !== 'All' ? country : undefined,
        },
      });
      return res as unknown as IGuiClick[];
    }
  );

  const guiFormOfRawData = useMemo(() => {
    return rawData?.map((item) => {
      return [
        { value: item.period },
        { value: item.id },
        { value: item.times.toString() },
        { value: `${convertText(item.users)} (${((item.users / item.daily_users) * 100).toFixed(1)}%)` },
      ];
    });
  }, [rawData]);

  const isLoading = rawDataLoading;
  
  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex gap-0 flex-wrap">
          {
            guiFormOfRawData && guiFormOfRawData.length > 0 && (
              <RawDataTable
                isCenterFull
                title="Top PU"
                headers={[
                  'Time',
                  'Gui ID',
                  'Times',
                  'Users',
                ]}
                data={guiFormOfRawData.map((item) => {
                  return item.map((subItem) => {
                    return itemToCell_rawStr({ value: subItem.value });
                  });
                })}
              />
            )
          }
        </div>
      )}
    </div>
  );
};

export default DaymareGuiClick;
