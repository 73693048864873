import React from 'react';

import { IVersionHistory } from 'config/api/version-history/versionHistoryApi';
import { CALENDAR_TEXT_ICON } from 'config/constants/general';
import dateHelper from 'config/helpers/dateHelper';
import numberHelper from 'config/helpers/numberHelper';

import { Tooltip } from 'antd';
import clsx from 'clsx';
import groupBy from 'lodash/groupBy';
import sum from 'lodash/sum';
import uniqueId from 'lodash/uniqueId';
import dayjs from 'dayjs';

const { toPercent } = numberHelper;

export interface ICohortData {
  [date: string]: number[];
  Total: number[];
}

interface ICohortProps {
  data: ICohortData;
  versionData?: IVersionHistory[];
  labels: string[];
}

const CohortChart = ({ data, labels, versionData }: ICohortProps) => {
  const renderHead = () => {
    return (
      <tr key={uniqueId()}>
        <th className={'table-head sticky left-0 top-0 z-10 h-full text-transparent'}>Blank</th>
        {labels.map((label) => (
          <th key={uniqueId()} className={'table-head'}>
            {label}
          </th>
        ))}
      </tr>
    );
  };

  const renderTotal = (totalList: number[]) => {
    return (
      <tr key={uniqueId()}>
        <th scope="row" className="table-row-title sticky left-0 top-[60px] lg:top-[76px]">
          Total
        </th>
        <td className="table-row-total">{totalList[0]}</td>
        {totalList.slice(1).map((value, index) => {
          /* Group lại data mà có retention theo index khác null 
            vd ngày 30-03 ko có retention 7 thì sẽ ko group
            còn ngày 21-03 có retention 7 thì sẽ group
          */
          const grouped = groupBy(data, (item) => item[index + 1] !== null);

          /**
           * Tính tổng số user của các ngày có retention theo index
           * Và tỉ lệ sẽ được chia theo tổng số user của retention theo index / tổng các ngày có retention theo index
           */
          const total = sum(grouped['true'].slice(1).map((item) => item[0]));
          return (
            <td
              key={index}
              style={{
                backgroundColor: `rgba(93,145,255, ${value / total})`,
              }}
              className="table-border"
            >
              <div className="table-cohort-cell">
                <p>{toPercent(value, total)}</p>
                <p>{value > 0 ? value + ' users' : '--users'}</p>
              </div>
            </td>
          );
        })}
      </tr>
    );
  };

  const renderRow = () => {
    return Object
      .keys(data)
      .sort((a: string, b: string) => a === 'Total' ? -Infinity : dayjs(b).unix() - dayjs(a).unix())
      .map((key: string, index) => {
        /* Vì các ngày của total có cách tính khác nên sẽ có hàm render riêng */
        if (key === 'Total') return renderTotal(data[key]);

        const verData = versionData?.find((item) => item.date === key);

        return (
          <tr key={index + uniqueId()}>
            <Tooltip
              title={verData ? `${verData.version} - ${verData.title} - ${verData.platform.toUpperCase()}` : ''}
              arrow={false}
            >
              <th
                scope="row"
                className={clsx(
                  'table-row-title sticky left-0 top-[60px] whitespace-nowrap lg:top-[76px]',
                  verData && 'text-primary font-medium',
                )}
              >
                {verData ? CALENDAR_TEXT_ICON : ''} {dateHelper.formatText(new Date(key))}
              </th>
            </Tooltip>
            <td className="table-row-total">{data[key][0]}</td>
            {data[key].slice(1).map((value, index) => {
              return (
                <td
                  key={index}
                  style={{
                    backgroundColor: `rgba(93,145,255, ${value / data[key][0]})`,
                  }}
                  className="table-border"
                >
                  {value === null || value === undefined ? (
                    ''
                  ) : (
                    <div className="table-cohort-cell">
                      <p>{toPercent(value, data[key][0])}</p>
                      <p>{value > 0 ? value + ' users' : '--users'}</p>
                    </div>
                  )}
                </td>
              );
            })}
          </tr>
        );
      });
  };

  return (
    <table className="w-fit max-w-full">
      <thead className="sticky top-0">{renderHead()}</thead>
      <tbody className="">{renderRow()}</tbody>
    </table>
  );
};

export default CohortChart;
