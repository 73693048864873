import React, { useState } from 'react';

import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import clsx from 'clsx';
import { format } from 'date-fns';

interface IMonthPicker {
  handleChangeMonth: (date: Date) => void;
}

const MonthPicker = ({ handleChangeMonth }: IMonthPicker) => {
  const [selectedMonth, setSelectedMonth] = useState(new Date());

  const handlePrevClick = () => {
    setSelectedMonth(new Date(selectedMonth.getFullYear(), selectedMonth.getMonth() - 1, 1));
    handleChangeMonth(new Date(selectedMonth.getFullYear(), selectedMonth.getMonth() - 1, 1));
  };

  const handleNextClick = () => {
    setSelectedMonth(new Date(selectedMonth.getFullYear(), selectedMonth.getMonth() + 1, 1));
    handleChangeMonth(new Date(selectedMonth.getFullYear(), selectedMonth.getMonth() + 1, 1));
  };

  const handleMonthClick = (month: any) => {
    setSelectedMonth(new Date(month.getFullYear(), month.getMonth(), 1));
    handleChangeMonth(new Date(month.getFullYear(), month.getMonth(), 1));
  };

  return (
    <div className="mx-auto max-w-[240px] py-3 px-3 text-center">
      <div className="flex items-center justify-between">
        <button
          onClick={handlePrevClick}
          className="cursor-pointer rounded border-none bg-transparent p-1 text-14 outline-none hover:bg-primary hover:text-white"
        >
          <LeftOutlined />
        </button>
        <h6 className="font-sans text-16">{format(selectedMonth, 'MMMM yyyy')}</h6>
        <button
          onClick={handleNextClick}
          className="cursor-pointer rounded border-none bg-transparent p-1 text-14 outline-none hover:bg-primary hover:text-white"
        >
          <RightOutlined />
        </button>
      </div>
      <Row gutter={[42, 30]} className="mt-2">
        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((monthIndex) => (
          <Col key={monthIndex} span={8}>
            <div
              className={clsx(
                'w-full cursor-pointer rounded-lg font-sans text-14 hover:bg-primary hover:text-white',
                format(selectedMonth, 'yyyy-MM') ===
                  format(new Date(selectedMonth.getFullYear(), monthIndex, 1), 'yyyy-MM') && 'bg-primary text-white',

                format(new Date(selectedMonth.getFullYear(), monthIndex, 1), 'yyyy-MM') >
                  format(new Date(), 'yyyy-MM') && 'cursor-not-allowed opacity-40',
              )}
              onClick={() => {
                if (
                  format(new Date(selectedMonth.getFullYear(), monthIndex, 1), 'yyyy-MM') >
                  format(new Date(), 'yyyy-MM')
                ) {
                  return;
                }
                handleMonthClick(new Date(selectedMonth.getFullYear(), monthIndex, 1));
              }}
            >
              {format(new Date(selectedMonth.getFullYear(), monthIndex, 1), 'MMM')}
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default MonthPicker;
