import React from 'react';

import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';

interface IAvatarProps {
  size?: number;
  imgUrl?: string;
}

const CustomAvatar = ({ size = 60, imgUrl }: IAvatarProps) => {
  return (
    <div className={`h-fit w-fit cursor-pointer leading-none`}>
      {imgUrl ? (
        <img className={`rounded-full`} width={size} height={size} src={imgUrl} alt="avatar" />
      ) : (
        <Avatar size={size} icon={<UserOutlined />} />
      )}
    </div>
  );
};

export default CustomAvatar;
