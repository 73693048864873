import toastHelper from 'config/helpers/toastHelper';

import api from '..';

import { AxiosRequestConfig } from 'axios';

const daymareZeroApi = {
  getIAPPackBuying: (config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game && game === 'DaymareZero') {
      return api.get(`/daymare-zero/iap-pack-buying-details`, config);
    } else {
      toastHelper.error('Please select a game first');
    }
  },
  downloadIAPPackBuying: (config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game && game === 'DaymareZero') {
      return api.get(`/daymare-zero/iap-pack-buying-details-download`, config);
    } else {
      toastHelper.error('Please select a game first');
    }
  },
  getSuccessIAP: (config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game && game === 'DaymareZero') {
      return api.post(`/daymare-zero/iap-success-rawdata`, config?.data, config);
    } else {
      toastHelper.error('Please select a game first');
    }
  },
  countSuccessIAP: (config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game && game === 'DaymareZero') {
      return api.post(`/daymare-zero/iap-success-rawdata-count`, config?.data, config);
    } else {
      toastHelper.error('Please select a game first');
    }
  },
  getAdsDetails: (config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game && game === 'DaymareZero') {
      return api.get(`/daymare-zero/ads-details`, config);
    } else {
      toastHelper.error('Please select a game first');
    }
  },
  getResource: (config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game && game === 'DaymareZero') {
      return api.get(`/daymare-zero/resources-details`, config);
    } else {
      toastHelper.error('Please select a game first');
    }
  },
  getBoss: (config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game && game === 'DaymareZero') {
      return api.get(`/daymare-zero/boss`, config);
    } else {
      toastHelper.error('Please select a game first');
    }
  },
  getBossCU: (config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game && game === 'DaymareZero') {
      return api.get(`/daymare-zero/boss-cu`, config);
    } else {
      toastHelper.error('Please select a game first');
    }
  },
  getUserProfile: (config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game && game === 'DaymareZero') {
      return api.post(`/daymare-zero/user-profile`, config?.data, config);
    } else {
      toastHelper.error('Please select a game first');
    }
  },
  countUserProfile: (config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game && game === 'DaymareZero') {
      return api.post(`/daymare-zero/user-profile-count`, config?.data, config);
    } else {
      toastHelper.error('Please select a game first');
    }
  },
  getUserEquipment: (config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game && game === 'DaymareZero') {
      return api.post(`/daymare-zero/user-equipment`, config?.data, config);
    } else {
      toastHelper.error('Please select a game first');
    }
  },
  countUserEquipment: (config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game && game === 'DaymareZero') {
      return api.post(`/daymare-zero/user-equipment-count`, config?.data, config);
    } else {
      toastHelper.error('Please select a game first');
    }
  },
  getStage_GroupByPeriod: (config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game && game === 'DaymareZero') {
      return api.get(`/daymare-zero/stage-group-by-period`, config);
    } else {
      toastHelper.error('Please select a game first');
    }
  },
  getStage_GroupByStage: (config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game && game === 'DaymareZero') {
      return api.get(`/daymare-zero/stage-group-by-stage`, config);
    } else {
      toastHelper.error('Please select a game first');
    }
  },
  getLastAction : (config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game && game === 'DaymareZero') {
      return api.get(`/daymare-zero/last-action`, config);
    } else {
      toastHelper.error('Please select a game first');
    }
  },
  getGacha: (config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game && game === 'DaymareZero') {
      return api.get(`/daymare-zero/gacha`, config);
    } else {
      toastHelper.error('Please select a game first');
    }
  },
  getOpenChest: (config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game && game === 'DaymareZero') {
      return api.get(`/daymare-zero/open-chest`, config);
    } else {
      toastHelper.error('Please select a game first');
    }
  },
  get7DayQuest: (config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game && game === 'DaymareZero') {
      return api.get(`/daymare-zero/get-7day-quest`, config);
    } else {
      toastHelper.error('Please select a game first');
    }
  },
  get7DayShop: (config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game && game === 'DaymareZero') {
      return api.get(`/daymare-zero/get-7day-shop`, config);
    } else {
      toastHelper.error('Please select a game first');
    }
  },
  battlePass: (config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game && game === 'DaymareZero') {
      return api.get(`/daymare-zero/battle-pass`, config);
    } else {
      toastHelper.error('Please select a game first');
    }
  },
  ltv: (config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game && game === 'DaymareZero') {
      return api.post(`/daymare-zero/ltv`, config?.data, config);
    } else {
      toastHelper.error('Please select a game first');
    }
  },
  ltvMonth: (config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game && game === 'DaymareZero') {
      return api.post(`/daymare-zero/ltv-month`, config?.data, config);
    } else {
      toastHelper.error('Please select a game first');
    }
  },
  getPvp: (config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game && game === 'DaymareZero') {
      return api.get(`/daymare-zero/pvp`, config);
    } else {
      toastHelper.error('Please select a game first');
    }
  },
  getTopPU: (config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game && game === 'DaymareZero') {
      return api.get(`/daymare-zero/top-pu`, config);
    } else {
      toastHelper.error('Please select a game first');
    }
  },
  getGuiClick: (config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game && game === 'DaymareZero') {
      return api.get(`/daymare-zero/gui-click`, config);
    } else {
      toastHelper.error('Please select a game first');
    }
  },
  getMonthlyReport: (config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game && game === 'DaymareZero') {
      return api.get(`/daymare-zero/monthly-report`, config);
    } else {
      toastHelper.error('Please select a game first');
    }
  },
  addCampaignReport: (config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game && game === 'DaymareZero') {
      return api.post(`/daymare-zero/add-campaign-report`, config?.data, config);
    } else {
      toastHelper.error('Please select a game first');
    }
  },
};

export default daymareZeroApi;
