import React, { useState } from 'react';

import AuthConsumer from 'hooks/useAuth';

import { IFilter } from 'pages/monetization/MonetizationPage';

import CountrySelect from 'components/shared/CountrySelect';
import CusSelect from 'components/shared/CusSelect';
import FullDatePicker from 'components/shared/FullDatePicker';
import TagSelect from 'components/shared/TagSelect';

import userRole from 'config/constants/userRole';
import monetizationHelper from 'config/helpers/monetizationHelper';

interface IMonetizationFilterProps {
  options: { label: string; value: string }[];
  filter: IFilter;
  setFilter: (filter: IFilter) => void;
}

const { sortByFilterList } = monetizationHelper;

const MonetizationFilter = ({ filter, setFilter, options }: IMonetizationFilterProps) => {
  const { role } = AuthConsumer();

  const [selected, setSelected] = useState<string[]>(filter.dataType);
  const [rangeDate, setRangeDate] = useState<{ start?: string; end?: string }>(filter.rangeDate);
  const [selectedCountry, setSelectedCountry] = useState<string>(filter.country);

  const handleFilter = () => {
    setFilter({
      ...filter,
      dataType: selected,
      rangeDate: rangeDate,
      country: selectedCountry,
    });
  };

  return (
    <div className="flex flex-wrap items-end gap-6">
      <FullDatePicker rangeDate={rangeDate} setRangeDate={setRangeDate} />

      <div className="max-w-lg flex-1">
        <h6 className="mb-2 text-16 font-[500]">Period</h6>
        <CusSelect
          value={selected}
          options={options}
          style={{
            minWidth: '200px',
            width: '100%',
          }}
          onChange={(selected) => {
            setSelected(sortByFilterList(selected));
          }}
          mode="multiple"
          tagRender={TagSelect}
          maxTagCount={'responsive'}
          disabled={role === userRole.PARTNER}
        ></CusSelect>
      </div>
      <CountrySelect selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry} />

      <button
        className="cursor-pointer rounded-md border-none bg-primary px-7 py-1 font-sans leading-6 text-white outline-none hover:shadow-lg"
        onClick={handleFilter}
      >
        Apply
      </button>
    </div>
  );
};

export default MonetizationFilter;
