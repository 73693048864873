import React from 'react';
import { Area, AreaConfig } from '@ant-design/plots';

const AreaChart = ({ data, xField, yField }: { data: any, xField: string, yField: string }) => {
  const config: AreaConfig = {
    data,
    xField,
    yField,
    areaStyle: () => {
      return {
        fill: 'l(270) 0:#ffffff 0.5:#7ec2f3 1:#1890ff',
      };
    },
  };

  return <Area {...config} />;
};

export default React.memo(AreaChart);
