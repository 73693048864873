import { useQuery } from '@tanstack/react-query';
import React from 'react';

import useDocumentTitle from 'hooks/useDocumentTitle';

import withPermission from 'hocs/withPermission';

import userApi, { IUser } from 'config/api/user/userApi';
import toastHelper from 'config/helpers/toastHelper';

import UserSettingTable from 'components/feature/user-setting/UserSettingTable';
import Skeleton from 'components/shared/Skeleton';

const { getAllUser } = userApi;

const UserSettingPage = () => {
  useDocumentTitle('Users and Permissions');

  const {
    data: userList,
    isLoading,
    refetch,
  } = useQuery(
    ['users'],
    async () => {
      const response = await getAllUser().catch((err) => {
        toastHelper.error(err.message);
      });
      return response as unknown as IUser[];
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  return (
    <div className="page-container">
      <section>
        <h3 className="page-section-title">Project settings</h3>
        <p className="page-section-subtitle">User and permission</p>

        {isLoading && <Skeleton height={300} />}
        {userList && <UserSettingTable refetch={refetch} users={userList} />}
      </section>
    </div>
  );
};

export default withPermission(UserSettingPage);
