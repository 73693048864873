import { useQuery } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import dayjs from 'dayjs';

import RawDataTable from 'components/feature/charts/RawDataTable';
import Loading from 'components/shared/Loading';
import daymareZeroApi from 'config/api/bigquery/daymareZeroApi';
import { IReportFilter } from '../../../config/types/reports';
import { itemToCell_rawStr } from '../../layout/protected/Custom';

interface Props {
  filter: IReportFilter;
}

const { getMonthlyReport } = daymareZeroApi;

const MonthlyReport = ({ filter }: Props) => {
  const [periods, setPeriods] = useState<string[]>([]);

  const { data: rawData, isLoading: rawDataLoading } = useQuery(
    ['monthly-report', filter.rangeMonth?.start, filter.rangeMonth?.end, filter.country],
    async () => {
        if (!filter || filter.isFirstLoad === true || !filter.rangeMonth?.start || !filter.rangeMonth?.end) {
        return [];
      }
      let newPeriods: string[] = [];
      let startDate = dayjs(filter.rangeMonth.start + '-01');
      let endDate = dayjs(filter.rangeMonth.end + '-01');
      while (startDate <= endDate) {
        newPeriods.push(startDate.format('YYYY-MM'));
        startDate = startDate.add(1, 'month');
      }
      setPeriods(newPeriods);

      const res = await getMonthlyReport({
        params: {
          start: filter.rangeMonth?.start,
          end: filter.rangeMonth?.end,
          country: filter.country !== 'All' ? filter.country : undefined,
        },
      });
      return res as unknown as Record<string, string>[];
    }
  );

  const guiFormOfRawData = useMemo(() => {
    return rawData?.map((item) => {
      return [
        { value: item['name'] },
        ...periods.map(p => ({ value: item[p].toString() }))
      ];
    });
  }, [rawData, periods]);

  const isLoading = rawDataLoading;
  
  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex gap-0 flex-wrap">
          {
            guiFormOfRawData && guiFormOfRawData.length > 0 && (
              <RawDataTable
                isCenterFull
                title="Monthly Report"
                headers={['Feature', ...periods]}
                data={guiFormOfRawData.map((item) =>
                  item.map((subItem) => itemToCell_rawStr({ value: subItem.value }))
                )}
                rawData={rawData}
              />
            )
          }
        </div>
      )}
    </div>
  );
};

export default MonthlyReport;
