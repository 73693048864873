import { useQuery } from '@tanstack/react-query';
import { BiTime, BiUser } from 'react-icons/bi';

import LayoutContextConsumer from 'contexts/LayoutContext';

import RawDataTable, { DateVersion } from 'components/feature/charts/RawDataTable';
import Loading from 'components/shared/Loading';

import mimilandApi from 'config/api/bigquery/mimilandApi';
import { IVersionHistory } from 'config/api/version-history/versionHistoryApi';
import numberHelper from 'config/helpers/numberHelper';

import { Empty, Tooltip } from 'antd';

interface Props {
  rangeDate: {
    start?: string;
    end?: string;
  };
  versionHistoryData?: IVersionHistory[];
  version: string | null;
}

const { getMimilandQuest } = mimilandApi;
const { toPercent } = numberHelper;

interface IQuest {
  time: string;
  platform: string;
  DAU: number;
  impr_menu_times: number;
  impr_shortcut_times: number;
  go_to_times: number;
  done_times: number;
  claim_reward_times: number;
  impr_menu_users: number;
  impr_shortcut_users: number;
  go_to_users: number;
  done_users: number;
  claim_reward_users: number;
}

const DataItem = ({ users, value, title, dau }: { users: number; value: string; title?: string; dau: number }) => {
  const rate = toPercent(users, dau);
  return (
    <Tooltip
      title={
        <span>
          <p>
            - {title}: {value} times
          </p>
          <p>
            - Users / DAU: {users}/{dau} ({rate})
          </p>
        </span>
      }
    >
      <div className="flex items-center gap-1">
        <BiTime className="text-12" />
        <p className="text-14">{value}</p>
      </div>
      <div className="flex items-center gap-1 whitespace-nowrap">
        <BiUser className="text-12" />
        <p className="text-14">
          {users} ({rate})
        </p>
      </div>
    </Tooltip>
  );
};

const Quest = ({ rangeDate, versionHistoryData }: Props) => {
  const { platform } = LayoutContextConsumer();

  const { data: questData, isLoading: questLoading } = useQuery(['mml-quest', rangeDate, platform], async () => {
    if (!rangeDate.start || !rangeDate.end) return [];

    const res = await getMimilandQuest({
      params: {
        platform: platform === 'unified' ? undefined : platform,
        start: rangeDate.start,
        end: rangeDate.end,
      },
    });

    return res as unknown as IQuest[];
  });
  const isLoading = questLoading;

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex flex-col gap-10">
          {questData && questData.length > 0 ? (
            <RawDataTable
              isCenterFull
              title="Quests"
              headers={[
                'Date',
                'Platform',
                'DAU',
                'Impression \n (Menu)',
                'Impression \n (Shortcut)',
                'Go To',
                'Done',
                'Claim Reward',
              ]}
              data={
                questData.map((item) => {
                  const verData = versionHistoryData?.find((ver) => ver.date === item.time);
                  return [
                    <DateVersion verData={verData} date={item.time} />,
                    item.platform,
                    item.DAU.toFixed(0),
                    <DataItem
                      users={item.impr_menu_users}
                      value={item.impr_menu_times + ''}
                      title="Impression (Menu)"
                      dau={item.DAU}
                    />,
                    <DataItem
                      users={item.impr_shortcut_users}
                      value={item.impr_shortcut_times + ''}
                      title="Impression (Shortcut)"
                      dau={item.DAU}
                    />,
                    <DataItem users={item.go_to_users} value={item.go_to_times + ''} title="Go To" dau={item.DAU} />,

                    <DataItem users={item.done_users} value={item.done_times + ''} title="Done" dau={item.DAU} />,

                    <DataItem
                      users={item.claim_reward_users}
                      value={item.claim_reward_times + ''}
                      title="Claim Reward"
                      dau={item.DAU}
                    />,
                  ];
                }) ?? []
              }
              rawData={questData}
            />
          ) : (
            <Empty />
          )}
        </div>
      )}
    </div>
  );
};

export default Quest;
