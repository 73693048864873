interface IPager {
  page: number;
  totalPage: number;
  setPage: (newValue: number) => void;
}

const Pager = ({ page, totalPage, setPage }: IPager) => {
  const goToNextPage = () => {
    if (page < totalPage) {
      setPage(page + 1);
    }
  };
  const goToPreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  return (
    <div className="my-4 flex flex-wrap gap-6">
      <button className="import-button" onClick={() => setPage(1)}>&lt;&lt;</button>
      <button className="import-button" onClick={goToPreviousPage}>&lt;</button>
      <label style={{ alignContent: "center" }}>{`${page}/${totalPage}`}</label>
      <button className="import-button" onClick={goToNextPage}>&gt;</button>
      <button className="import-button" onClick={() => setPage(totalPage)}>&gt;&gt;</button>
    </div>
  )
};
export default Pager;