import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { BiCoinStack, BiUser } from 'react-icons/bi';

import LayoutContextConsumer from 'contexts/LayoutContext';
import RawDataTable from 'components/feature/charts/RawDataTable';
import Loading from 'components/shared/Loading';

import daymareZeroApi from 'config/api/bigquery/daymareZeroApi';
import { IVersionHistory } from 'config/api/version-history/versionHistoryApi';
import numberHelper from 'config/helpers/numberHelper';

interface Props {
  rangeDate: {
    start?: string;
    end?: string;
  };
  versionHistoryData?: IVersionHistory[];
  version: string | null;
  country: string;
  reloadTrigger: boolean;
  isFirstLoad: boolean;
}

interface IBoss {
  period: string;
  platform: string;
  country: string;
  daily_users: number;
  click_menu_boss_times: number;
  click_menu_boss_users: number;
  click_party_up_times: number;
  click_party_up_users: number;
  enter_waiting_room_times: number;
  enter_waiting_room_users: number;
  pick_skill_times: number;
  pick_skill_users: number;
  start_times: number;
  start_users: number;
  win_times: number;
  win_users: number;
  dead_times: number;
  dead_users: number;
  timeout_times: number;
  timeout_users: number;
  win_duration: number;
  duration: number;
  take_rewards_times: number;
  take_rewards_users: number;
}

interface IBossCU {
  period: string;
  platform: string;
  max_CU_time: string;
  max_CU_users: number;
  min_CU_time: string;
  min_CU_users: number;
}

const { getBoss, getBossCU } = daymareZeroApi;

const { convertText } = numberHelper;

const ResourceItem = ({ users, value }: { users: string; value: string }) => {
  return (
    <div className="">
      <div className="flex items-center gap-1">
        <BiCoinStack className="text-12" />
        <p className="text-14">{value}</p>
      </div>
      <div className="flex items-center gap-1 whitespace-nowrap">
        <BiUser className="text-12" />
        <p className="text-14">{users}</p>
      </div>
    </div>
  );
};



const DaymareBoss = ({ rangeDate, versionHistoryData, version, country, reloadTrigger, isFirstLoad }: Props) => {
  const { platform } = LayoutContextConsumer();

  const { data: bossData, isLoading: bossLoading } = useQuery(
    ['boss', reloadTrigger],
    async () => {
      if (isFirstLoad === true || !rangeDate.start || !rangeDate.end) {
        return [];
      }
      const res = await getBoss({
        params: {
          version,
          platform: platform === 'unified' ? undefined : platform,
          start: rangeDate.start,
          end: rangeDate.end,
          country: country !== 'All' ? country : undefined,
        },
      });

      return res as unknown as IBoss[];
    },
  );

  const { data: bossCUData, isLoading: bossCULoading } = useQuery(
    ['boss-cu', reloadTrigger],
    async () => {
      if (isFirstLoad === true || !rangeDate.start || !rangeDate.end) {
        return [];
      }
      const res = await getBossCU({
        params: {
          version,
          platform: platform === 'unified' ? undefined : platform,
          start: rangeDate.start,
          end: rangeDate.end,
          country: country !== 'All' ? country : undefined,
        },
      });

      return res as unknown as IBossCU[];
    },
  );

  const bossTableData = useMemo(() => {
    return bossData?.map((item) => {
      return [
        {
          value: item.period,
          users: null,
          usersRaw: null,
        },
        {
          value: item.click_menu_boss_times,
          users: `${convertText(item.click_menu_boss_users)} (${((item.click_menu_boss_users / item.daily_users) * 100).toFixed(1)}%)`,
          usersRaw: item.click_menu_boss_users,
        },
        {
          value: item.click_party_up_times,
          users: `${convertText(item.click_party_up_users)} (${((item.click_party_up_users / item.daily_users) * 100).toFixed(1)}%)`,
          usersRaw: item.click_party_up_users,
        },
        {
          value: item.enter_waiting_room_times,
          users: `${convertText(item.enter_waiting_room_users)} (${((item.enter_waiting_room_users / item.daily_users) * 100).toFixed(1)}%)`,
          usersRaw: item.enter_waiting_room_users,
        },
        {
          value: item.pick_skill_times,
          users: `${convertText(item.pick_skill_users)} (${((item.pick_skill_users / item.daily_users) * 100).toFixed(1)}%)`,
          usersRaw: item.pick_skill_users,
        },
        {
          value: item.start_times,
          users: `${convertText(item.start_users)} (${((item.start_users / item.daily_users) * 100).toFixed(1)}%)`,
          usersRaw: item.start_users,
        },
        {
          value: item.win_times,
          users: `${convertText(item.win_users)} (${((item.win_users / item.daily_users) * 100).toFixed(1)}%)`,
          usersRaw: item.win_users,
        },
        {
          value: item.dead_times,
          users: `${convertText(item.dead_users)} (${((item.dead_users / item.daily_users) * 100).toFixed(1)}%)`,
          usersRaw: item.dead_users,
        },
        {
          value: item.timeout_times,
          users: `${convertText(item.timeout_users)} (${((item.timeout_users / item.daily_users) * 100).toFixed(1)}%)`,
          usersRaw: item.timeout_users,
        },
        {
          value: item.win_times + item.dead_times + item.timeout_times > 0
            ? `${(item.duration / (item.win_times + item.dead_times + item.timeout_times)).toFixed(0)}s` : `-`,
          users: null,
          usersRaw: null,
        },
        {
          value: item.take_rewards_times,
          users: `${convertText(item.take_rewards_users)} (${((item.take_rewards_users / item.daily_users) * 100).toFixed(1)}%)`,
          usersRaw: item.take_rewards_users,
        },
      ];
    });
  }, [bossData]);

  const bossCUTableData = useMemo(() => {
    return bossCUData?.map((item) => {
      return [
        {
          value: item.period,
          users: null,
        },
        {
          value: item.max_CU_time,
          users: null,
        },
        {
          value: item.max_CU_users,
          users: null,
        },
        {
          value: item.min_CU_time,
          users: null,
        },
        {
          value: item.min_CU_users,
          users: null,
        },
      ];
    });
  }, [bossCUData]);

  const isLoading = bossLoading || bossCULoading;

  return (
    <div className="w-full">
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex gap-8 flex-wrap">
            {bossTableData && bossTableData.length > 0 && (
            <RawDataTable
              isCenterFull
              title="Steps"
              headers={[
                'Time',
                'Click Menu Boss',
                'Click Party Up',
                'Enter Waiting Room',
                'Pick Skill',
                'Start Battle',
                'Win',
                'Dead',
                'Timeout',
                'Duration',
                'Take Rewards'
                ]}
              data={bossTableData.map((item) => {
                return item.map((subItem) => {
                  if (subItem.users) {
                    return <ResourceItem users={subItem.users} value={subItem.value as unknown as string} />;
                  } else {
                    return subItem.value;
                  }
                });
              })}
              rawData={bossData}
            />
          )}

            {bossCUTableData && bossCUTableData.length > 0 && (
            <RawDataTable
              title="Concurrent Users (CU)"
              isCenterFull
                headers={[
                  'Time',
                  'Max CU Time',
                  'Max CU Users',
                  'Min CU Time',
                  'Min CU Users'
                ]}
                data={bossCUTableData.map((item) => {
                return item.map((subItem) => {
                  return subItem.value;
                });
              })}
              rawData={bossCUData}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default DaymareBoss;
