import React from 'react';

import comingSoonImg from 'content/assets/images/coming-soon.png';

import useDocumentTitle from 'hooks/useDocumentTitle';

const ComingSoonPage = () => {
  useDocumentTitle('Coming soon');

  return (
    <div className="flex h-full w-full flex-col items-center justify-center">
      <img src={comingSoonImg} alt="coming-soon" className="h-fit w-[300px] max-w-[75%]" />
    </div>
  );
};

export default ComingSoonPage;
