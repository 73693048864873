import { useMutation, useQuery } from '@tanstack/react-query';
import { Fragment, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import LayoutContextConsumer from 'contexts/LayoutContext';

import useDocumentTitle from 'hooks/useDocumentTitle';

import CustomQueryChart from 'components/feature/charts/CustomQueryChart';
import BackBtn from 'components/shared/BackBtn';
import Loading from 'components/shared/Loading';
import TagSelect from 'components/shared/TagSelect';

import customQueryApi, {
  CustomQuery,
  CustomQueryGameSchema,
  CustomQueryResponse,
} from 'config/api/bigquery/custom-query';
import { SECONDARY_COLOR } from 'config/constants/theme';
import appHelper from 'config/helpers/appHelper';
import toastHelper from 'config/helpers/toastHelper';
import { DataType, OperatorType, dataTypeToOperators } from 'config/types/general';

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, ConfigProvider, DatePicker, Form, Input, InputNumber, Select } from 'antd';
import type { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';

const { getGameSchema, saveQuery, getCustomQuery, updateQuery, runCustomQuery } = customQueryApi;
const { convertText } = appHelper;

const QueryGroup = ({ name, remove, selectFields, getFieldType, setValue, ...restField }: any) => {
  const [type, setType] = useState<DataType>('string');
  const [operator, setOperator] = useState<OperatorType>();

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    // Can not select days before today and today
    return current && current > dayjs().endOf('day').subtract(1, 'day');
  };

  const valueInput = useMemo(() => {
    switch (type) {
      case 'string': {
        if (operator === 'IN' || operator === 'NOT_IN') {
          return (
            <Select
              mode="tags"
              tokenSeparators={[',']}
              style={{
                minWidth: 120,
              }}
              tagRender={TagSelect}
            />
          );
        }
        return <Input placeholder="Value" />;
      }
      case 'integer':
        return <InputNumber min={0} placeholder="Value" />;
      case 'float':
        return <InputNumber min={0} placeholder="Value" />;
      case 'date': {
        if (operator === 'BETWEEN') {
          return <DatePicker.RangePicker disabledDate={disabledDate} />;
        }
        return <DatePicker disabledDate={disabledDate} />;
      }
      default:
        return <Input placeholder="Value" />;
    }
  }, [type, operator]);

  return (
    <div className="flex w-full gap-4 items-baseline">
      <Form.Item
        {...restField}
        name={[name, 'field']}
        rules={[{ required: true, message: 'Missing field' }]}
        style={{ minWidth: 120 }}
      >
        <Select
          options={selectFields}
          placeholder="Field"
          onChange={(value) => {
            const fieldType = getFieldType(value);
            setValue(['condition', name, 'value'], undefined);
            setType(fieldType);
          }}
        />
      </Form.Item>
      <Form.Item
        {...restField}
        name={[name, 'operator']}
        rules={[{ required: true, message: 'Missing operator' }]}
        style={{ minWidth: 120 }}
      >
        <Select
          placeholder="Operator"
          options={dataTypeToOperators[type]?.map((type) => ({ label: type, value: type })) || []}
          onChange={(value) => {
            setValue(['condition', name, 'value'], undefined);
            setOperator(value);
          }}
        />
      </Form.Item>
      <Form.Item
        {...restField}
        name={[name, 'value']}
        rules={[{ required: true, message: 'Missing value' }]}
        style={{
          marginBottom: 'auto',
        }}
      >
        {valueInput}
      </Form.Item>
      <MinusCircleOutlined onClick={() => remove(name)} />
    </div>
  );
};

export interface FormValue {
  name: string;
  fields: string[];
  condition: {
    field: string;
    operator: OperatorType;
    value: any;
  }[];
  orderField: string;
  orderType: 'ASC' | 'DESC';
  limit: number;
}

const ExploreQueryBuilder = () => {
  useDocumentTitle('Query Builder');
  const [searchParams] = useSearchParams();
  const editParam = searchParams.get('edit');

  const { currentApp } = LayoutContextConsumer();

  const [form] = Form.useForm<FormValue>();

  const navigate = useNavigate();

  const { data: schema, isLoading: schemaLoading } = useQuery(
    ['custom-query', 'query-schema', currentApp],
    async () => {
      const res = await getGameSchema();

      return res as unknown as CustomQueryGameSchema[];
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const { data: queryData, mutate } = useMutation({
    mutationKey: ['custom-query', currentApp],
    mutationFn: async (values: Omit<CustomQuery, 'id'>) => {
      const query = await runCustomQuery(values);
      return query as unknown as CustomQueryResponse;
    },
    onSuccess: () => {
      toastHelper.success('Query run');
    },
    onError: () => {
      toastHelper.error('Something went wrong');
    },
  });

  const { data: queryEdit, isLoading: queryLoading } = useQuery(
    ['custom-query', editParam],
    async () => {
      const res = await getCustomQuery(editParam as string);

      return res as unknown as CustomQuery;
    },
    {
      enabled: !!editParam,
      refetchOnWindowFocus: false,
    },
  );

  const { mutate: submitQuery } = useMutation({
    mutationKey: ['custom-query', 'submit', currentApp],
    mutationFn: async (values: Omit<CustomQuery, 'id'>) => {
      if (!editParam) {
        await saveQuery(values);
      } else {
        await updateQuery(editParam, {
          ...queryEdit,
          ...values,
          id: editParam,
        });
      }
    },
    onSuccess: () => {
      toastHelper.success(editParam ? 'Query updated' : 'Query created');
      navigate('/explore');
    },
    onError: () => {
      toastHelper.error('Something went wrong');
    },
  });

  const getFieldType = (field: string): DataType => {
    const fieldSchema = schema?.find((s) => s.field === field);

    return fieldSchema?.type as DataType;
  };

  const selectFields = useMemo(() => {
    if (!schema) return [];

    return schema?.map((field) => ({
      label: convertText(field.field),
      value: field.field,
      name: field.field,
    }));
  }, [schema]);

  const setValues = (key: string, values: any) => {
    form.setFieldValue(key, values);
  };

  const handleSubmit = async (values: FormValue) => {
    const body: Omit<CustomQuery, 'id'> = {
      name: values.name,
      fields: values.fields,
      condition: values.condition
        ? values.condition.map((item) => {
            const dataType = getFieldType(item.field);
            if (dataType === 'date') {
              if (item.operator === 'BETWEEN') {
                return {
                  ...item,
                  value: item.value.map((date: any) => date.format('YYYY-MM-DD')),
                  type: dataType,
                };
              } else {
                return {
                  ...item,
                  value: item.value.format('YYYY-MM-DD'),
                  type: dataType,
                };
              }
            } else {
              return {
                ...item,
                type: dataType,
              };
            }
          })
        : [],
      limit: values.limit,
      order: {
        field: values.orderField,
        type: values.orderType,
      },
      game: currentApp?.id ? currentApp.id : '',
    };
    mutate(body);
  };

  const handleSaveQuery = async () => {
    submitQuery(queryData?.queryReq as unknown as Omit<CustomQuery, 'id'>);
  };

  const isLoading = useMemo(() => {
    return schemaLoading || (queryLoading && !!editParam);
  }, [schemaLoading, queryLoading, editParam]);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: SECONDARY_COLOR,
        },
      }}
    >
      <section className="mb-4">
        <BackBtn text="Explore" />
        <h3 className="page-section-title">Query Builder</h3>
      </section>

      {isLoading ? (
        <div className="flex w-full min-h-[60vh] justify-center items-center">
          <Loading />
        </div>
      ) : (
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          className="w-full max-w-5xl"
          initialValues={{
            name: queryEdit ? queryEdit.name : '',
            fields: queryEdit ? queryEdit.fields : [],
            condition: queryEdit ? queryEdit.condition : [],
            orderField: queryEdit ? queryEdit.order.field : '',
            orderType: queryEdit ? queryEdit.order.type : '',
            limit: queryEdit ? queryEdit.limit : 10,
          }}
        >
          <Form.Item
            name="name"
            label="Query name"
            rules={[
              {
                required: true,
                message: 'Missing query name',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="fields" label="Fields" required>
            <Select mode="tags" options={selectFields} tagRender={TagSelect} />
          </Form.Item>
          <Form.List name="condition">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => {
                  return (
                    <Fragment key={key}>
                      <QueryGroup
                        {...restField}
                        name={name}
                        setValue={setValues}
                        remove={remove}
                        selectFields={selectFields}
                        getFieldType={getFieldType}
                      />
                    </Fragment>
                  );
                })}
                <Form.Item>
                  <Button size="small" type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                    Add condition
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>

          <Form.Item label="Order">
            <div className="flex gap-4 items-baseline">
              <Form.Item name="orderField" noStyle>
                <Select options={selectFields} style={{ minWidth: 120, width: 'fit-content' }} placeholder="Field" />
              </Form.Item>
              <Form.Item name="orderType" noStyle>
                <Select
                  style={{ width: 120 }}
                  placeholder="Type"
                  options={[
                    { label: 'Ascending', value: 'ASC' },
                    { label: 'Descending', value: 'DESC' },
                  ]}
                />
              </Form.Item>
            </div>
          </Form.Item>

          <Form.Item name="limit" label={'Limit'} initialValue={1000}>
            <InputNumber min={1} max={10000} />
          </Form.Item>

          <Form.Item>
            <div className="flex gap-4">
              <Button size="small" type="primary" htmlType="submit">
                Run Query
              </Button>

              {(queryData || editParam) && (
                <Button size="small" type="primary" onClick={handleSaveQuery}>
                  Save Query
                </Button>
              )}
            </div>
          </Form.Item>
        </Form>
      )}

      {queryData && (
        <div className="">
          <code>{queryData?.query}</code>
          <div className="h-[64vh] w-fit overflow-auto mt-4">
            <CustomQueryChart data={queryData?.data ? queryData.data : []} />
          </div>
        </div>
      )}
    </ConfigProvider>
  );
};

export default ExploreQueryBuilder;
