import { RouteProps } from 'react-router-dom';

import Forbidden from 'pages/exception/Forbidden';
import NotFound from 'pages/exception/NotFound';
import ComingSoonPage from 'pages/main/ComingSoonPage';

const commonRoutes: RouteProps[] = [
  {
    path: '403',
    element: <Forbidden />,
  },
  {
    path: 'coming-soon',
    element: <ComingSoonPage />,
  },
  {
    path: '*',
    element: <NotFound />,
  },
];

export default commonRoutes;
