import { useMemo } from 'react';

import DynamicColumnChart from './DynamicColumnChart';
import DynamicLineChart from './DynamicLineChart';
import DynamicPieChart from './DynamicPieChart';
import RawDataTable from './RawDataTable';

import { Empty, Tabs } from 'antd';
import type { TabsProps } from 'antd';

interface Props {
  data: {
    [key in string]: any;
  }[];
}

const CustomQueryChart = ({ data }: Props) => {
  const tableHead = useMemo(() => {
    if (!data || data.length === 0) return [];
    const keys = Object.keys(data[0]);
    return keys;
  }, [data]);

  const tableBody = useMemo(() => {
    return data.map((item) => {
      const row = [];
      for (let i = 0; i < tableHead.length; i++) {
        row.push(item[tableHead[i]]);
      }
      return row;
    });
  }, [data, tableHead]);

  const tabs = useMemo(() => {
    const items: TabsProps['items'] = [
      {
        key: 'table',
        label: 'Table',
        children: <RawDataTable headers={tableHead} data={tableBody} />,
      },
      {
        key: 'line',
        label: 'Line Chart',
        children: <DynamicLineChart fields={tableHead} data={data} />,
      },
      {
        key: 'column',
        label: 'Column Chart',
        children: <DynamicColumnChart fields={tableHead} data={data} />,
      },
      {
        key: 'pie',
        label: 'Pie Chart',
        children: <DynamicPieChart fields={tableHead} data={data} />,
      },
    ];

    return items;
  }, [tableHead, tableBody, data]);

  if (!data || data.length === 0) return <Empty />;

  return <Tabs defaultActiveKey="table" items={tabs} />;
};

export default CustomQueryChart;
