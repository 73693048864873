import React, { useEffect, useState } from 'react';
import { FiChevronsRight, FiPlus, FiSearch } from 'react-icons/fi';
import { MdMoreVert, MdOutlineLockPerson, MdOutlineMarkEmailRead, MdPeopleAlt } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

import Avatar from 'components/shared/Avatar';

import userApi, { IUser } from 'config/api/user/userApi';
import { USER_LIMIT, USER_ROLE, userRoleIcon } from 'config/constants/userRole';
import appHelper from 'config/helpers/appHelper';
import toastHelper from 'config/helpers/toastHelper';

import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Checkbox, Col, Dropdown, Modal, Pagination, Row } from 'antd';
import capitalize from 'lodash/capitalize';
import chunk from 'lodash/chunk';
import get from 'lodash/get';
import lowerCase from 'lodash/lowerCase';

interface IProps {
  users: IUser[];
  refetch: () => void;
}

const { compareSearchKeyword } = appHelper;
const { checkUserExist, deleteUser, updateUserEmailReport } = userApi;

const UserSettingTable = ({ users, refetch }: IProps) => {
  const [addUserModalOpen, setAddUserModalOpen] = useState(false);
  const [search, setSearch] = useState<IUser[]>([]);
  const [addText, setAddText] = useState('');
  const [page, setPage] = useState(1);

  const navigate = useNavigate();

  const handleEditUser = (email: string) => {
    navigate(`/user-setting/edit/${email}`);
  };

  const handleCloseAddModal = () => {
    setAddUserModalOpen(false);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const filtered = users.filter((user) => {
      return compareSearchKeyword(user.email, value.toLowerCase());
    });
    setSearch(filtered);
  };

  const handleUpdateUserReceiveEmail = async (email: string, emailReport: boolean) => {
    await updateUserEmailReport(email, emailReport).then(() => {
      refetch();
    });
  };

  useEffect(() => {
    setSearch(users);
  }, [users]);

  return (
    <div className="w-full overflow-auto">
      <div className="w-full min-w-[500px]">
        <div className="rounded-t-[20px] bg-secondary px-6 py-4">
          <div className="mb-4 flex items-center justify-between">
            <div
              className="flex max-w-[100%] items-center gap-4 rounded-lg p-2 text-white md:min-w-[330px]"
              style={{
                backgroundColor: 'rgba(255, 255, 255, 0.3)',
              }}
            >
              <FiSearch size={24} />
              <input
                type="text"
                placeholder="Search"
                className="border-none bg-transparent font-sans text-base leading-[24px] text-white outline-none placeholder:font-sans placeholder:text-white"
                onChange={handleSearch}
              />
            </div>

            <button
              onClick={() => {
                setAddUserModalOpen(true);
              }}
              className="flex cursor-pointer items-center gap-1 rounded-md border-none bg-white px-5 py-2 font-sans text-14 text-black outline-none"
            >
              <FiPlus size={16} />
              <span>Add Member</span>
            </button>
          </div>
          <Row className="px-3">
            <Col xs={16} md={12}>
              <div className="flex items-center gap-2 text-14 text-white">
                <MdPeopleAlt size={20} />
                <p className="font-normal leading-[1]">Members</p>
              </div>
            </Col>
            <Col xs={4} md={6}>
              <div className="flex items-center gap-2 text-14 text-white">
                <MdOutlineLockPerson size={20} />
                <p className="font-normal leading-[1]">Role</p>
              </div>
            </Col>
            <Col xs={2} md={4}>
              <div className="flex h-full items-center justify-center gap-2 text-14 text-white">
                <MdOutlineMarkEmailRead size={20} />
                <p className="font-normal leading-[1]">Email Report</p>
              </div>
            </Col>
            <Col xs={2} md={2} className="flex gap-2 justify-end">
              <div className="flex h-full items-center justify-end gap-2 text-14 text-white">
                <FiChevronsRight size={20} />
                <p className="font-normal leading-[1]">More</p>
              </div>
            </Col>
          </Row>
        </div>

        <div className="flex w-full flex-col gap-3 rounded-b-[20px] bg-white px-3 py-2 md:px-6 md:py-4">
          {chunk(search, USER_LIMIT)[page - 1]?.map((user) => {
            const Icon = userRoleIcon[(lowerCase(user.role) || get(user, 'permission.account.role')) as USER_ROLE];

            return (
              <Row key={user.email} className="items-center rounded-lg bg-bg-grey px-4 py-3">
                <Col xs={16} md={12}>
                  <div className="flex items-center gap-2 text-16">
                    <Avatar size={36} imgUrl={user.profile?.photoURL ? user.profile.photoURL : undefined} />
                    <span>{user.email}</span>
                  </div>
                </Col>
                <Col xs={4} md={6}>
                  <div className="flex items-center gap-1 text-[#565656]">
                    {Icon ? <Icon size={20} /> : null}
                    <span>{capitalize(user.role || get(user, 'permission.account.role'))}</span>
                  </div>
                </Col>

                <Col xs={2} md={4} className="flex justify-center">
                  <Checkbox
                    defaultChecked={user?.emailReport ? user.emailReport : false}
                    onChange={(e) => {
                      handleUpdateUserReceiveEmail(user.email, e.target.checked);
                    }}
                  />
                </Col>

                <Col xs={2} md={2} className="flex items-center justify-end">
                  <Dropdown
                    placement="bottomRight"
                    menu={{
                      items: [
                        {
                          key: 'edit',
                          label: 'Edit',
                          icon: <EditOutlined />,
                          onClick: () => {
                            handleEditUser(user.email);
                          },
                        },
                        {
                          key: 'delete',
                          label: 'Delete',
                          icon: <DeleteOutlined />,
                          onClick: async () => {
                            await deleteUser(user.email);
                            refetch();
                          },
                        },
                      ],
                    }}
                    trigger={['click']}
                  >
                    <div className="cursor-pointer">
                      <MdMoreVert size={18} />
                    </div>
                  </Dropdown>
                </Col>
              </Row>
            );
          })}
          <div className="mt-1 flex justify-end">
            <Pagination pageSize={USER_LIMIT} current={page} total={search.length} onChange={(page) => setPage(page)} />
          </div>
        </div>
      </div>

      <Modal open={addUserModalOpen} onCancel={handleCloseAddModal} footer={null}>
        <div>
          <h4 className="text-center text-20 font-semibold">Add member</h4>
          <form
            className="mx-1 mt-3 text-center md:mx-4 md:mt-6"
            onSubmit={async (e) => {
              e.preventDefault();
              const exist = await checkUserExist(addText);
              if (exist) {
                toastHelper.error('Email đã tồn tại');
              } else {
                sessionStorage.setItem('addUserEmail', addText);
                navigate(`/user-setting/edit/${addText}`);
              }
              // if (checkTopeboxEmail(addText)) {
              // } else {
              //   toastHelper.error('Phải tạo bằng email Topebox');
              // }
            }}
          >
            <input
              placeholder="Email"
              type="email"
              className="w-full rounded-lg border-none bg-bg-grey px-5 py-3 font-sans outline-none"
              onChange={(e) => {
                setAddText(e.target.value);
              }}
              required
            />

            <button
              type="submit"
              className="mt-6 cursor-pointer rounded-md border-none bg-primary py-2 px-7 font-sans text-14 text-white outline-none hover:bg-bg-red-light"
            >
              Confirm
            </button>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default UserSettingTable;
