import React, { useCallback, useMemo, useState } from 'react';
import { DateRange, DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

import 'content/styles/cus-daypicker.css';

import CusDayPicker from 'components/shared/CusDayPicker';
import MonthPicker from 'components/shared/MonthPicker';

import dateHelper from 'config/helpers/dateHelper';

import { CalendarOutlined } from '@ant-design/icons';
import { Popover, Radio } from 'antd';

interface IFullDatePickerProps {
  setRangeDate: (rangeDate: { start?: string; end?: string }) => void;
  rangeDate?: { start?: string; end?: string } | null;
  isDisabled?: boolean;
  title?: string;
}

const { format: formatHelper, formatMonth, getAllDaysInWeek, getAllDaysInMonth } = dateHelper;

const FullDatePicker = ({
  setRangeDate,
  rangeDate,
  isDisabled = false,
  title = 'Select Date',
}: IFullDatePickerProps) => {
  const initialDays: Date[] = [];
  const [type, setType] = useState<'date' | 'week' | 'month' | 'range'>('date');
  const [day, setDay] = useState<Date | undefined>(new Date());
  const [days, setDays] = React.useState<Date[] | undefined>(initialDays);
  const [range, setRange] = useState<DateRange | undefined>();

  const handleWeekSelect = useCallback(
    (days: Date[] | undefined) => {
      if (days) {
        const allDate = getAllDaysInWeek(days[days.length - 1]);
        setDays(allDate);
        setRangeDate({
          start: formatHelper(new Date(allDate[0])),
          end: formatHelper(new Date(allDate[allDate.length - 1])),
        });
      }
    },
    [setRangeDate],
  );

  const handleChangeMonth = useCallback(
    (month: Date) => {
      const allDate = getAllDaysInMonth(month);
      setDays(allDate);
      setRangeDate({
        start: formatHelper(new Date(allDate[0])),
        end: formatHelper(new Date(allDate[allDate.length - 1])),
      });
    },
    [setRangeDate],
  );

  const renderPicker = useMemo(() => {
    switch (type) {
      case 'date':
        return (
          <CusDayPicker
            mode="single"
            selected={day}
            onSelect={(day: Date | undefined) => {
              if (!day) return;
              setDay(day);
              setRangeDate({ start: formatHelper(new Date(day)), end: formatHelper(new Date(day)) });
            }}
            showOutsideDays
            disabled={[{ from: new Date(), to: new Date(2030, 1, 1) }]}
          />
        );
      case 'week':
        return (
          <CusDayPicker
            mode="multiple"
            min={1}
            selected={days}
            onSelect={handleWeekSelect}
            showOutsideDays
            disabled={[{ from: new Date(), to: new Date(2030, 1, 1) }]}
          />
        );
      case 'month':
        return <MonthPicker handleChangeMonth={handleChangeMonth} />;
      case 'range':
        return (
          <DayPicker
            mode="range"
            showOutsideDays
            selected={range}
            onSelect={(range: DateRange | undefined) => {
              setRange(range);
              if (range && range.from && range.to) {
                setRangeDate({
                  start: formatHelper(new Date(range.from)),
                  end: formatHelper(new Date(range.to)),
                });
              }
            }}
            modifiersClassNames={{
              selected: 'cus-day-picker-selected',
              today: 'cus-day-picker-today',
              range_start: 'cus-day-picker-range-start',
              range_end: 'cus-day-picker-range-end',
              range_middle: 'cus-day-picker-range-middle',
            }}
            disabled={[{ from: new Date(), to: new Date(2030, 1, 1) }]}
          />
        );
    }
  }, [type, day, days, handleWeekSelect, range, setRangeDate, handleChangeMonth]);

  const renderLabel = useMemo(() => {
    if (!rangeDate) return '';
    if (rangeDate.start && rangeDate.end) {
      if (rangeDate.start === rangeDate.end) {
        return formatHelper(new Date(rangeDate.start));
      }
      if (type === 'month') {
        return formatMonth(new Date(rangeDate.start));
      }
      return `${formatHelper(new Date(rangeDate.start))} ~ ${formatHelper(new Date(rangeDate.end))}`;
    }
  }, [rangeDate, type]);

  return (
    <Popover
      content={
        <div className="mt-3 w-[280px] text-center">
          <Radio.Group
            value={type}
            onChange={(values) => {
              setType(values.target.value);
              setDay(undefined);
              setDays(undefined);
            }}
            buttonStyle="solid"
            size="small"
          >
            <Radio.Button value="date">Day</Radio.Button>
            <Radio.Button value="week">Week</Radio.Button>
            <Radio.Button value="month">Month</Radio.Button>
            <Radio.Button value="range">Range</Radio.Button>
          </Radio.Group>
          {renderPicker}
        </div>
      }
      trigger={isDisabled ? [] : ['click']}
      placement="bottom"
    >
      <div className="w-fit">
        <h6 className="mb-2 text-16 font-medium">{title}</h6>
        <button className="flex min-h-[32px] lg:min-w-[215px] max-w-fit cursor-pointer items-center justify-between gap-2 rounded-md border border-solid border-[#D8D8D8] bg-white px-3 py-1 font-sans text-14 leading-6 text-grey">
          <span>{renderLabel}</span>
          <CalendarOutlined />
        </button>
      </div>
    </Popover>
  );
};

export default FullDatePicker;
