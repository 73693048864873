import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';

import LayoutContextConsumer from 'contexts/LayoutContext';

import RawDataTable from 'components/feature/charts/RawDataTable';
import Loading from 'components/shared/Loading';

import skyDancer2Api from 'config/api/bigquery/skyDancer2Api';
import { IVersionHistory } from 'config/api/version-history/versionHistoryApi';
import dayjs from 'dayjs';
import dayOfYear from 'dayjs/plugin/dayOfYear'; // load on demand
import weekOfYear from 'dayjs/plugin/weekOfYear'; // load on demand

dayjs.extend(dayOfYear); // use plugin
dayjs.extend(weekOfYear); // use plugin

interface Props {
  rangeDate: {
    start?: string;
    end?: string;
  };
  versionHistoryData?: IVersionHistory[];
  version: string | null;
  setRangeDate?: (rangeDate: { start?: string; end?: string }) => void;
}

interface ILeaderboardUser {
  platform: string
  country: string
  lb_name: string
  rank: number
  score: number
  lb_type: number
  user_id: string
}

const { getLeaderboard } = skyDancer2Api;

const SkyLeaderboard = ({ rangeDate, setRangeDate }: Props) => {
  const { platform } = LayoutContextConsumer();

  useEffect(() => {
    setRangeDate?.({
      start: dayjs().subtract(2, 'day').format('YYYY-MM-DD'),
      end: dayjs().subtract(2, 'day').format('YYYY-MM-DD'),
    });
  }, []);

  const { data: packData, isLoading: packLoading } = useQuery(['iap-pack', rangeDate, platform], async () => {
    if (!rangeDate.start || !rangeDate.end) return [];

    const res = await getLeaderboard({
      params: {
        platform: platform === 'unified' ? undefined : platform,
        start: rangeDate.start,
        end: rangeDate.end,
      },
    });

    return res as unknown as ILeaderboardUser[];
  });

  const packTableData = useMemo(() => {
    const dailyData = packData?.filter((item) => item.lb_type === 1);
    const weeklyData = packData?.filter((item) => item.lb_type === 2);

    const dailyBottomRank = dailyData?.[dailyData.length - 1]?.rank;
    const weeklyBottomRank = weeklyData?.[weeklyData.length - 1]?.rank;

    const dailyGridData = Array.from({ length: dailyBottomRank ?? 0 }, (_, i) => {
      const rank = i + 1;
      const item = dailyData?.find((data) => data.rank === i + 1);
      return [
        rank,
        item?.score ?? "?",
        item === undefined ? "No" : "Yes",
        item?.country ?? "?",
        item?.user_id ?? "?",
      ];
    });

    const weeklyGridData = Array.from({ length: weeklyBottomRank ?? 0 }, (_, i) => {
      const rank = i + 1;
      const item = weeklyData?.find((data) => data.rank === i + 1);
      return [
        rank,
        item?.score ?? "?",
        item === undefined ? "No" : "Yes",
        item?.country ?? "?",
        item?.user_id ?? "?",
      ];
    });
    return {
      dailyData,
      weeklyData,
      dailyGridData,
      weeklyGridData,
    }
  }, [packData]);

  const isLoading = packLoading;

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex gap-8 flex-wrap">
          {packTableData?.dailyGridData?.length > 0 && (
            <RawDataTable
              isCenterFull
              title="Daily"
              headers={[
                'Rank',
                'Point',
                'Claimed?',
                'Country',
                'User ID'
              ]}
              data={packTableData.dailyGridData}
              rawData={packData}
            />
          )}
          {packTableData?.weeklyGridData?.length > 0 && (
            <RawDataTable
              isCenterFull
              title="Weekly"
              headers={[
                'Rank',
                'Point',
                'Claimed?',
                'Country',
                'User ID'
              ]}
              data={packTableData.weeklyGridData}
              rawData={packData}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default SkyLeaderboard;
