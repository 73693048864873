import { useQuery } from '@tanstack/react-query';
import { BiTime, BiUser } from 'react-icons/bi';

import LayoutContextConsumer from 'contexts/LayoutContext';

import RawDataTable, { DateVersion } from 'components/feature/charts/RawDataTable';
import Loading from 'components/shared/Loading';

import mimilandApi from 'config/api/bigquery/mimilandApi';
import { IVersionHistory } from 'config/api/version-history/versionHistoryApi';
import numberHelper from 'config/helpers/numberHelper';

import { Empty, Tooltip } from 'antd';

interface Props {
  rangeDate: {
    start?: string;
    end?: string;
  };
  versionHistoryData?: IVersionHistory[];
  version: string | null;
}

const { getMimilandStory } = mimilandApi;
const { toPercent } = numberHelper;

interface IStory {
  time: string;
  daily_active_users: number;
  open_profile_times: number;
  open_profile_users: number;
  share_story_times: number;
  view_other_story_times: number;
  love_story_times: number;
  comment_times: number;
  share_story_users: number;
  view_other_story_users: number;
  love_story_users: number;
  comment_users: number;
}

const DataItem = ({ users, value, title, dau }: { users: number; value: string; title?: string; dau: number }) => {
  const rate = toPercent(users, dau);
  return (
    <Tooltip
      title={
        <span>
          <p>
            - {title}: {value} times
          </p>
          <p>
            - Users / DAU: {users}/{dau} ({rate})
          </p>
        </span>
      }
    >
      <div className="flex items-center gap-1">
        <BiTime className="text-12" />
        <p className="text-14">{value}</p>
      </div>
      <div className="flex items-center gap-1 whitespace-nowrap">
        <BiUser className="text-12" />
        <p className="text-14">
          {users} ({rate})
        </p>
      </div>
    </Tooltip>
  );
};

const Story = ({ rangeDate, version, versionHistoryData }: Props) => {
  const { platform } = LayoutContextConsumer();

  const { data: storyData, isLoading: summaryLoading } = useQuery(
    ['mml-story', rangeDate, version, platform],
    async () => {
      if (!rangeDate.start || !rangeDate.end) return [];

      const res = await getMimilandStory({
        params: {
          platform: platform === 'unified' ? undefined : platform,
          version,
          start: rangeDate.start,
          end: rangeDate.end,
        },
      });

      return res as unknown as IStory[];
    },
  );
  const isLoading = summaryLoading;

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex flex-col gap-10">
          {storyData && storyData.length > 0 ? (
            <RawDataTable
              isCenterFull
              title="Story Summary"
              headers={['Date', 'DAU', 'Open Profile', 'Create Story', 'View Other Story', 'Love Story', 'Comment']}
              data={
                storyData.map((item) => {
                  const verData = versionHistoryData?.find((ver) => ver.date === item.time);
                  return [
                    <DateVersion verData={verData} date={item.time} />,
                    item.daily_active_users.toFixed(0),
                    <DataItem
                      users={item.open_profile_users}
                      value={item.open_profile_times.toFixed(0)}
                      title="Open Profile"
                      dau={item.daily_active_users}
                    />,
                    <DataItem
                      users={item.share_story_users}
                      value={item.share_story_times.toFixed(0)}
                      title="Create Story"
                      dau={item.daily_active_users}
                    />,
                    <DataItem
                      users={item.view_other_story_users}
                      value={item.view_other_story_times.toFixed(0)}
                      title="View Other Story"
                      dau={item.daily_active_users}
                    />,
                    <DataItem
                      users={item.love_story_users}
                      value={item.love_story_times.toFixed(0)}
                      title="Love Story"
                      dau={item.daily_active_users}
                    />,
                    <DataItem
                      users={item.comment_users}
                      value={item.comment_times.toFixed(0)}
                      title="Comment"
                      dau={item.daily_active_users}
                    />,
                  ];
                }) ?? []
              }
              rawData={storyData}
            />
          ) : (
            <Empty />
          )}
        </div>
      )}
    </div>
  );
};

export default Story;
