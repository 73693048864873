import { useQuery } from '@tanstack/react-query';

import RawDataTable from 'components/feature/charts/RawDataTable';
import Loading from 'components/shared/Loading';

import skyDancer2Api from 'config/api/bigquery/skyDancer2Api';
import { IVersionHistory } from 'config/api/version-history/versionHistoryApi';

import { Empty } from 'antd';

interface Props {
  rangeDate: {
    start?: string;
    end?: string;
  };
  versionHistoryData?: IVersionHistory[];
  version: string | null;
}

interface ILastAction {
  action_name: string;
  users: number;
}

const { getLastActionFirstLaunch } = skyDancer2Api;

const SkyLastActionFirstLaunch = ({ rangeDate, version }: Props) => {
  const { data, isLoading } = useQuery(['last-action', version, rangeDate], async () => {
    if (!rangeDate.start || !rangeDate.end) return [];

    const res = await getLastActionFirstLaunch({
      params: {
        version,
        start: rangeDate.start,
        end: rangeDate.end,
      },
    });

    return res as unknown as ILastAction[];
  });

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : data && data.length > 0 ? (
        <div className="overflow-x-auto">
          <RawDataTable
            isCenterFull
            title="Last Action Of First Launch"
            headers={['Action Name', 'Users']}
            data={data.map((item): [string, string] => [item.action_name, item.users.toFixed(0)])}
          />
        </div>
      ) : (
        <Empty />
      )}
    </div>
  );
};

export default SkyLastActionFirstLaunch;
