import CountrySelect from 'components/shared/CountrySelect';

import { filterCategory } from 'config/constants/explore';

import ExploreModal from './ExploreModal';

import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Select } from 'antd';
import capitalize from 'lodash/capitalize';
import { useExploreStore } from 'stores/exploreStore';

const ExploreFilter = () => {
  const { filter, categories, modalOpen, setFilter, setModalOpen } = useExploreStore((state) => state);

  const [form] = Form.useForm();

  return (
    <>
      <Form form={form} layout="vertical" className="flex flex-wrap gap-3 lg:gap-5">
        <div className="flex h-full min-w-[120px] max-w-[200px] flex-col justify-between">
          <span className="pb-2 text-16 font-medium">Metric</span>
          <Button
            size="small"
            style={{
              fontSize: 14,
              height: 30,
              padding: '0 10px',
              borderRadius: '6px',
              width: '100%',
              textAlign: 'left',
            }}
            onClick={() => {
              setModalOpen(true);
            }}
          >
            {capitalize(filter.metric)}
          </Button>
        </div>

        <div className="flex h-full min-w-[120px] max-w-[200px] flex-col justify-between">
          <span className="pb-2 text-16 font-medium">Category</span>
          <Select
            options={filter.metric === 'engagement' ? filterCategory : categories}
            onChange={(value) => {
              setFilter({
                ...filter,
                category: value,
              });
            }}
            value={filter.category}
            defaultValue={filter.category}
          />
        </div>
        <CountrySelect
          selectedCountry={filter.country}
          setSelectedCountry={(country: string) => {
            setFilter({
              ...filter,
              country: country,
            });
          }}
        />
        <Form.Item
          label={<span className="text-16 font-medium">Aggregation</span>}
          name="event_category"
          style={{
            maxWidth: '200px',
            minWidth: '120px',
            marginBottom: '0px',
          }}
        >
          <Input placeholder="Unique users" disabled={true} />
        </Form.Item>
        <Form.Item
          label={<span className="text-16 font-medium">Order</span>}
          name="order"
          style={{
            maxWidth: '200px',
            minWidth: '120px',
            marginBottom: '0px',
          }}
          initialValue={'x-asc'}
        >
          <Select disabled={true} options={[{ label: 'X axis ascending', value: 'x-asc' }]} />
        </Form.Item>
        <Form.Item className="mb-0 flex flex-col justify-end">
          <Button
            size="small"
            htmlType="submit"
            icon={<PlusOutlined />}
            style={{
              fontSize: 14,
              height: 30,
              padding: '0 10px',
              borderRadius: '6px',
            }}
            disabled={true}
          >
            Group by Split by
          </Button>
        </Form.Item>

        <Form.Item className="mb-0 flex flex-col justify-end">
          <Button
            size="small"
            htmlType="submit"
            icon={<PlusOutlined />}
            style={{
              fontSize: 14,
              height: 30,
              padding: '0 10px',
              borderRadius: '6px',
            }}
            disabled={true}
          >
            Global filters
          </Button>
        </Form.Item>
        <Form.Item className="mb-0 flex flex-col justify-end">
          <Button
            size="small"
            htmlType="submit"
            icon={<PlusOutlined />}
            style={{
              fontSize: 14,
              height: 30,
              padding: '0 10px',
              borderRadius: '6px',
            }}
            disabled={true}
          >
            Event filters
          </Button>
        </Form.Item>
        {/* <div className="flex gap-3 flex-wrap">
        </div> */}
        {/* <div className="flex gap-3 flex-wrap">
        </div> */}
      </Form>

      <div className="">
        <ExploreModal
          filter={filter}
          setFilter={setFilter}
          isModalVisible={modalOpen}
          setIsModalVisible={setModalOpen}
        />
      </div>
    </>
  );
};

export default ExploreFilter;
