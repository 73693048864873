import { useQuery } from '@tanstack/react-query';
import React from 'react';

import LayoutContextConsumer from 'contexts/LayoutContext';

import RawDataTable from 'components/feature/charts/RawDataTable';
import Loading from 'components/shared/Loading';

import skyDancer2Api from 'config/api/bigquery/skyDancer2Api';
import { IVersionHistory } from 'config/api/version-history/versionHistoryApi';

interface Props {
  rangeDate: {
    start?: string;
    end?: string;
  };
  versionHistoryData?: IVersionHistory[];
  version: string | null;
  country: string;
}

interface IUserLeft {
  time: string;
  platform: string;
  highest_stage: string;
  users: number;
}

const { getUserLeft } = skyDancer2Api;

const SkyUserLeft = ({ rangeDate, versionHistoryData, version, country }: Props) => {
  const { platform } = LayoutContextConsumer();

  const { data: userLeftData, isLoading } = useQuery(['iap-pack', version, rangeDate, country], async () => {
    if (!rangeDate.start || !rangeDate.end) return [];

    const res = await getUserLeft({
      params: {
        version,
        platform: platform === 'unified' ? undefined : platform,
        start: rangeDate.start,
        end: rangeDate.end,
        country: country === 'All' ? undefined : country,
      },
    });

    return res as unknown as IUserLeft[];
  });

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex gap-8 flex-wrap">
          {userLeftData && userLeftData.length > 0 && (
            <div className="overflow-x-auto">
              {/* <h6 className="mb-2 text-16 font-[500]"></h6> */}

              <RawDataTable
                isCenterFull
                title="Stage Of User Left"
                headers={['Time', 'Platform', 'Highest Stage', 'Users']}
                data={userLeftData.map((item) => {
                  return [item.time, item.platform, item.highest_stage, item.users.toFixed(0)];
                })}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SkyUserLeft;
