import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ArrowLeftOutlined } from '@ant-design/icons';

interface IProps {
  text?: string;
}

const BackBtn = ({ text }: IProps) => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div
      onClick={handleBack}
      className="mb-5 block w-fit cursor-pointer rounded-lg bg-[#C1C1C1] py-1 px-2 font-sans text-14 text-white hover:text-white"
    >
      <ArrowLeftOutlined className="mr-2" />
      {text}
    </div>
  );
};

export default BackBtn;
