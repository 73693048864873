export const funnelOpt = {
  Politaire2: [
    {
      label: 'Users Play Politaire Games',
      value: 'UserPlayingLevels',
      level: 'Play Times',
    },
    {
      label: 'Users Play Building Tower',
      value: 'UserTowerLevels',
      level: 'Tower Levels',
    },
    {
      label: 'Users Play Tutorial Step',
      value: 'UserTutorialStep',
      level: 'Tutorial Step',
    },
  ],
  SkyDancer2: [
    {
      label: 'Users Play Tutorial Step',
      value: 'FunnelTutorialSteps',
      level: 'Tutorial Step',
    },
  ],
  GunAndDungeon: [
    {
      label: 'Users Play Tutorial Step',
      value: 'FunnelTutorialSteps',
      level: 'Tutorial Step',
    },
  ],
  Mimiland: [
    {
      label: 'First Active',
      value: 'FunnelFirstActive',
      level: 'First Active Step',
    },
  ],
};
