import toastHelper from 'config/helpers/toastHelper';

import api from '..';

import { AxiosRequestConfig } from 'axios';

const reportApi = {
  getReportTemplate: (config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game) return api.get(`/report-template/${game}`, config);
    else toastHelper.error('Please select a game first');
  },
};

export default reportApi;
