import React from 'react';

import notFoundImg from 'content/assets/images/not-found.png';

import useDocumentTitle from 'hooks/useDocumentTitle';

const NotFound = () => {
  useDocumentTitle('Page Not Found');
  return (
    <div className="flex h-screen w-full flex-col items-center justify-center">
      <img src={notFoundImg} alt="NotFound" className="w-xl h-fit max-w-[90%]" />
    </div>
  );
};

export default NotFound;
