import toastHelper from 'config/helpers/toastHelper';

import api from '..';

import { AxiosRequestConfig } from 'axios';

export interface IRevenueBody {
  start?: string;
  end?: string;
  country?: string;
}

const revenueApi = {
  getAds: (body?: IRevenueBody, config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game) return api.post(`/revenue/ads/${game}`, body, config);
    else toastHelper.error('Please select a game first');
  },
  getIAP: (body?: IRevenueBody, config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game) return api.post(`/revenue/IAP/${game}`, body, config);
    else toastHelper.error('Please select a game first');
  },
  getReports: (body?: IRevenueBody, config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game) return api.post(`/revenue/${game}`, body, config);
    else toastHelper.error('Please select a game first');
  },
};

export default revenueApi;
