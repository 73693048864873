import toastHelper from 'config/helpers/toastHelper';

import api from '..';

import { AxiosRequestConfig } from 'axios';

export interface GetFirstLaunchReq {
  start?: string;
  end?: string;
}

const getFirstLaunchData = (body: GetFirstLaunchReq, config?: AxiosRequestConfig) => {
  const game = sessionStorage.getItem('currentApp');
  if (game) {
    return api.post(`first-launch/${game}`, body, config);
  } else {
    toastHelper.error('Please select a game first');
  }
};

const firstLaunchApi = {
  getFirstLaunchData,
};

export default firstLaunchApi;
