import { useQuery } from '@tanstack/react-query';
import { BiTime, BiUser } from 'react-icons/bi';

import LayoutContextConsumer from 'contexts/LayoutContext';

import RawDataTable, { DateVersion } from 'components/feature/charts/RawDataTable';
import Loading from 'components/shared/Loading';

import mimilandApi from 'config/api/bigquery/mimilandApi';
import { IVersionHistory } from 'config/api/version-history/versionHistoryApi';
import numberHelper from 'config/helpers/numberHelper';

import { Empty, Tooltip } from 'antd';

interface Props {
  rangeDate: {
    start?: string;
    end?: string;
  };
  versionHistoryData?: IVersionHistory[];
  version: string | null;
}

const { getMimilandTetSummary } = mimilandApi;
const { toPercent } = numberHelper;

interface ISummary {
  time: string;
  collect_LM_times: number;
  increase_LM_limit_times: number;
  cooking_RC_times: number;
  claim_RC_times: number;
  steal_RC_times: number;
  collect_LM_users: number;
  increase_LM_limit_users: number;
  cooking_RC_users: number;
  claim_RC_users: number;
  steal_RC_users: number;
  daily_active_users: number;
  donate_RC_users: number;
  donate_RC_times: number;
  claimed_pass_users: number;
  claimed_pass_times: number;
}

const DataItem = ({ users, value, title, dau }: { users: number; value: string; title?: string; dau: number }) => {
  const rate = toPercent(users, dau);
  return (
    <Tooltip
      title={
        <span>
          <p>
            - {title}: {value} lần
          </p>
          <p>
            - Người tham gia / DAU: {users}/{dau} ({rate})
          </p>
        </span>
      }
    >
      <div className="flex items-center gap-1">
        <BiTime className="text-12" />
        <p className="text-14">{value}</p>
      </div>
      <div className="flex items-center gap-1 whitespace-nowrap">
        <BiUser className="text-12" />
        <p className="text-14">
          {users} ({rate})
        </p>
      </div>
    </Tooltip>
  );
};

const TetEvent = ({ rangeDate, version, versionHistoryData }: Props) => {
  const { platform } = LayoutContextConsumer();

  const { data: summaryData, isLoading: summaryLoading } = useQuery(
    ['tet-summary', rangeDate, version, platform],
    async () => {
      if (!rangeDate.start || !rangeDate.end) return [];

      const res = await getMimilandTetSummary({
        params: {
          platform: platform === 'unified' ? undefined : platform,
          version,
          start: rangeDate.start,
          end: rangeDate.end,
        },
      });

      return res as unknown as ISummary[];
    },
  );

  // const { data: passData, isLoading: passLoading } = useQuery(['tet-pass', rangeDate, version, platform], async () => {
  //   if (!rangeDate.start || !rangeDate.end || !version) return [];

  //   const res = await getMimilandTetPass({
  //     params: {
  //       platform: platform === 'unified' ? undefined : platform,
  //       version,
  //     },
  //   });

  //   return res as unknown as IPass[];
  // });

  const isLoading = summaryLoading;

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex flex-col gap-10">
          {summaryData && summaryData.length > 0 ? (
            <RawDataTable
              isCenterFull
              title="Tet Event Summary"
              headers={[
                'Date',
                'DAU',
                'Nhặt Lì Xì',
                'Đổi Lì Xì',
                'Mua thêm lì xì',
                'Nấu bánh chưng',
                'Nhận bánh chưng',
                'Cướp bánh chưng',
                'Góp bánh chưng',
              ]}
              data={
                summaryData?.map((item) => {
                  const verData = versionHistoryData?.find((ver) => ver.date === item.time);
                  return [
                    <DateVersion verData={verData} date={item.time} />,
                    item.daily_active_users.toFixed(0),
                    <DataItem
                      users={item.collect_LM_users}
                      value={item.collect_LM_times.toFixed(0)}
                      title="Nhặt Lì Xì"
                      dau={item.daily_active_users}
                    />,
                    <DataItem
                      users={item.claimed_pass_users}
                      value={item.claimed_pass_times.toFixed(0)}
                      title="Đổi Lì Xì"
                      dau={item.daily_active_users}
                    />,
                    <DataItem
                      users={item.increase_LM_limit_users}
                      value={item.increase_LM_limit_times.toFixed(0)}
                      title="Mua thêm lì xì"
                      dau={item.daily_active_users}
                    />,
                    <DataItem
                      users={item.cooking_RC_users}
                      value={item.cooking_RC_times.toFixed(0)}
                      title="Nấu bánh chưng"
                      dau={item.daily_active_users}
                    />,
                    <DataItem
                      users={item.claim_RC_users}
                      value={item.claim_RC_times.toFixed(0)}
                      title="Nhận bánh chưng"
                      dau={item.daily_active_users}
                    />,
                    <DataItem
                      users={item.steal_RC_users}
                      value={item.steal_RC_times.toFixed(0)}
                      title="Cướp bánh chưng"
                      dau={item.daily_active_users}
                    />,
                    <DataItem
                      users={item.donate_RC_users}
                      value={item.donate_RC_times.toFixed(0)}
                      title="Góp bánh chưng"
                      dau={item.daily_active_users}
                    />,
                  ];
                }) || []
              }
              rawData={summaryData}
            />
          ) : (
            <Empty />
          )}

          {/* {passData && passData.length > 0 ? (
            <div className="py-4 overflow-x-auto">
              <h6 className="text-14 font-semibold lg:text-16">Pass</h6>
              <RawDataTable
                isCenterFull
                headers={['Milestone', 'Reached Users', 'Claimed Users']}
                data={
                  passData?.map((item): [string, string, string] => [
                    item.milestone,
                    item.reached_users.toFixed(0),
                    item.claimed_users.toFixed(0),
                  ]) || []
                }
              />
            </div>
          ) : (
            <Empty />
          )} */}
        </div>
      )}
    </div>
  );
};

export default TetEvent;
