import { Checkbox } from "antd";
import { BiCoinStack, BiTimer, BiUser } from "react-icons/bi";
import { RiPercentLine } from "react-icons/ri";

const getIconComponent = (icon: string) => {
  switch (icon) {
    case 'users':
      return <BiUser className="text-12" />;
    case 'times':
      return <BiTimer className="text-12" />;
    case 'coins':
      return <BiCoinStack className="text-12" />;
    case 'percent':
      return <RiPercentLine className="text-12" />;
    default:
      return null;
  }
};

export const itemToCell_rawStr = ({ value }: { value: string }) => {
  return (
    <div className="">
      <div className="flex items-center gap-1 whitespace-nowrap">
        <p className="text-14">{value}</p>
      </div>
    </div>
  );
};

export const itemToCell_icon = ({ value, icon }: { value: string, icon: string }) => {
  return (
    <div className="">
      <div className="flex items-center gap-1 whitespace-nowrap">
        {icon !== '' ? getIconComponent(icon) : null}
        <p className="text-14">{value}</p>
      </div>
    </div>
  );
};

export const itemToCell_checkbox = ({ value }: { value: boolean }) => {
  return (
    <div className="">
      <div className="flex items-center gap-1">
        <Checkbox checked={value} disabled />
      </div>
    </div>
  );
};

export const itemToCell_timeUsers = ({ times, users }: { times: string; users: string; }) => {
  return (
    <div className="">
      <div className="flex items-center gap-1">
        <BiTimer className="text-12" />
        <p className="text-14">{times}</p>
      </div>
      <div className="flex items-center gap-1 whitespace-nowrap">
        <BiUser className="text-12" />
        <p className="text-14">{users}</p>
      </div>
    </div>
  );
};

export const itemToCell_rows = ({ rows }: { rows: { icon: string, value: string }[] }) => {
  return (
    <div className="">
    {
      rows.map((it) => {
        return it.value && it.value !== ''
          ? (<div className="flex items-center gap-1 whitespace-nowrap">
              {it.icon !== '' ? getIconComponent(it.icon) : null}
              <p className="text-14">{it.value}</p>
            </div>)
          : (<></>);
      })
    }
    </div>
  );
};