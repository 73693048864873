import { useMemo } from 'react';

import ComingSoonPage from 'pages/main/ComingSoonPage';

import ExploreCustomQuery from 'components/feature/explore/ExploreCustomQuery';
import ExploreEngagement from 'components/feature/explore/ExploreEngagement';

import { useExploreStore } from 'stores/exploreStore';

const ExploreContent = () => {
  const { filter } = useExploreStore((state) => state);

  const content = useMemo(() => {
    switch (filter.metric) {
      case 'engagement':
        return <ExploreEngagement />;
      case 'custom':
        return <ExploreCustomQuery />;
      default:
        return <ComingSoonPage />;
    }
  }, [filter]);
  return content;
};

export default ExploreContent;
