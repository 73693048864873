import { useQuery } from '@tanstack/react-query';
import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import AuthConsumer from 'hooks/useAuth';
import useSessionStorage from 'hooks/useSessionStorage';

import { IApp, IAppResponse, getApps } from 'config/api/apps/appApi';
import type { Platform } from 'config/constants/platform';
import resourceEnum from 'config/constants/resource';
import userRole, { USER_ROLE } from 'config/constants/userRole';

import get from 'lodash/get';
import uniq from 'lodash/uniq';

interface ILayoutContext {
  apps: IApp[] | [];
  currentApp: IApp | null;
  isLoading: boolean;
  search: IApp[];
  sidebarOpen: boolean;
  isGameError: boolean;
  platform: Platform;
  resources: resourceEnum[];
  setCurrentApp: (app: IApp | null) => void;
  handleSearch: (value: string) => void;
  closeSidebar: () => void;
  setSidebarOpen: (value: boolean) => void;
  setPlatform: (value: Platform) => void;
}

export const LayoutContext = createContext<ILayoutContext>({
  apps: [],
  currentApp: null,
  isLoading: true,
  search: [],
  sidebarOpen: false,
  isGameError: false,
  platform: 'unified',
  resources: [],
  setCurrentApp: () => { },
  handleSearch: () => { },
  closeSidebar: () => { },
  setSidebarOpen: () => { },
  setPlatform: () => { },
});

export const LayoutContextProvider = ({ children }: { children: ReactNode }) => {
  const [currentApp, setCurrentApp] = useState<IApp | null>(null);
  const [search, setSearch] = useState<IApp[]>([]);
  const [sidebarDrawer, setSidebarDrawer] = useState(false);
  const [platform, setPlatform] = useSessionStorage<Platform>('platform', 'ios');
  const [resources, setResources] = useState<resourceEnum[]>([]);

  const { role, setRole, permissions } = AuthConsumer();

  const { data, isLoading, isError } = useQuery(
    ['apps'],
    async () => {
      const res = await getApps();
      return res as unknown as IAppResponse;
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const navigate = useNavigate();

  const handleSearch = (value: string) => {
    if (value && data) {
      // Check the app name contains the search value
      const newApps = get(data, 'games', []).filter((item) => item.name.toLowerCase().includes(value.toLowerCase()));
      setSearch(newApps);
    } else {
      setSearch(get(data, 'games', []) || []);
    }
  };

  const onSidebarClose = () => {
    setSidebarDrawer(false);
  };

  useEffect(() => {
    /**
     * Get the current app from session storage
     * If the current app is not exist, redirect to apps page
     * If the current app is exist, allow user to access the dashboard
     */
    const id = sessionStorage.getItem('currentApp');
    if (id) {
      const app = get(data, 'games', [])?.find((app) => app.id === id);
      if (app) {
        setCurrentApp(app);
      }
    }
    setSearch(get(data, 'games', []) || []);
  }, [data, navigate]);

  useEffect(() => {
    // If the user is super admin, allow user to access all resources
    if (role === userRole.SUPERADMIN) {
      setResources(Object.values(resourceEnum));
      return;
    }

    /** If the user is not super admin, get the resources from the user's role
     * The user has 2 resources permission: account and app
     * Account resources apply for all apps
     * App resources apply for the specific app
     */
    const userResources = get(data, 'permission');

    if (userResources && currentApp) {
      const appId = currentApp.id;
      const combine = uniq([...get(userResources, 'account', []), ...get(userResources, appId, [])]);
      setResources(combine);
    }
  }, [currentApp, data, role]);

  useEffect(() => {
    if (currentApp) {
      const r = get(permissions, `${currentApp.id}.role`, userRole.GUEST) as USER_ROLE;
      const appResources = get(permissions, `${currentApp.id}.resources`, []) as resourceEnum[];
      if (currentApp.appstore === undefined && platform === "ios") {
        setPlatform("unified")
      }
      if (currentApp.ggplay === undefined && platform === "android") {
        setPlatform("unified")
      }
      if (role !== userRole.SUPERADMIN) {
        setRole(r);
        setResources(appResources);
      }
    }
  }, [currentApp, permissions, role, setRole, platform]);

  return (
    <LayoutContext.Provider
      value={{
        apps: get(data, 'games', []) as IApp[],
        currentApp,
        isLoading,
        search,
        sidebarOpen: sidebarDrawer,
        isGameError: isError,
        platform,
        resources,
        setCurrentApp,
        handleSearch,
        closeSidebar: onSidebarClose,
        setSidebarOpen: setSidebarDrawer,
        setPlatform,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};

// Context Consumer for functional components
export default function LayoutContextConsumer() {
  return useContext(LayoutContext);
}
