import React from 'react';
import { CgSoftwareDownload } from 'react-icons/cg';

import useExportExcel from 'hooks/useExportExcel';

import { IVersionHistory } from 'config/api/version-history/versionHistoryApi';
import { CALENDAR_TEXT_ICON } from 'config/constants/general';
import monetizationHelper from 'config/helpers/monetizationHelper';

import { Tooltip } from 'antd';
import clsx from 'clsx';
import uniqueId from 'lodash/uniqueId';

interface IMonetizationTableProps {
  columnsData: string[][];
  versionData?: IVersionHistory[];
  labels: string[];
}

const MonetizationTable = ({ labels, columnsData, versionData }: IMonetizationTableProps) => {
  const exportExcel = useExportExcel({
    data: [labels, ...columnsData],
    fileName: 'seen_monetization',
    type: 'string',
  });

  return (
    <div className="monetization-table max-h-[84vh] w-full overflow-auto">
      <div className="flex justify-between p-2">
        <div></div>
        <CgSoftwareDownload className="text-26 cursor-pointer hover:text-secondary" onClick={exportExcel} />
      </div>
      <table>
        <thead className="sticky top-0">
          <tr>
            {labels.map((item) => (
              <th
                className={clsx('monetization-table-head bg-brown', monetizationHelper.renderBgColor(item))}
                key={uniqueId()}
              >
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {columnsData.map((item) => (
            <tr key={uniqueId()}>
              {item.map((cell, index) => {
                if (index === 0) {
                  const verData = versionData?.find((version) => version.date === cell);

                  return (
                    <Tooltip
                      title={verData ? `${verData.version} - ${verData.title} - ${verData.platform.toUpperCase()}` : ''}
                      arrow={false}
                    >
                      <td
                        className={clsx('monetization-table-cell', verData && 'text-primary font-medium')}
                        key={uniqueId()}
                      >
                        {verData ? CALENDAR_TEXT_ICON : ''} {cell}
                      </td>
                    </Tooltip>
                  );
                }

                return (
                  <td className="monetization-table-cell" key={uniqueId()}>
                    {cell}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MonetizationTable;
