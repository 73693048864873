import { useMemo, useState } from 'react';

import useWindowDimensions from 'hooks/useWindowDimensions';

import ChartContainer from 'components/shared/ChartContainer';

import PieChart from './PieChart';

import { Button, Form, Select } from 'antd';
import { useForm } from 'antd/es/form/Form';
import groupBy from 'lodash/groupBy';
import sumBy from 'lodash/sumBy';

interface Props {
  fields: string[];
  data: any[];
}

interface FormValues {
  filterField: string;
  valueField: string;
}

const DynamicPieChart = ({ fields, data }: Props) => {
  const [form] = useForm<FormValues>();
  const dimensions = useWindowDimensions();

  const [filterField, setFilterField] = useState<string>();
  const [valueField, setValueFields] = useState<string>();
  const [chartOptions, setChartOptions] = useState<FormValues>();

  const options = useMemo(() => {
    const items = fields
      .filter((item) => item !== filterField && valueField !== item)
      .map((item) => ({ label: item, value: item }));

    return items;
  }, [fields, filterField, valueField]);

  const chartData = useMemo(() => {
    if (!chartOptions || !chartOptions.filterField || !chartOptions.valueField) return [];

    // Group by filterField and Sum data by the valueField
    const filterFields = groupBy(data, chartOptions.filterField);
    const result = Object.keys(filterFields).map((key) => {
      return {
        [chartOptions.filterField]: key,
        value: sumBy(filterFields[key], valueField),
      };
    });
    return result;
  }, [chartOptions, data, valueField]);

  return (
    <div className="">
      <Form
        form={form}
        name="dynamic-pie-chart"
        layout={dimensions && dimensions?.width > 756 ? 'inline' : 'horizontal'}
        onFinish={(values) => {
          setChartOptions(values);
        }}
      >
        <Form.Item name="filterField" label="filterField">
          <Select
            options={options}
            onChange={(value) => {
              setFilterField(value);
            }}
            placeholder="Set filterField"
            style={{
              minWidth: 120,
              width: 'fit-content',
            }}
          />
        </Form.Item>

        <Form.Item name="valueField" label="valueField">
          <Select
            options={options.filter((item) => typeof data[0][item.value] === 'number')}
            onChange={(value) => {
              setValueFields(value);
            }}
            placeholder="Set valueField"
            style={{
              minWidth: 120,
              width: 'fit-content',
            }}
          />
        </Form.Item>

        <Form.Item>
          <Button
            size="small"
            style={{
              fontSize: 14,
              height: 30,
              padding: '0 10px',
              borderRadius: '6px',
              width: 'fit-content',
              textAlign: 'left',
            }}
            type="primary"
            htmlType="submit"
          >
            Create Chart
          </Button>
        </Form.Item>
      </Form>

      {chartOptions && (
        <div className="mt-4">
          <ChartContainer>
            <PieChart angleField="value" colorField={chartOptions.filterField} data={chartData} />
          </ChartContainer>
        </div>
      )}
    </div>
  );
};

export default DynamicPieChart;
