import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { BiTime, BiUser } from 'react-icons/bi';

import LayoutContextConsumer from 'contexts/LayoutContext';

import RawDataTable from 'components/feature/charts/RawDataTable';
import Loading from 'components/shared/Loading';

import skyDancer2Api from 'config/api/bigquery/skyDancer2Api';
import { IVersionHistory } from 'config/api/version-history/versionHistoryApi';
import numberHelper from 'config/helpers/numberHelper';

const { convertText } = numberHelper;

interface Props {
  rangeDate: {
    start?: string;
    end?: string;
  };
  versionHistoryData?: IVersionHistory[];
  version: string | null;
}

interface IAdsSteptimes {
  time: string
  platform: string
  DAU: number
  all_times_request: number
  all_users_request: number
  all_times_success: number
  all_users_success: number
  bronze_chest_ads_times_request: number
  bronze_chest_ads_users_request: number
  ads_coin_times_request: number
  ads_coin_users_request: number
  ads_gem_times_request: number
  ads_gem_users_request: number
  ads_stamina_times_request: number
  ads_stamina_users_request: number
  ads_reset_daily_deal_times_request: number
  ads_reset_daily_deal_users_request: number
  ads_refresh_skill_times_request: number
  ads_refresh_skill_users_request: number
  ads_daily_pack_times_request: number
  ads_daily_pack_users_request: number
  ads_gameplay_result_times_request: number
  ads_gameplay_result_users_request: number
  ads_boss_result_times_request: number
  ads_boss_result_users_request: number
  ads_quick_earning_times_request: number
  ads_quick_earning_users_request: number
  ads_undefined_times_request: number
  ads_undefined_users_request: number
}

const AdsItem = ({ users, value }: { users: string; value: string }) => {
  return (
    <div className="">
      <div className="flex items-center gap-1">
        <BiTime className="text-12" />
        <p className="text-14">{value}</p>
      </div>
      <div className="flex items-center gap-1 whitespace-nowrap">
        <BiUser className="text-12" />
        <p className="text-14">{users}</p>
      </div>
    </div>
  );
};

const { getAdsSteptimes } = skyDancer2Api;

const SkyAdsDetail = ({ rangeDate, versionHistoryData }: Props) => {
  const { platform } = LayoutContextConsumer();

  const { data: packData, isLoading: packLoading } = useQuery(['iap-pack', rangeDate, platform], async () => {
    if (!rangeDate.start || !rangeDate.end) return [];

    const res = await getAdsSteptimes({
      params: {
        platform: platform === 'unified' ? undefined : platform,
        start: rangeDate.start,
        end: rangeDate.end,
      },
    });
    console.debug('SkyAdsDetail', res);

    return res as unknown as IAdsSteptimes[];
  });

  const packTableData = useMemo(() => {
    return packData?.map((item) => {
      return [
        {
          value: item.time,
          users: null,
        },
        {
          value: item.platform,
          users: null,
        },
        {
          value: item.all_times_request,
          users: `${convertText(item.all_users_request)} (${((item.all_users_request / item.DAU) * 100).toFixed(1)}%)`,
        },
        {
          value: item.all_times_success,
          users: `${convertText(item.all_users_success)} (${((item.all_users_success / item.DAU) * 100).toFixed(1)}%)`,
        },
        {
          value: item.bronze_chest_ads_times_request,
          users: `${convertText(item.bronze_chest_ads_users_request)} (${(
            (item.bronze_chest_ads_users_request / item.DAU) *
            100
          ).toFixed(1)}%)`,
        },
        {
          value: item.ads_coin_times_request,
          users: `${convertText(item.ads_coin_users_request)} (${(
            (item.ads_coin_users_request / item.DAU) *
            100
          ).toFixed(1)}%)`,
        },
        {
          value: item.ads_gem_times_request,
          users: `${convertText(item.ads_gem_users_request)} (${((item.ads_gem_users_request / item.DAU) * 100).toFixed(
            1,
          )}%)`,
        },
        {
          value: item.ads_stamina_times_request,
          users: `${convertText(item.ads_stamina_users_request)} (${(
            (item.ads_stamina_users_request / item.DAU) *
            100
          ).toFixed(1)}%)`,
        },
        {
          value: item.ads_reset_daily_deal_times_request,
          users: `${convertText(item.ads_reset_daily_deal_users_request)} (${((item.ads_reset_daily_deal_users_request / item.DAU) * 100).toFixed(1)}%)`,
        },
        {
          value: item.ads_refresh_skill_times_request,
          users: `${convertText(item.ads_refresh_skill_users_request)} (${((item.ads_refresh_skill_users_request / item.DAU) * 100).toFixed(1)}%)`,
        },
        {
          value: item.ads_daily_pack_times_request,
          users: `${convertText(item.ads_daily_pack_users_request)} (${((item.ads_daily_pack_users_request / item.DAU) * 100).toFixed(1)}%)`,
        },
        {
          value: item.ads_gameplay_result_times_request,
          users: `${convertText(item.ads_gameplay_result_users_request)} (${((item.ads_gameplay_result_users_request / item.DAU) * 100).toFixed(1)}%)`,
        },
        {
          value: item.ads_boss_result_times_request,
          users: `${convertText(item.ads_boss_result_users_request)} (${((item.ads_boss_result_users_request / item.DAU) * 100).toFixed(1)}%)`,
        },
        {
          value: item.ads_quick_earning_times_request,
          users: `${convertText(item.ads_quick_earning_users_request)} (${((item.ads_quick_earning_users_request / item.DAU) * 100).toFixed(1)}%)`,
        },
        {
          value: item.ads_undefined_times_request,
          users: `${convertText(item.ads_undefined_users_request)} (${(
            (item.ads_undefined_users_request / item.DAU) *
            100
          ).toFixed(1)}%)`,
        },
      ];
    });
  }, [packData]);

  const isLoading = packLoading;

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex gap-8 flex-wrap">
          {packTableData && packTableData.length > 0 && (
            <RawDataTable
              isCenterFull
              title="Ads Steptimes"
              headers={[
                'Date',
                'Platform',
                'Total Request',
                'Total Success',
                'Bronze Chest',
                'Coin',
                'Gem',
                'Stamina',
                'Reset Daily Deal',
                'Refresh Skill',
                'Daily Pack',
                'Gameplay Result',
                'Boss Result',
                'Quick Earning',
                'Undefined',
              ]}
              data={packTableData.map((item) => {
                return item.map((subItem) => {
                  if (subItem.users) {
                    return <AdsItem users={subItem.users} value={subItem.value as unknown as string} />;
                  } else {
                    return subItem.value;
                  }
                });
              })}
              rawData={packData}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default SkyAdsDetail;
