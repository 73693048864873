import React from 'react';
import { FiChevronDown } from 'react-icons/fi';

import LayoutContextConsumer from 'contexts/LayoutContext';

import { IApp } from 'config/api/apps/appApi';
import { PRIMARY_COLOR } from 'config/constants/theme';
import toastHelper from 'config/helpers/toastHelper';

import { SearchOutlined } from '@ant-design/icons';
import { Input, Popover } from 'antd';

interface IHeaderSelectPopoverProps {
  apps: IApp[];
  handleSearch: (value: string) => void;
  setCurrentApp: (app: IApp | null) => void;
}

interface IHeaderSelectAppProps {
  isDrawer?: boolean;
}

const HeaderSelectPopover = ({ apps, handleSearch, setCurrentApp }: IHeaderSelectPopoverProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    handleSearch(value);
  };

  return (
    <div className="w-[270px]">
      <div className="p-1">
        <Input prefix={<SearchOutlined />} onChange={handleChange} />
      </div>
      {apps.map((item) => (
        <div
          className="flex cursor-pointer items-center gap-2 rounded-lg p-1 pr-2 text-text-heading hover:text-primary"
          onClick={() => {
            if (item.status) {
              sessionStorage.setItem('currentApp', item.id);
              setCurrentApp(item);
            } else {
              toastHelper.info('Coming soon');
            }
          }}
          key={item.id}
        >
          {item?.icon ? (
            <img className="h-8 w-8 rounded-md" src={item.icon} alt={item.name} />
          ) : (
            <div className="h-8 min-h-[32px] w-8 min-w-[32px] rounded-md bg-grey2"></div>
          )}

          <span className="max-w-[240px] font-sans text-14 font-normal">{item.name}</span>
        </div>
      ))}
    </div>
  );
};

const HeaderSelectApp = ({ isDrawer = false }: IHeaderSelectAppProps) => {
  const { currentApp, search, handleSearch, setCurrentApp } = LayoutContextConsumer();

  return (
    currentApp && (
      <Popover
        content={<HeaderSelectPopover apps={search} handleSearch={handleSearch} setCurrentApp={setCurrentApp} />}
        placement="bottomRight"
        trigger={['click']}
      >
        <div
          className="flex cursor-pointer items-center gap-2 rounded-lg p-1 pr-2 text-white"
          style={{
            backgroundColor: isDrawer ? PRIMARY_COLOR : 'rgba(0, 0, 0, 0.15)',
          }}
        >
          {currentApp?.icon ? (
            <img className="h-8 w-8 rounded-md" src={currentApp.icon} alt={currentApp.name} />
          ) : (
            <div className="h-8 min-h-[32px] w-8 min-w-[32px] rounded-md bg-grey2"></div>
          )}

          <span className="max-w-[240px] font-sans text-14 font-[400] text-white">{currentApp.name}</span>

          <FiChevronDown size={18} />
        </div>
      </Popover>
    )
  );
};

export default HeaderSelectApp;
