import React from 'react';

import LayoutContextConsumer from 'contexts/LayoutContext';

import useWindowDimensions from 'hooks/useWindowDimensions';

import type { Platform } from 'config/constants/platform';

import { Radio } from 'antd';

const AppSelect = () => {
  const dimension = useWindowDimensions();
  const { platform, setPlatform, currentApp } = LayoutContextConsumer();

  return (
    <Radio.Group
      value={platform}
      onChange={(e) => {
        setPlatform(e.target.value as Platform);
      }}
      buttonStyle="solid"
      size={dimension && dimension.width < 450 ? 'small' : 'middle'}
    >
      <Radio.Button value="ios" disabled={currentApp?.appstore == undefined}>App store</Radio.Button>
      <Radio.Button value="android" disabled={currentApp?.ggplay == undefined}>Google Play</Radio.Button>
      <Radio.Button value="unified">All</Radio.Button>
    </Radio.Group>
  );
};

export default AppSelect;
