import React from 'react';
import { FiLogOut, FiMail, FiSearch } from 'react-icons/fi';
import { RiUserSettingsLine } from 'react-icons/ri';
import { Link, useLocation } from 'react-router-dom';

import LayoutContextConsumer from 'contexts/LayoutContext';

import AuthConsumer from 'hooks/useAuth';
import useWindowDimensions from 'hooks/useWindowDimensions';

import userRole from 'config/constants/userRole';

import Avatar from 'components/shared/Avatar';
import Logo from 'components/shared/Logo';

import HeaderSelectApp from './HeaderSelectApp';

import { MenuOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Popover } from 'antd';
import clsx from 'clsx';

interface IAvatarProps {
  email: string | null;
  signOut: () => void;
}

interface IHeaderSearchProps {
  handleSearch: (value: string) => void;
}

interface IHeaderProps {
  setSearchDrawer: (value: boolean) => void;
  setSidebarDrawer: (value: boolean) => void;
}

const AvatarPopoverContent = ({ email, signOut }: IAvatarProps) => {
  const { role } = AuthConsumer();

  return (
    <div className="flex flex-col items-start">
      <div className="flex items-center gap-4 p-2">
        <FiMail size={16} />
        <span>{email}</span>
      </div>

      {role === userRole.SUPERADMIN && (
        <Link
          to={'/user-setting'}
          className="flex w-full cursor-pointer items-center gap-4 rounded-lg p-2 text-secondary hover:bg-bg-grey"
        >
          <RiUserSettingsLine size={16} />
          <span>User Setting</span>
        </Link>
      )}

      <div
        className="flex w-full cursor-pointer items-center gap-4 rounded-lg p-2 text-primary hover:bg-bg-grey"
        onClick={signOut}
      >
        <FiLogOut size={16} />
        <span>Sign Out</span>
      </div>
    </div>
  );
};

const HeaderSearch = ({ handleSearch }: IHeaderSearchProps) => {
  return (
    <div className="flex min-w-[330px] items-center gap-4 rounded-lg bg-bg-red-dark p-2 text-white">
      <FiSearch size={24} />
      <input
        type="text"
        placeholder="Search"
        className="border-none bg-transparent font-sans text-base leading-[24px] text-white outline-none placeholder:font-sans placeholder:text-white"
        onChange={(e) => {
          handleSearch(e.target.value);
        }}
      />
    </div>
  );
};

const Header = ({ setSearchDrawer, setSidebarDrawer }: IHeaderProps) => {
  const { signOut, currentUser } = AuthConsumer();

  const { currentApp, handleSearch } = LayoutContextConsumer();

  const dimension = useWindowDimensions();
  const { pathname } = useLocation();

  return (
    <div className="flex h-[76px] w-full items-center justify-between bg-bg-red px-3 md:px-9">
      <Link to="/apps" className="flex-1">
        <Logo height={dimension && dimension.width > 768 ? 50 : 36} type="white" />
      </Link>

      <div
        className={clsx(
          'flex flex-1 gap-3 sm:gap-[50px]',
          (dimension && dimension.width > 768) || pathname.includes('/apps') ? 'justify-end' : '',
        )}
      >
        {dimension && dimension.width > 768 ? (
          currentApp && !pathname.includes('/apps') ? (
            <HeaderSelectApp />
          ) : (
            <HeaderSearch handleSearch={handleSearch} />
          )
        ) : null}

        {dimension && dimension.width < 768 && currentApp && pathname.includes('/apps') && (
          <Button
            shape="circle"
            icon={<SearchOutlined />}
            type="primary"
            className="bg-bg-red-dark"
            onClick={() => {
              setSearchDrawer(true);
            }}
          />
        )}

        {currentUser && currentUser.photoURL && (
          <Popover
            content={<AvatarPopoverContent signOut={signOut} email={currentUser.email} />}
            placement={'bottomRight'}
            arrow={true}
            trigger={'click'}
          >
            <div className="">
              <Avatar size={42} imgUrl={currentUser.photoURL} />
            </div>
          </Popover>
        )}
      </div>

      {dimension && dimension.width < 768 && currentApp && !pathname.includes('/apps') && (
        <Button
          shape="circle"
          icon={<MenuOutlined />}
          type="primary"
          className="bg-bg-red-dark"
          onClick={() => {
            setSidebarDrawer(true);
          }}
        />
      )}
    </div>
  );
};

export default Header;
