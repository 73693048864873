// import { useNavigate } from 'react-router-dom';
import LayoutContextConsumer from 'contexts/LayoutContext';

import useDocumentTitle from 'hooks/useDocumentTitle';

import ComingSoonPage from 'pages/main/ComingSoonPage';


import { funnelOpt } from 'config/constants/funnel';
import { ConfigProvider, DatePicker, Select } from 'antd';
import { GREY_COLOR_2, SECONDARY_COLOR } from 'config/constants/theme';
import dayjs from 'dayjs';
import { useQuery } from '@tanstack/react-query';
import Loading from 'components/shared/Loading';
import { useMemo, useState } from 'react';
import mimilandApi from 'config/api/bigquery/mimilandApi';
import { groupBy, sumBy } from 'lodash';
import LineChart from 'components/feature/charts/LineChart';
import Widget from 'components/shared/Widget';
import { IoEyeSharp } from 'react-icons/io5';
import { MdAdsClick } from 'react-icons/md';
import { FaPersonChalkboard } from "react-icons/fa6";
import { BRANDS } from 'config/constants/branding';
const { getMimilandBrandingSummary } = mimilandApi;

const BrandingPage = () => {
  useDocumentTitle('Branding');
  // const navigate = useNavigate();
  const { currentApp } = LayoutContextConsumer();

  if (!currentApp?.id || !Object.keys(funnelOpt).includes(currentApp.id)) {
    return <ComingSoonPage />;
  }
  if (currentApp?.id !== "Mimiland") return <ComingSoonPage />;

  return (
    <div className="mb-10 w-full ">
      <section className="mb-5">
        <h3 className="page-section-title">Branding </h3>
        <p className="page-section-subtitle">
          Partners data analytics.
        </p>
        <BrandingContent />
      </section>

    </div>
  );
};

export default BrandingPage;

export interface BrandingSummary {
  interactions: Interaction[]
  impressions: Impression[]
}

export interface Interaction {
  time: string
  date_str: string
  platform: string
  version: any
  partner: string
  collect_item_times: number
  collect_item_users: number
  click_billboard_times: number
  click_billboard_users: number
  click_store_npc_times: number
  click_store_npc_users: number
  open_website_times: number
  open_website_users: number
  exchange_gifts_times: number
  exchange_gifts_users: number
}

export interface Impression {
  time: string
  date_str: string
  platform: string
  version: any
  partner: string
  ads_times: number
  ads_users: number
  heart_ads_times: number
  heart_ads_users: number
  billboard_ads_times: number
  billboard_ads_users: number
  store_ads_times: number
  store_ads_users: number
}


const BrandingContent = () => {
  const { platform } = LayoutContextConsumer();
  const [filter, setFilter] = useState({
    start: dayjs().subtract(7, 'day'),
    end: dayjs(),
  });
  const [partner, setPartner] = useState(BRANDS[0].value);

  const { data: rawData, isLoading, isError } = useQuery(
    ['guardian-summary', filter, platform],
    async () => {
      const res = await getMimilandBrandingSummary({
        start: filter.start.format('YYYY-MM-DD'),
        end: filter.end.format('YYYY-MM-DD'),
        platform,
      }) as unknown as BrandingSummary;
      return res;
      // sum by same 'time'
      // const groups = groupBy(res, 'time');
      // const sumData = Object.keys(groups).map((key) => {
      //   const group = groups[key];
      //   return {
      //     time: key,
      //     to_guardian_store_times: sumBy(group, 'to_guardian_store_times'),
      //     click_billboard_times: sumBy(group, 'click_billboard_times'),
      //     gui_pass_show_times: sumBy(group, 'gui_pass_show_times'),
      //     gui_lb_show_times: sumBy(group, 'gui_lb_show_times'),
      //     collect_item_times: sumBy(group, 'collect_item_times'),
      //     heart_users: sumBy(group, 'heart_ads_users'),
      //     billboard_users: sumBy(group, 'billboard_ads_users'),
      //     store_users: sumBy(group, 'store_ads_users'),
      //     Heart: sumBy(group, 'heart_ads_times'),
      //     Billboard: sumBy(group, 'billboard_ads_times'),
      //     Store: sumBy(group, 'store_ads_times'),
      //   };
      // });

      // const chartData = ["Heart", "Billboard", "Store"].map((key) => {
      //   return sumData.map(d => ({
      //     tag: key,
      //     time: d.time,
      //     value: d[key as keyof typeof d]
      //   }))
      // }).flat();

      // const webEngagement = sumData.reduce((acc, cur) => acc + cur.to_guardian_store_times, 0);
      // const totalImpressions = sumData.reduce((acc, cur) => acc + cur.Heart + cur.Billboard + cur.Store, 0);
      // const totalUsers = sumData.reduce((acc, cur) => acc + cur.heart_users + cur.billboard_users + cur.store_users, 0);
      // const totalInteractions = sumData.reduce(
      //   (acc, cur) => acc +
      //     cur.click_billboard_times +
      //     cur.gui_pass_show_times +
      //     cur.gui_lb_show_times +
      //     cur.to_guardian_store_times +
      //     cur.collect_item_times
      //   , 0);

      // return { chartData, totalImpressions, webEngagement, totalUsers, totalInteractions };

    },
    {
      refetchOnWindowFocus: false,
      retry: 1,
      retryDelay: 0,
    },
  );
  const data = useMemo(() => {
    if (rawData === undefined) return undefined;
    const rawImpressions = rawData.impressions.filter((d) => d.partner === partner);
    const impressionsGroup = groupBy(rawImpressions, 'time');
    console.debug(impressionsGroup);
    const impressionsData = Object.keys(impressionsGroup).map((key) => {
      const group = impressionsGroup[key];
      return {
        time: key,
        Billboard: sumBy(group, 'billboard_ads_times'),
        Store: sumBy(group, 'store_ads_times'),
      };
    });

    const chartData = ["Billboard", "Store"].map((key) => {
      return impressionsData.map(d => ({
        tag: key,
        time: d.time,
        value: d[key as keyof typeof d]
      }))
    }).flat();

    const totalImpressions = sumBy(impressionsData, (v) => v.Billboard + v.Store);

    const rawInteractions = rawData.interactions.filter((d) => d.partner === partner);
    const totalInteractions = sumBy(rawInteractions, (v) => {
      return v.collect_item_times + v.click_billboard_times + v.click_store_npc_times + v.exchange_gifts_times
    })
    const webEngagement = sumBy(rawInteractions, 'open_website_times');

    return { chartData, totalImpressions, webEngagement, totalInteractions };

  }, [rawData, partner]);
  console.debug(partner)
  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: SECONDARY_COLOR,
            colorBorder: GREY_COLOR_2,
          },
        }}
      >
        <div className="flex flex-wrap items-end gap-6">
          <div className="">
            <h6 className="mb-2 text-16 font-[500]">Start</h6>
            <DatePicker value={filter.start} onChange={(d) => setFilter({ ...filter, start: d ?? dayjs() })}></DatePicker>
          </div>
          <div className="">
            <h6 className="mb-2 text-16 font-[500]">End</h6>
            <DatePicker value={filter.end} onChange={(d) => setFilter({ ...filter, end: d ?? dayjs() })}></DatePicker>
          </div>
          <div className="">
            <h6 className="mb-2 text-16 font-[500]">Partner</h6>
            <Select value={partner} onChange={(v) => setPartner(v)} options={BRANDS} />
          </div>
        </div>
      </ConfigProvider>
      <h3 className="mt-8 text-xl font-bold">Overview</h3>

      <div className='gap-8 w-full mt-4 grid xl:grid-cols-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3'>
        {data && (
          <>
            <Widget
              icon={<IoEyeSharp size={24} />}
              label='Total Impressions'
              number={data?.totalImpressions ?? -1}
              description="Number of views of the brand on screen."
            />
            <Widget
              icon={<FaPersonChalkboard size={24} />}
              label='Total Interactions'
              number={data?.totalInteractions ?? NaN}
              description='Views of banners introducing events related to the brand.'
            />
            <Widget icon={<MdAdsClick size={24} />} label='Web engage' number={data?.webEngagement ?? NaN} description='Number of visits to brand website.' />
          </>)
        }
      </div>
      <div className="mt-6">
        {isError && <div>Error</div>}
        {isLoading && <Loading />}
        {(
          <div className="w-full overflow-auto">
            <div className='w-full'>
              <h3 className="mb-8 text-xl font-bold">Views</h3>
              <LineChart data={data?.chartData ?? []} xField="time" yField="value" seriesField='tag' className="w-full" />
            </div>
          </div>
        )}
      </div>
    </>
  );
};
