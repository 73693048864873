import React from 'react';
import { BsPlusSquareFill } from 'react-icons/bs';
import { Link, useNavigate } from 'react-router-dom';

import LayoutContextConsumer from 'contexts/LayoutContext';

import useDocumentTitle from 'hooks/useDocumentTitle';

import { IApp } from 'config/api/apps/appApi';
import { BG_RED_COLOR } from 'config/constants/theme';

import AppGridItem from 'components/feature/app/AppGridItem';
import Loading from 'components/shared/Loading';

import { Col, Empty, Row } from 'antd';

const ListApp = () => {
  useDocumentTitle('Apps');
  const { isLoading, search, isGameError } = LayoutContextConsumer();
  const navigate = useNavigate();

  const handleSelectApp = (id: string) => {
    sessionStorage.setItem('currentApp', id);
    navigate(`/`);
  };

  return (
    <div className="px-2 text-left  sm:px-3">
      <Link to={'add'} className="flex w-fit cursor-pointer items-center gap-5 px-1 no-underline">
        <h4 className="text-[26px] text-text-heading">App</h4>
        <BsPlusSquareFill size={24} color={BG_RED_COLOR} />
      </Link>
      {isLoading ? (
        <div className="flex min-h-[50vh] items-center justify-center">
          <Loading />
        </div>
      ) : (
        <Row
          className="mt-6"
          gutter={[
            {
              xs: 12,
              sm: 18,
              md: 24,
              lg: 36,
            },
            {
              xs: 18,
              sm: 24,
              md: 30,
            },
          ]}
        >
          {search.length > 0 && !isGameError ? (
            search
              .sort((a: IApp, b: IApp) => (a.status && b.status ? 1 : 0))
              .map((item) => (
                <Col key={item?.id} span={24} sm={12} lg={8} xxl={6}>
                  <AppGridItem {...item} handleSelectApp={handleSelectApp} />
                </Col>
              ))
          ) : (
            <div className="mt-4 flex w-full justify-center">
              <Empty description="No Games Found" />
            </div>
          )}
        </Row>
      )}
    </div>
  );
};

export default ListApp;
