import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

export interface IFilter {
  metric: string;
  category: string;
  country: string;
}

export type ICategory = {
  value: string;
  label: string;
};

type State = {
  filter: IFilter;
  categories: ICategory[];
  modalOpen: boolean;
};

type Actions = {
  setFilter: (filter: IFilter) => void;
  setCategories: (categories: ICategory[]) => void;
  setModalOpen: (modalOpen: boolean) => void;
};

export const useExploreStore = create(
  persist<State & Actions>(
    (set) => ({
      filter: {
        metric: 'engagement',
        category: 'retention',
        country: 'All',
      },
      modalOpen: false,
      categories: [],
      setFilter: (filter) => set({ filter }),
      setCategories: (categories) => set({ categories }),
      setModalOpen: (modalOpen) => set({ modalOpen }),
    }),
    {
      name: 'explore-zustand',
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);
