import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import useDocumentTitle from 'hooks/useDocumentTitle';

import FunnelStep from 'components/feature/funnels/FunnelStep';
import FunnelStepHeader from 'components/feature/funnels/FunnelStepHeader';

import { ArrowLeftOutlined, CaretRightOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Collapse } from 'antd';
import { isArray, uniqueId } from 'lodash';

export interface IStep {
  id: string;
  order?: number;
  name: string;
  query: IQuery[];
}

export interface IQuery {
  id: string;
  method: string;
  params: string;
  op: string;
  value: string;
  logic?: 'AND' | 'OR';
}

const stepInit: IStep[] = [
  {
    id: '1',
    order: 1,
    name: 'Step 1',
    query: [],
  },
  {
    id: '2',
    order: 2,
    name: 'Step 2',
    query: [],
  },
];

const { Panel } = Collapse;

const FunnelsCreatePage = () => {
  useDocumentTitle('Create Funnel');
  const [listStep, setListStep] = useState<IStep[]>(stepInit);

  const addStep = () => {
    const newStep: IStep = {
      id: uniqueId(),
      name: `Step ${listStep.length + 1}`,
      query: [],
    };

    setListStep(
      [...listStep, newStep].map((step, index) => ({ ...step, order: index + 1, name: `Step ${index + 1}` })),
    );
  };

  const delStep = (id: string) => {
    const newListStep = listStep.filter((step) => step.id !== id);

    setListStep(newListStep.map((step, index) => ({ ...step, order: index + 1, name: `Step ${index + 1}` })));
  };

  const copyStep = (id: string) => {
    const newStep = {
      id: uniqueId(),
      name: `Step ${listStep.length + 1}`,
      query: [],
    };

    setListStep(
      [...listStep, newStep].map((step, index) => ({ ...step, order: index + 1, name: `Step ${index + 1}` })),
    );
  };

  return (
    <div className="">
      <Link
        to={'/funnels/list'}
        className="mb-5 block w-fit cursor-pointer rounded-lg bg-[#C1C1C1] py-1 px-2 font-sans text-14 text-white hover:text-white"
      >
        <ArrowLeftOutlined className="mr-2" />
        Funnel
      </Link>
      <div className="flex flex-col gap-6">
        {isArray(listStep) && listStep.length > 0 && (
          <Collapse
            ghost
            defaultActiveKey={[listStep[0].id]}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined
                style={{ fontSize: '18px', height: '100%', marginTop: '6px' }}
                rotate={isActive ? 90 : 0}
              />
            )}
          >
            {listStep.map((step, index) => (
              <Panel header={<FunnelStepHeader {...step} tools={{ addStep, delStep, copyStep }} />} key={step.id}>
                <FunnelStep key={index} {...step} tools={{ addStep, delStep, copyStep }} />
              </Panel>
            ))}
          </Collapse>
        )}

        <Button
          icon={<PlusOutlined />}
          size="small"
          className="ml-3 w-fit"
          type="primary"
          style={{ fontSize: 14 }}
          onClick={addStep}
        >
          Add query
        </Button>
      </div>
    </div>
  );
};

export default FunnelsCreatePage;
