import { useMutation } from '@tanstack/react-query';
import { useEffect } from 'react';

import versionHistoryApi, { IVersionHistory } from 'config/api/version-history/versionHistoryApi';
import toastHelper from 'config/helpers/toastHelper';

import { Button, DatePicker, Form, Input, Modal, Radio } from 'antd';
import dayjs from 'dayjs';

interface IVersionHistoryModalProps {
  visible: boolean;
  onClose: () => void;
  isEdit?: boolean;
  data?: IVersionHistory;
  refetch: () => void;
}

const { add, update, deleteVersion } = versionHistoryApi;

const VersionHistoryModal = ({ visible, onClose, refetch, isEdit, data }: IVersionHistoryModalProps) => {
  const [form] = Form.useForm();

  const { mutate: submitData } = useMutation({
    mutationKey: ['version-history', data, isEdit, visible],
    mutationFn: async (data: IVersionHistory) => {
      if (isEdit && data && data.id) {
        return update(data, data.id);
      }
      return add(data);
    },
    onSuccess: () => {
      refetch();
      onClose();
      form.resetFields();
      toastHelper.success(isEdit && data && data.id ? 'Updated' : 'Added');
    },
    onError: () => {
      toastHelper.error(isEdit && data && data.id ? 'Update failed' : 'Add failed');
    },
    onMutate: () => {
      toastHelper.loading(isEdit && data && data.id ? 'Updating' : 'Adding...');
    },
    retry: false,
  });

  const { mutate: deleteVersionHistory } = useMutation({
    mutationFn: async (id: string) => {
      return deleteVersion(id);
    },
    onSuccess: () => {
      refetch();
      onClose();
      form.resetFields();
      toastHelper.success('Deleted');
    },
    onError: () => {
      toastHelper.error('Delete failed');
    },
    onMutate: () => {
      toastHelper.loading('Deleting...');
    },
  });

  useEffect(() => {
    if (isEdit && data) {
      form.setFieldsValue({
        version: data.version,
        title: data.title,
        description: data.description,
        date: dayjs(data.date),
        platform: data.platform,
      });
    } else {
      form.resetFields();
    }
  }, [isEdit, data, form]);

  return (
    <Modal open={visible} onCancel={onClose} footer={null} title="Add new version">
      <Form
        form={form}
        layout="vertical"
        onFinish={(values) => {
          submitData({
            ...data,
            ...values,
          });
        }}
        autoComplete="off"
      >
        <Form.Item
          name="version"
          label="Version"
          rules={[
            {
              required: true,
              message: 'Please input version',
            },
          ]}
        >
          <Input placeholder="v0.0.0" />
        </Form.Item>
        <Form.Item
          name="title"
          label="Title"
          rules={[
            {
              required: true,
              message: 'Please input title',
            },
          ]}
        >
          <Input placeholder="New update..." />
        </Form.Item>
        <Form.Item name="description" label="Description">
          <Input.TextArea
            autoSize={{
              minRows: 5,
            }}
            placeholder="What's new?"
          />
        </Form.Item>
        <Form.Item
          name="platform"
          label="Platform"
          rules={[
            {
              required: true,
              message: 'Please select platform',
            },
          ]}
        >
          <Radio.Group>
            <Radio value="ios">IOS</Radio>
            <Radio value="android">Android</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="date"
          label="Date"
          rules={[
            {
              required: true,
              message: 'Please input date',
            },
          ]}
        >
          <DatePicker />
        </Form.Item>

        <div className="flex justify-between gap-2 mt-2">
          {isEdit && data && (
            <Button size="small" danger onClick={() => deleteVersionHistory(data.id)}>
              Delete
            </Button>
          )}
          <div className="flex gap-2 ml-auto">
            <Button
              size="small"
              onClick={() => {
                form.resetFields();
                onClose();
              }}
            >
              Cancel
            </Button>
            <Form.Item>
              <Button size="small" type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default VersionHistoryModal;
