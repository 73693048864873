export type FilterGroup = 'retention' | 'revenue' | 'session' | 'store';

export interface IFilterData {
  key: string;
  label: string;
  group: FilterGroup;
  disabled?: boolean;
}

export const bgColor = {
  retention: 'bg-secondary',
  revenue: 'bg-brown',
  session: 'bg-green',
  store: 'bg-purple',
};

export const filterData: IFilterData[] = [
  {
    key: 'DAU',
    label: 'DAU',
    group: 'retention',
  },
  {
    key: 'new-user',
    label: 'New Users',
    group: 'retention',
  },
  {
    key: 'DAU7',
    label: 'DAU7',
    group: 'retention',
    disabled: true,
  },
  {
    key: 'DAU30',
    label: 'DAU30',
    group: 'retention',
    disabled: true,
  },
  {
    key: 'RR1',
    label: 'RR1',
    group: 'retention',
  },
  {
    key: 'RR3',
    label: 'RR3',
    group: 'retention',
  },
  {
    key: 'RR7',
    label: 'RR7',
    group: 'retention',
  },
  {
    key: 'RR15',
    label: 'RR15',
    group: 'retention',
  },
  {
    key: 'RR30',
    label: 'RR30',
    group: 'retention',
  },
  {
    key: 'paying-user',
    label: 'Paying User',
    group: 'revenue',
    disabled: true,
  },
  // {
  //   key: 'ARPDAU',
  //   label: 'ARPDAU',
  //   group: 'revenue',
  // },

  {
    key: 'playing-time',
    label: 'Playing Time',
    group: 'session',
    disabled: true,
  },
  {
    key: 'session-p-user',
    label: 'Session per user',
    group: 'session',
    disabled: true,
  },

  {
    key: 'store-impressions',
    label: 'Store Impressions',
    group: 'store',
    disabled: true,
  },
  {
    key: 'product-page-views',
    label: 'Product Page Views',
    group: 'store',
    disabled: true,
  },
  {
    key: 'store-conversion',
    label: 'Store Conversion',
    group: 'store',
    disabled: true,
  },
];
