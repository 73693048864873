import React, { useEffect } from 'react';

interface IChartContainer {
  children: React.ReactNode;
  isLoading?: boolean;
}

const ChartContainer = ({ children }: IChartContainer) => {
  const containerRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Set the scrollbar to the end on component mount

    if (containerRef.current) {
      console.log('Scroll', containerRef.current.scrollWidth);
      setTimeout(() => {
        containerRef.current?.scrollTo({
          left: containerRef.current?.scrollWidth,
          behavior: 'smooth',
        });
      }, 0); // del
    }
  }, []);

  return (
    <div ref={containerRef} className="rounded-[20px] bg-white py-9 px-3 relative overflow-auto">
      {children}
    </div>
  );
};

export default ChartContainer;
