import { useCallback } from 'react';

import * as XLSX from 'xlsx';

interface Props {
  data: any;
  fileName: string;
  type: 'json' | 'string';
}

const useExportExcel = ({ data, fileName, type }: Props) => {
  const exportExcel = useCallback(() => {
    if (!data) return;

    const ws = type === 'json' ? XLSX.utils.json_to_sheet(data) : XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  }, [data, fileName, type]);

  return exportExcel;
};

export default useExportExcel;
