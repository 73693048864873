import React from 'react';

import { GREY_COLOR_2, SECONDARY_COLOR } from 'config/constants/theme';

import { ConfigProvider, Select, SelectProps } from 'antd';

const CusSelect = (props: SelectProps) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: SECONDARY_COLOR,
          colorBorder: GREY_COLOR_2,
        },
      }}
    >
      <Select {...props} />
    </ConfigProvider>
  );
};

export default CusSelect;
