import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { BiTime, BiUser } from 'react-icons/bi';

import LayoutContextConsumer from 'contexts/LayoutContext';

import RawDataTable from 'components/feature/charts/RawDataTable';
import Loading from 'components/shared/Loading';

import daymareZeroApi from 'config/api/bigquery/daymareZeroApi';
import { IVersionHistory } from 'config/api/version-history/versionHistoryApi';

interface Props {
  rangeDate: {
    start?: string;
    end?: string;
  };
  versionHistoryData?: IVersionHistory[];
  version: string | null;
  country: string;
}

interface IStage_GroupByStage {
  world: number;
  daily_users: number;
  start_times: number,
  start_users: number,
  win_times: number,
  win_users: number,
  lose_times: number,
  lose_users: number,
  quit_times: number,
  quit_users: number
}

const { getStage_GroupByStage } = daymareZeroApi;

const ItemToCell = ({ users, value }: { users: number; value: number }) => {
  return (
    <div className="">
      <div className="flex items-center gap-1">
        <BiTime className="text-12" />
        <p className="text-14">{value}</p>
      </div>
      <div className="flex items-center gap-1 whitespace-nowrap">
        <BiUser className="text-12" />
        <p className="text-14">{users}</p>
      </div>
    </div>
  );
};



const DaymareStage_GroupByStage = ({ rangeDate, versionHistoryData, version, country }: Props) => {
  const { platform } = LayoutContextConsumer();

  const { data: stageData, isLoading: stageLoading } = useQuery(
    [version, rangeDate, country, platform],
    async () => {
      if (!rangeDate.start || !rangeDate.end) return [];

      const res = await getStage_GroupByStage({
        params: {
          version,
          platform: platform === 'unified' ? undefined : platform,
          start: rangeDate.start,
          end: rangeDate.end,
          country: country !== 'All' ? country : undefined,
          //includeCountryInResp: 'true',
        },
      });

      return res as unknown as IStage_GroupByStage[];
    },
  );

  const tableData = useMemo(() => {
    return stageData?.map((item) => {
      return [
        {
          value: item.world,
          users: null,
          usersRaw: null,
        },
        {
          value: item.start_times,
          users: item.start_users,
          usersRaw: item.start_users,
        },
        {
          value: item.win_times,
          users: item.win_users,
          usersRaw: item.win_users,
        },
        {
          value: item.lose_times,
          users: item.lose_users,
          usersRaw: item.lose_users,
        },
        {
          value: item.quit_times,
          users: item.quit_users,
          usersRaw: item.quit_users,
        },
      ];
    });
  }, [stageData]);

  const isLoading = stageLoading;

  return (
    <div className="w-full">
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex gap-8 flex-wrap">
            {tableData && tableData.length > 0 && (
            <RawDataTable
              isCenterFull
              title="Steps"
              headers={[
                'Stage',
                'Start',
                'Win',
                'Lose',
                'Quit',
                ]}
                data={tableData.map((item) => {
                return item.map((subItem) => {
                  if (subItem.users) {
                    return <ItemToCell users={subItem.users} value={subItem.value} />;
                  } else {
                    return subItem.value;
                  }
                });
              })}
              rawData={stageData}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default DaymareStage_GroupByStage;
