import React, { useMemo } from 'react';

import DashboardRetention from 'components/feature/dashboard/DashboardRetention';
import { IExploreFilterUserData, IExploreRetentionData } from 'components/feature/explore/ExploreEngagement';

import { IVersionHistory } from 'config/api/version-history/versionHistoryApi';
import { CALENDAR_TEXT_ICON } from 'config/constants/general';
import { GREY_COLOR, PRIMARY_COLOR } from 'config/constants/theme';

import LineChart from '../charts/LineChart';

type IExploreChartProps = {
  title?: string;
  data: IExploreRetentionData[] | IExploreFilterUserData[];
  category: string;
  versionData?: IVersionHistory[];
};

const ExploreChart = ({ title, data, category, versionData }: IExploreChartProps) => {
  const chart = useMemo(() => {
    if (category === 'retention') {
      return <DashboardRetention data={data as IExploreRetentionData[]} versionData={versionData} />;
    } else if (category === 'new-users') {
      const chartData = data as IExploreFilterUserData[];
      return (
        <LineChart
          data={
            chartData
              ? chartData.map((data) => ({
                  period: data.period,
                  value: data.newUsers,
                }))
              : []
          }
          xField="period"
          yField="value"
          height={330}
          xAxis={{
            label: {
              style(_, index, items) {
                const date = items[index].id;
                const verData = versionData?.find((version) => version.date === date);

                return {
                  fill: verData ? PRIMARY_COLOR : GREY_COLOR,
                  fontWeight: verData ? 600 : 400,
                };
              },
              formatter(text) {
                const verData = versionData?.find((version) => version.date === text);
                return `${verData ? CALENDAR_TEXT_ICON : ''}${text}`;
              },
              autoRotate: true,
            },
            range: [0, 1],
          }}
        />
      );
    } else if (category === 'returning-users') {
      const chartData = data as IExploreFilterUserData[];
      return (
        <LineChart
          data={
            chartData
              ? chartData.map((data) => ({
                  period: data.period,
                  value: data.returningUsers,
                }))
              : []
          }
          xField="period"
          yField="value"
          xAxis={{
            label: {
              style(_, index, items) {
                const date = items[index].id;
                const verData = versionData?.find((version) => version.date === date);

                return {
                  fill: verData ? PRIMARY_COLOR : GREY_COLOR,
                  fontWeight: verData ? 600 : 400,
                };
              },
              formatter(text) {
                const verData = versionData?.find((version) => version.date === text);
                return `${verData ? CALENDAR_TEXT_ICON : ''}${text}`;
              },
              autoRotate: true,
            },
            range: [0, 1],
          }}
          height={330}
        />
      );
    } else if (category === 'users-new-returning') {
      const chartData = data as IExploreFilterUserData[];

      return (
        <LineChart
          data={
            chartData
              ? [
                  ...chartData.map((item) => {
                    return {
                      period: item.period,
                      value: item.newUsers,
                      category: 'New Users',
                    };
                  }),
                  ...chartData.map((item) => {
                    return {
                      period: item.period,
                      value: item.returningUsers,
                      category: 'Returning Users',
                    };
                  }),
                ]
              : []
          }
          xField="period"
          yField="value"
          seriesField="category"
          xAxis={{
            label: {
              style(_, index, items) {
                const date = items[index].id;
                const verData = versionData?.find((version) => version.date === date);

                return {
                  fill: verData ? PRIMARY_COLOR : GREY_COLOR,
                  fontWeight: verData ? 600 : 400,
                };
              },
              formatter(text) {
                const verData = versionData?.find((version) => version.date === text);
                return `${verData ? CALENDAR_TEXT_ICON : ''}${text}`;
              },
              autoRotate: true,
            },
            range: [0, 1],
          }}
          height={330}
        />
      );
    }
  }, [data, category, versionData]);

  return (
    <section className="mb-10">
      <h6 className="mb-3 text-18 font-semibold">{title}</h6>
      {chart}
    </section>
  );
};

export default ExploreChart;
