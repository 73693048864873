import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { BsFillPersonPlusFill, BsPersonFill } from 'react-icons/bs';
import { FaMicrophoneAlt } from 'react-icons/fa';
import { IoIosChatboxes } from 'react-icons/io';
import { IoFootsteps, IoGameController, IoHeart } from 'react-icons/io5';
import { MdAccessTimeFilled, MdMarkUnreadChatAlt } from 'react-icons/md';
import { RiUserHeartLine, RiUserVoiceFill } from 'react-icons/ri';
import { Col, Row } from 'antd';
import { groupBy, sumBy } from 'lodash';
import { TbPigMoney } from 'react-icons/tb';
import { appTimezoneConst, currHourToUnix } from '../../config/constants/general';
import { format } from 'date-fns';

import LayoutContextConsumer from 'contexts/LayoutContext';
import AuthConsumer from 'hooks/useAuth';
import useDocumentTitle from 'hooks/useDocumentTitle';
import AreaChart from '../../components/feature/charts/AreaChart';
import ColumnLineChart from '../../components/feature/charts/ColumnLineChart';
import LineChart from 'components/feature/charts/LineChart';
import ChartContainer from 'components/shared/ChartContainer';
import CountrySelect from 'components/shared/CountrySelect';
import CusSelect from 'components/shared/CusSelect';
import Skeleton from 'components/shared/Skeleton';
import Widget from 'components/shared/Widget';
import mimilandApi from 'config/api/bigquery/mimilandApi';
import sessionApi, { ISessionOverview } from 'config/api/bigquery/sessionApi';
import userRole from 'config/constants/userRole';
import dateHelper from 'config/helpers/dateHelper';
import clsx from 'clsx';
import get from 'lodash/get';
import dayjs from 'dayjs';

interface IDAU {
  period: string;
  DAU: number;
  tag: string;
}

interface INRU {
  period: string;
  NRU: number;
  tag: string;
}

interface IData {
  value: number;
  percent: number;
}

interface IStepSummary {
  period: string;
  userChat: IData;
  totalMoveDistance: IData;
  totalChatTimes: IData;
  userVoice: IData;
  totalVoiceTimes: IData;
  totalVoiceDuration: IData;
}

interface ILove {
  period: string;
  totalLove: IData;
  totalUsers: IData;
}

interface IIAP {
  period: string;
  platform: string | null;
  country: string | null;
  amount: number;
  times: number;
}

const STEP_DISTANCE = 0.5;
const CCU_5_MINUTES = '5 Minutes';
const CCU_15_MINUTES = '15 Minutes';
const CCU_HOUR = '1 Hour';
const CCU_24_HOURS = '24 Hours';

function getOrderTag(tag: string): number {
  switch (tag) {
    case 'Today':
    case 'A. Today':
      return 1;
    case 'Yesterday':
    case 'A. Yesterday':
      return 2;
    case 'Last Week':
    case 'A. Last Week':
      return 3;
    default:
      return 4;
  }
}

const OverviewPage = () => {
  useDocumentTitle('Overview');
  const [selectedCountry, setSelectedCountry] = useState<string>('All');
  const { role } = AuthConsumer();
  const { currentApp, platform } = LayoutContextConsumer();

  const timezone = (currentApp && appTimezoneConst[currentApp.id]) ? appTimezoneConst[currentApp.id] : -7;

  const {
    data: overviewData,
    isLoading: isSessionLoading,
    isError: isSessionError,
  } = useQuery(
    ['overview', currentApp, platform, selectedCountry],
    async (): Promise<ISessionOverview> => {
      const response = await sessionApi.getOverview({
        params: {
          platform: platform && platform !== 'unified' ? platform : undefined,
          country: selectedCountry && selectedCountry !== 'All' ? selectedCountry : undefined,
        },
      });
      return response as unknown as ISessionOverview;
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const {
    data: stepSummaryData,
    isLoading: isStepSummaryLoading,
    isError: isStepSummaryError,
  } = useQuery(
    ['step-summary-overview', currentApp, platform, selectedCountry],
    async () => {
      const response = await mimilandApi.getMimilandOverview({
        params: {
          platform: platform && platform !== 'unified' ? platform : undefined,
        },
      });
      return response as unknown as IStepSummary;
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const {
    data: loveData,
    isLoading: isLoveLoading,
    isError: isLoveError,
  } = useQuery(
    ['love-overview', currentApp, platform, selectedCountry],
    async () => {
      const response = await mimilandApi.getMimilandLoveOverview({
        params: {
          platform: platform && platform !== 'unified' ? platform : undefined,
        },
      });
      return response as unknown as ILove;
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const additionalPercent = useMemo(() => {
    if (!overviewData || !stepSummaryData || !loveData) return null;

    const DAU = get(overviewData, 'DAU.value', 1);
    const prevDAU = (100 * get(overviewData, 'DAU.value', 0)) / (100 + get(overviewData, 'DAU.percent', 0));

    const userChat = get(stepSummaryData, 'userChat.value', 0);
    const userChatRate = (userChat / DAU) * 100;
    const prevUserChat =
      (100 * get(stepSummaryData, 'userChat.value', 0)) / (100 + get(stepSummaryData, 'userChat.percent', 0));
    const prevUserChatRate = (prevUserChat / prevDAU) * 100;

    const totalChatTimes = get(stepSummaryData, 'totalChatTimes.value', 0);
    const avgTotalChatTimes = totalChatTimes / DAU;
    const prevTotalChatTimes =
      (100 * get(stepSummaryData, 'totalChatTimes.value', 0)) /
      (100 + get(stepSummaryData, 'totalChatTimes.percent', 0));
    const prevAvgTotalChatTimes = prevTotalChatTimes / prevDAU;

    const totalLove = get(loveData, 'totalLove.value', 0);
    const avgTotalLove = totalLove / DAU;
    const prevTotalLove = (100 * get(loveData, 'totalLove.value', 0)) / (100 + get(loveData, 'totalLove.percent', 0));
    const prevAvgTotalLove = prevTotalLove / prevDAU;

    const userLove = get(loveData, 'totalUsers.value', 0);
    const userLoveRate = (userLove / DAU) * 100;
    const prevUserLove = (100 * get(loveData, 'totalUsers.value', 0)) / (100 + get(loveData, 'totalUsers.percent', 0));
    const prevUserLoveRate = (prevUserLove / prevDAU) * 100;

    const userVoice = get(stepSummaryData, 'userVoice.value', 0);
    const userVoiceRate = (userVoice / DAU) * 100;
    const prevUserVoice =
      (100 * get(stepSummaryData, 'userVoice.value', 0)) / (100 + get(stepSummaryData, 'userVoice.percent', 0));
    const prevUserVoiceRate = (prevUserVoice / prevDAU) * 100;

    const totalVoiceTimes = get(stepSummaryData, 'totalVoiceTimes.value', 0);
    const avgTotalVoiceTimes = totalVoiceTimes / DAU;
    const prevTotalVoiceTimes =
      (100 * get(stepSummaryData, 'totalVoiceTimes.value', 0)) /
      (100 + get(stepSummaryData, 'totalVoiceTimes.percent', 0));
    const prevAvgTotalVoiceTimes = prevTotalVoiceTimes / prevDAU;

    const totalVoiceDuration = get(stepSummaryData, 'totalVoiceDuration.value', 0);
    const avgTotalVoiceDuration = totalVoiceDuration / totalVoiceTimes;
    const prevTotalVoiceDuration =
      (100 * get(stepSummaryData, 'totalVoiceDuration.value', 0)) /
      (100 + get(stepSummaryData, 'totalVoiceDuration.percent', 0));
    const prevAvgTotalVoiceDuration = prevTotalVoiceDuration / prevTotalVoiceTimes;

    return {
      userChat: {
        value: userChatRate,
        percent: (userChatRate / prevUserChatRate) * 100 - 100,
      },

      totalChatTimes: {
        value: avgTotalChatTimes,
        percent: (avgTotalChatTimes / prevAvgTotalChatTimes) * 100 - 100,
      },

      totalLove: {
        value: avgTotalLove,
        percent: (avgTotalLove / prevAvgTotalLove) * 100 - 100,
      },

      userLove: {
        value: userLoveRate,
        percent: (userLoveRate / prevUserLoveRate) * 100 - 100,
      },

      userVoice: {
        value: userVoiceRate,
        percent: (userVoiceRate / prevUserVoiceRate) * 100 - 100,
      },

      totalVoiceTimes: {
        value: avgTotalVoiceTimes,
        percent: (avgTotalVoiceTimes / prevAvgTotalVoiceTimes) * 100 - 100,
      },

      totalVoiceDuration: {
        value: avgTotalVoiceDuration,
        percent: (avgTotalVoiceDuration / prevAvgTotalVoiceDuration) * 100 - 100,
      },
    };
  }, [stepSummaryData, loveData, overviewData]);

  return (
    <div>
      <section>
        <h3 className="page-section-title">Overview</h3>
        <div className="mt-2 flex md:mt-3 lg:mt-4">
          <CountrySelect selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry} />
        </div>
        <Row
          className="mt-6 mb-6 md:mt-9 md:mb-11"
          gutter={[
            { xs: 12, sm: 12, md: 30 },
            { xs: 12, sm: 12, md: 30 },
          ]}
        >
          <Col span={24} sm={12} lg={6}>
            <Widget
              label="DAU"
              number={get(overviewData, 'DAU.value', 0)}
              percent={get(overviewData, 'DAU.percent', 0)}
              icon={<BsPersonFill size={30} />}
              isLoading={isSessionLoading || isSessionError}
              description={`The number of unique users from ${dateHelper.formatText(
                new Date(get(overviewData, 'period') as string),
              )}. Compared to the same weekday 7 days ago.`}
            />
          </Col>
          <Col span={24} sm={12} lg={6}>
            <Widget
              label="New users"
              number={get(overviewData, 'newUsers.value', 0)}
              percent={get(overviewData, 'newUsers.percent', 0) || 0}
              icon={<BsFillPersonPlusFill size={30} />}
              isLoading={isSessionLoading || isSessionError}
              description={`The count of new users ${dateHelper.formatText(
                new Date(get(overviewData, 'period') as string),
              )}. Compared to the same weekday 7 days ago.`}
            />
          </Col>
          {overviewData?.bindingUsers && (
            <Col span={24} sm={12} lg={6}>
              <Widget
                label="Binding Account Users"
                number={get(overviewData, 'bindingUsers.value', 0)}
                percent={get(overviewData, 'bindingUsers.percent', 0) || 0}
                icon={<BsFillPersonPlusFill size={30} />}
                isLoading={isSessionLoading || isSessionError}
                description={`The count of binding account users ${dateHelper.formatText(
                  new Date(get(overviewData, 'period') as string),
                )}. Compared to the same weekday 7 days ago.`}
              />
            </Col>
          )}
          {role !== userRole.PARTNER && (
            <Col span={24} sm={12} lg={6}>
              <Widget
                label="Sessions"
                number={get(overviewData, 'sessions.value', 0)}
                percent={get(overviewData, 'sessions.percent', 0)}
                icon={<IoGameController size={30} />}
                isLoading={isSessionLoading || isSessionError}
                description={`The count of sessions played ${dateHelper.formatText(
                  new Date(get(overviewData, 'period') as string),
                )}. Compared to the same weekday 7 days ago.`}
              />
            </Col>
          )}
          {role !== userRole.PARTNER && (
            <Col span={24} sm={12} lg={6}>
              <Widget
                label="Playtime"
                number={get(overviewData, 'playtime.value', 0)}
                percent={get(overviewData, 'playtime.percent', 0)}
                icon={<MdAccessTimeFilled size={30} />}
                isLoading={isSessionLoading || isSessionError}
                isTime={true}
                description={`The average playtime per user ${dateHelper.formatText(
                  new Date(get(overviewData, 'period') as string),
                )}. Compared to the same weekday 7 days ago.`}
              />
            </Col>
          )}
        </Row>

        {currentApp?.id === 'Mimiland' && (
          <>
            <h3 className="page-section-title">Communication</h3>
            <Row
              className="mt-6 mb-6 md:mt-9 md:mb-11"
              gutter={[
                { xs: 12, sm: 12, md: 30 },
                { xs: 12, sm: 12, md: 30 },
              ]}
            >
              <Col span={24} sm={12} lg={6}>
                <Widget
                  label="User chat"
                  number={get(stepSummaryData, 'userChat.value', 0)}
                  percent={get(stepSummaryData, 'userChat.percent', 0)}
                  icon={<MdMarkUnreadChatAlt size={30} />}
                  isLoading={isStepSummaryLoading || isStepSummaryError}
                  description={`Users chat count ${dateHelper.formatText(
                    new Date(get(stepSummaryData, 'period') as string),
                  )}. Compared to the same weekday 7 days ago.`}
                  additional={
                    additionalPercent && (
                      <div className="text-grey text-xs text-right">
                        <p>Rate: {additionalPercent.userChat.value.toFixed(2) + '%'}</p>
                        <p className={clsx(additionalPercent.userChat.percent > 0 ? 'text-green' : 'text-primary')}>
                          {(additionalPercent.userChat.percent > 0 ? '+' : '') +
                            additionalPercent.userChat.percent.toFixed(2) +
                            '%'}
                        </p>
                      </div>
                    )
                  }
                />
              </Col>

              <Col span={24} sm={12} lg={6}>
                <Widget
                  label="Chat times"
                  number={get(stepSummaryData, 'totalChatTimes.value', 0)}
                  percent={get(stepSummaryData, 'totalChatTimes.percent', 0)}
                  icon={<IoIosChatboxes size={30} />}
                  isLoading={isStepSummaryLoading || isStepSummaryError}
                  description={`Chat times ${dateHelper.formatText(
                    new Date(get(stepSummaryData, 'period') as string),
                  )}. Compared to the same weekday 7 days ago.`}
                  additional={
                    additionalPercent && (
                      <div className="text-grey text-xs text-right">
                        <p>Avg: {additionalPercent.totalChatTimes.value.toFixed(2)}</p>
                        <p
                          className={clsx(additionalPercent.totalChatTimes.percent > 0 ? 'text-green' : 'text-primary')}
                        >
                          {(additionalPercent.totalChatTimes.percent > 0 ? '+' : '') +
                            additionalPercent.totalChatTimes.percent.toFixed(2) +
                            '%'}
                        </p>
                      </div>
                    )
                  }
                />
              </Col>

              <Col span={24} sm={12} lg={6}>
                <Widget
                  label="User voice"
                  number={get(stepSummaryData, 'userVoice.value', 0)}
                  percent={get(stepSummaryData, 'userVoice.percent', 0)}
                  icon={<RiUserVoiceFill size={30} />}
                  isLoading={isStepSummaryLoading || isStepSummaryError}
                  description={`Users voice count ${dateHelper.formatText(
                    new Date(get(stepSummaryData, 'period') as string),
                  )}. Compared to the same weekday 7 days ago.`}
                  additional={
                    additionalPercent && (
                      <div className="text-grey text-xs text-right">
                        <p>Rate: {additionalPercent.userVoice.value.toFixed(2) + '%'}</p>
                        <p className={clsx(additionalPercent.userVoice.percent > 0 ? 'text-green' : 'text-primary')}>
                          {(additionalPercent.userVoice.percent > 0 ? '+' : '') +
                            additionalPercent.userVoice.percent.toFixed(2) +
                            '%'}
                        </p>
                      </div>
                    )
                  }
                />
              </Col>

              <Col span={24} sm={12} lg={6}>
                <Widget
                  label="Voice times"
                  number={get(stepSummaryData, 'totalVoiceTimes.value', 0)}
                  percent={get(stepSummaryData, 'totalVoiceTimes.percent', 0)}
                  icon={<FaMicrophoneAlt size={30} />}
                  isLoading={isStepSummaryLoading || isStepSummaryError}
                  description={`Voice times ${dateHelper.formatText(
                    new Date(get(stepSummaryData, 'period') as string),
                  )}. Compared to the same weekday 7 days ago.`}
                  additional={
                    additionalPercent && (
                      <div className="text-grey text-xs text-right">
                        <p>Avg: {additionalPercent.totalVoiceTimes.value.toFixed(2)}</p>
                        <p
                          className={clsx(
                            additionalPercent.totalVoiceTimes.percent > 0 ? 'text-green' : 'text-primary',
                          )}
                        >
                          {(additionalPercent.totalVoiceTimes.percent > 0 ? '+' : '') +
                            additionalPercent.totalVoiceTimes.percent.toFixed(2) +
                            '%'}
                        </p>
                      </div>
                    )
                  }
                />
              </Col>

              <Col span={24} sm={12} lg={6}>
                <Widget
                  label="Voice duration"
                  number={get(stepSummaryData, 'totalVoiceDuration.value', 0)}
                  percent={get(stepSummaryData, 'totalVoiceDuration.percent', 0)}
                  icon={<FaMicrophoneAlt size={30} />}
                  isLoading={isStepSummaryLoading || isStepSummaryError}
                  description={`Voice duration ${dateHelper.formatText(
                    new Date(get(stepSummaryData, 'period') as string),
                  )}. Compared to the same weekday 7 days ago.`}
                  additional={
                    additionalPercent && (
                      <div className="text-grey text-xs text-right">
                        <p>Avg: {additionalPercent.totalVoiceDuration.value.toFixed(2)}</p>
                        <p
                          className={clsx(
                            additionalPercent.totalVoiceDuration.percent > 0 ? 'text-green' : 'text-primary',
                          )}
                        >
                          {(additionalPercent.totalVoiceDuration.percent > 0 ? '+' : '') +
                            additionalPercent.totalVoiceDuration.percent.toFixed(2) +
                            '%'}
                        </p>
                      </div>
                    )
                  }
                  unit="s"
                />
              </Col>
            </Row>

            <h3 className="page-section-title">Engagement</h3>
            <Row
              className="mt-6 mb-6 md:mt-9 md:mb-11"
              gutter={[
                { xs: 12, sm: 12, md: 30 },
                { xs: 12, sm: 12, md: 30 },
              ]}
            >
              <Col span={24} sm={12} lg={6}>
                <Widget
                  label="User Love"
                  number={get(loveData, 'totalUsers.value', 0)}
                  percent={get(loveData, 'totalUsers.percent', 0)}
                  icon={<RiUserHeartLine size={30} />}
                  isLoading={isLoveLoading || isLoveError}
                  description={`Users ${dateHelper.formatText(
                    new Date(get(loveData, 'period') as string),
                  )}. Compared to the same weekday 7 days ago.`}
                  additional={
                    additionalPercent && (
                      <div className="text-grey text-xs text-right">
                        <p>Rate: {additionalPercent.userLove.value.toFixed(2) + '%'}</p>
                        <p className={clsx(additionalPercent.userLove.percent > 0 ? 'text-green' : 'text-primary')}>
                          {(additionalPercent.userLove.percent > 0 ? '+' : '') +
                            additionalPercent.userLove.percent.toFixed(2) +
                            '%'}
                        </p>
                      </div>
                    )
                  }
                />
              </Col>

              <Col span={24} sm={12} lg={6}>
                <Widget
                  label="Total Love"
                  number={get(loveData, 'totalLove.value', 0)}
                  percent={get(loveData, 'totalLove.percent', 0)}
                  icon={<IoHeart size={30} />}
                  isLoading={isLoveLoading || isLoveError}
                  description={`Love ${dateHelper.formatText(
                    new Date(get(loveData, 'period') as string),
                  )}. Compared to the same weekday 7 days ago.`}
                  additional={
                    additionalPercent && (
                      <div className="text-grey text-xs text-right">
                        <p>Avg: {additionalPercent.totalLove.value.toFixed(2)}</p>
                        <p className={clsx(additionalPercent.totalLove.percent > 0 ? 'text-green' : 'text-primary')}>
                          {(additionalPercent.totalLove.percent > 0 ? '+' : '') +
                            additionalPercent.totalLove.percent.toFixed(2) +
                            '%'}
                        </p>
                      </div>
                    )
                  }
                />
              </Col>
            </Row>

            <h3 className="page-section-title">Explore</h3>
            <Row className="mt-6 mb-6 md:mt-9 md:mb-11">
              <Col span={24} sm={12} lg={6}>
                <Widget
                  label="Average steps"
                  number={
                    get(stepSummaryData, 'totalMoveDistance.value', 0) /
                    get(overviewData, 'DAU.value', 1) /
                    STEP_DISTANCE
                  }
                  percent={get(stepSummaryData, 'totalMoveDistance.percent', 0)}
                  icon={<IoFootsteps size={30} />}
                  isLoading={isStepSummaryLoading || isStepSummaryError}
                  description={`The average steps ${dateHelper.formatText(
                    new Date(get(stepSummaryData, 'period') as string),
                  )}. Compared to the same weekday 7 days ago.`}
                />
              </Col>
            </Row>
          </>
        )}
      </section>
      {currentApp?.id === 'DaymareZero'
        ? (<DAUOverviewV2 country={selectedCountry} timezone={timezone} />)
        : (<DAUOverviewV1 country={selectedCountry} timezone={timezone} />)
      }
      {currentApp?.id === 'DaymareZero'
        && (<NRUOverview country={selectedCountry} timezone={timezone} />)
      }
      <IAPOverview selectedCountry={selectedCountry} />
    </div>
  );
};

const DAUOverviewV2 = ({ country, timezone }: { country: string, timezone: number }) => {
  const { currentApp, platform } = LayoutContextConsumer();

  const {
    data: DAUData,
    isLoading: isDAULoading,
    isError: isDAUError,
  } = useQuery(
    ['DAU', currentApp, platform, country, '1-hour'],
    async () => {
      const response = await sessionApi.getDAUHourly({
        params: {
          platform: platform && platform !== 'unified' ? platform : undefined,
          country: country && country !== 'All' ? country : undefined,
          timestamp: currHourToUnix(timezone),
        },
      });
      return response as unknown as IDAU[];
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const {
    data: DAUData15Min,
    isLoading: isDAU15MinLoading,
    isError: isDAU15MinError,
  } = useQuery(
    ['DAU', currentApp, platform, country, '15-min'],
    async () => {
      const response = await sessionApi.getDAU5Min({
        params: {
          platform: platform && platform !== 'unified' ? platform : undefined,
          country: country && country !== 'All' ? country : undefined,
          timestamp: currHourToUnix(timezone),
        },
      });
      return response as unknown as IDAU[];
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const plainDAUData = useMemo(() => {
    return (DAUData ?? []).map((item) => {
      return {
        ...item,
        period: item.period,
      };
    }).sort((a, b) => getOrderTag(a.tag) - getOrderTag(b.tag));
  }, [DAUData]);

  const accumulatedDAUData = useMemo(() => {
    const plainData = (DAUData ?? []).map((item) => {
      return {
        ...item,
        period: item.period,
        tag: `A. ${item.tag}`,
      };
    }).sort((a, b) => getOrderTag(a.tag) - getOrderTag(b.tag));
    const now = dayjs().format('HH:00');
    for (let i = 1; i < plainData.length; i++) {
      if (plainData[i].tag !== 'A. Today' || plainData[i].period.localeCompare(now) < 0) {
        if (plainData[i].tag === plainData[i - 1].tag) {
          plainData[i].DAU += plainData[i - 1].DAU;
        }
      }
    }
    return plainData;
  }, [DAUData]);

  return (
    <section className="">
      <div className="flex justify-between items-center">
        <div className="">
          <h3 className="page-section-title">Active users</h3>
          <p className="">Period: 15 min - Range: Last 24 hours</p>
        </div>
      </div>
      {DAUData15Min && !isDAU15MinLoading && !isDAU15MinError ? (
        <ChartContainer>
          <div className={clsx('w-[78vw]')}>
            <AreaChart
              data={DAUData15Min}
              xField='period'
              yField='DAU'
            />
          </div>
        </ChartContainer>
      ) : (
        <div className="mt-4 overflow-hidden rounded-[10px]">
          <Skeleton height={100} />
        </div>
      )}
      <div className="flex justify-between items-center mt-2">
        <p className="">Period: 1 hour - Range: Today</p>
      </div>
      {DAUData && !isDAULoading && !isDAUError ? (
        <ChartContainer>
          <div className={clsx('w-[78vw]')}>
            <ColumnLineChart
              dataColumn={plainDAUData}
              dataLine={accumulatedDAUData}
              xField='period'
              yFieldColumn='DAU'
              yFieldLine='DAU'
              colorFieldColumn='tag'
              colorFieldLine='tag'
            />
          </div>
        </ChartContainer>
      ) : (
        <div className="mt-4 overflow-hidden rounded-[10px]">
          <Skeleton height={100} />
        </div>
      )}
    </section>
  );
}

const DAUOverviewV1 = ({ country, timezone }: { country: string, timezone: number }) => {
  const { currentApp, platform } = LayoutContextConsumer();
  const [viewFormat, setViewFormat] = useState<'5 Minutes' | '1 Hour' | '24 Hours'>('5 Minutes');

  const {
    data: DAUData,
    isLoading: isDAULoading,
    isError: isDAUError,
  } = useQuery(
    ['DAU', currentApp, platform, country, viewFormat],
    async () => {
      switch (viewFormat) {
        case CCU_5_MINUTES: {
          const response = await sessionApi.getDAU5Min({
            params: {
              platform: platform && platform !== 'unified' ? platform : undefined,
              country: country && country !== 'All' ? country : undefined,
              timestamp: currHourToUnix(timezone),
            },
          });
          return response as unknown as IDAU[];
        }

        case CCU_HOUR: {
          const response = await sessionApi.getDAUHourly({
            params: {
              platform: platform && platform !== 'unified' ? platform : undefined,
              country: country && country !== 'All' ? country : undefined,
              timestamp: currHourToUnix(timezone),
            },
          });
          return response as unknown as IDAU[];
        }

        case CCU_24_HOURS: {
          const response = await sessionApi.getDAUDaily({
            params: {
              platform: platform && platform !== 'unified' ? platform : undefined,
              country: country && country !== 'All' ? country : undefined,
              timestamp: currHourToUnix(timezone),
            },
          });
          return response as unknown as IDAU[];
        }

        default: {
          return [];
        }
      }
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  return (
    <section className="">
      <div className="flex justify-between items-center">
        <div className="">
          <h3 className="page-section-title">Active users</h3>
          <p className="">Last 24 hours</p>
        </div>
        <CusSelect
          value={viewFormat}
          options={[
            {
              label: CCU_5_MINUTES,
              value: CCU_5_MINUTES,
            },
            {
              label: CCU_HOUR,
              value: CCU_HOUR,
            },
            {
              label: CCU_24_HOURS,
              value: CCU_24_HOURS,
            },
          ]}
          onChange={(value) => {
            setViewFormat(value);
          }}
          className="min-w-[120px]"
        />
      </div>
      {DAUData && !isDAULoading && !isDAUError ? (
        <ChartContainer>
          <div className={clsx(viewFormat === '5 Minutes' ? 'w-[540vw]' : 'w-auto')}>
            <LineChart
              data={DAUData?.map((item) => {
                return {
                  ...item,
                  period: item.period,
                };
              }).sort((a, b) => getOrderTag(a.tag) - getOrderTag(b.tag))}
              xField="period"
              yField="DAU"
              seriesField="tag"
              tooltip={{
                formatter: (datum) => {
                  return {
                    name: datum.tag,
                    value: datum.DAU,
                  };
                },
              }}
              point={{
                size: 0,
              }}
              lineStyle={({ tag }) => {
                if (tag !== 'Today') {
                  return {
                    opacity: 0.4,
                    lineDash: [10, 10],
                  };
                }

                return {
                  opacity: 1,
                  lineWidth: 3,
                };
              }}
              xAxis={{
                range: [0, 1],
                label: {
                  autoRotate: true,
                },
              }}
              legend={{
                position: 'bottom-left',
              }}
            />
          </div>
        </ChartContainer>
      ) : (
        <div className="mt-4 overflow-hidden rounded-[10px]">
          <Skeleton height={400} />
        </div>
      )}
    </section>
  );
}

const IAPOverview = ({ selectedCountry }: { selectedCountry: string }) => {
  const { currentApp, platform } = LayoutContextConsumer();
  const [viewFormat, setViewFormat] = useState<'5 Minutes' | '15 Minutes' | '1 Hour' | '24 Hours'>(
    (currentApp === null || currentApp.id === 'SkyDancer2') ? '5 Minutes' : '15 Minutes'
  );

  useEffect(() => {
    if (currentApp) {
      setViewFormat(currentApp.id === 'SkyDancer2' ? '5 Minutes' : '15 Minutes');
    }
  }, [currentApp])

  const { 
    data,
    isLoading,
    isError,
  } = useQuery(
    ['IAP', currentApp, platform, selectedCountry, viewFormat],
    async () => {
      if (currentApp === null || !['SkyDancer2', 'DaymareZero'].includes(currentApp.id)) {
        return [];
      }

      let allResponse: Record<string, IIAP[]>;
      const stepedPeriods = [] as string[];

      if (currentApp.id === 'SkyDancer2') {
        const api = viewFormat === CCU_5_MINUTES
          ? sessionApi.getIAP5Min
          : (viewFormat === CCU_HOUR ? sessionApi.getIAPHourly : sessionApi.getIAPDaily);

        // Delay 19 hours to get the data of the previous day
        const today = dayjs().subtract(14, "hours");
        // Time diff is 1 day for 5 min and 1 hour, 1 week for 24 hours
        const timeDiff = viewFormat === CCU_24_HOURS ? 7 : 1;

        const todayParams = {
          platform: platform && platform !== 'unified' ? platform : undefined,
          start: today.subtract(timeDiff + 1, 'day').format('YYYY-MM-DD HH:mm:ss'),
          end: today.format('YYYY-MM-DD HH:mm:ss'),
        };
        const yesterdayParams = {
          platform: platform && platform !== 'unified' ? platform : undefined,
          start: today.subtract(1 + timeDiff + 1, 'day').format('YYYY-MM-DD HH:mm:ss'),
          end: today.subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss'),
        };
        const lastWeekParams = {
          platform: platform && platform !== 'unified' ? platform : undefined,
          start: today.subtract(7 + timeDiff + 1, 'day').format('YYYY-MM-DD HH:mm:ss'),
          end: today.subtract(7, 'day').format('YYYY-MM-DD HH:mm:ss'),
        };

        const todayResponse = api({ params: todayParams });
        const yesterdayResponse = api({ params: yesterdayParams });
        const lastWeekResponse = api({ params: lastWeekParams });
        allResponse = {
          Today: ((await todayResponse) as unknown as IIAP[]).map((item) => ({ ...item, period: dayjs(item.period).format('YYYY-MM-DD HH:mm:ss') })),
          Yesterday: ((await yesterdayResponse) as unknown as IIAP[]).map((item) => ({ ...item, period: dayjs(item.period).add(1, 'day').format('YYYY-MM-DD HH:mm:ss') })),
          "Last Week": ((await lastWeekResponse) as unknown as IIAP[]).map((item) => ({ ...item, period: dayjs(item.period).add(7, 'day').format('YYYY-MM-DD HH:mm:ss') })),
        } as Record<string, IIAP[]>;

        // const todayPeriods = allResponse.Today.map((item) => item.period);
        const maxPeriod = dayjs(today.format('YYYY-MM-DD HH:00:00'))
          // Subtract to sync with DAU data
          .subtract(viewFormat === CCU_5_MINUTES ? 5 : (viewFormat === CCU_HOUR ? 60 : 0), 'minutes')
          .format('YYYY-MM-DD HH:mm:ss')
        const minPeriod = dayjs(maxPeriod).subtract(timeDiff, 'day').format('YYYY-MM-DD HH:mm:ss');
        const stepTime = (viewFormat === CCU_5_MINUTES) ? 5 : (viewFormat === CCU_HOUR ? 60 : 24 * 60);
        for (let i = dayjs(minPeriod).add(stepTime, 'minutes'); i.isBefore(dayjs(maxPeriod).add(stepTime, 'minutes')); i = i.add(stepTime, 'minutes')) {
          stepedPeriods.push(i.format(viewFormat === CCU_24_HOURS ? 'YYYY-MM-DD 00:00:00' : 'YYYY-MM-DD HH:mm:ss'))
        }
        //console.debug('stepedPeriods', stepedPeriods)

        const result = Object.keys(allResponse).map((key) => {
          const data = allResponse[key].filter((item) => item.country === selectedCountry || selectedCountry === 'All');
          const groupData = groupBy(data, 'period');
          //console.debug('groupData', groupData)

          const sumDataByPeriod = stepedPeriods.map((period) => {
            const items = groupData[period] || [];
            return {
              period,
              amount: sumBy(items, 'amount'),
              times: sumBy(items, 'times'),
            };
          });
          //console.debug('sumDataByPeriod', sumDataByPeriod)

          return sumDataByPeriod.map((item) => {
            return {
              ...item,
              tag: key,
            };
          })
        })
          .flat()
          .sort((a, b) => dayjs(a.period).unix() - dayjs(b.period).unix())
        //console.debug('result', result)
        return result;

      } else {
        const api = viewFormat === CCU_15_MINUTES
          ? sessionApi.getIAP15Min
          : (viewFormat === CCU_HOUR ? sessionApi.getIAPHourly : sessionApi.getIAPDaily);

        const chunk = viewFormat === CCU_15_MINUTES ? 900000 : (viewFormat === CCU_HOUR ? 3600000 : 86400000);
        const timeDiff = viewFormat === CCU_24_HOURS ? 7 : 1;
        const ms = Math.floor(Date.now() / chunk) * chunk;

        const todayParams = {
          platform: platform && platform !== 'unified' ? platform : undefined,
          country: selectedCountry !== 'All' ? selectedCountry : undefined,
          start: (ms - timeDiff * 86400000) * 1000,
          end: (ms + chunk) * 1000,
        };
        const yesterdayParams = {
          platform: platform && platform !== 'unified' ? platform : undefined,
          country: selectedCountry !== 'All' ? selectedCountry : undefined,
          start: (ms - (1 + timeDiff) * 86400000) * 1000,
          end: (ms - 86400000 + chunk) * 1000,
        };
        const lastWeekParams = {
          platform: platform && platform !== 'unified' ? platform : undefined,
          country: selectedCountry !== 'All' ? selectedCountry : undefined,
          start: (ms - (7 + timeDiff) * 86400000) * 1000,
          end: (ms - 7 * 86400000 + chunk) * 1000,
        };

        const todayResponse = api({ params: todayParams });
        const yesterdayResponse = api({ params: yesterdayParams });
        const lastWeekResponse = api({ params: lastWeekParams });
        allResponse = {
          Today: ((await todayResponse) as unknown as IIAP[]).map((item) => ({ ...item, period: dayjs(item.period).format('YYYY-MM-DD HH:mm:ss') })),
          Yesterday: ((await yesterdayResponse) as unknown as IIAP[]).map((item) => ({ ...item, period: dayjs(item.period).add(1, 'day').format('YYYY-MM-DD HH:mm:ss') })),
          "Last Week": ((await lastWeekResponse) as unknown as IIAP[]).map((item) => ({ ...item, period: dayjs(item.period).add(7, 'day').format('YYYY-MM-DD HH:mm:ss') })),
        } as Record<string, IIAP[]>;

        const maxPeriod = format(new Date(ms), 'yyyy-MM-dd HH:mm:ss');
        const minPeriod = format(new Date(ms - timeDiff * 86400000), 'yyyy-MM-dd HH:mm:ss');
        const stepTime = (viewFormat === CCU_15_MINUTES) ? 15 : (viewFormat === CCU_HOUR ? 60 : 24 * 60);
        for (let i = dayjs(minPeriod).add(stepTime, 'minutes'); i.isBefore(dayjs(maxPeriod).add(stepTime, 'minutes')); i = i.add(stepTime, 'minutes')) {
          stepedPeriods.push(i.format(viewFormat === CCU_24_HOURS ? 'YYYY-MM-DD 00:00:00' : 'YYYY-MM-DD HH:mm:ss'))
        }

        const result = Object.keys(allResponse).map((key) => {
          const groupData = groupBy(allResponse[key], 'period');
          const sumDataByPeriod = stepedPeriods.map((period) => {
            const items = groupData[period] || [];
            return {
              period,
              amount: sumBy(items, 'amount'),
              times: sumBy(items, 'times'),
            };
          });
          return sumDataByPeriod.map((item) => {
            return { ...item, tag: key };
          })
        }).flat().sort((a, b) => dayjs(a.period).unix() - dayjs(b.period).unix());
        return result;
      }
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  if (currentApp === null || !['SkyDancer2', 'DaymareZero'].includes(currentApp.id)) {
    return null;
  }

  const today1 = currentApp.id === 'SkyDancer2' ? dayjs().subtract(14, "hours") : dayjs();
  const todayRevenue = data
    ?.filter((item) => item.period.startsWith(today1.format('YYYY-MM-DD')) && item.tag === 'Today')
    ?.reduce((acc, item) => acc + item.amount, 0)

  return ((
    <section className="mt-8">
      <div className="flex justify-between items-center">
        <div className="">
          <h3 className="page-section-title">IAP Realtime Revenue</h3>
          <div className='my-8 w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8'>
            <Widget
              unit='$'
              icon={<TbPigMoney size={30} />}
              label='Today Revenue'
              number={todayRevenue ?? NaN}
              isLoading={isLoading || isError}
            />
          </div>
          <p className="">Last 24 hours</p>
        </div>

        <CusSelect
          value={viewFormat}
          options={[
            currentApp.id === 'SkyDancer2' ? {
              label: CCU_5_MINUTES,
              value: CCU_5_MINUTES,
            } : {
              label: CCU_15_MINUTES,
              value: CCU_15_MINUTES,
            },
            {
              label: CCU_HOUR,
              value: CCU_HOUR,
            },
            {
              label: CCU_24_HOURS,
              value: CCU_24_HOURS,
            },
          ]}
          onChange={(value) => {
            setViewFormat(value);
          }}
          className="min-w-[120px]"
        />
      </div>
      {data && !isLoading && !isError ? (
        <ChartContainer>
          <div className={clsx(data.length > 200 ? 'w-[540vw]' : 'w-auto')}>
            <LineChart
              data={data}
              xField="period"
              yField="amount"
              seriesField="tag"
              tooltip={{
                formatter: (datum) => {
                  return {
                    name: datum.tag,
                    value: datum.amount?.toFixed(2),
                  };
                },
              }}
              point={{
                size: 0,
              }}
              lineStyle={({ tag }) => {
                if (tag !== 'Today') {
                  return {
                    opacity: 0.4,
                    lineDash: [10, 10],
                  };
                }

                return {
                  opacity: 1,
                  lineWidth: 3,
                };
              }}
              xAxis={{
                range: [0, 1],
                label: {
                  autoRotate: true,
                  formatter: (text) => {
                    return dayjs(text).format(viewFormat === CCU_24_HOURS ? 'YYYY-MM-DD' : 'HH:mm');
                  }
                },
              }}
              legend={{
                position: 'bottom-left',
              }}
            />
          </div>
        </ChartContainer>
      ) : (
        <div className="mt-4 overflow-hidden rounded-[10px]">
          <Skeleton height={400} />
        </div>
      )}
    </section>
  ))
}

const NRUOverview = ({ country, timezone }: { country: string, timezone: number }) => {
  const { currentApp, platform } = LayoutContextConsumer();

  const {
    data: NRUData,
    isLoading: isNRULoading,
    isError: isNRUError,
  } = useQuery(
    ['NRU', currentApp, platform, country],
    async () => {
      const response = await sessionApi.getNRUHourly({
        params: {
          platform: platform && platform !== 'unified' ? platform : undefined,
          country: country && country !== 'All' ? country : undefined,
          timestamp: currHourToUnix(timezone),
        },
      });
      return response as unknown as INRU[];
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const plainNRUData = useMemo(() => {
    return (NRUData ?? []).map((item) => {
      return {
        ...item,
        period: item.period,
      };
    }).sort((a, b) => getOrderTag(a.tag) - getOrderTag(b.tag));
  }, [NRUData]);

  const accumulatedNRUData = useMemo(() => {
    const plainData = (NRUData ?? []).map((item) => {
      return {
        ...item,
        period: item.period,
        tag: `A. ${item.tag}`,
      };
    }).sort((a, b) => getOrderTag(a.tag) - getOrderTag(b.tag));
    const now = dayjs().format('HH:00:00');
    for (let i = 1; i < plainData.length; i++) {
      if (plainData[i].tag !== 'A. Today' || plainData[i].period.localeCompare(now) < 0) {
        if (plainData[i].tag === plainData[i - 1].tag) {
          plainData[i].NRU += plainData[i - 1].NRU;
        }
      }
    }
    return plainData;
  }, [NRUData]);

  return (
    <div>
      <section className="mt-8">
        <div className="flex justify-between items-center">
          <div className="">
            <h3 className="page-section-title">New users</h3>
            <p className="">Last 24 hours</p>
          </div>
        </div>
        {NRUData && !isNRULoading && !isNRUError ? (
          <ChartContainer>
            <div className={clsx('w-[78vw]')}>
              <ColumnLineChart
                dataColumn={plainNRUData}
                dataLine={accumulatedNRUData}
                xField='period'
                yFieldColumn='NRU'
                yFieldLine='NRU'
                colorFieldColumn='tag'
                colorFieldLine='tag'
              />
            </div>
          </ChartContainer>
        ) : (
          <div className="mt-4 overflow-hidden rounded-[10px]">
            <Skeleton height={400} />
          </div>
        )}
      </section>
    </div>
  );
}

export default OverviewPage;
