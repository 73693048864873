import React, { useState } from 'react';

import LayoutContextConsumer from 'contexts/LayoutContext';

import appHelper from 'config/helpers/appHelper';
import toastHelper from 'config/helpers/toastHelper';

import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';

interface IPopListAppProps {
  handleAppClick: (id: string) => void;
}

const { compareSearchKeyword } = appHelper;

const PopListApp = ({ handleAppClick }: IPopListAppProps) => {
  const { apps } = LayoutContextConsumer();
  const [search, setSearch] = useState(apps);

  const handleChange = (value: React.ChangeEvent<HTMLInputElement>) => {
    const keyword = value.target.value;
    const newSearch = apps.filter((item) => compareSearchKeyword(item.name, keyword));
    setSearch(newSearch);
  };

  return (
    <div className="w-[270px]">
      <div className="p-1">
        <Input prefix={<SearchOutlined />} onChange={handleChange} />
      </div>
      {search.map((item) => (
        <div
          className="flex cursor-pointer items-center gap-2 rounded-lg p-1 pr-2 text-text-heading hover:text-primary"
          onClick={() => {
            if (item.status) {
              handleAppClick(item.id);
            } else {
              toastHelper.info('Coming soon');
            }
          }}
          key={item.id}
        >
          {item?.icon ? (
            <img className="h-8 w-8 rounded-md" src={item.icon} alt={item.name} />
          ) : (
            <div className="h-8 min-h-[32px] w-8 min-w-[32px] rounded-md bg-grey2"></div>
          )}

          <span className="max-w-[240px] font-sans text-14 font-normal">{item.name}</span>
        </div>
      ))}
    </div>
  );
};

export default PopListApp;
