import React from 'react';

import AuthConsumer from 'hooks/useAuth';

import userRole from 'config/constants/userRole';

import Forbidden from 'pages/exception/Forbidden';

const withAdminPermission = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
  const Wrapper: React.FC<P> = (props) => {
    const { role } = AuthConsumer();

    const checkPermission = (): boolean => {
      if (role === userRole.SUPERADMIN || role === userRole.ADMIN) return false;

      return true;
    };

    return checkPermission() ? <Forbidden /> : <WrappedComponent {...props} />;
  };

  Wrapper.displayName = `withAdminPermission(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;

  return Wrapper;
};
export default withAdminPermission;
