import { useQuery } from '@tanstack/react-query';
import { BiTime, BiUser } from 'react-icons/bi';

import LayoutContextConsumer from 'contexts/LayoutContext';

import RawDataTable, { DateVersion } from 'components/feature/charts/RawDataTable';
import Loading from 'components/shared/Loading';

import mimilandApi from 'config/api/bigquery/mimilandApi';
import { IVersionHistory } from 'config/api/version-history/versionHistoryApi';
import numberHelper from 'config/helpers/numberHelper';

import { Empty, Tooltip } from 'antd';

interface Props {
  rangeDate: {
    start?: string;
    end?: string;
  };
  versionHistoryData?: IVersionHistory[];
  version: string | null;
}

const { getMimilandDonate } = mimilandApi;
const { toPercent } = numberHelper;

interface IDonate {
  time: string;
  daily_active_users: number;
  show_from_avatar_times: number;
  show_from_avatar_users: number;
  show_from_profile_times: number;
  show_from_profile_users: number;
  gift_times: number;
  gift_users: number;
  open_profile_times: number;
  open_profile_users: number;
}

const DataItem = ({ users, value, title, dau }: { users: number; value: string; title?: string; dau: number }) => {
  const rate = toPercent(users, dau);
  return (
    <Tooltip
      title={
        <span>
          <p>
            - {title}: {value} times
          </p>
          <p>
            - Users / DAU: {users}/{dau} ({rate})
          </p>
        </span>
      }
    >
      <div className="flex items-center gap-1">
        <BiTime className="text-12" />
        <p className="text-14">{value}</p>
      </div>
      <div className="flex items-center gap-1 whitespace-nowrap">
        <BiUser className="text-12" />
        <p className="text-14">
          {users} ({rate})
        </p>
      </div>
    </Tooltip>
  );
};

const TetEvent = ({ rangeDate, version, versionHistoryData }: Props) => {
  const { platform } = LayoutContextConsumer();

  const { data: donateData, isLoading: summaryLoading } = useQuery(
    ['mml-donate', rangeDate, version, platform],
    async () => {
      if (!rangeDate.start || !rangeDate.end) return [];

      const res = await getMimilandDonate({
        params: {
          platform: platform === 'unified' ? undefined : platform,
          version,
          start: rangeDate.start,
          end: rangeDate.end,
        },
      });

      return res as unknown as IDonate[];
    },
  );
  const isLoading = summaryLoading;

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex flex-col gap-10">
          {donateData && donateData.length > 0 ? (
            <RawDataTable
              title="Donate Summary"
              isCenterFull
              headers={['Date', 'DAU', 'Open Profile', 'Show from Avatar', 'Show from Profile', 'Gift']}
              data={
                donateData.map((item) => {
                  const verData = versionHistoryData?.find((ver) => ver.date === item.time);
                  return [
                    <DateVersion verData={verData} date={item.time} />,
                    item.daily_active_users.toFixed(0),
                    <DataItem
                      users={item.open_profile_users}
                      value={item.open_profile_times.toFixed(0)}
                      title="Open Profile"
                      dau={item.daily_active_users}
                    />,
                    <DataItem
                      users={item.show_from_avatar_users}
                      value={item.show_from_avatar_times.toFixed(0)}
                      title="Show from Avatar"
                      dau={item.daily_active_users}
                    />,
                    <DataItem
                      users={item.show_from_profile_users}
                      value={item.show_from_profile_times.toFixed(0)}
                      title="Show from Profile"
                      dau={item.daily_active_users}
                    />,
                    <DataItem
                      users={item.gift_users}
                      value={item.gift_times.toFixed(0)}
                      title="Gift"
                      dau={item.daily_active_users}
                    />,
                  ];
                }) ?? []
              }
              rawData={donateData}
            />
          ) : (
            <Empty />
          )}
        </div>
      )}
    </div>
  );
};

export default TetEvent;
