import { useMemo, useState } from 'react';

import ChartContainer from 'components/shared/ChartContainer';
import TagSelect from 'components/shared/TagSelect';

import LineChart from './LineChart';

import { Button, Form, Select } from 'antd';
import { useForm } from 'antd/es/form/Form';
import flatten from 'lodash/flatten';
import groupBy from 'lodash/groupBy';
import sumBy from 'lodash/sumBy';

interface Props {
  fields: string[];
  data: any[];
}

interface FormValues {
  xField: string;
  yFields: string[];
}

const DynamicLineChart = ({ fields, data }: Props) => {
  const [form] = useForm<FormValues>();

  const [xField, setXField] = useState<string>();
  const [yFields, setYFields] = useState<string[]>([]);
  const [chartOptions, setChartOptions] = useState<FormValues>();

  const options = useMemo(() => {
    const items = fields
      .filter((item) => item !== xField && !yFields.includes(item))
      .map((item) => ({ label: item, value: item }));

    return items;
  }, [fields, xField, yFields]);

  const chartData = useMemo(() => {
    if (!chartOptions || !chartOptions.xField || !chartOptions.yFields) return [];

    // Group by xField and Sum data by the yFields
    const xFields = groupBy(data, chartOptions.xField);
    const result = Object.keys(xFields).map((key) => {
      const dataByYField: any[] = [];

      // Loop through each yField and sum the value
      for (let i = 0; i < chartOptions?.yFields.length; i++) {
        const yField = chartOptions?.yFields[i];
        const sum = sumBy(xFields[key], yField);
        dataByYField.push({
          value: sum,
          category: yField,
          [chartOptions.xField]: key,
        });
      }

      return dataByYField;
    });
    // return the the array that have data of each YField[]
    return flatten(result);
  }, [chartOptions, data]);

  return (
    <div className="">
      <Form
        form={form}
        name="dynamic-line-chart"
        layout="inline"
        onFinish={(values) => {
          setChartOptions(values);
        }}
      >
        <Form.Item name="xField" label="xField">
          <Select
            options={options}
            onChange={(value) => {
              setXField(value);
            }}
            placeholder="Set xField"
            style={{
              minWidth: 120,
              width: 'fit-content',
            }}
          />
        </Form.Item>

        <Form.Item name="yFields" label="yField">
          <Select
            options={options.filter((item) => typeof data[0][item.value] === 'number')}
            onChange={(value) => {
              setYFields(value);
            }}
            placeholder="Set yField"
            style={{
              minWidth: 120,
              width: 'fit-content',
            }}
            mode="multiple"
            tagRender={TagSelect}
          />
        </Form.Item>
        <Form.Item>
          <Button
            size="small"
            style={{
              fontSize: 14,
              height: 30,
              padding: '0 10px',
              borderRadius: '6px',
              width: '100%',
              textAlign: 'left',
            }}
            type="primary"
            htmlType="submit"
          >
            Create Chart
          </Button>
        </Form.Item>
      </Form>

      {chartOptions && (
        <div className="mt-4">
          <ChartContainer>
            <LineChart xField={chartOptions.xField} yField="value" seriesField="category" data={chartData} />
          </ChartContainer>
        </div>
      )}
    </div>
  );
};

export default DynamicLineChart;
