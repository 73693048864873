import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { BiCoinStack, BiUser } from 'react-icons/bi';

import LayoutContextConsumer from 'contexts/LayoutContext';

import RawDataTable from 'components/feature/charts/RawDataTable';
import Loading from 'components/shared/Loading';

import daymareZeroApi from 'config/api/bigquery/daymareZeroApi';
import { IVersionHistory } from 'config/api/version-history/versionHistoryApi';
import numberHelper from 'config/helpers/numberHelper';

interface Props {
  rangeDate: {
    start?: string;
    end?: string;
  };
  versionHistoryData?: IVersionHistory[];
  version: string | null;
  country: string;
}

interface IResourceCoinIncome {
  time: string;
  platform: string;
  daily_users: number;
  iap: number;
  iap_users: number;
  gameplay: number;
  gameplay_users: number;
  end_world_chest: number;
  end_world_chest_users: number;
  rewards_1st_purchase: number;
  rewards_1st_purchase_users: number;
  coin_packs_in_shop: number;
  coin_packs_in_shop_users: number;
  gem_packs_in_shop: number;
  gem_packs_in_shop_users: number;
  boss: number;
  boss_users: number;
  limited_pack: number;
  limited_pack_users: number;
  event_beginner: number;
  event_beginner_users: number;
  event_star_light: number;
  event_star_light_users: number;
  newbie_pass: number;
  newbie_pass_users: number;
  wealth_pass: number;
  wealth_pass_users: number;
  adventure_pass: number;
  adventure_pass_users: number;
  elite_pass: number;
  elite_pass_users: number;
  undefined: number;
  undefined_users: number;
}

interface IResourceCoinExpend {
  time: string;
  platform: string;
  daily_users: number;
  talent: number;
  talent_users: number;
  hero: number;
  hero_users: number;
  equipment: number;
  equipment_users: number;
  undefined: number;
  undefined_users: number;
}

interface IResourceGemExpend {
  time: string;
  platform: string;
  daily_users: number;
  chest_hero: number;
  chest_hero_users: number;
  chest_equip: number;
  chest_equip_users: number;
  coin_packs_in_shop: number;
  coin_packs_in_shop_users: number;
  boss_ticket_packs_in_shop: number;
  boss_ticket_packs_in_shop_users: number;
  refill_energy: number;
  refill_energy_users: number;
  reset_hero: number;
  reset_hero_users: number;
  reset_equip: number;
  reset_equip_users: number;
  event_beginner_shop: number;
  event_beginner_shop_users: number;
  event_star_light_shop: number;
  event_star_light_shop_users: number;
  undefined: number;
  undefined_users: number;
}

interface IResourceGemIncome {
  time: string;
  platform: string;
  daily_users: number;
  iap: number;
  iap_users: number;
  user_level_up: number;
  user_level_up_users: number;
  coin_packs_in_shop: number;
  coin_packs_in_shop_users: number;
  gem_packs_in_shop: number;
  gem_packs_in_shop_users: number;
  level_pass: number;
  level_pass_users: number;
  newbie_pass: number;
  newbie_pass_users: number;
  treasure_pass: number;
  treasure_pass_users: number;
  elite_pass: number;
  elite_pass_users: number;
  limited_pack: number;
  limited_pack_users: number;
  event_beginner: number;
  event_beginner_users: number;
  event_star_light: number;
  event_star_light_users: number;
  undefined: number;
  undefined_users: number;
}

const { getResource } = daymareZeroApi;

const { convertText } = numberHelper;

const ResourceItem = ({ users, value }: { users: string; value: string }) => {
  return (
    <div className="">
      <div className="flex items-center gap-1">
        <BiCoinStack className="text-12" />
        <p className="text-14">{value}</p>
      </div>
      <div className="flex items-center gap-1 whitespace-nowrap">
        <BiUser className="text-12" />
        <p className="text-14">{users}</p>
      </div>
    </div>
  );
};



const DaymareResource = ({ rangeDate, versionHistoryData, version, country }: Props) => {
  const { platform } = LayoutContextConsumer();

  const { data: coinIncomeData, isLoading: coinIncomeLoading } = useQuery(
    ['resource-coin-income', version, rangeDate, country, platform],
    async () => {
      if (!rangeDate.start || !rangeDate.end) return [];

      const res = await getResource({
        params: {
          version,
          platform: platform === 'unified' ? undefined : platform,
          start: rangeDate.start,
          end: rangeDate.end,
          type: 'coin_income',
        },
      });

      return res as unknown as IResourceCoinIncome[];
    },
  );

  const { data: coinExpendData, isLoading: coinExpendLoading } = useQuery(
    ['resource-coin_expend', version, rangeDate, country, platform],
    async () => {
      if (!rangeDate.start || !rangeDate.end) return [];

      const res = await getResource({
        params: {
          version,
          platform: platform === 'unified' ? undefined : platform,
          start: rangeDate.start,
          end: rangeDate.end,
          type: 'coin_expend',
        },
      });

      return res as unknown as IResourceCoinExpend[];
    },
  );

  const { data: gemIncomeData, isLoading: gemIncomeLoading } = useQuery(
    ['resource-gem-income', version, rangeDate, country, platform],
    async () => {
      if (!rangeDate.start || !rangeDate.end) return [];

      const res = await getResource({
        params: {
          version,
          platform: platform === 'unified' ? undefined : platform,
          start: rangeDate.start,
          end: rangeDate.end,
          type: 'gem_income',
        },
      });

      return res as unknown as IResourceGemIncome[];
    },
  );

  const { data: gemExpendData, isLoading: gemExpendLoading } = useQuery(
    ['resource-gem-expend', version, rangeDate, country, platform],
    async () => {
      if (!rangeDate.start || !rangeDate.end) return [];

      const res = await getResource({
        params: {
          version,
          platform: platform === 'unified' ? undefined : platform,
          start: rangeDate.start,
          end: rangeDate.end,
          type: 'gem_expend',
        },
      });

      return res as unknown as IResourceGemExpend[];
    },
  );

  const coinIncomeTableData = useMemo(() => {
    return coinIncomeData?.map((item) => {
      return [
        {
          value: item.time,
          users: null,
        },
        {
          value: item.platform,
          users: null,
          usersRaw: null,
        },
        {
          value: item.iap,
          users: `${convertText(item.iap_users)} (${((item.iap_users / item.daily_users) * 100).toFixed(1)}%)`,
        },
        {
          value: item.gameplay,
          users: `${convertText(item.gameplay_users)} (${((item.gameplay_users / item.daily_users) * 100).toFixed(1)}%)`,
        },
        {
          value: item.end_world_chest,
          users: `${convertText(item.end_world_chest_users)} (${((item.end_world_chest_users / item.daily_users) * 100).toFixed(1)}%)`,
        },
        {
          value: item.rewards_1st_purchase,
          users: `${convertText(item.rewards_1st_purchase_users)} (${((item.rewards_1st_purchase_users / item.daily_users) * 100).toFixed(1)}%)`,
        },
        {
          value: item.coin_packs_in_shop,
          users: `${convertText(item.coin_packs_in_shop_users)} (${((item.coin_packs_in_shop_users / item.daily_users) * 100).toFixed(1)}%)`,
        },
        {
          value: item.boss,
          users: `${convertText(item.boss_users)} (${((item.boss_users / item.daily_users) * 100).toFixed(1)}%)`,
        },
        {
          value: item.limited_pack,
          users: `${convertText(item.limited_pack_users)} (${((item.limited_pack_users / item.daily_users) * 100).toFixed(1)}%)`,
        },
        {
          value: item.event_beginner,
          users: `${convertText(item.event_beginner_users)} (${((item.event_beginner_users / item.daily_users) * 100).toFixed(1)}%)`,
        },
        {
          value: item.event_star_light,
          users: `${convertText(item.event_star_light_users)} (${((item.event_star_light_users / item.daily_users) * 100).toFixed(1)}%)`,
        },
        {
          value: item.newbie_pass,
          users: `${convertText(item.newbie_pass_users)} (${((item.newbie_pass_users / item.daily_users) * 100).toFixed(1)}%)`,
        },
        {
          value: item.wealth_pass,
          users: `${convertText(item.wealth_pass_users)} (${((item.wealth_pass_users / item.daily_users) * 100).toFixed(1)}%)`,
        },
        {
          value: item.adventure_pass,
          users: `${convertText(item.adventure_pass_users)} (${((item.adventure_pass_users / item.daily_users) * 100).toFixed(1)}%)`,
        },
        {
          value: item.elite_pass,
          users: `${convertText(item.elite_pass_users)} (${((item.elite_pass_users / item.daily_users) * 100).toFixed(1)}%)`,
        },
        {
          value: item.undefined,
          users: `${convertText(item.undefined_users)} (${((item.undefined_users / item.daily_users) * 100).toFixed(1)}%)`,
        },
      ];
    });
  }, [coinIncomeData]);

  const coinExpendTableData = useMemo(() => {
    return coinExpendData?.map((item) => {
      return [
        {
          value: item.time,
          users: null,
        },
        {
          value: item.platform,
          users: null,
        },
        {
          value: item.talent,
          users: `${convertText(item.talent_users)} (${((item.talent_users / item.daily_users) * 100).toFixed(1)}%)`,
        },
        {
          value: item.hero,
          users: `${convertText(item.hero_users)} (${((item.hero_users / item.daily_users) * 100).toFixed(1)}%)`,
        },
        {
          value: item.equipment,
          users: `${convertText(item.equipment_users)} (${((item.equipment_users / item.daily_users) * 100).toFixed(1)}%)`,
        },
        {
          value: item.undefined,
          users: `${convertText(item.undefined_users)} (${((item.undefined_users / item.daily_users) * 100).toFixed(1)}%)`,
        },
      ];
    });
  }, [coinExpendData]);

  const gemIncomeTableData = useMemo(() => {
    return gemIncomeData?.map((item) => {
      return [
        {
          value: item.time,
          users: null,
        },
        {
          value: item.platform,
          users: null,
        },
        {
          value: item.iap,
          users: `${convertText(item.iap_users)} (${((item.iap_users / item.daily_users) * 100).toFixed(1)}%)`,
        },
        {
          value: item.user_level_up,
          users: `${convertText(item.user_level_up_users)} (${((item.user_level_up_users / item.daily_users) * 100).toFixed(1)}%)`,
        },
        {
          value: item.coin_packs_in_shop,
          users: `${convertText(item.coin_packs_in_shop_users)} (${((item.coin_packs_in_shop_users / item.daily_users) * 100).toFixed(1)}%)`,
        },
        {
          value: item.gem_packs_in_shop,
          users: `${convertText(item.gem_packs_in_shop_users)} (${((item.gem_packs_in_shop_users / item.daily_users) * 100).toFixed(1)}%)`,
        },
        {
          value: item.level_pass,
          users: `${convertText(item.level_pass_users)} (${((item.level_pass_users / item.daily_users) * 100).toFixed(1)}%)`,
        },
        {
          value: item.newbie_pass,
          users: `${convertText(item.newbie_pass_users)} (${((item.newbie_pass_users / item.daily_users) * 100).toFixed(1)}%)`,
        },
        {
          value: item.treasure_pass,
          users: `${convertText(item.treasure_pass_users)} (${((item.treasure_pass_users / item.daily_users) * 100).toFixed(1)}%)`,
        },
        {
          value: item.elite_pass,
          users: `${convertText(item.elite_pass_users)} (${((item.elite_pass_users / item.daily_users) * 100).toFixed(1)}%)`,
        },
        {
          value: item.limited_pack,
          users: `${convertText(item.limited_pack_users)} (${((item.limited_pack_users / item.daily_users) * 100).toFixed(1)}%)`,
        },
        {
          value: item.event_beginner,
          users: `${convertText(item.event_beginner_users)} (${((item.event_beginner_users / item.daily_users) * 100).toFixed(1)}%)`,
        },
        {
          value: item.event_star_light,
          users: `${convertText(item.event_star_light_users)} (${((item.event_star_light_users / item.daily_users) * 100).toFixed(1)}%)`,
        },
        {
          value: item.undefined,
          users: `${convertText(item.undefined_users)} (${((item.undefined_users / item.daily_users) * 100).toFixed(1)}%)`,
        },
      ];
    });
  }, [gemIncomeData]);

  const gemExpendTableData = useMemo(() => {
    return gemExpendData?.map((item) => {
      return [
        {
          value: item.time,
          users: null,
        },
        {
          value: item.platform,
          users: null,
        },
        {
          value: item.chest_hero,
          users: `${convertText(item.chest_hero_users)} (${((item.chest_hero_users / item.daily_users) * 100).toFixed(1)}%)`,
        },
        {
          value: item.chest_equip,
          users: `${convertText(item.chest_equip_users)} (${((item.chest_equip_users / item.daily_users) * 100).toFixed(1)}%)`,
        },
        {
          value: item.coin_packs_in_shop,
          users: `${convertText(item.coin_packs_in_shop_users)} (${((item.coin_packs_in_shop_users / item.daily_users) * 100).toFixed(1)}%)`,
        },
        {
          value: item.boss_ticket_packs_in_shop,
          users: `${convertText(item.boss_ticket_packs_in_shop_users)} (${((item.boss_ticket_packs_in_shop_users / item.daily_users) * 100).toFixed(1)}%)`,
        },
        {
          value: item.refill_energy,
          users: `${convertText(item.refill_energy_users)} (${((item.refill_energy_users / item.daily_users) * 100).toFixed(1)}%)`,
        },
        {
          value: item.reset_hero,
          users: `${convertText(item.reset_hero_users)} (${((item.reset_hero_users / item.daily_users) * 100).toFixed(1)}%)`,
        },
        {
          value: item.reset_equip,
          users: `${convertText(item.reset_equip_users)} (${((item.reset_equip_users / item.daily_users) * 100).toFixed(1)}%)`,
        },
        {
          value: item.event_beginner_shop,
          users: `${convertText(item.event_beginner_shop_users)} (${((item.event_beginner_shop_users / item.daily_users) * 100).toFixed(1)}%)`,
        },
        {
          value: item.event_star_light_shop,
          users: `${convertText(item.event_star_light_shop_users)} (${((item.event_star_light_shop_users / item.daily_users) * 100).toFixed(1)}%)`,
        },
        {
          value: item.undefined,
          users: `${convertText(item.undefined_users)} (${((item.undefined_users / item.daily_users) * 100).toFixed(1)}%)`,
        },
      ];
    });
  }, [gemExpendData]);

  const isLoading = coinIncomeLoading || coinExpendLoading || gemIncomeLoading || gemExpendLoading;

  return (
    <div className="w-full">
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex gap-8 flex-wrap">
          {coinIncomeTableData && coinIncomeTableData.length > 0 && (
            <RawDataTable
              isCenterFull
              title="Coin Income"
              headers={[
                'Time',
                'Platform',
                'IAP',
                'Gameplay',
                'Stage Chest',
                '1st IAP Rewards',
                'Coin Packs',
                'Boss',
                'Limited Packs',
                'Event Beginner',
                'Event Star Light',
                'Newbie Pass',
                'Wealth Pass',
                'Adventure Pass',
                'Elite Pass',
                'Undefined',
                ]}
              data={coinIncomeTableData.map((item) => {
                return item.map((subItem) => {
                  if (subItem.users) {
                    return <ResourceItem users={subItem.users} value={subItem.value as unknown as string} />;
                  } else {
                    return subItem.value;
                  }
                });
              })}
              rawData={coinIncomeData}
            />
            )}

          {coinExpendTableData && coinExpendTableData.length > 0 && (
            <RawDataTable
              title="Coin Expend"
              isCenterFull
                headers={[
                  'Time',
                  'Platform',
                  'Talent',
                  'Hero',
                  'Equipment',
                  'Undefined'
                ]}
              data={coinExpendTableData.map((item) => {
                return item.map((subItem) => {
                  if (subItem.users) {
                    return <ResourceItem users={subItem.users} value={subItem.value as unknown as string} />;
                  } else {
                    return subItem.value;
                  }
                });
              })}
              rawData={coinExpendData}
            />
          )}

          {gemIncomeTableData && gemIncomeTableData.length > 0 && (
            <RawDataTable
              isCenterFull
              title="Gem Income"
              headers={[
                'Time',
                'Platform',
                'IAP',
                'Level Up',
                'Coin Packs',
                'Gem Packs',
                'Level Pass',
                'Newbie Pass',
                'Treasure Pass',
                'Elite Pass',
                'Limited Packs',
                'Event Beginner',
                'Event Star Light',
                'Undefined',
              ]}
              data={gemIncomeTableData.map((item) => {
                return item.map((subItem) => {
                  if (subItem.users) {
                    return <ResourceItem users={subItem.users} value={subItem.value as unknown as string} />;
                  } else {
                    return subItem.value;
                  }
                });
              })}
              rawData={gemIncomeData}
            />
          )}

          {gemExpendTableData && gemExpendTableData.length > 0 && (
            <RawDataTable
              title="Gem Expend"
              isCenterFull
              headers={[
                'Time',
                'Platform',
                'Gacha Hero',
                'Gacha Equipment',
                'Buy Coin Packs',
                'Boss Ticket Packs',
                'Refill Energy',
                'Reset Hero',
                'Reset Equip',
                'Event Beginner',
                'Event Star Light',
                'Undefined',
              ]}
              data={gemExpendTableData.map((item) => {
                return item.map((subItem) => {
                  if (subItem.users) {
                    return <ResourceItem users={subItem.users} value={subItem.value as unknown as string} />;
                  } else {
                    return subItem.value;
                  }
                });
              })}
              rawData={gemExpendData}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default DaymareResource;
