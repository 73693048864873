import { useMemo } from 'react';

import { IDashboardRetentionCp, IDashboardRetentionData } from 'pages/dashboard/Dashboard';

import { lineChartColors } from 'components/feature/charts/LineChart';
import ChartContainer from 'components/shared/ChartContainer';

import { IVersionHistory } from 'config/api/version-history/versionHistoryApi';
import { CALENDAR_TEXT_ICON } from 'config/constants/general';
import { GREY_COLOR, PRIMARY_COLOR } from 'config/constants/theme';

import { DualAxes } from '@ant-design/charts';
import groupBy from 'lodash/groupBy';
import sum from 'lodash/sum';

interface IDashboardRetentionProps {
  data: IDashboardRetentionData[];
  compareData?: IDashboardRetentionCp[];
  versionData?: IVersionHistory[];
}

const DashboardRetention = ({ data, versionData, compareData }: IDashboardRetentionProps) => {
  const avgData = useMemo(() => {
    const grouped = groupBy(data, 'category');

    return Object.keys(grouped).map((key) => {
      /* Tạo ra các ngày có retention theo key vd: tìm các ngày có retention 7 */
      const listDate = grouped[key].map((item) => item.date);
      /* Lọc trong group của day0 ngày nào có trong list ngày thì tính tổng lại  */
      const total = sum(grouped[0].filter((item) => listDate.includes(item.date)).map((item) => item.users));
      /* tổng của retention theo key chia cho tổng của các ngày có retention key */
      return (sum(grouped[key].map((item) => item.users)) / total) * 100;
    });
  }, [data]);

  const compare = useMemo(() => {
    const baseData = data.filter((item) => item.category !== '0');
    const cpData = compareData?.filter((item) => item.category !== '0');

    const newCpData = [];

    if (!compareData || !cpData) {
      return [baseData];
    }

    const baseDate = Array.from(new Set(baseData.map((item) => item.date))).sort(
      (a, b) => new Date(a).getTime() - new Date(b).getTime(),
    );
    const cpDate = Array.from(new Set(cpData.map((item) => item.date))).sort(
      (a, b) => new Date(a).getTime() - new Date(b).getTime(),
    );

    for (let i = 0; i < baseDate.length && i < cpDate.length; i++) {
      const cp = cpData.filter((item) => item.date === cpDate[i]);

      if (cp.length > 0) {
        newCpData.push(...cp.map((item) => ({ ...item, date: baseDate[i], category: 'C-' + item.category })));
      }
    }

    return [baseData, newCpData];
  }, [data, compareData]);

  return (
    <ChartContainer>
      <DualAxes
        data={compare}
        xField="date"
        yField={['value', 'value']}
        tooltip={{
          formatter: (datum) => {
            return {
              name: datum.type === 'compare' ? 'C-' : '' + datum.category,
              value: parseFloat(datum.value).toFixed(2) + '%',
            };
          },
          title(title, datum) {
            const verData = versionData?.find((item) => item.date === datum.date);
            return verData ? `${verData.version} - ${verData.title} - ${verData.platform.toUpperCase()}` : title;
          },
        }}
        // yAxis={{
        //   label: {
        //     formatter: (value) => {
        //       return value + '%';
        //     },
        //   },
        // }}
        xAxis={{
          label: {
            style(_, index, items) {
              const date = items[index].id;
              const verData = versionData?.find((version) => version.date === date);

              return {
                fill: verData ? PRIMARY_COLOR : GREY_COLOR,
                fontWeight: verData ? 600 : 400,
              };
            },
            formatter(text) {
              const verData = versionData?.find((version) => version.date === text);
              return `${verData ? CALENDAR_TEXT_ICON : ''}${text}`;
            },
            autoRotate: true,
          },
          range: [0, 1],
        }}
        // legend={{
        //   position: 'bottom',
        //   marker: (_, index) => {
        //     return {
        //       symbol: 'circle',
        //       style: {
        //         fill: lineChartColors[index],
        //       },
        //     };
        //   },
        //   itemValue: {
        //     formatter: (value) => {
        //       if (value) {
        //         return `(${avgData[parseInt(value)] ? avgData[parseInt(value)].toFixed(2) : 0}%)`;
        //       } else {
        //         return value;
        //       }
        //     },
        //   },
        // }}
        legend={false}
        geometryOptions={[
          {
            geometry: 'line',
            seriesField: 'category',
            color: lineChartColors,
            lineStyle: {
              lineWidth: 2,
            },
            smooth: true,
          },
          {
            geometry: 'line',
            seriesField: 'category',
            color: lineChartColors,
            lineStyle: {
              lineWidth: 1,
              lineDash: [5, 5],
              opacity: 0.8,
            },
            point: {
              size: 0,
            },
            smooth: true,
            legend: false,
          },
        ]}
        yAxis={[
          {
            label: {
              formatter: (value) => {
                return value + '%';
              },
            },
          },
          {
            label: {
              formatter: (value) => {
                return '';
              },
            },
          },
        ]}
      />

      <div className="flex flex-wrap items-center gap-6 lg:gap-8 justify-center mt-5">
        {[1, 2, 3, 4, 5, 6, 7].map((item) => (
          <div className="flex gap-2 items-center">
            <div
              className="h-[14px] w-[14px] rounded-full"
              style={{
                backgroundColor: lineChartColors[item - 1],
              }}
            ></div>

            <span className="text-xs">
              {item} ({avgData[item] ? avgData[item].toFixed(2) : 0}%)
            </span>
          </div>
        ))}
      </div>
    </ChartContainer>
  );
};

export default DashboardRetention;
