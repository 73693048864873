import React from 'react';

import { Button } from 'antd';

interface IVersionHistoryFilterProps {
  setModalOpen: (value: boolean) => void;
}

const VersionHistoryFilter = ({ setModalOpen }: IVersionHistoryFilterProps) => {
  return (
    <div className="px-3 text-center">
      <Button
        type="primary"
        onClick={() => {
          setModalOpen(true);
        }}
      >
        Add New Version
      </Button>
    </div>
  );
};

export default VersionHistoryFilter;
