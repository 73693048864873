import { bgColor, filterData } from 'config/constants/monetization';

const sortByFilterList = (list: string[]) => {
  return list.sort((a, b) => {
    const aIndex = filterData.findIndex((item) => item.key === a);
    const bIndex = filterData.findIndex((item) => item.key === b);
    return aIndex - bIndex;
  });
};

const renderBgColor = (value: string) => {
  if (value === 'Date') return 'bg-secondary';
  const group = filterData.find((item) => item.label === value)?.group;
  return group ? bgColor[group] : '';
};

const monetizationHelper = {
  sortByFilterList,
  renderBgColor,
};

export default monetizationHelper;
