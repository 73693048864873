import React, { useState } from 'react';

import LayoutContextConsumer from 'contexts/LayoutContext';

import { IUser } from 'config/api/user/userApi';

import AppGridItem from 'components/feature/app/AppGridItem';
import PopListApp from 'components/shared/PopListApp';

import SettingPermissionForm from './UserSettingPermissionForm';

import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Popover, Row } from 'antd';
import get from 'lodash/get';

interface IUserSettingTabPane {
  userData: IUser;
  triggerRefresh?: () => void;
}

const UserSettingTabPane = ({ userData, triggerRefresh }: IUserSettingTabPane) => {
  const { apps } = LayoutContextConsumer();
  const [selectedApp, setSelectedApp] = useState<string | null>(null);
  const [edit, setEdit] = useState(false);

  const handleAppClick = (id: string) => {
    setSelectedApp(id);
    setEdit(true);
  };

  const handleCancel = () => {
    setEdit(false);
    setSelectedApp(null);
    if (triggerRefresh) return triggerRefresh();
  };

  if (edit && selectedApp) {
    return (
      userData && (
        <SettingPermissionForm data={userData} isGame={true} gameId={selectedApp} handleCancel={handleCancel} />
      )
    );
  }

  return (
    <div>
      <Popover
        placement="bottomLeft"
        showArrow={false}
        trigger={['click']}
        content={<PopListApp handleAppClick={handleAppClick} />}
      >
        <Button type="primary" icon={<PlusOutlined />}>
          Add App
        </Button>
      </Popover>
      <Row gutter={[32, 32]} className="mt-4">
        {apps
          .filter((game) => {
            return get(userData, `permission[${game.id}]`, false);
          })
          .map((app) => (
            <Col key={app.id} span={24} md={12} lg={8}>
              <AppGridItem handleSelectApp={handleAppClick} {...app} />
            </Col>
          ))}
      </Row>
    </div>
  );
};

export default UserSettingTabPane;
