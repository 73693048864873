import { useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import LayoutContextConsumer from 'contexts/LayoutContext';
import RawDataTable from 'components/feature/charts/RawDataTable';
import Loading from 'components/shared/Loading';
import Pager from '../../../shared/Pager';

import daymareZeroApi from 'config/api/bigquery/daymareZeroApi';
import { IVersionHistory } from 'config/api/version-history/versionHistoryApi';
import { appTimezoneConst, dateStrToUnix, formatTimezoneOffset, toExcelFileName, writeExcelFile } from '../../../../config/constants/general';

interface Props {
  rangeDate: {
    start?: string;
    end?: string;
  };
  versionHistoryData?: IVersionHistory[];
  version: string | null;
  country: string | null;
  userID: string | null;
  reloadTrigger: boolean;
  isFirstLoad: boolean;
}

interface IIAPSuccessRawData {
  time: string,
  platform: string,
  country: string,
  user_id: string,
  user_name: string,
  user_level: number,
  placement: string,
  package_id: string,
  currency: string,
  topup_amount: number,
  transaction_id: string,
}

interface IIAPSuccessRawData_Counter {
  total_items: number,
}

const { getSuccessIAP, countSuccessIAP } = daymareZeroApi;

const DaymareIAPSuccessRawData = ({ rangeDate, country, versionHistoryData, version, userID, reloadTrigger, isFirstLoad }: Props) => {
  const { currentApp, platform } = LayoutContextConsumer();
  const [successIAPPage, setSuccessIAPPage] = useState<number>(1);

  const { data: rawData, isLoading: rawDataLoading } = useQuery(
    ['iap-success', reloadTrigger, successIAPPage],
    async () => {
      if (isFirstLoad === true) {
        return [];
      }
      //let endUnix = dateStrToUnix(rangeDate.end, timezone);
      //if (endUnix) {
      //  endUnix += 86400000000;
      //}
      const res = await getSuccessIAP({
        params: {
          version,
          platform: platform === 'unified' ? undefined : platform,
          //startUnix: dateStrToUnix(rangeDate.start, timezone),
          //endUnix: endUnix,
          start: rangeDate.start,
          end: rangeDate.end,
          country: country !== 'All' ? country : undefined,
          timeOffset: formatTimezoneOffset(timezone),
          page: successIAPPage,
          pageSize: 10,
        },
        data: {
          userID: userID,
        }
      });
      return res as unknown as IIAPSuccessRawData[];
    }
  );

  const { data: rawDataCounter, isLoading: rawDataCounterLoading } = useQuery(
    ['iap-success-counter', reloadTrigger],
    async () => {
      if (isFirstLoad === true) {
        return [];
      }
      //let endUnix = dateStrToUnix(rangeDate.end, timezone);
      //if (endUnix) {
      //  endUnix += 86400000000;
      //}
      const res = await countSuccessIAP({
        params: {
          version,
          platform: platform === 'unified' ? undefined : platform,
          //startUnix: dateStrToUnix(rangeDate.start, timezone),
          //endUnix: endUnix,
          start: rangeDate.start,
          end: rangeDate.end,
          country: country !== 'All' ? country : undefined,
        },
        data: {
          userID: userID,
        }
      });
      return res as unknown as IIAPSuccessRawData_Counter[];
    }
  );

  const guiFormOfRawData = useMemo(() => {
    return rawData?.map((item) => {
      return [
        { value: item.time },
        { value: item.platform },
        { value: item.country },
        { value: item.user_id },
        { value: item.user_name },
        { value: item.user_level },
        { value: item.placement },
        { value: item.package_id },
        { value: item.currency },
        { value: item.topup_amount },
        { value: item.transaction_id },
      ];
    });
  }, [rawData]);

  const guiFormOfCounterData = useMemo(() => {
    if (rawDataCounter !== undefined && rawDataCounter.length > 0) {
      return rawDataCounter[0].total_items;
    }
    return 1;
  }, [rawDataCounter]);

  const onDownload = async () => {
    //let endUnix = dateStrToUnix(rangeDate.end, timezone);
    //if (endUnix) {
    //  endUnix += 86400000000;
    //}
    const res = await getSuccessIAP({
      params: {
        version,
        platform: platform === 'unified' ? undefined : platform,
        //startUnix: dateStrToUnix(rangeDate.start, timezone),
        //endUnix: endUnix,
        start: rangeDate.start,
        end: rangeDate.end,
        country: country !== 'All' ? country : undefined,
        timeOffset: formatTimezoneOffset(timezone),
      },
      data: {
        userID: userID,
      }
    });
    writeExcelFile(res, toExcelFileName('Success IAP'), 'json');
  };

  const isLoading = rawDataLoading || rawDataCounterLoading;
  const timezone = (currentApp && appTimezoneConst[currentApp.id]) ? appTimezoneConst[currentApp.id] : 0;

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex gap-0 flex-wrap">
          {
            guiFormOfRawData && guiFormOfRawData.length > 0 && (
              <RawDataTable
                isCenterFull
                title="Success IAP"
                headers={[
                  'Time',
                  'Platform',
                  'Country',
                  'User ID',
                  'User Name',
                  'User Level',
                  'Placement',
                  'Package ID',
                  'Topup Amount',
                  'Currency',
                  'Transaction ID',
                ]}
                data={guiFormOfRawData.map((item) => {
                  return item.map((subItem) => {
                    return subItem.value;
                  });
                })}
                onDownload={onDownload}
              />
            )
          }
          <Pager
            page={successIAPPage}
            totalPage={Math.floor((guiFormOfCounterData + 9) / 10)}
            setPage={setSuccessIAPPage}
          />
        </div>
      )}
    </div>
  );
};

export default DaymareIAPSuccessRawData;
