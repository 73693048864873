import { useEffect, useMemo, useState } from 'react';
import LayoutContextConsumer from 'contexts/LayoutContext';
import useDocumentTitle from 'hooks/useDocumentTitle';
import useIsomorphicLayoutEffect from 'hooks/useIsomorphicLayoutEffect';
import ComingSoonPage from 'pages/main/ComingSoonPage';
import CountrySelect from 'components/shared/CountrySelect';
import UserIDInput from 'components/shared/UserIDInput';
import TextInput from '../../components/shared/TextInput';
import DropdownSelect from '../../components/shared/DropdownSelect';
import DateRangeV2 from '../../components/shared/DateRangeV2';
import MonthRange from '../../components/shared/MonthRange';
import LtvPeriodPicker from '../../components/shared/LtvPeriodPicker';
import { reportsConst } from 'config/constants/customTracking';
import { FilterType, customTrackingExtraFilterConst } from 'config/constants/customTrackingExtraFilter';
import dateHelper from 'config/helpers/dateHelper';
import { Radio } from 'antd';
import { channels, dayLtvPeriodOptions, monthLtvPeriodOptions, platforms } from '../../config/constants/general';
import SingleDate from '../../components/shared/SingleDate';
import dayjs, { Dayjs } from 'dayjs';
import { IReportFilter } from '../../config/types/reports';

const { format, getDayBefore } = dateHelper;

const defaultRange = {
  start: format(getDayBefore(new Date(), 7)),
  end: format(getDayBefore(new Date(), 0)),
}

const ReportPage = () => {
  const { currentApp } = LayoutContextConsumer();
  useDocumentTitle('Reports');

  const listTabs = currentApp && reportsConst[currentApp.id] ? reportsConst[currentApp.id] : [];
  const initFilter: IReportFilter = {
    isFirstLoad: true,
    singleDate: null,
    rangeDate: {},
    rangeMonth: {},
    country: '',
    userID: '',
    userName: '',
    dayLtvPeriods: [],
    monthLtvPeriods: [],
  };

  const [tab, setTab] = useState<string>(listTabs[0] ? listTabs[0].id : '');
  const [rangeDate, setRangeDate] = useState<{ start?: string; end?: string; }>();
  const [rangeMonth, setRangeMonth] = useState<{ start?: string; end?: string; }>();
  const [singleDate, setSingleDate] = useState<Dayjs | null>(dayjs());
  const [country, setCountry] = useState<string>('All');
  const [countryF, setCountryF] = useState<string>('All');
  const [userID, setUserID] = useState<string>('');
  const [userName, setUserName] = useState<string>('');
  const [dayLtvPeriods, setDayLtvPeriods] = useState<number[]>([1, 3, 7, 14, 30]);
  const [monthLtvPeriods, setMonthLtvPeriods] = useState<number[]>([1, 2, 3, 6]);
  const [platformF, setPlatformF] = useState<string>(platforms[0].value);
  const [channel, setChannel] = useState<string>(channels[0].value);
  const [selectedFilter, setSelectedFilter] = useState<IReportFilter>(initFilter);

  const appExtraFilters = (currentApp && customTrackingExtraFilterConst[currentApp.id]) ? customTrackingExtraFilterConst[currentApp.id] : {};
  const tabExtraFilters = (appExtraFilters && appExtraFilters[tab]) ? appExtraFilters[tab] : [];

  const generateFilter = () => {
    return {
      isFirstLoad: false,
      singleDate: singleDate,
      rangeDate: rangeDate,
      rangeMonth: rangeMonth,
      country: country,
      userID: userID,
      userName: userName,
      dayLtvPeriods: dayLtvPeriods,
      monthLtvPeriods: monthLtvPeriods,
    };
  };

  useIsomorphicLayoutEffect(() => {
    setTab(listTabs && listTabs[0] ? listTabs[0].id : '');
  }, [listTabs]);

  useEffect(() => {
    if (tab) {
      const tabInfo = listTabs.find((item) => item.id === tab);
      if (tabInfo) {
        setSelectedFilter(initFilter);
        if (tabInfo.defaultRange) {
          setRangeDate(tabInfo.defaultRange);
        } else {
          setRangeDate(defaultRange);
        }
        if (tabInfo.defaultMonthRange) {
          setRangeMonth(tabInfo.defaultMonthRange);
        }
      }
    }
  }, [tab])


  const renderTab = useMemo(() => {
    if (tab) {
      const tabInfo = listTabs.find((item) => item.id === tab);
      if (tabInfo) {
        const TabComponent = tabInfo.component;
        if (tabInfo.id === 'CampaignReport') {
          return (<TabComponent platformF={platformF} country={countryF} channel={channel} />);
        } else {
          return (<TabComponent filter={selectedFilter} />);
        }
      }
    }
    return null;
  }, [tab, selectedFilter, platformF, countryF, channel]);

  if (!currentApp || listTabs.length === 0) {
    return <ComingSoonPage />;
  }

  return (
    <div>
      <Radio.Group
        value={tab}
        buttonStyle="solid"
        className="mt-4"
        onChange={(e: any) => {
          setTab(e.target.value);
        }}
      >
        {listTabs
          .filter((item) => item.type !== 'event')
          .map((item) => (
            <Radio.Button key={item.id} value={item.id}>
              {item.name}
            </Radio.Button>
          ))}
      </Radio.Group>

      <div className="my-4 flex flex-wrap gap-6">
      {
        (tabExtraFilters && tabExtraFilters.length > 0)
        ? tabExtraFilters.map((item) => {
          if (item.type === FilterType.Apply) {
            return (<button className="primary-button" onClick={() => {
              setSelectedFilter(generateFilter());
            }}>Apply</button>);
          }
          if (item.type === FilterType.Country) {
            return (<CountrySelect selectedCountry={country} setSelectedCountry={setCountry} />);
          }
          if (item.type === FilterType.UserID) {
            return (<UserIDInput value={userID as unknown as string} onValueChanged={setUserID} />);
          }
          if (item.type === FilterType.UserName) {
            return (<TextInput value={userName as unknown as string} onValueChanged={setUserName} />);
          }
          if (item.type === FilterType.DateRange1) {
            const title = item.title ?? 'Select Date';
            return (<DateRangeV2 rangeDate={rangeDate} setRangeDate={setRangeDate} title={title} />);
          }
          if (item.type === FilterType.DayLtvPeriod) {
            return (<LtvPeriodPicker values={dayLtvPeriods} onValuesChanged={setDayLtvPeriods} options={dayLtvPeriodOptions} />);
          }
          if (item.type === FilterType.MonthLtvPeriod) {
            return (<LtvPeriodPicker values={monthLtvPeriods} onValuesChanged={setMonthLtvPeriods} options={monthLtvPeriodOptions} />);
          }
          if (item.type === FilterType.MonthRange1) {
            const title = item.title ?? 'Select Month';
            return (<MonthRange rangeDate={rangeMonth} setRangeDate={setRangeMonth} title={title} />);
          }
          if (item.type === FilterType.PlatformF) {
            return (<DropdownSelect value={platformF} onValueChanged={setPlatformF} options={platforms} title='Platform' />);
          }
          if (item.type === FilterType.CountryF) {
            return (<CountrySelect selectedCountry={countryF} setSelectedCountry={setCountryF} />);
          }
          if (item.type === FilterType.Channel) {
            return (<DropdownSelect value={channel} onValueChanged={setChannel} options={channels} title='Channel' />);
          }
          if (item.type === FilterType.SingleDate1) {
            return (<SingleDate value={singleDate} onValueChanged={setSingleDate} />);
          }
          return (<></>);
        })
        : (<></>)
      }
      </div>
      {renderTab}
    </div>
    
  );
};

export default ReportPage;
