// import { useNavigate } from 'react-router-dom';
import LayoutContextConsumer from 'contexts/LayoutContext';

import useDocumentTitle from 'hooks/useDocumentTitle';

import ComingSoonPage from 'pages/main/ComingSoonPage';

import FunnelContent from 'components/feature/funnels/FunnelContent';

// import funnelImg from 'content/assets/images/funnels.png';
// import { CheckCircleOutlined, PlusOutlined } from '@ant-design/icons';
// import { Button, Col, Row } from 'antd';
import { funnelOpt } from 'config/constants/funnel';

const FunnelsPage = () => {
  useDocumentTitle('Funnels');
  // const navigate = useNavigate();
  const { currentApp } = LayoutContextConsumer();

  if (!currentApp?.id || !Object.keys(funnelOpt).includes(currentApp.id)) {
    return <ComingSoonPage />;
  }

  return (
    <div className="mb-10 w-full ">
      <section className="mb-5">
        <h3 className="page-section-title">Funnels</h3>
        <p className="page-section-subtitle">
          Understand player progression and conversion by defining a desired player path via multiple steps.
        </p>
        {/* <div className="relative min-h-[200px] w-full">
          <img
            src={funnelImg}
            alt=""
            className="h-auto min-h-[200px] w-full rounded-[20px] object-cover object-center"
          />
          <div className="absolute left-[64px] top-1/2 -translate-y-1/2">
            <h3 className="mb-3 text-26 font-semibold text-white">Let’s get started!</h3>
            <p className="mb-8 text-14 font-normal text-white">Set up a funnel and get results in seconds.</p>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                navigate('/funnels/create');
              }}
            >
              Create
            </Button>
          </div>
        </div> */}
      </section>

      <FunnelContent />
    </div>
  );
};

export default FunnelsPage;
