import { Checkbox } from 'antd';
import React from 'react';

interface ICusCheckbox {
  value: boolean;
  onValueChanged: (newValue: boolean) => void;
  title?: string;
}

const CusCheckbox: React.FC<ICusCheckbox> = ({ value, onValueChanged, title }) => {
  const id = title ? title.toLowerCase().replace(' ', '-') : 'checkbox';
  return (
    <div className="">
      <h6 className="mb-2 text-16 font-[500]"> </h6>
      <br/>
      <div className="flex" style={{ alignItems: 'center', maxWidth: '150px' }}>
        <Checkbox
          id={id}
          onChange={(e) => onValueChanged(e.target.checked)}
          checked={value}
        />
        <label htmlFor={id} className="ml-2">{title}</label>
      </div>
    </div>
  );
};

export default CusCheckbox;