import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import RawDataTable from 'components/feature/charts/RawDataTable';
import Loading from 'components/shared/Loading';

import daymareZeroApi from 'config/api/bigquery/daymareZeroApi';
import { IVersionHistory } from 'config/api/version-history/versionHistoryApi';
import LayoutContextConsumer from '../../../../contexts/LayoutContext';

interface Props {
  rangeDate: {
    start?: string;
    end?: string;
  };
  versionHistoryData?: IVersionHistory[];
  version: string | null;
  country: string;
  reloadTrigger: boolean;
  isFirstLoad: boolean;
}

interface ITopPU {
  user_id: string,
  user_name: string,
  amount: number,
}

const { getTopPU } = daymareZeroApi;

const DaymareTopPU = ({ rangeDate, versionHistoryData, country, reloadTrigger, isFirstLoad }: Props) => {
  const { platform } = LayoutContextConsumer();

  const { data: rawData, isLoading: rawDataLoading } = useQuery(
    ['top-pu', reloadTrigger],
    async () => {
      if (isFirstLoad === true) {
        return [];
      }
      const res = await getTopPU({
        params: {
          start: rangeDate?.start,
          end: rangeDate?.end,
          platform: platform === 'unified' ? undefined : platform,
          country: country !== 'All' ? country : undefined,
        },
      });
      return res as unknown as ITopPU[];
    }
  );

  const guiFormOfRawData = useMemo(() => {
    return rawData?.map((item) => {
      return [
        { value: item.user_id },
        { value: item.user_name },
        { value: item.amount },
      ];
    });
  }, [rawData]);

  const isLoading = rawDataLoading;
  
  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex gap-0 flex-wrap">
          {
            guiFormOfRawData && guiFormOfRawData.length > 0 && (
              <RawDataTable
                isCenterFull
                title="Top PU"
                headers={[
                  'User ID',
                  'User Name',
                  'Amount',
                ]}
                data={guiFormOfRawData.map((item) => {
                  return item.map((subItem) => {
                    return subItem.value;
                  });
                })}
              />
            )
          }
        </div>
      )}
    </div>
  );
};

export default DaymareTopPU;
