import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import { BiTime, BiUser } from 'react-icons/bi';

import LayoutContextConsumer from 'contexts/LayoutContext';

import RawDataTable from 'components/feature/charts/RawDataTable';
import Loading from 'components/shared/Loading';

import skyDancer2Api from 'config/api/bigquery/skyDancer2Api';
import { IVersionHistory } from 'config/api/version-history/versionHistoryApi';
import dateHelper from 'config/helpers/dateHelper';
import numberHelper from 'config/helpers/numberHelper';

const { convertText } = numberHelper;

const { format, getDayBefore } = dateHelper;

interface Props {
  rangeDate: {
    start?: string;
    end?: string;
  };
  setRangeDate: (value: { start?: string; end?: string }) => void;
  versionHistoryData?: IVersionHistory[];
  version: string | null;
}

interface IBoss {
  time: string;
  platform: string;
  DAU: number;
  gui_opened_times: number;
  gui_opened_users: number;
  enter_WR_times: number;
  enter_WR_users: number;
  quit_WR_times: number;
  quit_WR_users: number;
  start_fight_times: number;
  start_fight_users: number;
  win_times: number;
  win_users: number;
  timeout_times: number;
  timeout_users: number;
  quit_times: number;
  quit_users: number;
  avg_user_spend: number;
  avg_battle_duration: number;
  common_scale_drop: number;
  rare_scale_drop: number;
  epic_scale_drop: number;
  ads_reward_times: number;
  ads_reward_users: number;
  epic_scale_opened: number;
  rare_scale_opened: number;
  common_scale_opened: number;
}

const BossItem = ({ users, value }: { users: string; value: string }) => {
  return (
    <div className="">
      <div className="flex items-center gap-1">
        <BiTime className="text-12" />
        <p className="text-14">{value}</p>
      </div>
      <div className="flex items-center gap-1 whitespace-nowrap">
        <BiUser className="text-12" />
        <p className="text-14">{users}</p>
      </div>
    </div>
  );
};

const { getBoss } = skyDancer2Api;

const SkyBoss = ({ rangeDate, setRangeDate }: Props) => {
  const { platform } = LayoutContextConsumer();

  useEffect(() => {
    setRangeDate({
      start: format(getDayBefore(new Date(), 10)),
      end: format(getDayBefore(new Date(), 1)),
    });
  }, [setRangeDate]);

  const { data: packData, isLoading: packLoading } = useQuery(['sky-boss', rangeDate, platform], async () => {
    if (!rangeDate.start || !rangeDate.end) return [];

    const res = await getBoss({
      params: {
        platform: platform === 'unified' ? undefined : platform,
        start: rangeDate.start,
        end: rangeDate.end,
      },
    });

    return res as unknown as IBoss[];
  });

  const packTableData = useMemo(() => {
    return packData?.map((item) => {
      return [
        {
          value: item.time,
          users: null,
        },
        {
          value: item.platform,
          users: null,
        },
        {
          value: item.gui_opened_times,
          users: `${convertText(item.gui_opened_users)} (${((item.gui_opened_users / item.DAU) * 100).toFixed(1)}%)`,
        },
        {
          value: item.enter_WR_times,
          users: `${convertText(item.enter_WR_users)} (${((item.enter_WR_users / item.DAU) * 100).toFixed(1)}%)`,
        },
        {
          value: item.quit_WR_times,
          users: `${convertText(item.quit_WR_users)} (${((item.quit_WR_users / item.DAU) * 100).toFixed(1)}%)`,
        },
        {
          value: item.start_fight_times,
          users: `${convertText(item.start_fight_users)} (${((item.start_fight_users / item.DAU) * 100).toFixed(1)}%)`,
        },
        {
          value: item.win_times,
          users: `${convertText(item.win_users)} (${((item.win_users / item.DAU) * 100).toFixed(1)}%)`,
        },
        {
          value: item.timeout_times,
          users: `${convertText(item.timeout_users)} (${((item.timeout_users / item.DAU) * 100).toFixed(1)}%)`,
        },
        {
          value: item.quit_times,
          users: `${convertText(item.quit_users)} (${((item.quit_users / item.DAU) * 100).toFixed(1)}%)`,
        },
        {
          value: item.avg_user_spend.toFixed(2),
          users: null,
        },
        {
          value: item.avg_battle_duration.toFixed(2),
          users: null,
        },
        {
          value: item.common_scale_drop,
          users: null,
        },
        {
          value: item.rare_scale_drop,
          users: null,
        },
        // {
        //   value: item.epic_scale_drop,
        //   users: null,
        // },
        {
          value: item.ads_reward_times,
          users: `${convertText(item.ads_reward_users)} (${((item.ads_reward_users / item.DAU) * 100).toFixed(1)}%)`,
        },
        // {
        //   value: item.epic_scale_opened,
        //   users: null,
        // },
        {
          value: item.rare_scale_opened,
          users: null,
        },
        {
          value: item.common_scale_opened,
          users: null,
        },
      ];
    });
  }, [packData]);

  const isLoading = packLoading;

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex gap-8 flex-wrap">
          {packTableData && packTableData.length > 0 && (
            <RawDataTable
              isCenterFull
              title="Ads Steptimes"
              headers={[
                'Date',
                'Platform',
                'UI Opened',
                'Enter Waiting Room',
                'Quit Waiting Room',
                'Start Fight',
                'Win',
                'Timeout',
                'Quit',
                'Avg User Spend',
                'Avg Battle Duration',
                'Common Scale Drop',
                'Rare Scale Drop',
                // 'Epic Scale Drop',
                'Ads Reward',
                // 'Epic Scale Opened',
                'Rare Scale Opened',
                'Common Scale Opened',
              ]}
              data={packTableData.map((item) => {
                return item.map((subItem) => {
                  if (subItem.users) {
                    return <BossItem users={subItem.users} value={subItem.value as unknown as string} />;
                  } else {
                    return subItem.value;
                  }
                });
              })}
              rawData={packData}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default SkyBoss;
