/**
 * This is a custom React hook that returns a memoized event callback function that can be used to
 * handle events in a performant way.
 * @param fn - A function that takes in arguments of type Args and returns a value of type R. This
 * function is the event handler that we want to memoize and return as a callback function.
 * @returns The `useEventCallback` hook returns a memoized callback function that wraps the original
 * function passed as an argument. The returned callback function can be safely used in event handlers,
 * as it will always reference the latest version of the original function.
 */
import { useCallback, useRef } from 'react';

import useIsomorphicLayoutEffect from './useIsomorphicLayoutEffect';

export default function useEventCallback<Args extends unknown[], R>(fn: (...args: Args) => R) {
  const ref = useRef<typeof fn>(() => {
    throw new Error('Cannot call an event handler while rendering.');
  });

  useIsomorphicLayoutEffect(() => {
    ref.current = fn;
  }, [fn]);

  return useCallback((...args: Args) => ref.current(...args), [ref]);
}
