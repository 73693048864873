import toastHelper from 'config/helpers/toastHelper';

import api from '..';

import { AxiosRequestConfig } from 'axios';

const skyDancer2Api = {
  getDeathReason: (config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game && game === 'SkyDancer2') {
      return api.get(`/skydancer2/gameplay-result/death-reason`, config);
    } else {
      toastHelper.error('Please select a game first');
    }
  },
  getRoadIndex: (config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game && game === 'SkyDancer2') {
      return api.get(`/skydancer2/gameplay-result/road-index`, config);
    } else {
      toastHelper.error('Please select a game first');
    }
  },
  getLastActionFirstLaunch: (config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game && game === 'SkyDancer2') {
      return api.get(`/skydancer2/last-action-first-launch`, config);
    } else {
      toastHelper.error('Please select a game first');
    }
  },
  getLastActionUserLeft: (config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game && game === 'SkyDancer2') {
      return api.get(`/skydancer2/last-action-user-left`, config);
    } else {
      toastHelper.error('Please select a game first');
    }
  },
  getStageProgression: (config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game && game === 'SkyDancer2') {
      return api.get(`/skydancer2/stage-progression`, config);
    } else {
      toastHelper.error('Please select a game first');
    }
  },
  getIAPPack: (config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game && game === 'SkyDancer2') {
      return api.get(`/skydancer2/iap-pack`, config);
    } else {
      toastHelper.error('Please select a game first');
    }
  },
  getIAPEarning: (config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game && game === 'SkyDancer2') {
      return api.get(`/skydancer2/iap-earning/version`, config);
    } else {
      toastHelper.error('Please select a game first');
    }
  },
  getUserLeft: (config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game && game === 'SkyDancer2') {
      return api.get(`/skydancer2/user-left-24h`, config);
    } else {
      toastHelper.error('Please select a game first');
    }
  },
  getResource: (config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game && game === 'SkyDancer2') {
      return api.get(`/skydancer2/resource`, config);
    } else {
      toastHelper.error('Please select a game first');
    }
  },
  getResourceV2: (config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game && game === 'SkyDancer2') {
      return api.get(`/skydancer2/resourceV2`, config);
    } else {
      toastHelper.error('Please select a game first');
    }
  },
  getAdsSteptimes: (config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game && game === 'SkyDancer2') {
      return api.get(`/skydancer2/ads-steptimes`, config);
    } else {
      toastHelper.error('Please select a game first');
    }
  },
  getLeaderboard: (config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game && game === 'SkyDancer2') {
      return api.get(`/skydancer2/leaderboard`, config);
    } else {
      toastHelper.error('Please select a game first');
    }
  },
  getBoss: (config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game && game === 'SkyDancer2') {
      return api.get(`/skydancer2/boss`, config);
    } else {
      toastHelper.error('Please select a game first');
    }
  },
};

export default skyDancer2Api;
